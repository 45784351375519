import React, { Component } from "react";
import './__genericBtn.scss'

export class GenericButton extends Component {
    render() {
        const {
            className = "",
            label = "",
            onClick,
            id,
            disabled = false,
            viewButtonHeader = false,
            viewButtonColorHeader = false,
            primary = false,
            src,
            adjustIcon,
            needBtnPic = false,
            needNewBtnIcon = false,
            newSrc,
            adjustNewIcon,
            alignBtn = false,
        } = this.props;

        return (


            <button
                id={id}
                className={`generic-btn btn cursor-pointer d-flex justify-content-start align-items-center 
                ${className}`}
                onClick={onClick}
                disabled={disabled}
                type="button"
            >
                {needNewBtnIcon && (
                    <img src={newSrc} className={adjustNewIcon} alt="pic" />
                )}
                <span>{label}</span>

                {needBtnPic && <img className={adjustIcon} src={src} alt="pic" />}
            </button>


        );
    }
}
