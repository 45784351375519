import React from "react";

export const BreakDownTable = ({ TableData }) => {
	return (
		<div>
			<table>
				<thead>
					<tr>
						{TableData.theadData.map((h) => {
							return <td key={h}>{h}</td>;
						})}
					</tr>
				</thead>
				<tbody>
					{TableData.tbodyData.map((item) => {
						return (
							<tr>
								{item.items.map((data) => {
									return (
										<td key={data.id}>
											<div>{data}</div>
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};
