import React from "react";
import "./Style.scss";
const HeaderCloseNav = ({ pageName, title, isBottom = true }) => {
	
	return (
		<div className={`${isBottom && "is-bottom"} header_close_nav_container`}>
			<p className="pt-3">{pageName}</p>
			<h6>{title}</h6>
		</div>
	);
};

export default HeaderCloseNav;
