import React from "react";
import { Box, Typography, Icon, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";

function FooterAboutCard({ items }) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        display: "flex",
        flexDirection: "column",
        padding: "0 35px 35px 35px",
        gap: "24px",
        borderRadius: 20,
        boxShadow: "0px 4px 10px rgba(9, 30, 66, 0.06)",
        backgroundColor: "#FFFFFF",
      }}
    >
      <Box
        sx={{
          height: "fit-content",
        }}
      >
        {/* <Typography className="FooterAbtFetureTitleDescription">
          {t('footerAbout.paragraph')}
        </Typography> */}
      </Box>


      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexDirection: "column",
          backgroundColor: "#fff",
          marginTop: 1,
          padding: "5px",
        }}
        className="FooterAbtFeatureOuterBox"
      >
        {items.map((item) => (
          <div className="FooterAbtFeature mt-2 mb-2" key={item.cardIcon}>
            {item.cardIcon ? (
              <Icon style={{ width: "48px", height: "48px" }}>
                <img
                  className="FooterAbtFeatureImg"
                  src={item.cardIcon}
                  alt="about feature image"
                />
              </Icon>
            ) : (
              ""
            )}
            <div className="FooterAbtFetureInfo">
              <Typography className="FooterAbtFetureTitle">
                {item.cardTitle}
              </Typography>
              <Typography className="FooterAbtFetureSubtitile">
                {item.cardDiscription}
              </Typography>
            </div>
          </div>
        ))}
      </Box>
    </Box>
  );
}

export default FooterAboutCard;
