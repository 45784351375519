import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import CardComp from "../../common/HomeServiceCard/index";
import { NormalSearch } from "../../common/NormalSearch/index";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from "@material-ui/core";
import ArrowForward from "assets/svg/HomeServiceFroward1Arrow.svg";
import ArrowBack from "assets/svg/HomeServiceBackArrow.svg";
import { getI18n, useTranslation } from "react-i18next";
// import { history } from "service/helpers";
import { DetectOutsideClicks } from "../../../hooks";
import colors from "assets/scss/abstracts/colors.scss";
import { staticSearchData } from "component/common/MockData";
import { useHistory } from "react-router-dom"

const useStyles = makeStyles((theme) => ({
   dots: {
      bottom: -63,
      "& li.slick-active button::before": {
         color: colors["bright-purple"],
      },
      "& li": {
         width: "12px",
         "& button::before": {
            fontSize: theme.typography.pxToRem(9),
            color: "#4C565C",
         },
      },
   },
}));

export const HomeServices = (props) => {
   const { neededSearch = true } = props;
   const [navPill, setNavPill] = useState(0);
   const [navPillName, setNavPillName] = useState("All Services");
   const [tabselectedItemsLength, setTabselectedItemsLength] = useState(0);
   const history = useHistory()

   const classes = useStyles();

   const sliderRef = useRef(null);
   const [search, setSearch] = useState("");
   const [searchResults, setSearchResults] = useState([]);
   const [isSearchOpened, setIsSearchOpened] = useState(false);

   const handleActionClick = (url) => {
      setIsSearchOpened(false);
      setSearch("");
      history.push(url.replace('https://www.tawuniya.com', ''));
   };

   const settings = {
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: false,
      infinite: false, //tabselectedItemsLength > 4,
      dots: true,
      dotsClass: `slick-dots ${classes.dots}`,
      responsive: [
         {
            breakpoint: 1199,
            settings: {
               slidesToShow: 6,
               slidesToScroll: 2,
            },
         },
         {
            breakpoint: 800,
            settings: {
               slidesToShow: 3,
               slidesToScroll: 1,
               infinite: true,
               dots: true,
            },
         },
      ],
   };

   const { language } = useSelector((data) => data.languageReducer);
   const currentLanguage = getI18n().language;
   const { i18n, t } = useTranslation(); const searchArea = useRef(null);
   const isClickOutside = DetectOutsideClicks(searchArea);
   const [langSpecificResults, setLangSpecificResults] = useState([]);
   const [searchResultOpen, setSearchResultOpen] = useState(false);

   const handleInputChange = (e) => {
      setSearch(e.target.value);
      setSearchResultOpen(true);
   };

   useEffect(() => {
      setTabselectedItemsLength(props.HomeServicesData[0].Services[0].serviceCardData.length);
   }, []);

   useEffect(() => {
      if (language) {
         if (language === "en") {
            const lSpecData = staticSearchData.filter((item) => item.lang === "EN");
            setLangSpecificResults(lSpecData);
         } else if (language === "ar") {
            const lSpecData = staticSearchData.filter((item) => item.lang === "AR");
            setLangSpecificResults(lSpecData);
         }
      }
   }, [language]);

   useEffect(() => {
      if (!search) return setSearchResults([]);
      const fSearch = langSpecificResults.filter((item) =>
         item.keywords.split(",").find((itm) => itm.includes(search))
      );
      setSearchResults(fSearch);
   }, [search]);

   useEffect(() => {
      if (isSearchOpened) {
         isClickOutside && setIsSearchOpened(false);
      }
   }, [isClickOutside]);

   const trackEvent = (tabClicked) => {
      window.lmSMTObj.track(tabClicked);
   }

   return (
      <>
         {/* <div className="serviceContainer">
            {neededSearch ? (
               <>
                  {props.HomeServicesData[0].Search ? (
                     <div className="serviceContainerFlex">
                        <div className="serviceContainerText">
                           <h1 className="HomeServiceHeading1">{props.HomeServicesData[0].serviceTitle}</h1>
                        </div>
                     </div>
                  ) : (
                     <>
                        <h1 className="HomeServiceHeading1 text-center">
                           {props.HomeServicesData[0].serviceTitle}
                        </h1>

                        <p className="homeservicePar1 text-center">{props.HomeServicesData[0].serviceSubtitle}</p>
                     </>
                  )}

                  {props.isPillNeeded && (
                     <React.Fragment>
                        {props.HomeServicesData[0].Services ? (
                           <div className="d-flex justify-content-between">
                              <div className="ServiceNavCont">
                                 <div className="ServiceNavEl">
                                    {props.HomeServicesData[0].Services ? (
                                       props.HomeServicesData[0].Services.map((item, i) => (
                                          <p
                                             key={item.id + i}
                                             className={navPill === item.id ? "navEl-highlight" : "navEl"}
                                             onClick={() => {
                                                setNavPill(item.id);
                                                setTabselectedItemsLength(item.serviceCardData.length);
                                                trackEvent(item.pillName + "Clicked");
                                                setNavPillName(item.pillName)
                                             }}
                                          >
                                             {item.pillName}
                                          </p>
                                       ))
                                    ) : (
                                       <></>
                                    )}
                                 </div>
                              </div>
                              <div className="otherServiceNavCont" ref={searchArea}>
                                 <React.Fragment>
                                    <NormalSearch
                                       className="headerSearch"
                                       name="search"
                                       value={search}
                                       placeholder={t("navbar.searchPlaceholder")}
                                       onChange={handleInputChange}
                                       needRightIcon={true}
                                    />
                                    {searchResultOpen && search ? (
                                       <div className="searchResultBox">
                                          <p className="fs-12 fw-800 search-ResultTitle">{t("navbar.results")}</p>
                                          {search.length === 0 ? (
                                             <p className="fs-10 fw-400 search-ResultPara">{t("navbar.noResultFound")}</p>
                                          ) : (
                                             <React.Fragment>
                                                <p className="fs-10 fw-400 search-ResultPara">
                                                   {t("navbar.search.found")} {searchResults.length} {t("navbar.search.res_for")}
                                                   <span>“</span>
                                                   <span className="results_color">{search}</span>
                                                   <span>”</span>
                                                </p>
                                                {searchResults.map((item, idx) => {
                                                   return (
                                                      <div className="d-flex justify-content-between resultContainer" key={idx}>
                                                         <div className="py-2">
                                                            <p className="fs-10 fw-400 result_Name m-0">{item.category}</p>
                                                         </div>
                                                         <div
                                                            className="py-2 resultContainer_action"
                                                            onClick={() => { handleActionClick(item.webUrl); trackEvent(item.action + " for " + item.category + " Clicked") }}
                                                         >
                                                            <p className="fs-10 fw-400 result_Title m-0 cursor-pointer" style={{ color: "#6b47f5" }}>{item.action}</p>

                                                         </div>
                                                      </div>
                                                   );
                                                })}
                                             </React.Fragment>
                                          )}
                                       </div>
                                    ) : (
                                       ""
                                    )}
                                 </React.Fragment>
                              </div>
                           </div>
                        ) : (
                           <></>
                        )}
                     </React.Fragment>
                  )}
               </>
            ) : (
               <div className="nosearch_ourservices">
                  <h4>{t("our_exclusive_services")}</h4>
                  <p>{t("our_exclusive_services_desc")}</p>
               </div>
            )}

            <React.Fragment>
               {props.HomeServicesData.map((item, i) => {
                  return (
                     <div key={"services_" + i}>
                        {item?.Services?.map((items, index) => {

                           let finalComponent = null;
                           if (props.isPillNeeded && navPill === items.id) {
                              finalComponent = <Slider ref={sliderRef} {...settings}>
                                 {items?.serviceCardData?.map((val, i) => {
                                    return (
                                       <div className="slider-card-root" key={i}>
                                          {!val.hideCard && <CardComp item={val} pillName={navPillName} />}
                                       </div>
                                    );
                                 })}
                              </Slider>;
                           } else if (!props.isPillNeeded && items.id === 2) {
                              finalComponent = <Slider ref={sliderRef} {...settings} key={i}>
                                 {items?.serviceCardData?.map((val, i) => {
                                    return (
                                       <div className="slider-card-root" key={val + i}>
                                          {!val.hideCard && <CardComp item={val} pillName={navPillName} />}
                                       </div>
                                    );
                                 })}
                              </Slider>;
                           }
                           return (
                              <React.Fragment key={"nestedServices_" + index}>
                                 {finalComponent}
                              </React.Fragment>
                           );
                        })}
                     </div>
                  );
               })}
            </React.Fragment>

            <div className="arrowDotContainer">
               <div className="arrowContainer" onClick={() => sliderRef.current.slickPrev()}>
                  <img src={ArrowBack} alt="Arrow Back" />
               </div>
               <div className="dotContainer">

               </div>

               <div className="arrowContainer" onClick={() => sliderRef.current.slickNext()}>
                  <img src={ArrowForward} alt="Arrow Forward" />
               </div>
            </div>
         </div> */}
      </>
   );
};
