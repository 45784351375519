import React from "react";
import "./RecentMedical.scss";
import timer from "assets/svg/recentTimer.svg";
import hospitalicon from "assets/svg/hospitalicon.svg";
import DocumentIcon from "assets/svg/document-icon-grey.svg";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";
import teleMedIcon from "assets/images/teleMedicineIcon.svg";
import roadLogo from "assets/images/road-assistance-logo.svg";
import orange from "assets/svg/orangeArrow.svg";

export const RecentInteractionMedical = ({
	data,
	isMember = false,
	isMvpi = false,
	isRoadSide = false,
	greyBg = false,
}) => {
	const { i18n, t } = useTranslation();
	const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
	const isRTL = selectedLanguage === "ar" ? true : false;
	const timeNow = isMember
		? moment(data?.submissionDate).format("DD/MM/YYYY")
		: moment(data?.requestDate).format("DD/MM/YYYY");
	return (
		<div className={"recentPolicyContainerVAS" + (greyBg ? " grey-bg" : "")}>
			<div className="d-flex justify-content-between">
				<span className="fs-10 fw-400 timerText">
					{" "}
					<img src={timer} className="img-fluid pr-1" alt="timer" /> {timeNow}
				</span>
				<div
					className={
						data?.referenceNumber || data?.requestNumber
							? "status-with-file-name"
							: ""
					}
				>
					{(data?.referenceNumber || data?.requestNumber) && (
						<span className="file-name fs-10 fw-400 ">
							<img src={DocumentIcon} alt="DocumentIcon" />{" "}
							{isMvpi || isRoadSide
								? data?.requestNumber
								: data?.referenceNumber}
						</span>
					)}
					<p
						className={
							"fs-12 fw-400 rpc-progressLabel m-0 " +
							(data?.status === "Closed" ? "completed" : "")
						}
					>
						{data?.status}
					</p>
				</div>
			</div>
			<div className="rpc-sub-container">
				<div className="d-flex flex-row">
					<div className={`policyIconContainer ${isRTL ? "pl-2" : "pr-2"}`}>
						<img
							src={isMember ? teleMedIcon : roadLogo}
							className="img-fluid policyIcon"
							alt="isMember"
						/>
					</div>
					<div className={`${isRTL ? "mr-2" : ""}`}>
						<p className="fs-12 fw-400 policytitle m-0">
							{data?.s_ProviderName}
						</p>
						<p className="fs14 fw-500 rpc-userName m-0">
							{data?.memberName || data?.serviceName}
						</p>
						<p className="fs12 fw-400 rpc-userName m-0">
							{data?.subServiceName}
						</p>
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-between pt-3"></div>

			<div
				className="pt-2"
				onClick={() => window.location.assign("/dashboard/approvals")}
			>
				<span className="fs-14 fw-800 viewInteractionLink">
					{t("dashboardHome.ViewMore")}
				</span>{" "}
				<img
					src={orange}
					className={`img-fluid ${selectedLanguage === "en"
							? "orange-arrow-services"
							: "orange-arrow-services-rtl"
						}`}
					alt="orange"
				/>
			</div>
		</div>
	);
};
