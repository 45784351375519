import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import BottomPopup from "../../../component/common/MobileReuseable/BottomPopup";
import "./style.scss";
import { dialingCodes } from "component/common/MockData";
import VerifyMobile from "./VerifyMobile";
import { history } from "service/helpers";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import * as globalUtils from "../../../helpers/utils";
import * as LoginServices from "../../../service/login/loginservices";
import { Dropdown } from "primereact/dropdown";
import preloader from "assets/loader.gif";
import {
  setLoginResponse,
  setLoginInput,
  setRegisterResponse,
  setCustomerValidation,
  SetMobileNumberInput,
  setGccUserInfo,
} from "action/LoginResponse";
import { updateLoader } from "action/DashboardInformation";
import { Captcha } from "primereact/captcha";
import { ReactComponent as CreditCardIcon } from "assets/svg/credit-card.svg";
import { ReactComponent as Calender } from "assets/svg/menu/Vectorxalender.svg";
import { config as globalConfig } from "../../../helpers/config";
import { Button } from "primereact/button";
import ResetMobileNumber from "./resetNumber";
import SetNumber from "./setNumber";
import { TabView, TabPanel } from "primereact/tabview";
import {
  gccCustomerLogin,
  sendEmailOtp,
} from "../../../service/login/loginservices";
import emailIcon from "assets/images/email-icon.svg";
import VerifyEmailOtp from "./verifyEmailOtp";
import { TwuniyaAppAdvert } from 'component/common/TwuniyaAppAdvert';

const LoginPageMobile = ({ isOpenLoginModel, setIsOpenLoginModel }) => {
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");

  const [isVerifyOpen, setIsVerifyOpen] = useState(false);
  const [isResetOpen, setisResetOpen] = useState(false);
  const [isSetNumOpen, setisSetNumOpen] = useState(false);
  const [emailError, setEmailError] = useState("");

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const loader = useSelector(
    (state) => state.dashboardInformationReducer.loader
  );
  const [apiResponse, setapiResponse] = useState(false);
  const [error, seterror] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [isValidCaptcha, setIsValidCaptcha] = useState(false);
  const [gccCaptcha, setIsGccCaptcha] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [gccEmail, setGccEmail] = useState(false);
  const [isEmailVerifyOpen, setIsEmailVerifyOpen] = useState(false);
  const [emailChange, setEmailChange] = useState(false);

  const formik = useFormik({
    initialValues: {
      nationalId: "",
      yob: "",
      prefixMobile: "+966",
      mobile: "",
      eMail: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.nationalId) {
        errors.nationalId = t("login_footer.national_id");
      } else if (!globalUtils.isValidSaudiIDOrIQama(data.nationalId)) {
        errors.nationalId = t("login_footer.id_not_valid");
      }
      if (!data.yob) {
        errors.yob = t("login_footer.year_birth");
      } else if (
        (data.yob[0] === "1" && data.yob[1] !== "9") ||
        (data.yob[0] === "2" && data.yob[1] !== "0")
      ) {
        errors.yob = t("login_footer.year_birth");
      }

      if (!data.mobile) {
        errors.mobile = t("login_footer.mob_require");
      } else if (!globalUtils.isValidSaudiPhoneNumber(data.mobile)) {
        errors.mobile = t("login_footer.mob_notrequire");
      }

      return errors;
    },
    onSubmit: (data) => {
      if (isValidCaptcha) {
        dispatch(setLoginInput(data));
        dispatch(updateLoader(true));
        LoginServices.getcustomerValidation(data)
          .then((response) => {
            if (response.registrationFlag === "1") {
              if ("+966" + data.mobile == response?.mobile) {
                dispatch(setCustomerValidation(response));
                triggerLoginSendOtp(response);
              } else {
                dispatch(updateLoader(false));
                if (response?.mobile)
                  setMobileError(t("Telemedicine.PleaseCorrect"));
                else seterror(response.errorDescription);
              }
            } else if (response.registrationFlag === "2") {
              triggerRegistration(data, response);
            } else {
              dispatch(updateLoader(false));
              seterror(response.errorDescription);
              setapiResponse(true);
            }
          })
          .catch((error) => dispatch(updateLoader(false)));
      } else {
        seterror("Please Fill the Captcha");
        setapiResponse(true);
      }
    },
  });

  const gccFormik = useFormik({
    initialValues: {
      id: "",
      yob: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.id) {
        errors.id = t("login_footer.national_id");
      }
      if (!data.yob) {
        errors.yob = t("login_footer.year_birth");
      }
      return errors;
    },
    onSubmit: (data) => {
      if (gccCaptcha) {
        dispatch(updateLoader(true));
        let requestData = {
          idNumber: data.id,
          lang: selectedLanguage.slice(0, 1).toUpperCase(),
          yob: data.yob,
        };
        gccCustomerLogin(requestData)
          .then((response) => {
            if (response.status === "S") {
              let user_info = {
                user_info: response?.user_info,
              };
              dispatch(updateLoader(false));
              setGccEmail(true);
              dispatch(setGccUserInfo(response?.user_info));
              dispatch(setLoginResponse(user_info));
            } else {
              dispatch(updateLoader(false));
            }
          })
          .finally(() => {
            dispatch(updateLoader(false));
          });
      }
    },
  });

  const emailFormik = useFormik({
    initialValues: {
      email: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.email) {
        errors.email = "Email ID is required";
      }
      return errors;
    },
    onSubmit: (data) => {
      dispatch(updateLoader(true));
      let requestData = {
        emailID: data.email,
        channel: "Portal",
        languageCode: selectedLanguage.slice(0, 1).toUpperCase(),
        LOB: "GCCLogin",
      };
      sendEmailOtp(requestData)
        .then((response) => {
          if (response?.status === "1") {
            setIsEmailVerifyOpen(true);
            setGccEmail(false);
            setIsOpenLoginModel(false);
            setEmailError("");
          } else {
            setEmailError(response?.statusDescritpion);
          }
        })
        .catch(() => { })
        .finally(() => {
          dispatch(updateLoader(false));
        });
    },
  });

  useEffect(() => {
    setEmailError("");
  }, [emailFormik.values.email]);

  const triggerRegistration = (data, responseData) => {
    LoginServices.customerRegistration(data, responseData).then((response) => {
      if (response.errorCode === "S") {
        dispatch(setRegisterResponse(response));
        setIsVerifyOpen(true);
      } else if (response.errorCode === "V") {
        dispatch(updateLoader(false));
        seterror(response.errorDescription);
        setapiResponse(true);
      } else {
        dispatch(updateLoader(false));
        seterror(response.errorDescription);
        setapiResponse(true);
      }
    });
  };
  const triggerLoginSendOtp = (data) => {
    LoginServices.sendOTP(data).then((response) => {
      if (response.status === "1") {
        dispatch(updateLoader(false));
        setIsVerifyOpen(true);
      } else if (response.status === "2") {
        dispatch(updateLoader(false));
        seterror(response.statusDescritpion);
        setapiResponse(true);
      } else {
        dispatch(updateLoader(false));
        seterror(response.statusDescritpion);
        setapiResponse(true);
      }
    });
  };
  const getValueTemplate = (rowData) => {
    return (
      <div className="d-flex justify-content-between">
        <img src={rowData.flag} alt="flag" width="20" />
        <span>{rowData.label}</span>
      </div>
    );
  };
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };
  const isFormFieldValidGcc = (name) =>
    !!(gccFormik.touched[name] && gccFormik.errors[name]);
  const getFormErrorMessageGcc = (name) => {
    return (
      isFormFieldValidGcc(name) && (
        <small className="p-error">{gccFormik.errors[name]}</small>
      )
    );
  };

  const isFormFieldValidEmail = (name) =>
    !!(emailFormik.touched[name] && emailFormik.errors[name]);
  const getFormErrorMessageEmail = (name) => {
    return (
      isFormFieldValidEmail(name) && (
        <small className="p-error">{emailFormik.errors[name]}</small>
      )
    );
  };

  const isResetNumOpen = useSelector(
    (state) => state.loginDetailsReducer?.isResetOpen
  );

  useEffect(() => {
    if (selectedLanguage === "ar") {
      document
        .getElementsByTagName("body")[0]
        .classList.add("language-alignment-right");
      i18n.changeLanguage("ar");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("language-alignment-right");
      i18n.changeLanguage("en");
    }
  }, [selectedLanguage]);

  return (
    <div>
      <BottomPopup
        open={isOpenLoginModel}
        setOpen={setIsOpenLoginModel}
        setGccEmail={setGccEmail}
        setActiveIndex={setActiveIndex}
        gccFormik={gccFormik}
        setEmailChange={setEmailChange}
        emailFormik={emailFormik}
      >
        {loader && (
          <div className="loader-tawuniya">
            <img src={preloader} alt="preloader" />
          </div>
        )}
        {!gccEmail && (
          // <div className="login_page_mobile">
          //   <h5>{t("login_footer.join")}</h5>
          //   <TabView
          //     activeIndex={activeIndex}
          //     onTabChange={(e) => setActiveIndex(e.index)}
          //   >
          //     <TabPanel header={t("Citizen")}>
          //       <form
          //         onSubmit={formik.handleSubmit}
          //         className="p-fluid mt-4 formclass"
          //       >
          //         <div className="p-input-icon-left mt-4 field d-flex">
          //           <CreditCardIcon
          //             className={`${selectedLanguage === "ar" ? "card-rtl" : ""
          //               }`}
          //           />
          //           <InputText
          //             name="nationalId"
          //             value={formik.values.nationalId}
          //             maxLength={10}
          //             onChange={formik.handleChange}
          //             className={`${selectedLanguage === "ar" ? "input-rtl" : ""
          //               }`}
          //             placeholder={t("login_footer.id")}
          //           />
          //         </div>
          //         {getFormErrorMessage("nationalId")}
          //         <div className="p-input-icon-left mt-4 field d-flex">
          //           <Calender
          //             className={`${selectedLanguage === "ar" ? "card-rtl" : ""
          //               }`}
          //           />
          //           <InputText
          //             name="yob"
          //             value={formik.values.yob}
          //             className={`${selectedLanguage === "ar" ? "input-rtl" : ""
          //               }`}
          //             placeholder={t("login_footer.birth")}
          //             onChange={formik.handleChange}
          //             keyfilter="int"
          //             maxLength={4}
          //           />
          //         </div>
          //         {getFormErrorMessage("yob")}

          //         <div className="field mt-3 phone-input">
          //           <span className="p-input-icon-left d-flex">
          //             <Dropdown
          //               disabled
          //               value={formik.values.prefixMobile}
          //               valueTemplate={getValueTemplate}
          //               className="keys"
          //               onChange={formik.handleChange}
          //               options={globalConfig.phoneKeysList}
          //               optionLabel=""
          //               optionValue="value"
          //             />

          //             <InputText
          //               name="mobile"
          //               value={formik.values.mobile}
          //               onChange={formik.handleChange}
          //               className={classNames({
          //                 values: true,
          //                 "p-invalid": isFormFieldValid("mobile"),
          //               })}
          //               placeholder="ex: 5xxxxxxxx"
          //             />
          //           </span>
          //         </div>
          //         {getFormErrorMessage("mobile")}
          //         {getFormErrorMessage(
          //           "Technical Error. Please try again later"
          //         )}
          //         {mobileError.length > 0 && (
          //           <small className="p-error">{mobileError}</small>
          //         )}

          //         <div className="col-12 mt-3">
          //           {selectedLanguage === "en" && (
          //             <Captcha
          //               siteKey="6LepJyUhAAAAAIJRzeb3kqeRe0De3_lVYFvo7-PD"
          //               onResponse={(_token) => setIsValidCaptcha(true)}
          //               onExpire={() => setIsValidCaptcha(false)}
          //             />
          //           )}
          //           {selectedLanguage === "ar" && (
          //             <Captcha
          //               siteKey="6LepJyUhAAAAAIJRzeb3kqeRe0De3_lVYFvo7-PD"
          //               onResponse={(_token) => setIsValidCaptcha(true)}
          //               onExpire={() => setIsValidCaptcha(false)}
          //               language="ar"
          //             />
          //           )}
          //         </div>
          //         {apiResponse && <small>{error}</small>}
          //         <Button
          //           label={t("login_footer.conti")}
          //           className={"authContinueBtn font-Lato p-4 mt-4 "}
          //           onClick={formik.submitForm}
          //           type="button"
          //         />
          //       </form>
          //     </TabPanel>
          //     <TabPanel header={t("Visitor")}>
          //       <form
          //         onSubmit={gccFormik.handleSubmit}
          //         className="p-fluid mt-4 formclass"
          //       >
          //         <div className="p-input-icon-left mt-4 field d-flex">
          //           <CreditCardIcon
          //             className={`${selectedLanguage === "ar" ? "card-rtl" : ""
          //               }`}
          //           />
          //           <InputText
          //             name="id"
          //             value={gccFormik.values.id}
          //             maxLength={10}
          //             onChange={gccFormik.handleChange}
          //             className={`${selectedLanguage === "ar" ? "input-rtl" : ""
          //               }`}
          //             placeholder={t("GCC")}
          //           />
          //         </div>
          //         {getFormErrorMessageGcc("id")}
          //         <div className="p-input-icon-left mt-4 field d-flex">
          //           <Calender
          //             className={`${selectedLanguage === "ar" ? "card-rtl" : ""
          //               }`}
          //           />
          //           <InputText
          //             name="yob"
          //             value={gccFormik.values.yob}
          //             className={`${selectedLanguage === "ar" ? "input-rtl" : ""
          //               }`}
          //             placeholder={t("login_footer.birth")}
          //             onChange={gccFormik.handleChange}
          //             keyfilter="int"
          //             maxLength={4}
          //           />
          //         </div>
          //         {getFormErrorMessageGcc("yob")}
          //         <div className="col-12 mt-3">
          //           {selectedLanguage === "en" && (
          //             <Captcha
          //               siteKey="6LepJyUhAAAAAIJRzeb3kqeRe0De3_lVYFvo7-PD"
          //               onResponse={(_token) => setIsGccCaptcha(true)}
          //               onExpire={() => setIsGccCaptcha(false)}
          //             />
          //           )}
          //           {selectedLanguage === "ar" && (
          //             <Captcha
          //               siteKey="6LepJyUhAAAAAIJRzeb3kqeRe0De3_lVYFvo7-PD"
          //               onResponse={(_token) => setIsGccCaptcha(true)}
          //               onExpire={() => setIsGccCaptcha(false)}
          //               language="ar"
          //             />
          //           )}
          //         </div>
          //         {apiResponse && <small>{error}</small>}
          //         <Button
          //           label={t("login_footer.conti")}
          //           className={"authContinueBtn font-Lato p-4 mt-4 "}
          //           onClick={gccFormik.submitForm}
          //           type="button"
          //         />
          //       </form>
          //     </TabPanel>
          //   </TabView>
          // </div>
          <TwuniyaAppAdvert />
        )}
        {gccEmail && (
          <>
            <div className="gcc-email">
              <span className="gcc-email-header">
                {emailChange ? t("changeEmail") : t("welcomeToTawuniya")}
              </span>
              <span className="gcc-email-title">
                {emailChange ? t("verificationCode") : t("gladToHaveYou")}
              </span>
            </div>
            <div className="email-box">
              <div className="email-field mt-4 field d-flex">
                <img
                  src={emailIcon}
                  alt="email"
                  width={20}
                  height={18}
                  className={`${selectedLanguage === "ar" ? "ml-2" : "mr-2"
                    }`}
                />
                <InputText
                  name="email"
                  value={emailFormik.values.email}
                  onChange={emailFormik.handleChange}
                  className={`${selectedLanguage === "ar" ? "input-rtl" : ""
                    }`}
                  placeholder={"ex: email@tawuniya.com.sa"}
                />
              </div>
              {getFormErrorMessageEmail("email")}
              {emailError.length > 0 && (
                <small className="email-error">{emailError}</small>
              )}
              <div>
                <Button
                  label={t("login_footer.conti")}
                  className={"authContinueBtn font-Lato p-4 mt-4 "}
                  onClick={emailFormik.submitForm}
                  type="button"
                />
              </div>
            </div>
          </>
        )}
      </BottomPopup>
      <ResetMobileNumber
        isResetOpen={isResetOpen}
        setisResetOpen={setisResetOpen}
        setIsOpenLoginModel={setIsOpenLoginModel}
      />

      {isResetNumOpen && (
        <SetNumber
          isSetNumOpen={isSetNumOpen}
          setisSetNumOpen={setisSetNumOpen}
          setIsOpenLoginModel={setIsOpenLoginModel}
          setisResetOpen={setisResetOpen}
        />
      )}
      <VerifyMobile
        isVerifyOpen={isVerifyOpen}
        setIsVerifyOpen={setIsVerifyOpen}
        setIsOpenLoginModel={setIsOpenLoginModel}
      />
      <VerifyEmailOtp
        isEmailVerifyOpen={isEmailVerifyOpen}
        setIsEmailVerifyOpen={setIsEmailVerifyOpen}
        setIsOpenLoginModel={setIsOpenLoginModel}
        setGccEmail={setGccEmail}
        email={emailFormik.values.email}
        id={gccFormik.values.id}
        setEmailChange={setEmailChange}
        emailFormik={emailFormik}
      />
    </div>
  );
};

export default LoginPageMobile;
