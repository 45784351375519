import React, { useEffect } from "react";
import "../style.scss";
import { useTranslation } from "react-i18next";
import CommonIndividualsForm from "./CommonIndividualsForm/CommonIndividualsForm";
import { externalLinks } from "helpers/constant";

export const MedmalForm = () => {
    const btnPill = false;
    const { i18n, t } = useTranslation();

    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "view_item",
            ecommerce: {
                funnel_step: "step_0",
                step_name: "mmp_new_view_item",
                items: [{
                    item_name: "MMP New",
                    item_category: "PNC Insurance",
                    item_class: "Individual",
                    page_source: "internal_click"
                }]
            }
        });
    }, [])
    return (
        <div className="recentFeed-SubForm mt-3">
            <div className="row">
                <div className="col-12">
                    <CommonIndividualsForm
                        sme={btnPill}
                        medmal={true}
                        isRedirect={false}
                        application="Medmal"
                    //url={externalLinks.common + "medmal"}
                    />
                    <p className="fs-9 fw-400 recentFeedTerms pt-2 m-0">
                        {t("form_agreement")}
                    </p>
                </div>
            </div>
        </div>
    );
};
