import { languageTranslate } from "service/actionType";

const initialState = {
 languageArab: false,
 layoutPosition: {},
 language: "ar",
 toggleCanvas: false,
 toggleValue: null,
 toggleContent: {},
};
const languageReducer = (state = initialState, action) => {
 switch (action.type) {
  case languageTranslate.languageArab:
   return {
    ...state,
    languageArab: action.payload,
   };
  case languageTranslate.layoutPosition:
   return {
    ...state,
    layoutPosition: action.payload,
   };
  case languageTranslate.requestedService:
   return {
    ...state,
    requestedService: action.payload,
   };
  case languageTranslate.language:
   return {
    ...state,
    language: action.payload,
   };
  case languageTranslate.updateLayout:
   return {
    ...state,
    language: action.payload,
   };
  case languageTranslate.toggleCanvas:
   return {
    ...state,
    toggleCanvas: action.payload,
   };
  case languageTranslate.toogleValue:
   return {
    ...state,
    toggleValue: action.payload,
   };
  case languageTranslate.toggleContent:
   return {
    ...state,
    toggleContent: action.payload,
   };
  default:
   return state;
 }
};

export default languageReducer;
