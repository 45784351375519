import React, { useEffect, useState } from "react";
import { Navbar } from "../../component/common/Navbar";
import { NormalOffCanvas } from "../../component/common/NormalOffCanvas";
import { HomeBanner } from "../../component/HomePage/LandingComponent";
import {
  EmergencyCard,
  LanguageCard,
  SupportCard,
  GetStartedCard,
} from "../../component/HomePage/LandingComponent/NavbarCards";

import "./layoutStyle.scss";
import LoginPageMobile from "../../component/Auth/LoginPageMobile";
import { useDispatch, useSelector } from "react-redux";
import { arabTranslate, updateLanguage } from "../../action/LanguageAct";
import { useTranslation } from "react-i18next";
import phoneIcon from "../../assets/svg/phoneCircleIcon.svg";
import mailIcon from "../../assets/svg/menu/mailmessage.svg";
import "../app.css";
import chatIcon from "../../assets/svg/menu/Vectorchat.svg";
import isEmpty from "lodash/isEmpty";
import preloader from "../../assets/loader.gif";
import { AppBanner } from "../../component/MotorPage/MotorInsuranceComponent";
import ExtraHeaderDesktop from "../../component/common/ExtraHeader/ExtraHeaderDesktop";
import { history } from "../../service/helpers";
import { Helmet } from "react-helmet";
import PageNotFoundLayoutFooter from "./PageNotFoundLayoutFooter";
import HeaderStickyMenu from "component/common/MobileReuseable/HeaderStickyMenu";
import FooterMobile from "component/common/MobileReuseable/FooterMobile";


const PageNotFoundLayout = (props) => {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  useEffect(() => {
    // Clear session storage
    sessionStorage.clear();

    // Clear local storage
    localStorage.clear();
  }, []);

  const [emergencyCard, setEmergencyCard] = useState(false);
  const [getStartedCard, setGetStartedCard] = useState(false);
  const [isOpenLoginModel, setIsOpenLoginModel] = useState(false);
  const valueLang = useSelector((state) => state?.languageReducer?.language)
  const selectedLanguage = isEmpty(localStorage.getItem("newTawuniyaLanguageParam")) ? valueLang : localStorage.getItem("newTawuniyaLanguageParam");

  useEffect(() => {

    dispatch(updateLanguage(selectedLanguage));
  }, [selectedLanguage])
  const loginResponse = useSelector((state) => state?.loginDetailsReducer?.loginResponse);
  const loader = useSelector((state) => state.dashboardInformationReducer.loader);
  const userInfo = loginResponse?.user_info;
  const [languageCard, setLanguageCard] = useState(false);
  const [open, setOpen] = useState(false);
  const [supportCard, setSupportCard] = useState(false);
  const isOtpVerified = useSelector((state) => state.loginDetailsReducer?.isOtpVerified);

  const toggleLanguageCard = () => {
    setLanguageCard(!languageCard);
  };

  const toggleSupportCard = () => {
    setSupportCard(!supportCard);
  };

  const toggleEmergencyCard = () => {
    setEmergencyCard(!emergencyCard);
  };

  const toggleGetStartedCard = () => {
    setGetStartedCard(!getStartedCard);
  };

  const navHomeContent = [
    {
      id: 0,
      navText: t("navbar.individuals"),
      link: "/individuals",
    },
    {
      id: 1,
      navText: t("navbar.corporate"),
      link: "/corporate",
    },
    {
      id: 4,
      navText: t("navbar.Partners"),
      link: "https://jisr.waseel.com/signIn",
    },
    {
      id: 2,
      navText: t("navbar.investor"),
      link: "/investor",
    },
    {
      id: 3,
      navText: t("navbar.claims"),
    },

  ];

  const historyLinks = ["/", "/motorpage"];

  useEffect(() => {
    if (selectedLanguage === "ar") {
      document.getElementsByTagName("body")[0].classList.add("language-alignment-right");
      i18n.changeLanguage("ar");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("language-alignment-right");
      i18n.changeLanguage("en");
    }
  }, [selectedLanguage]);

  const closeToggler = () => {
    setToggle(false);
  };

  const handleToggler = () => {
    setToggle(true);
  };

  const handleProfileClick = () => {
    if (!isOtpVerified) {
      return setIsOpenLoginModel(true);
    } else {
      history.push("/dashboard/myprofile");
    }
  };

  const handleSupportClick = () => {
    history.push("/individuals/customer-service/support");
  };

  const handleHomeClick = () => {
    if (isOtpVerified) {
      history.push("/dashboard");
    } else {
      history.push("/");
    }
  };

  const handleClaimClick = () => {
    if (isEmpty(userInfo)) {
      history.push("/individuals/products/motor/claims");
    } else {
      history.push("/dashboard/all-claims");
    }
  };

  const pushPlaystore = () => {
    window.location.assign(
      "https://apps.apple.com/us/app/tawuniya-%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%88%D9%86%D9%8A%D8%A9/id573176914"
    );
  };

  const [swap, setSwap] = useState(null);

  const changeImg = [chatIcon, mailIcon, phoneIcon];

  const [transition, setTransition] = useState(0);

  useEffect(() => {
    setSwap(changeImg[transition]);
  }, [transition]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (transition + 1 === changeImg.length) {
        setTransition(0);
      } else {
        setTransition((item) => item + 1);
      }
    }, 3000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 300000);
    return () => clearTimeout(timer);
  });

  const translateHandler = () => {
    selectedLanguage === "ar" ? dispatch(arabTranslate(false)) : dispatch(arabTranslate(true));
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: selectedLanguage === "ar" ? "ar" : "en" }} />
      {window.innerWidth > 950 ? (
        <>
            <React.Fragment>
              {loader && (
                <div className="loader-tawuniya">
                  <img src={preloader} alt="preloader" />
                </div>
              )}
              <div
                className="vh-100"
                onClick={
                  supportCard
                    ? () => supportCard && setSupportCard(false)
                    : languageCard
                      ? () => languageCard && setLanguageCard(false)
                      : getStartedCard
                        ? () => getStartedCard && setGetStartedCard(false)
                        : emergencyCard
                          ? () => emergencyCard && setEmergencyCard(false)
                          : null
                }
              >
                {historyLinks.includes(history.location.pathname) && <HomeBanner />}

                {window.location.hash === "/individuals/products/motor" && <AppBanner />}

                <div className="row">
                  <div className="col-12">
                    <Navbar
                      handleToggler={handleToggler}
                      toggleLanguageCard={toggleLanguageCard}
                      toggleSupportCard={toggleSupportCard}
                      toggleEmergencyCard={toggleEmergencyCard}
                      toggleGetStartedCard={toggleGetStartedCard}
                      navContent={navHomeContent}
                    />

                    <ExtraHeaderDesktop />
                  </div>
                  <div className="col-12 content-container">
                    {languageCard ? <LanguageCard translateHandler={translateHandler} /> : null}
                    {supportCard ? <SupportCard /> : null}
                    {emergencyCard ? <EmergencyCard /> : null}
                    {getStartedCard ? <GetStartedCard /> : null}

                    {props.children}
                  </div>
                </div>
                <PageNotFoundLayoutFooter />
              </div>
            </React.Fragment>
        </>
      ) : (
        <>
        <div className="error-mobile-container">
          <div className="error-mobile-navbar">
            <HeaderStickyMenu />
          </div>
          <div className="error-mobile-content">
            {props.children}
          </div>
          <div className="error-mobile-footer">
          <FooterMobile />
          </div>
        </div>
        </>
      )}
    </>
  );
};

export default PageNotFoundLayout;


