import requestCar from "assets/svg/dashboardIcons/requsetVehicle.svg";
import requestLoaction from "assets/svg/dashboardIcons/requestLocation.svg";
import requestService from "assets/svg/dashboardIcons/serviceDashboard.svg";
import requestUser from "assets/svg/dashboardIcons/requestUser.svg";
import requestMobile from "assets/svg/dashboardIcons/requestPhone.svg";
import Emoji1 from "assets/tele-request/emoji1.svg";
import Emoji2 from "assets/tele-request/emoji2.svg";
import Emoji3 from "assets/tele-request/emoji3.svg";
import Emoji4 from "assets/tele-request/emoji4.svg";
import Emoji5 from "assets/tele-request/emoji5.svg";
import Number1 from "assets/tele-request/number1.svg";
import Number2 from "assets/tele-request/number2.svg";
import Number3 from "assets/tele-request/number3.svg";
import Number4 from "assets/tele-request/number4.svg";
import Number5 from "assets/tele-request/number5.svg";
import Number6 from "assets/tele-request/number6.svg";
import Number7 from "assets/tele-request/number7.svg";
import Number8 from "assets/tele-request/number8.svg";
import Number9 from "assets/tele-request/number9.svg";
import Number10 from "assets/tele-request/number10.svg";
import medicConsult from "assets/svg/dashboardIcons/medicConsult.svg";

export const carMaintainRequestHeaderData = {
	requestTitle: "Road Assistance",
	requestPara: "New Request",
	url: "/dashboard/service/car-maintainance",
};

export const roadAssistRequestIdData = {
	requestIdTitle: "Road Assistance",
	requestId: "Request #123456",
	detailTitleOne: "Vehicle",
	detailParaOne: "Merc. Benz",
	detailIconOne: requestCar,
	detailTitleTwo: "Service",
	detailParaTwo: "Tire",
	detailIconTwo: requestService,
	detailTitleThree: "Your Location",
	detailParaThree: "Alyasmin, Riyadh 13331",
	detailIconThree: requestLoaction,
};

export const periodicInspectionRequestIdData = {
	requestIdTitle: "Periodic Inspection",
	requestId: "Request #123456",
	detailTitleOne: "Vehicle",
	detailParaOne: "Merc. Benz",
	detailIconOne: requestCar,
	detailTitleTwo: "Your Location",
	detailParaTwo: "Alyasmin, Riyadh 13331",
	detailIconTwo: requestLoaction,
};

export const carMaintainRequestIdData = {
	requestIdTitle: "Car Assistance",
	requestId: "Request #123456",
	detailTitleOne: "Vehicle",
	detailParaOne: "Merc. Benz",
	detailIconOne: requestCar,
	detailTitleTwo: "Your Location",
	detailParaTwo: "Alyasmin, Riyadh 13331",
	detailIconTwo: requestLoaction,
};

export const teleMedicineRequestIdData = {
	requestIdTitle: "Telemedicine",
	requestId: "Request #123456",
	detailTitleOne: "Member",
	detailParaOne: "Prashant Dixit",
	detailIconOne: requestUser,
	detailTitleTwo: "Mobile Number",
	detailParaTwo: "966503195993",
	detailIconTwo: requestMobile,
	detailTitleFour: "Medical Consultation",
	detailParaFour:
		"Here is a sample text: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida eleifend nisl magnis quis eu, leo consequat pharetra aliquam. Tortor pharetra iaculis dictum amet non, nunc, non. Sit pretium amet nunc natoque at metus turpis nunc. Eget eget nulla justo, vel aliquam sagittis.",
	detailIconFour: medicConsult,
};

export const emojiFiData = [
	{
		id: 0,
		icon: Emoji1,
	},
	{
		id: 1,
		icon: Emoji2,
	},
	{
		id: 2,
		icon: Emoji3,
	},
	{
		id: 3,
		icon: Emoji4,
	},
	{
		id: 4,
		icon: Emoji5,
	},
];

export const ratingData = [
	{
		id: 0,
		icon: Number1,
	},
	{
		id: 1,
		icon: Number2,
	},
	{
		id: 2,
		icon: Number3,
	},
	{
		id: 3,
		icon: Number4,
	},
	{
		id: 4,
		icon: Number5,
	},
	{
		id: 5,
		icon: Number6,
	},
	{
		id: 6,
		icon: Number7,
	},
	{
		id: 7,
		icon: Number8,
	},
	{
		id: 8,
		icon: Number9,
	},
	{
		id: 9,
		icon: Number10,
	},
];

export const feedbackContentData = {
	formTitle:
		"We would really appreciate it if you would just take a moment to let us know about your experience.",
	contentData: [
		{
			id: 0,
			questionOne:
				"How satisfied were you with the effort it took to complete your request?",
			questionTwo:
				"How likely are you would recommend Tawuniya to a friend, relative, or colleague?",
			questionThree: "How may we make your experience better in the future?",
		},
	],
};

export const feedbackLink = [
	{
		id: 0,
		link: ["/dashboard/service/road-assistance/request-confirmation/1"],
	},
	{
		id: 1,
		link: ["/dashboard/service/periodic-inspection/request-confirmation/1"],
	},
	{
		id: 2,
		link: ["/dashboard/service/car-maintainance/request-confirmation/1"],
	},
	{
		id: 3,
		link: ["/dashboard/service/eligibility-letter/request-confirmation/1"],
	},
	{
		id: 4,
		link: ["/dashboard/service/tele-medicine/request-confirmation/1"],
	},
	{
		id: 5,
		link: [
			"/dashboard/service/pregnancy-follow-up-program/request-confirmation",
		],
	},
	{
		id: 6,
		link: [""],
	},
];

export const carWashRequestHeaderData = {
	requestTitle: "Car Wash",
	requestPara: "New Request",
	url: "/dashboard/service/car-wash",
};

export const carWashRequestIdData = {
	requestIdTitle: "Car Maintenance",
	requestId: "Request #123456",
	detailTitleOne: "Vehicle",
	detailParaOne: "Merc. Benz",
	detailIconOne: requestCar,
	detailTitleTwo: "Your Location",
	detailParaTwo: "Alyasmin, Riyadh 13331",
	detailIconTwo: requestLoaction,
};
