import React from "react";
import { InsuranceCardMobile } from "component/common/MobileReuseable/InsuranceCardMobile";
import { servicesOffered as servicesMockData } from "component/common/MockData";
import { useTranslation } from "react-i18next";
import RightArrowIcon from "assets/images/menuicons/right-arrow.svg";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";


import periodicInspection from "assets/svg/icons/all-services/periodic-inspection.svg";
import roadAssist from "assets/svg/icons/all-services/road-assistance.svg";
import carAccident from "assets/svg/icons/all-services/motor-car-accident.svg";
import refilMedi from "assets/svg/icons/all-services/health-refill-medication.svg";
import medReim from "assets/svg/icons/all-services/health-medical-reimbursement.svg";
import teleMedi from "assets/svg/icons/all-services/health-telemed.svg";
import eligibilityLetter from "assets/svg/icons/all-services/health-coverage-letter.svg";
import pregnancyProg from "assets/svg/icons/all-services/health-pregnancy.svg";
import homeChildVacc from "assets/svg/icons/all-services/health-home-child-vac.svg";
import chrnicDises from "assets/svg/icons/all-services/health-chronic-disease-management.svg";
import asstAmerica from "assets/svg/icons/all-services/health-assist-america.svg";
import flightDelayBlue from "assets/svg/icons/all-services/flight-delay-assistance.svg";


function ServicesOffered({
  //data = servicesMockData,
  onClickAll = () => {
    return;
  },
}) {
  const { i18n, t } = useTranslation(); const loggedProfile = useSelector((state) => state?.loginDetailsReducer?.loginResponse);
  const userID = loggedProfile?.user_info?.userID;

  const data = [
    {
      content: t("servicesOffered.periodic_insptn"),
      cardIcon: periodicInspection,
      type: "health",
      url: !isEmpty(userID)
        ? "/dashboard/service/periodic-inspection"
        : "/individuals/customer-service/periodic-inspection",
    },
    {
      content: t("servicesOffered.road_asst"),
      cardIcon: roadAssist,
      type: "motor",
      url: !isEmpty(userID)
        ? "/dashboard/service/road-assistance"
        : "/individuals/customer-service/road-assistances",
    },
    {
      content: t("servicesOffered.car_acc"),
      cardIcon: carAccident,
      type: "motor",
      url: "/individuals/car-accident",
    },
    {
      content: t("servicesOffered.refil_medi"),
      cardIcon: refilMedi,
      url: !isEmpty(userID) ? "/dashboard/service/dawai" : "/individuals/products/health/dawai",
    },
    {
      content: t("servicesOffered.med_rem"),
      cardIcon: medReim,
      type: "health",
      url: !isEmpty(userID)
        ? "/dashboard/service/medical-reimbursement"
        : "/individuals/products/health/medical-reimbursement",
    },
    {
      content: t("servicesOffered.telemedi"),
      cardIcon: teleMedi,
      type: "health",
      url: !isEmpty(userID)
        ? "/dashboard/service/request-telemedicine"
        : "/individuals/products/health/tele-medicine",
    },
    {
      content: t("servicesOffered.elg_ltr"),
      cardIcon: eligibilityLetter,
      type: "health",
      url: !isEmpty(userID)
        ? "/dashboard/service/eligibility-letter"
        : "/individuals/products/health/eligibility-letter",
    },
    // {
    //   content: t("servicesOffered.prgncy_prog"),
    //   cardIcon: pregnancyProg,
    //   type: "health",
    //   url: !isEmpty(userID)
    //     ? "/dashboard/service/pregnancy-follow-up-program"
    //     : "/individuals/customer-service/pregnancy-follow-up-program",
    // },
    {
      content: t("servicesOffered.chrnic_dises_mngt"),
      cardIcon: chrnicDises,
      type: "health",
      url: !isEmpty(userID)
        ? "/dashboard/service/chronic-disease-management"
        : "/individuals/customer-service/chronic-disease-management",
    },
    {
      content: t("servicesOffered.home_child_vacc"),
      cardIcon: homeChildVacc,
      type: "health",
      url: !isEmpty(userID) ? "/dashboard/service/home-child-vaccination" : "/login",
    },
    {
      content: t("servicesOffered.ass_amrca"),
      cardIcon: asstAmerica,
      type: "health",
      url: !isEmpty(userID)
        ? "/dashboard/service/assist-america"
        : "/individuals/customer-service/assist-america",
    },
    // {
    //   content: t("servicesOffered.flgt_delay_ass"),
    //   cardIcon: flightDelayBlue,
    //   type: "prptyncasualty",
    //   url: "/individuals/flight-delay-assistance",
    // },
  ];

  return (
    <div className="mobile_menu_common_services">
      <h5>{t("servicesOffered.srvs_offer")}</h5>
      <InsuranceCardMobile data={data} />
      <div className="view_all_services" onClick={() => {onClickAll(true);
        window.lmSMTObj.track(t("servicesOffered.view_all_srvs")+"Clicked");
        }}>
        <p>{t("servicesOffered.view_all_srvs")}</p>
        <img src={RightArrowIcon} alt="RightArrowIcon" />
      </div>
    </div>
  );
}

export default ServicesOffered;
