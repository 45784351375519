import React from "react";
import { useTranslation, getI18n } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import "./style.scss";
import RightArrowIcon from "assets/images/menuicons/right-arrow.svg";

import { history } from "service/helpers";
import * as actions from "action/reportAction";

const CustomerService = ({ closeMenu, currentMenu }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const isRTL = i18n.dir() === "rtl";
  const currentLanguage = i18n.language;
  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked,{
      category:currentMenu,
      Product:"CustomerService"
    });
  }
  return (
    <div className="menu-customer-service-details">
      <div className="customer-service-top">
        <div className="top-title">{t("custm_service.subtitle")}</div>
        <p>
          {t("custm_service.product_para_1")} {t("custm_service.product_para_2")}
        </p>
        <div className="top-section-links">
          <div className="top-section-links-left">
            <Link to="/individuals/customer-service" onClick={() => {closeMenu();trackEvent(t("custm_service.motor")+"TabClicked")}}>
              {t("custm_service.motor")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon"/>
            </Link>
            <Link to="/individuals/customer-service" onClick={() => {closeMenu();trackEvent(t("Health")+"TabClicked")}}>
              {t("Health")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
            </Link>
            <Link to="/individuals/customer-service" onClick={() => {closeMenu();trackEvent(t("custm_service.prop_casuality")+"TabClicked")}}>
              {t("custm_service.prop_casuality")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon"  />
            </Link>
          </div>
          <div className="top-section-links-right">
            <Link to="/individuals/customer-service" onClick={() => {closeMenu();trackEvent(t("custm_service.all_categories")+"TabClicked")}}>
              {t("custm_service.all_categories")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
            </Link>
          </div>
        </div>
      </div>
      <div className="customer-service-box-root">
        <div className="customer-service-box-content content-width-50">
          <div className="customer-service-box-title">
            {t("custm_service.protect_title")}
            <br />
            {t("custm_service.against_fraud")}
          </div>
          <p>{t("custm_service.protect_subtitle-1")}</p>
          <div className="customer-service-box-links">
            <div>
              <Link
                to="/individuals/customer-service/report-fraud"
                onClick={() => {
                  closeMenu();
                  dispatch(actions.setReportPage("medicalFraud"));
                  trackEvent(t("custm_service.medical_fraud")+"TabClicked");
                }}
              >
                {t("custm_service.medical_fraud")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
            <div>
              <Link
                to="/individuals/customer-service/report-fraud"
                onClick={() => {
                  closeMenu();
                  dispatch(actions.setReportPage("motorFraud"));
                  trackEvent(t("custm_service.motor_fraud")+"TabClicked");
                }}
              >
                {t("custm_service.motor_fraud")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
            <div>
              <Link
                to="/individuals/customer-service/report-fraud"
                onClick={() => {
                  closeMenu();
                  dispatch(actions.setReportPage("travelFraud"));
                  trackEvent(t("custm_service.insurance_fraud")+"TabClicked");
                }}
              >
                {t("custm_service.insurance_fraud")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
            <div>
              <Link to="/individuals/customer-service/report-fraud" onClick={() => {closeMenu();trackEvent(t("reportFraud.ReportAFraud")+"TabClicked");}}>
                {t("reportFraud.ReportAFraud")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
          </div>
        </div>
        <div className="customer-service-box-content content-width-50">
          <div className="customer-service-box-title"> {t("custm_service.violations")}</div>
          <p>{t("custm_service.protect-subtitle-2")}</p>
          <div className="customer-service-box-links">
            <div>
              <Link to="/individuals/customer-service/violations" onClick={() => {closeMenu();trackEvent(t("reportFraud.learnMore")+"TabClicked");}}>
                {t("reportFraud.learnMore")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
            <div>
              <Link
                to={{
                  pathname: "/individuals/customer-service/violations",
                  state: { toForm: true },
                }}
                onClick={() => {closeMenu();trackEvent(t("reportFraud.reportViolations")+"TabClicked");}}
              >
                {t("reportFraud.reportViolations")}
                <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
          </div>

        </div>

        <div className="customer-service-box-content content-width-50">
          <div className="customer-service-box-title">
            {" "}
            {currentLanguage == "en" ? "Surplus" : "الفائض"}
          </div>
          <p>
            {currentLanguage == "en"
              ? " Surplus Distribution Policy, issued by the Insurance Authority"
              : "سياسة توزيع الفائض الصادرة عن هيئة التأمين"}
          </p>
          <div className="customer-service-box-links">
            <div>
              {currentLanguage == "en" ? (
                <Link to="/individuals/customer-service/surplus" onClick={() => {closeMenu();trackEvent(currentLanguage == "en" ? "Learn More" : "اكتشف المزيد"+"TabClicked");}}>
                  {currentLanguage == "en" ? "Learn More" : "اكتشف المزيد"}
                  <img src={RightArrowIcon} alt="..." />
                </Link>
              ) : (
                <Link to="/individuals/customer-service/surplus" onClick={() => {closeMenu();trackEvent(currentLanguage == "en" ? "Learn More" : "اكتشف المزيد"+"TabClicked");}}>
                  {currentLanguage == "en" ? "Learn More" : "اكتشف المزيد"}
                  <img src={RightArrowIcon} alt="..." />
                </Link>
              )}
            </div>
            <div>
              {currentLanguage == "en" ? (
                <a
                  onClick={() => {
                    closeMenu();
                    window.open("https://tawn.me/surplus");
                    trackEvent(currentLanguage == "en" ? "Apply to Surplus" : "التقديم على الفائض"+"TabClicked");
                  }}
                >
                  {currentLanguage == "en" ? "Apply to Surplus" : "التقديم على الفائض"}
                  <img src={RightArrowIcon} alt="..." />                 
                </a>
              ) : (
                <a
                  onClick={() => {
                    closeMenu();
                    window.open("https://tawn.me/surplus");
                    trackEvent(currentLanguage == "en" ? "Apply to Surplus" : "التقديم على الفائض"+"TabClicked");
                  }}
                >
                  {currentLanguage == "en" ? "Apply to Surplus" : "التقديم على الفائض"}
                  <img src={RightArrowIcon} alt="..." />
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="customer-service-box-content">
          <div className="network-coverage">
            <div className={`${isRTL ? "network-coverage-left-rtl" : "network-coverage-left"}`}>
              <div className="customer-service-box-title">{t("networkCoverage.network_covrg")}</div>
              <p>{t("drawer.customer_service.all_you_need")}</p>
            </div>

            <div className={`${isRTL ? "network-coverage-right-rtl" : "network-coverage-right"}`}>
              <div>
                <Link to="/individuals/customer-service/network-coverage" onClick={() => {closeMenu();trackEvent(t("custm_service.medical_providers")+"TabClicked");}}>
                  {t("custm_service.medical_providers")}{" "}
                  <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
                </Link>
              </div>
              <div>
                <Link to="/individuals/customer-service/network-coverage" onClick={() => {closeMenu();trackEvent(t("custm_service.tawn_network")+"TabClicked");}}>
                  {t("custm_service.tawn_network")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
                </Link>
              </div>
              {/* <div>
								<a
									onClick={() => {
										closeMenu();
										history.push("/individuals/customer-service/insurance-agent");
									}}
								>
									{t("custm_service.tawn_agents")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
								</a>
							</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerService;
