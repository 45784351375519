import React from "react";
import { Grid } from "@material-ui/core";
import { otherProducts, productContents } from "component/common/MockData";
import tweet from "assets/svg/Twitter.svg";
import fb from "assets/svg/Facebook.svg";
import uTube from "assets/svg/Youtube.svg";
import linkedIn from "assets/svg/Linkedin.svg";
import insta from "assets/svg/Instagram.svg";
import whatzApp from "assets/svg/Whatsapp.svg";
import closeIcon from "assets/svg/canvasClose.svg";
import appStore from "assets/images/appleStore.png";
import playStore from "assets/images/playStore.png";
import appGal from "assets/images/appGallery.png";
import styles from "./style.module.scss";

const BannerCanvas = ({ closeToggler }) => {
	const iconData = [tweet, fb, uTube, linkedIn, insta, whatzApp];
	return (
		<>
			<div className={`${styles.offCanvasContainer} pb-5 mb-5`}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="flex-start"
					className={`${styles.canvasContainerOne} pb-4`}
				>
					<Grid item lg={6}>
						<div className="d-flex flex-row">
							<div>
								<p className={`${styles.canvasHeadingOne} fs-32 fw-400 m-0`}>
									I’m a
								</p>
								<p className={`${styles.canvasSubHeadingOne} fs-32 fw-700 m-0`}>
									Individuals{" "}
									<span className={`${styles.lineSeperator} ml-3`}></span>
								</p>
							</div>
							<div className="pl-3">
								<p
									className={`${styles.canvasHeadingTwo} text-center fs-32 fw-400`}
								>
									Corporate{" "}
									<span className={`${styles.lineSeperator} ml-3`}></span>
								</p>
							</div>
							<div className="pl-3">
								<p
									className={`${styles.canvasHeadingThree} text-center fs-32 fw-400 `}
								>
									Investor
								</p>
							</div>
						</div>
					</Grid>
					<Grid item lg={0}>
						<div className="pr-5">
							<img
								src={closeIcon}
								alt="Tawanya"
								className={`${styles.canvasClose} img-fluid`}
								onClick={closeToggler}
							/>
						</div>
					</Grid>
				</Grid>
				<Grid
					container
					direction="row"
					justifyContent="flex-start"
					alignItems="flex-start"
					className={`${styles.canvasContainerTwo} pt-4 mt-1`}
				>
					<Grid item lg={4} md={4}>
						{productContents.map((item, index) => {
							return (
								<p
									key={index}
									id={
										!item.highlightItem
											? styles.nonHighlightProducts
											: styles.highlightProducts
									}
									className={`${styles.canvasProducts} fw-700 m-0`}
								>
									{item.productTitle}
									<img
										alt="Tawanya"
										src={item.arrowIcon}
										className="img-fluid pl-3"
									/>
								</p>
							);
						})}
					</Grid>
					<Grid item lg={8} md={8}>
						<div className="d-flex flex-row">
							{otherProducts.map((item, index) => {
								return (
									<div className="pl-4 pt-4" key={index}>
										<p
											className={`${styles.otherProductsContents} fs-22 fw-700`}
										>
											{item.productTitle}{" "}
											<img
												alt="Tawanya"
												src={item.subIcon}
												className="img-fluid pl-2 pr-2"
											/>
										</p>
										{item.productContent?.map((items) => {
											return (
												<p
													className={`${styles.otherProductSubContent} fs-16 fw-300`}
												>
													{items}
												</p>
											);
										})}
									</div>
								);
							})}
						</div>
					</Grid>
				</Grid>
			</div>
			<div className={`${styles.offCanvasFooterContainer} py-2`}>
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="flex-start"
					className={`${styles.canvasContainerThree}`}
				>
					<Grid item lg={8}>
						<div className="d-flex flex-row">
							{iconData.map((items) => {
								return (
									<div className="pr-3">
										<img
											src={items}
											alt="Tawanya"
											className={`${styles.socialIcons} img-fluid`}
										/>
									</div>
								);
							})}
						</div>
					</Grid>
					<Grid item lg={4}>
						<div className="d-flex flex-row m-0">
							<div>
								<img
									alt="Tawanya"
									src={appStore}
									className={`${styles.canvasButtonContainer} img-fluid`}
								/>
							</div>
							<div>
								<img
									src={playStore}
									className={`${styles.canvasButtonContainer} img-fluid`}
									alt="iconBtns"
								/>
							</div>
							<div>
								<img
									src={appGal}
									className={`${styles.canvasButtonContainer} img-fluid`}
									alt="iconBtns"
								/>
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default BannerCanvas;
