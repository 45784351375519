/** @format */
import { Calendar } from 'primereact/calendar';

import React, { useState } from "react";
import whiteCar from "assets/svg/claims/white-car.svg";
import blueCar from "assets/svg/claims/blue-car.svg";
import whiteHeart from "assets/svg/claims/white-heart.svg";
import blueHeart from "assets/svg/claims/blue-heart.svg";
import "./style.scss";
import * as globalUtils from "./utils";
// import { history } from "service/helpers";
import { useDispatch } from "react-redux";
import { externalLinks } from "helpers/constant";

import { getClaimTrakerDetails } from "service/dashboard/dashboardService";
import {
  updateLoader,
  updateMotorClaimTrackDetails,
} from "action/DashboardInformation";
import HeaderStickyMenu from "component/common/MobileReuseable/HeaderStickyMenu";
import { useTranslation } from "react-i18next";
import colors from "assets/scss/abstracts/colors.scss";
import framearrow from "assets/svg/motor/framearrow.svg";
import { useHistory, useLocation } from "react-router-dom";

import { Alshamal, SanadIcon } from "assets/svg/motor/ValueAddedIcons";
import { DatePickerInput } from "component/common/DatePickerInput";
import DatePicker from "react-multi-date-picker";

import { getGregYearOfBirthList, getHijriYearOfBirthList } from 'helpers/utils';
const ClaimsForm = ({ setMenuOpen = () => { } }) => {
  const { t, i18n } = useTranslation();
  const [policyType, setPolicyType] = useState("motor");
  const [policyPlan, setPolicyPlan] = useState("comprehensive");
  const [nationid, setNationanid] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const [claimNumber, setClaimNumber] = useState("");
  const [sanadNationid, setSanadNationanid] = useState("");
  const [nationalId, setNationalId] = useState("");
  const [yearOfBirth, setYearOfBirth] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [najmNumber, setNajmNumber] = useState("");
  const [medicalPolicyNumber, setMedicalPolicyNumber] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const history = useHistory();
	const [date, setDate] = useState();

  const selectedLanguage = localStorage.getItem("lang");
  const dispatch = useDispatch();
  const location = useLocation();
  let pathArray = history.location.pathname.split("/");
  let currentPage = pathArray[pathArray.length - 1];

  function onChangePolicy(event) {
    setPolicyType(event.target.value);
  }
  function onChangePlan(event) {
    setPolicyPlan(event.target.value);
  }

  const medicalForm = () => {
    if (medicalFields()) {
      window.lmSMTObj.track("medicalClaimForm",{
        "Claim Type": "Medical",
        // "Policy Number": medicalPolicyNumber,
        // "Card Number": cardNumber
      });
      history.push("/login");
    } else {
      //console.log("error");
    }
  };


  //for google tagging
  const calLem = (url) => {
    window.lmSMTObj.page(url, {
      path: document.location.pathname,
      language: selectedLanguage == 'ar' ? "Arabic" : "English",
      referrer: document.referrer,
      title: document.title,
      url: document.URL,
      search: location.search,
    });
  };

  const TrackEventWithProduct = (tabClicked, product) => {
    window.lmSMTObj.track(tabClicked, {
      "Product": product
    });
  }


  const sanadForm = () => {
    if (sanadValidateFields()) {
      const data = {
        langCode: i18n.language.slice(0, 1).toUpperCase(),
        policyNumber: referenceNumber,
        idNumber: sanadNationid,
        claimNumber: "1",
        claimDetailNumber: "",
        najmNumber: "",
        cardCode: "",
        claimType: "MOTOR",
      };
      dispatch(updateLoader(true));
      getClaimTrakerDetails(data).then((response) => {
        //console.log("sanadmotresponse", response);
        window.lmSMTObj.track("motorClaimForm",{
          "Claim Type": "Motor",
          "Policy Plan" : "sanad",
          "Status":"Success", 
          // "Iqama Number": sanadNationid,
          // "Reference Number": referenceNumber,
          // "Najm Number": najmNumber
        });
        dispatch(
          updateMotorClaimTrackDetails({
            ...response.return.data,
            policyNumber: policyNumber,
            claimNumber: referenceNumber,
          })
        );
        //console.log("success");
        history.push("/claims/motor");
        dispatch(updateLoader(false));
      }).catch(() => {
        window.lmSMTObj.track("motorClaimForm",{
          "Claim Type": "Motor",
          "Policy Plan" : "sanad",
          "Status":"Failed", 
        //   "Iqama Number": sanadNationid,
        //   "Reference Number": referenceNumber,
        //   "Najm Number": najmNumber
        });
      }).finally( e => history.push("/claims/motor"));
    } else {
      //console.log("error");
    }
  };
  const compForm = () => {
    if (validateFields()) {
      const data = {
        langCode: i18n.language.slice(0, 1).toUpperCase(),
        policyNumber: policyNumber,
        idNumber: nationid,
        claimNumber: claimNumber,
        claimDetailNumber: "",
        najmNumber: "",
        cardCode: "",
        claimType: "MOTOR",
      };
      dispatch(updateLoader(true));
      getClaimTrakerDetails(data)
        .then((response) => {
          //console.log("compmotresponse", response);
          if (response?.return?.status === "SUCCESS") {
            setMenuOpen(null);
            dispatch(
              updateMotorClaimTrackDetails({
                ...response.return.data,
                policyNumber: policyNumber,
                claimNumber: claimNumber,
              })
            );
            //console.log("success");
            history.push("/claims/motor");
            dispatch(updateLoader(false));
            window.lmSMTObj.track("motorClaimForm",{
              "Claim Type": "Motor",
              "Policy Plan" : "comprehensive",
              "Status":"Success",
              // "Iqama Number": nationid,
              // "Policy Number": policyNumber,
              // "Claim Number": claimNumber
            });
          } else {
            dispatch(updateLoader(false));
            window.lmSMTObj.track("motorClaimForm",{
              "Claim Type": "Motor",
              "Policy Plan" : "comprehensive",
              "Status":"Failed",
              // "Iqama Number": nationid,
              // "Policy Number": policyNumber,
              // "Claim Number": claimNumber
            });
            // window.alert("Something went wrong!");
          }
        })
        .catch(() => {
          window.lmSMTObj.track("motorClaimForm",{
            "Claim Type": "Motor",
            "Policy Plan" : "comprehensive",
            "Status":"Failed",
            // "Iqama Number": nationid,
            // "Policy Number": policyNumber,
            // "Claim Number": claimNumber
          });
          dispatch(updateLoader(false));
          // window.alert("Something went wrong!");
        }).finally( e => history.push("/claims/motor"))
    } else {
      //console.log("error");
    }
  };
  const uploadForm = () => {
      window.location.href = `${process.env.REACT_APP_PURCHASE_URL}tp-claims?co=false&type=upload&claim=${sanadNationid}&report=${referenceNumber}&lang=${i18n.language}&date=${date.toString()}&id=${nationalId.toString()}&yob=${yearOfBirth.getFullYear()}`
  };
  
  const validateFields = () => {
    let errors = {};
    if (!globalUtils.isValidSaudiIDOrIQama(nationid)) {
      errors.nationid = t("claimTracker.NationalIDInvalid");
    }
    if (!globalUtils.isValidSaudiIDOrIQama(nationalId)) {
      errors.nationid = t("claimTracker.nationalId");
    }
    if (!globalUtils.isPolicyNumber(policyNumber)) {
      errors.policyNumber = t("claimTracker.PolicyNumberInvalid");
    }
    if (!globalUtils.isClaimNumber(claimNumber)) {
      errors.claimNumber = t("claimTracker.ClaimNumberInvalid");
    }
    if (Object.keys(errors).length === 0) {
      //console.log("All Fields are valid");
      return true;
    } else {
      setFormErrors(errors);
      return false;
    }
  };

  const sanadValidateFields = () => {
    let errors = {};
    if (!globalUtils.isValidSaudiIDOrIQama(sanadNationid)) {
      errors.sanadNationid = t("claimTracker.NationalIDInvalid");
    }
    if (!globalUtils.isPolicyNumber(referenceNumber)) {
      errors.referenceNumber = t("claimTracker.ReferenceNumberInvalid");
    }
    if (!globalUtils.isClaimNumber(najmNumber)) {
      errors.najmNumber = t("claimTracker.NajmNumberInvalid");
    }
    if (Object.keys(errors).length === 0) {
      //console.log("All Fields are valid");
      return true;
    } else {
      setFormErrors(errors);
      return false;
    }
  };

  const medicalFields = () => {
    let errors = {};
    if (!globalUtils.isPolicyNumber(medicalPolicyNumber)) {
      errors.medicalPolicyNumber = t("claimTracker.PolicyNumberInvalid");
    }
    if (!globalUtils.isPolicyNumber(cardNumber)) {
      errors.cardNumber = t("claimTracker.CardNumberInvalid");
    }
    if (Object.keys(errors).length === 0) {
      //console.log("All Fields are valid");
      return true;
    } else {
      setFormErrors(errors);
      return false;
    }
  };
  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked);
  }

  const trackEventWithProduct = (tabClicked, product) => {
    window.lmSMTObj.track(tabClicked,{
      "Product":product
    });
  }

  const getYobList = () => {
    return globalUtils.isSaudi(nationalId)
      ? getHijriYearOfBirthList()
      : getGregYearOfBirthList();
  };

  return (
    <div className="claim-form">
      {window.innerWidth < 768 && <HeaderStickyMenu profileClass={true} />}
      {window.innerWidth < 600 && (
        <div className="page-head mt-3">
          <h2 className="mb-0">{t("claimTracker.Claims")}</h2>
        </div>
      )}
      <div className="claim-form-area">
        {window.innerWidth < 600 && (
          <div className="claim-hope">
            <h3>{t("claimTracker.Hope")}</h3>
            <p>{t("claimTracker.Available")}</p>
          </div>
        )}
        <div className="form-head">
          <div className="ClimRedirect">
            {window.innerWidth < 600 && (
              <div className="Container mt-4 mb-4 ">
                <div className="row mx-1">
                  {" "}
                  <h4 className="m-3">
                    {t("dashboardHome.SubmitAndTrackClaims")}
                  </h4>
                </div>
                <div className="row boxing">
                  <div
                    className="col-md-6 d-flex classman"
                    onClick={() => {
                      trackEventWithProduct(
                        t("AlShamel") + t("SanadPlus") + "Clicked",
                        "Claims"
                      );
                    }}
                  >
                    <div className="mt-2">
                      <Alshamal />
                    </div>
                    <div className="mx-3">
                      <div className="Tiitldis">
                        {t("AlShamel")} & {t("SanadPlus")}
                      </div>
                      <div className="divdis">
                        {t("Comprehensive_cars_insurance")}
                      </div>
                    </div>
                    <div>
                      <img className="my-2 " src={framearrow} />
                    </div>
                  </div>
                  <div
                    className="col-md-6 d-flex classman"
                    onClick={() => {
                      trackEventWithProduct(t("Sanad") + "Clicked", "Claims");
                    }}
                  >
                    <div className="mt-2">
                      <SanadIcon fill={colors["bright-purple"]} />{" "}
                    </div>
                    <div className="mx-3">
                      <div className="Tiitldis">{t("Sanad")}</div>
                      <div className="divdis">
                        {t("Motor_vehicle_liability_insurance")}
                      </div>
                    </div>
                    <img className="my-2 cursor-pointer" src={framearrow} onClick={() => { calLem(window.location.assign(externalLinks.common + "tp-claims")); TrackEventWithProduct("tp-claimsTabClicked", "Claims") }} />
                  </div>
                </div>
                </div>
            )}
          </div>
          <h4 className="my-2 mx-4 mx-lg-0">
            {t("claimTracker.TrackYourClaim")}
          </h4>
        </div>
        <div className="mx-4 mx-lg-0" onChange={onChangePolicy}>
          <div
            className={
              "form-radio cursor-pointer" +
              (policyType === "motor" ? " policyactive" : "")
            }
          >
            <input
              type="radio"
              id="motor"
              value="motor"
              name="motor"
              checked={policyType === "motor"}
            />
            <label
              className="d-flex align-items-center cursor-pointer mx-3"
              for="motor"
            >
              {policyType === "motor" ? (
                <img src={blueCar} alt="blueCar" />
              ) : (
                <img src={whiteCar} alt="whiteCar" />
              )}
              <p className="mb-0 ml-3 mr-3">{t("claimTracker.Motor")}</p>
            </label>
          </div>
          <div
            className={
              "form-radio cursor-pointer" +
              (policyType === "medical" ? " policyactive" : "")
            }
          >
            <input
              type="radio"
              id="medical"
              value="medical"
              name="medical"
              checked={policyType === "medical"}
            />
            <label
              className="d-flex align-items-center cursor-pointer mx-3"
              for="medical"
            >
              {policyType === "medical" ? (
                <img src={blueHeart} alt="blueHeart" />
              ) : (
                <img src={whiteHeart} alt="whiteHeart" />
              )}
              <p className="mb-0 ml-3 mr-3">{t("claimTracker.Medical")}</p>
            </label>
          </div>
        </div>
        {policyType === "motor" && (
          <div className="motor-form">
            <div
              className="d-flex align-items-center form-top"
              onChange={onChangePlan}
            >
              <div
                className={
                  "form-radio cursor-pointer" +
                  (policyPlan === "comprehensive" ? " planactive" : "")
                }
              >
                <input
                  type="radio"
                  id="comprehensive"
                  value="comprehensive"
                  name="comprehensive"
                  checked={policyPlan === "comprehensive"}
                />{" "}
                <label className="cursor-pointer" for="comprehensive">
                  {t("claimTracker.Comprehensive")}
                </label>
              </div>
              <div
                className={
                  "form-radio cursor-pointer" +
                  (policyPlan === "sanad" ? " planactive" : "")
                }
              >
                <input
                  type="radio"
                  id="sanad"
                  value="sanad"
                  name="sanad"
                  checked={policyPlan === "sanad"}
                />
                <label className="cursor-pointer" for="sanad">
                  {t("claimTracker.Sanad")}
                </label>
              </div>

              <div
                className={
                  "form-radio cursor-pointer" +
                  (policyPlan === "upload" ? " planactive" : "")
                }
                onClick={(e) => {}}
              >
                <input
                  type="radio"
                  id="upload"
                  value="upload"
                  name="upload"
                  checked={policyPlan === "upload"}
                />
                <label className="cursor-pointer" for="upload">
                  {t("claimTracker.UploadDocs")}
                </label>
              </div>
            </div>
            {policyPlan === "comprehensive" && (
              <div className="comp-form">
                <div className="nation-id">
                  {/* <label>{t("claimTracker.NationalID")}</label> */}
                  <input
                    type="text"
                    placeholder={t("claimTracker.EnterIqamaNumber")}
                    pattern="[0-9]*"
                    value={nationid}
                    onChange={(e) => {
                      setNationanid(e.target.value);
                      setFormErrors((state) =>
                        globalUtils.deleteByKey("nationid", state)
                      );
                    }}
                  />
                  {formErrors.hasOwnProperty("nationid") && (
                    <small className="p-error block">
                      {formErrors.nationid}
                    </small>
                  )}
                </div>
                <div className="nation-id">
                  {/* <label>{t("claimTracker.PolicyNumber")}</label> */}
                  <input
                    type="text"
                    placeholder={t("claimTracker.EnterPolicyNumber")}
                    pattern="[0-9]*"
                    value={policyNumber}
                    onChange={(e) => {
                      setPolicyNumber(e.target.value);
                      setFormErrors((state) =>
                        globalUtils.deleteByKey("policyNumber", state)
                      );
                    }}
                  />
                  {formErrors.hasOwnProperty("policyNumber") && (
                    <small className="p-error block">
                      {formErrors.policyNumber}
                    </small>
                  )}
                </div>
                <div className="nation-id">
                  {/* <label>{t("claimTracker.ClaimNumber")}</label> */}
                  <input
                    type="text"
                    placeholder={t("claimTracker.EnterClaimNumber")}
                    pattern="[0-9]*"
                    value={claimNumber}
                    onChange={(e) => {
                      setClaimNumber(e.target.value);
                      setFormErrors((state) =>
                        globalUtils.deleteByKey("claimNumber", state)
                      );
                    }}
                  />
                  {formErrors.hasOwnProperty("claimNumber") && (
                    <small className="p-error block">
                      {formErrors.claimNumber}
                    </small>
                  )}
                </div>
                <div className="form-sub">
                  <button onClick={compForm}>{t("claimTracker.Track")}</button>
                </div>
              </div>
            )}
            {policyPlan === "sanad" && (
              <div className="comp-form">
                <div className="nation-id">
                  {/* <label>{t("claimTracker.NationalID")}</label> */}
                  <input
                    type="text"
                    placeholder={t("claimTracker.EnterIqamaNumber")}
                    pattern="[0-9]*"
                    value={sanadNationid}
                    onChange={(e) => {
                      setSanadNationanid(e.target.value);
                      setFormErrors((state) =>
                        globalUtils.deleteByKey("sanadNationid", state)
                      );
                    }}
                  />
                  {formErrors.hasOwnProperty("sanadNationid") && (
                    <small className="p-error block">
                      {formErrors.sanadNationid}
                    </small>
                  )}
                </div>
                <div className="nation-id">
                  {/* <label>{t("claimTracker.ReferenceNumber")}</label> */}
                  <input
                    type="text"
                    placeholder={t("claimTracker.EnterReferenceNumber")}
                    pattern="[0-9]*"
                    value={referenceNumber}
                    onChange={(e) => {
                      setReferenceNumber(e.target.value);
                      setFormErrors((state) =>
                        globalUtils.deleteByKey("referenceNumber", state)
                      );
                    }}
                  />
                  {formErrors.hasOwnProperty("referenceNumber") && (
                    <small className="p-error block">
                      {formErrors.referenceNumber}
                    </small>
                  )}
                </div>
                <div className="nation-id">
                  {/* <label>{t("claimTracker.NajmNumber")}</label> */}
                  <input
                    type="text"
                    placeholder={t("claimTracker.EnterNajmNumber")}
                    pattern="[0-9]*"
                    value={najmNumber}
                    onChange={(e) => {
                      setNajmNumber(e.target.value);
                      setFormErrors((state) =>
                        globalUtils.deleteByKey("najmNumber", state)
                      );
                    }}
                  />
                  {formErrors.hasOwnProperty("najmNumber") && (
                    <small className="p-error block">
                      {formErrors.najmNumber}
                    </small>
                  )}
                </div>
                <div className="form-sub">
                  <button onClick={sanadForm}>{t("claimTracker.Track")}</button>
                </div>
              </div>
            )}
            {policyPlan === "upload" && (
              <div className="comp-form">
                <div className="nation-id">
                  <input
                    type="text"
                    placeholder={t("claimTracker.EnterIqamaNumber")}
                    pattern="[0-9]*"
                    value={nationalId}
                    onChange={(e) => {
                      setNationalId(e.target.value);
                      setFormErrors((state) =>
                        globalUtils.deleteByKey("nationalId", state)
                      );
                    }}
                  />
                  {formErrors.hasOwnProperty("nationalId") && (
                    <small className="p-error block">
                      {formErrors.nationalId}
                    </small>
                  )}
                </div>

                <div className="nation-id">
                    <Calendar
                      inputId="yearOfBirth"
                      value={yearOfBirth}
                      onChange={(e) => setYearOfBirth(e.value)}
                      placeholder={t("Year_of_Birth")}
                      view="year" 
                      dateFormat="yy"  
                      yearNavigator={true} 
                      yearRange="1900:2024"
                    />
                </div>


                <div className="nation-id">
                  <input
                    type="text"
                    placeholder={t("claimTracker.ClaimNumber")}
                    pattern="[0-9]*"
                    value={sanadNationid}
                    onChange={(e) => {
                      setSanadNationanid(e.target.value);
                    }}
                  />
                </div>
                <div className="nation-id">
                  <input
                    type="text"
                    placeholder={t("claimTracker.AccedientReportNumber")}
                    pattern="[0-9]*"
                    value={referenceNumber}
                    onChange={(e) => {
                      setReferenceNumber(e.target.value);
                    }}
                  />
                </div>
                <div className="nation-id">
                    <Calendar
                      inputId="accedant_date"
                      value={date}
                      onChange={(e) => setDate(e.value)}
                      placeholder={t("claimTracker.AccidentDate")}
                      dateFormat={'dd/mm/yy'}
                      maxDate={new Date()}
                    />
                </div>
                <div className="form-sub">
                  <button onClick={uploadForm}>
                    {t("claimTracker.Submit")}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        {policyType === "medical" && (
          <div className="comp-form">
            <div className="nation-id">
              {/* <label>{t("claimTracker.MedicalPolicyNumber")}</label> */}
              <input
                type="text"
                placeholder={t("claimTracker.EnterPolicyNumber")}
                pattern="[0-9]*"
                value={medicalPolicyNumber}
                onChange={(e) => {
                  setMedicalPolicyNumber(e.target.value);
                  setFormErrors((state) =>
                    globalUtils.deleteByKey("medicalPolicyNumber", state)
                  );
                }}
              />
              {formErrors.hasOwnProperty("medicalPolicyNumber") && (
                <small className="p-error block">
                  {formErrors.medicalPolicyNumber}
                </small>
              )}
            </div>
            <div className="nation-id">
              {/* <lable>{t("claimTracker.MedicalCardNumber")}</lable> */}
              <input
                type="text"
                placeholder={t("claimTracker.PleaseCardNumber")}
                pattern="[0-9]*"
                value={cardNumber}
                onChange={(e) => {
                  setCardNumber(e.target.value);
                  setFormErrors((state) =>
                    globalUtils.deleteByKey("cardNumber", state)
                  );
                }}
              />
              {formErrors.hasOwnProperty("cardNumber") && (
                <small className="p-error block">{formErrors.cardNumber}</small>
              )}
            </div>
            <div className="form-sub">
              <button onClick={medicalForm}>{t("claimTracker.Track")}</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClaimsForm;
