import { createTheme } from "@mui/material";
import colors from "assets/scss/abstracts/colors.scss"

let theme = createTheme({
  palette: {
    custom: colors
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true
      },
      variants: [
        {
          props: {
            variant: 'outlined'
          },
          style: {
            borderColor: colors['bright-purple']
          }
        }
      ]
    }
  }
});


export default theme;
