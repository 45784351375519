import React from "react";
import { useHistory } from "react-router-dom";
//import { history } from "service/helpers";
import customerHome from "assets/svg/customerHome.svg";
import customerBreadCrumb from "assets/svg/customerBreadCrumb.svg";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

export const CommonBreadCrumb = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const selectedCardInfo = useSelector(
    (state) => state.dashboardInformationReducer?.selectedCardInfo
  );
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  const policyNo = selectedCardInfo?.policyData?.s_PolicyNo;
  const policyType =
    selectedCardInfo?.currentPage === "health"
      ? "Health Policy"
      : selectedCardInfo?.currentPage === "motor"
        ? "Motor Policy"
        : selectedCardInfo?.currentPage === "travel"
          ? "Travel Policy"
          : "";
  const crumbLinks = [
    {
      id: 0,
      baseLink: "/",
      link: "/customerservice/opencomplaint",
      navigateLink: "/individuals/customer-service",
      crumbHeading: "Customer Service",
      crumbContent: "Open a Support Request",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 1,
      baseLink: "/",
      link: "/individuals/customer-service/medical-fraud",
      navigateLink: "/individuals/customer-service",
      crumbHeading: "Customer Service",
      crumbContent: "Medical Fraud",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 2,
      baseLink: "/",
      link: "/individuals/customer-service/motor-fraud",
      navigateLink: "/individuals/customer-service",
      crumbHeading: "Customer Service",
      crumbContent: "Motor Fraud",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 3,
      baseLink: "/",
      link: "/individuals/customer-service/travel-fraud",
      navigateLink: "/individuals/customer-service",
      crumbHeading: "Customer Service",
      crumbContent: "Travel Fraud",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 4,
      baseLink: "/",
      link: "/dashboard/tele-medicine",
      navigateLink: null,
      crumbHeading: t("common.dashboard"),
      crumbContent: t("roadAssistance.roadAssistance"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 5,
      baseLink: "/",
      link: "/aboutus",
      navigateLink: null,
      crumbHeading: "About Us",
      crumbClass: "pt-2",
      needCrumbIcon: false,
      needHighlight: false,
    },
    {
      id: 6,
      baseLink: "/",
      link: "/individuals/customer-service/support-request-confirmation",
      navigateLink: "/individuals/customer-service",
      crumbHeading: "Customer Service",
      crumbContent: "Open a Support Request",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 7,
      baseLink: "/",
      link: "/individuals/customer-service/service-questions",
      navigateLink: "/individuals/customer-service",
      crumbHeading: "Customer Service",
      crumbContent: "Product - Service Questions",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 8,
      baseLink: "/",
      link: "/individuals/customer-service/violations",
      navigateLink: "/individuals/customer-service",
      crumbHeading: "Customer Service",
      crumbContent: "Violations",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    // {
    //  id: 9,
    //  baseLink: "/",
    //  link: "/mediacenter",
    //  navigateLink: "/mediacenter",
    //  subNavigateLink: "/mediacenter/medianewscategory",
    //  crumbHeading: "Media Center",
    //  crumbContent: "News",
    //  subCrumbContent:
    //   "Tawuniya is the first company in the Kingdom to install vehicle insurance policies",
    //  crumbClass: "pt-4",
    //  needCrumbIcon: true,
    //  needHighlight: true,
    // },
    // {
    //  id: 10,
    //  baseLink: "/",
    //  link: "/mediacenter/medianewscategory",
    //  navigateLink: "/mediacenter",
    //  crumbHeading: "Media Center",
    //  crumbContent: "News",
    //  crumbClass: "pt-4",
    //  needCrumbIcon: true,
    //  needHighlight: false,
    // },
    // {
    //  id: 11,
    //  baseLink: "/",
    //  link: "/mediacenter",
    //  navigateLink: null,
    //  crumbHeading: "Media Center",
    //  crumbClass: "pt-4",
    //  needCrumbIcon: false,
    //  needHighlight: false,
    // },
    {
      id: 12,
      baseLink: "/",
      link: "/social",
      navigateLink: null,
      crumbHeading: "Social Responsibility",
      crumbClass: "pt-4",
      needCrumbIcon: false,
      needHighlight: false,
    },
    {
      id: 13,
      baseLink: "/",
      link: "/social/project",
      navigateLink: "/aboutus",
      crumbHeading: "Social Responsibility",
      crumbContent: "Health",
      subCrumbContent:
        "Tawuniya is the first company in the Kingdom to install vehicle insurance policies",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: true,
    },
    {
      id: 14,
      baseLink: "/",
      link: "/aboutus/financialhighlights",
      navigateLink: "/aboutus",
      crumbHeading: "About Us",
      crumbContent: "Financial Highlights",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 15,
      baseLink: "/",
      link: "/aboutus/board-of-directors",
      navigateLink: "/aboutus",
      crumbHeading: "About Us",
      crumbContent: "Board Of Directors & Senior Executives",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 16,
      baseLink: "/",
      link: "/individuals/customer-service/surplus",
      navigateLink: "/individuals/customer-service",
      crumbHeading: "Customer Service",
      crumbContent: "Surplus Insurance",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 16,
      baseLink: "/",
      link: "/individuals/customer-service",
      navigateLink: "/individuals/customer-service",
      crumbHeading: "Customer Service",
      crumbClass: "pt-4",
      needCrumbIcon: false,
      needHighlight: false,
    },
    {
      id: 23,
      baseLink: "/",
      link: "/individuals/products/motor/claims",
      navigateLink: "/dashboard/all-claims",
      crumbHeading: t("Breadcrum.Dashboard"),

      crumbClass: "pt-4",
      needCrumbIcon: false,
      needHighlight: false,
    },
    {
      id: 48,
      baseLink: "/",
      link: "/individuals/products/health/medical-claims",
      navigateLink: "/dashboard/medical-claims",

      crumbHeading: t("Breadcrum.Dashboard"),

      crumbClass: "pt-4",
      needCrumbIcon: false,
      needHighlight: false,
    },
    {
      id: 49,
      baseLink: "/",
      link: "/claims/motor",
      navigateLink: "/dashboard/motor-claims",

      crumbHeading: t("Breadcrum.Dashboard"),

      crumbClass: "pt-4",
      needCrumbIcon: false,
      needHighlight: false,
    },
    {
      id: 17,
      baseLink: "/",
      link: "/dashboard/tele-request",
      navigateLink: "/dashboard/tele-request",
      subNavigateLink: "/dashboard/tele-request",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "Telemedicine",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: true,
    },
    {
      id: 18,
      baseLink: "/",
      link: "/dashboard",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/tele-request",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "",
      crumbClass: "pt-4",
      needCrumbIcon: false,
      needHighlight: false,
    },
    {
      id: 19,
      baseLink: "/",
      link: "/individuals/customer-service/service-category",
      navigateLink: "/individuals/customer-service",
      crumbHeading: "Customer Service",
      crumbContent: "Motor Support",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },

    {
      id: 20,
      baseLink: "/",
      link: "/dashboard/road-assistance",
      navigateLink: "/dashboard/tele-request",
      subNavigateLink: "/dashboard/tele-request",
      crumbHeading: t("common.dashboard"),
      crumbContent: t("roadAssistance.roadAssistance"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: true,
    },
    {
      id: 21,
      baseLink: "/",
      link: "/dashboard/products-and-offerings",
      navigateLink: "/dashboard/products-and-offerings",

      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "Notifications",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 22,
      baseLink: "/",
      link: "/dashboard/support-center",
      navigateLink: "/dashboard/support-center",

      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "Support Center",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },

    {
      id: 50,
      baseLink: "/",
      link: "/dashboard/all-claims",
      navigateLink: "/dashboard/all-claims",

      crumbHeading: t("Breadcrum.Dashboard"),

      crumbClass: "pt-4",
      needCrumbIcon: false,
      needHighlight: false,
    },
    {
      id: 24,
      baseLink: "/",
      link: "/dashboard/requests-and-approvals",
      navigateLink: "/dashboard/requests-and-approvals",

      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "Your Requests & Approvals",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 25,
      baseLink: "/",
      link: "/dashboard/notifications",
      navigateLink: "/dashboard/notifications",

      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "Notifications",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 26,
      baseLink: "/",
      link: "/dashboard/your-policies",
      navigateLink: "/dashboard/your-policies",

      crumbHeading: t("Breadcrum.Dashboard"),

      crumbClass: "pt-4",
      needCrumbIcon: false,
      needHighlight: false,
    },
    {
      id: 27,
      baseLink: "/",
      link: "/dashboard/policydetails/health",
      navigateLink: "/dashboard/policydetails/health",

      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "Health Policy #23456",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 28,
      baseLink: "/",
      link: "/dashboard/policydetails/motor",
      navigateLink: "/dashboard/policydetails/motor",

      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "Motor Policy #23456",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 29,
      baseLink: "/",
      link: "/dashboard/policydetails/travel",
      navigateLink: "/dashboard/policydetails/travel",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "Travel Policy #23456",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 30,
      baseLink: "/",
      link: "/dashboard/service/periodic-inspection",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/periodic-inspection",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.PeriodicInspection"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 31,
      baseLink: "/",
      link: "/dashboard/service/road-assistance/new-request",
      navigateLink: "/",
      crumbHeading: t("common.dashboard"),
      crumbContent: t("roadAssistance.roadAssistance"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 32,
      baseLink: "/",
      link: "/dashboard/service/road-assistance/request-confirmation/1",
      navigateLink: "/",
      crumbHeading: t("common.dashboard"),
      crumbContent: t("roadAssistance.roadAssistance"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 33,
      baseLink: "/",
      link: "/dashboard/service/periodic-inspection/new-request",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/periodic-inspection/new-request",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.PeriodicInspection"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 34,
      baseLink: "/",
      link: "/dashboard/service/periodic-inspection/request-confirmation/1",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/periodic-inspection/request-confirmation/1",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.PeriodicInspection"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 35,
      baseLink: "/",
      link: "/dashboard/service/car-maintainance",
      navigateLink: "/",

      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "Car Maintenance",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 36,
      baseLink: "/",
      link: "/dashboard/service/car-maintainance/new-request",
      navigateLink: "/",

      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "Car Maintenance",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 37,
      baseLink: "/",
      link: "/dashboard/service/car-maintainance/request-confirmation/1",
      navigateLink: "/",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "Car Maintenance",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 38,
      baseLink: "/",
      link: "/dashboard/service/eligibility-letter",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/eligibility-letter",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.EligibilityLetter"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 39,
      baseLink: "/",
      link: "/dashboard/service/eligibility-letter/new-request",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/eligibility-letter/new-request",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.EligibilityLetter"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 38,
      baseLink: "/",
      link: "/dashboard/service/eligibility-letter/request-confirmation/1",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/eligibility-letter/request-confirmation/1",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.EligibilityLetter"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 39,
      baseLink: "/",
      link: "/dashboard/service",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Services"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    // {
    //   id: 40,
    //   baseLink: "/",
    //   link: "/dashboard/service/pregnancy-program",
    //   navigateLink: "/dashboard",
    //   subNavigateLink: "/",
    //   crumbHeading: t("Breadcrum.Dashboard"),
    //   crumbContent: "Pregnancy Program",
    //   crumbClass: "pt-4",
    //   needCrumbIcon: true,
    //   needHighlight: false,
    // },
    {
      id: 41,
      baseLink: "/",
      link: "/dashboard/service/request-telemedicine",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/request-telemedicine",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.Telemedicine"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 42,
      baseLink: "/",
      link: "/dashboard/service/tele-medicine/new-request",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/tele-medicine/new-request",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.Telemedicine"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 43,
      baseLink: "/",
      link: "/dashboard/service/tele-medicine/request-confirmation/1",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/tele-medicine/request-confirmation/1",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.Telemedicine"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 44,
      baseLink: "/",
      link: "/dashboard/service/medical-reimbursement",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/medical-reimbursement",
      crumbHeading: t("medical_reimbursement.dashboard"),
      crumbContent: t("medical_reimbursement.medical_reimbursement"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 45,
      baseLink: "/",
      link: "/dashboard/service/road-assistance",
      navigateLink: "/",
      subNavigateLink: "/",
      crumbHeading: t("common.dashboard"),
      crumbContent: t("roadAssistance.roadAssistance"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 46,
      baseLink: "/",
      link: "/",
      navigateLink: "/",
      subNavigateLink: "/",
      crumbHeading: t("common.dashboard"),
      crumbContent: t("roadAssistance.roadAssistance"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 47,
      baseLink: "/",
      link: "/individuals/loyalty-program/ithra",
      navigateLink: "/",
      subNavigateLink: "/",
      crumbHeading: "Loyalty Programs",
      crumbContent: "Ithra",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 47,
      baseLink: "/",
      link: "/individuals/loyalty-program/ithra-prime",
      navigateLink: "/",
      subNavigateLink: "/",
      crumbHeading: "Loyalty Programs",
      crumbContent: "Ithra-Prime",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 48,
      baseLink: "/",
      link: "/dashboard/service/pregnancy-follow-up-program",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/pregnancy-follow-up-program",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.pregnancy"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 49,
      baseLink: "/",
      link: "/dashboard/service/pregnancy-follow-up-program/new-request",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/pregnancy-follow-up-program/new-request",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.pregnancy"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 50,
      baseLink: "/",
      link: "/dashboard/service/pregnancy-follow-up-program/request-confirmation",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/pregnancy-follow-up-program/request-confirmation",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.pregnancy"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 51,
      baseLink: "/",
      link: "/dashboard/service/home-child-vaccination",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/home-child-vaccination",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.HomeChild"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 52,
      baseLink: "/",
      link: "/dashboard/service/home-child-vaccination/new-request",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/home-child-vaccination/new-request",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.HomeChild"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 53,
      baseLink: "/",
      link: "/dashboard/service/home-child-vaccination/request-confirmation",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/home-child-vaccination/request-confirmation",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.HomeChild"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 54,
      baseLink: "/",
      link: "/dashboard/service/chronic-disease-management",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/chronic-disease-management",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.chronic"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 55,
      baseLink: "/",
      link: "/dashboard/service/chronic-disease-management/new-request",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/chronic-disease-management/new-request",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.chronic"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 56,
      baseLink: "/",
      link: "/dashboard/service/chronic-disease-management/request-confirmation",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/chronic-disease-management/request-confirmation",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.chronic"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 57,
      baseLink: "/",
      link: "/dashboard/service/medical-reimbursement/new-request",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/medical-reimbursement/new-request",
      crumbHeading: t("medical_reimbursement.dashboard"),
      crumbContent: t("medical_reimbursement.medical_reimbursement"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 58,
      baseLink: "/",
      link: "/dashboard/service/medical-reimbursement/request-status",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/medical-reimbursement/request-status",
      crumbHeading: t("medical_reimbursement.dashboard"),
      crumbContent: t("medical_reimbursement.medical_reimbursement"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 59,
      baseLink: "/",
      link: "/dashboard/service/assist-america",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/assist-america",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: t("Breadcrum.AssistAmerica"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 60,
      baseLink: "/",
      link: "/dashboard/network-coverage",
      navigateLink: "/",
      subNavigateLink: "/",
      crumbHeading: t("Breadcrum.Dashboard"),
      crumbContent: "tawuniya Network",
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 61,
      baseLink: "/",
      link: "/individuals/loyalty-program/vitality",
      navigateLink: "/",
      subNavigateLink: "/",
      crumbHeading: t("Loyalty_Programs"),
      crumbContent: t("vitality.vitality"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 62,
      baseLink: "/",
      link: "/dashboard/service/embassy-letter",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/embassy-letter",
      crumbHeading: t("medical_reimbursement.dashboard"),
      crumbContent: t("embassyLetter.embassy_ltr"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 62,
      baseLink: "/",
      link: "/dashboard/service/embassy-letter/new-request",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/embassy-letter/new-request",
      crumbHeading: t("medical_reimbursement.dashboard"),
      crumbContent: t("embassyLetter.embassy_ltr"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
    {
      id: 63,
      baseLink: "/",
      link: "/dashboard/service/embassy-letter/new-request/status",
      navigateLink: "/dashboard",
      subNavigateLink: "/dashboard/service/embassy-letter/new-request",
      crumbHeading: t("medical_reimbursement.dashboard"),
      crumbContent: t("embassyLetter.embassy_ltr"),
      crumbClass: "pt-4",
      needCrumbIcon: true,
      needHighlight: false,
    },
  ];

  return (
    <React.Fragment>
      {crumbLinks.map((item, index) => {
        return (
          <React.Fragment key={item.link + index}>
            {history.location.pathname === item.link && (
              <Box className="row supportRequestContainer w-100" key={index}>
                <Box className="col-lg-12 col-12">
                  <Box className={`${item.crumbClass} d-flex flex-row breadCrumbLayout`}>
                    <Box>
                      <img
                        src={customerHome}
                        className="img-fluid cursor-pointer homeCrumbIcon pr-2"
                        onClick={() => history.push(item.baseLink)}
                        alt="icon"
                      />
                      <span
                        className="breadcrumbTitle fs-14 fw-400"
                        onClick={() => history.push(item.navigateLink)}
                      >
                        {item.crumbHeading}
                      </span>
                    </Box>
                    <Box>
                      {item.needCrumbIcon && (
                        <img src={customerBreadCrumb} className="img-fluid pl-3 pr-3" alt="icon" />
                      )}
                      <span
                        className={`${item.needHighlight ? "breadcrumbSubTitleDark" : "breadcrumbSubTitle"
                          } fs-14 fw-400`}
                        onClick={() =>
                          Boolean(item?.subNavigateLink?.length) && history.push(item?.subNavigateLink)
                        }
                      >
                        {item?.crumbContent}
                      </span>
                    </Box>
                    {item?.needHighlight && (
                      <Box>
                        <img
                          src={customerBreadCrumb}
                          className={`img-fluid pl-3 pr-3 ${selectedLanguage === "ar" ? "rtl-arrow" : ""}`}
                          alt="icon"
                        />
                        <span className="breadcrumbSubTitle fs-14 fw-400">{item.subCrumbContent}</span>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
