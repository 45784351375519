import React from "react";
import { useTranslation } from "react-i18next";

import Document from "assets/images/mobile/document.png";
import Hospital from "assets/images/mobile/hospital.png";
import health_policy_H from "assets/images/mobile/health_policy_H.svg";
import timer from "assets/svg/recentTimer.svg";
import orangeArrow from "assets/svg/orangeArrow.svg";
import viewDetails from "assets/svg/viewDetailsIcon.svg";
import chatSmile from "assets/svg/Chat-smile.svg";

import "./style.scss";

const RecentInteraction = ({
	minute = "30",
	docNumber = "17364427",
	progress = "In progress",
	title = "Car Wash",
	number = "Merc. Benz - 3576 TND",
	isHealth = false,
	recentText = false,
	viewAllText = false,
}) => {
	const { t } = useTranslation();

	return (
		<div>
			{recentText && (
				<div>
					<h4>{t("medical_reimbursement.recent_interactions")}</h4>
				</div>
			)}
			<div className="recent-card">
				<div className="d-flex justify-content-between">
					<p>
						<img src={timer} alt="Clock" />
						{minute} {t("medical_reimbursement.minutes_ago")}
					</p>
					<div className="d-flex">
						<p className="pr-2">
							<img src={Document} alt="Document" />
							{docNumber}
						</p>
						<span>{progress}</span>
					</div>
				</div>
				<div className="hospital d-flex align-items-center">
					<img
						src={isHealth ? health_policy_H : Hospital}
						className="pr-2"
						alt="Hospital"
					/>
					<div>
						<p>{title}</p>
						<h5>{number}</h5>
					</div>
				</div>
				<div className="help-section">
					<div className="get-help">
						<p>
							<img src={chatSmile} alt="GetHelp" />
							{t("medical_reimbursement.get_help")}
						</p>
					</div>
					<div className="detail">
						<h6>{t("medical_reimbursement.view_details")}</h6>
						<img src={viewDetails} alt="ArrowBlue" />
					</div>
				</div>
			</div>
			{viewAllText && (
				<div className="view_all_text_cont">
					<span className="fs-12 fw-800 pr-2 text-uppercase">
						{t("medical_reimbursement.view_all_interactions")}
					</span>{" "}
					<img
						src={orangeArrow}
						className="img-fluid orangeNewArrow"
						alt="arrow"
					/>
				</div>
			)}
		</div>
	);
};

export default RecentInteraction;
