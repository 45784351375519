  export const isValidEmail = (email) => {
    return email;
  };
  
  export const isValidSaudiIDOrIQama = (idNumber) => {
    return /^[1,2]{1}[0-9]{9}$/i.test(idNumber);
  };
  
  export const isPolicyNumber = (policyNumber) => {
    return /[0-9]$/i.test(policyNumber);
  };
  export const isClaimNumber = (claimNumber) => {
    return /[0-9]$/i.test(claimNumber);
  };
  
  
  export const isValidSaudiPhoneNumber = (value) => {
    return /^(5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/.test(value);
  };
  
  export const isNumber = (value) => {
    const regex = /[0-9]+/g;
    return regex.test(value);
  };
  
  export const isArabic = (language) => {
    return language === "ar";
  };
  
  export const numbersOnly = (event) => {
    const regex = /[0-9]+/g;
    return regex.test(event.key);
  };
  
  
  export const isSaudi = (nationalId) => {
    return nationalId && nationalId[0] === "1";
  };

  export const deleteByKey = (key, { [key]: deletedKey, ...others }) => others;

  
  