const formValidation = (errorMessages, formInput, currentError, keyName) => {
    if (!errorMessages || !formInput) return "NoData";
    let tempError = Object.assign({}, currentError);
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const phoneNumberFormat = /^\d{10}$/;
    if (keyName) {
        if (typeof tempError === "object") delete tempError[keyName];
        if (errorMessages[keyName]) {
            if (errorMessages[keyName].required) {
                if (!formInput[keyName]) {
                    if (!tempError[keyName]) tempError[keyName] = {};
                  return  tempError[keyName].required = errorMessages[keyName].required;
                }
            }
            if (errorMessages[keyName].emailError) {
                if (formInput[keyName] && !(mailformat.test(formInput[keyName]))) {
                    if (!tempError[keyName]) tempError[keyName] = {};
                    return   tempError[keyName].emailError = errorMessages[keyName].emailError;
                }
            }
        }
    } else {
        let formInputKeys = Object.keys(formInput);
        tempError = {};
        formInputKeys.map((key) => {
            if (errorMessages[key]) {
                if (errorMessages[key].required) {
                    if (!formInput[key]) {
                        if (!tempError[key]) tempError[key] = {};
                       return tempError[key].required = errorMessages[key].required;
                    } 
                }
                if (errorMessages[key].emailError) {
                    if (formInput[key] && !(mailformat.test(formInput[key]))) {
                        if (!tempError[key]) tempError[key] = {};
                        return  tempError[key].emailError = errorMessages[key].emailError;
                    }
                }
                if (errorMessages[key].phone) {
                    if (formInput[key] && !(phoneNumberFormat.test(formInput[key]))) {
                        if (!tempError[key]) tempError[key] = {};
                        return tempError[key].phone = errorMessages[key].phone;
                    }
                }
            }
        });
    }
    if (Object.keys(tempError).length !== 0) {
        return tempError;
    } else {
        return null;
    }
}

export default formValidation;