import React, { useState } from "react";
import BottomPopup from "component/common/MobileReuseable/BottomPopup";
import RoadAssistanceMobilePage from "pages/RoadAssistanceMobilePage/RoadAssistanceMobilePage";
import insuranceArrow from "assets/svg/insuranceArrow.svg";
import { setSelectedCardType } from "action/DashboardInformation";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

export const InsuranceCardMobile = ({
	data = [],
	textWidth,
	isArrow = true,
	rowWiseList = false,
}) => {
	const [isOpenServiceDetails, setIsOpenServiceDetails] = useState(false);
	const [checkservices, setCheckservices] = useState({});
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const handleselect = (e) => {
		const fSrvs = data.find((item) => item.content === e.currentTarget.target);
		setIsOpenServiceDetails(true);
		setCheckservices(fSrvs);
	};
	const trackEvent = (tabClicked) => {
		window.lmSMTObj.track(tabClicked);
	  }
	return (
		<div
			className={`insuranceBannerCardContainerMobile ${
				rowWiseList && "row m-0 row_wice_list"
			}`}
		>
			{data.map((item, index) => {
				return (
					<div key={index} className="insuranceBannerCardMobile">
						<div
							id={item.id}
							onClick={() => {
								dispatch(setSelectedCardType(item.type));
								history.push(item.url);
								trackEvent(t(item.content)+"Clicked")
							}}
						>
							<img
								src={item.cardIcon}
								className="img-fluid service-img"
								id={item.contentAlign}
								alt="icon"
							/>
							<p
								className={`${"InsuranceCardText"} fs-16 fw-800  pt-2`}
								id={textWidth}
							>
								{t(item.content)}
							</p>
							{!isArrow && (
								<img
									src={insuranceArrow}
									className="img-fluid pb-3"
									alt="icon"
								/>
							)}
						</div>
					</div>
				);
			})}

			<BottomPopup
				open={isOpenServiceDetails}
				setOpen={setIsOpenServiceDetails}
				bg={"gray"}>
				<RoadAssistanceMobilePage services={checkservices} />
			</BottomPopup>
		</div>
	);
};
