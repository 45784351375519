import React, { useEffect, useState } from "react";
import PinInput from "react-pin-input";
import { history } from "service/helpers";
import { NormalButton } from "component/common/NormalButton";
import BottomPopup from "../../../component/common/MobileReuseable/BottomPopup";
import "./style.scss";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoginResponse } from "action/LoginResponse";
import preloader from "assets/loader.gif";
import { updateLoader } from "action/DashboardInformation";
import * as LoginServices from "../../../service/login/loginservices";
import { setOtpVerified } from "action/LoginResponse";
import { externalLinks } from "helpers/constant";

const VerifyMobile = ({
  isVerifyOpen,
  setIsVerifyOpen,
  setIsOpenLoginModel,
}) => {
  const [resend, setResend] = useState(false);
  const [timer, setTimer] = useState(60);
  const mobileNumber = useSelector(
    (state) => state?.loginDetailsReducer?.customerValidation?.mobile
  );
  const CryptoJS = require("crypto-js");
  const [newTimer, setNewTimer] = useState(60);
  const loader = useSelector(
    (state) => state.dashboardInformationReducer.loader
  );
  const [addNo, setAddNo] = useState(false);
  let coUrl = "";
  const [apiResponse, setapiResponse] = useState(false);
  const [addNewNo, setAddNewNo] = useState(false);
  const [error, seterror] = useState("");
  const [saveOtp, setSaveOtp] = useState("");
   const { i18n, t } = useTranslation();  const dispatch = useDispatch();
  const resendCode = () => {
    setResend(true);
    setNewTimer(60);
  };
  const isRegistered = useSelector(
    (data) => data?.loginDetailsReducer?.registerResponse
  );

  const coClaimsRedirect = useSelector(
    (state) => state?.loginDetailsReducer?.coClaimsRedirect
  );

  const coClaimsRedirectAr = useSelector(
    (state) => state?.loginDetailsReducer?.coClaimsRedirectAr
  );

  const isLogedIN = useSelector(
    (data) => data?.loginDetailsReducer?.loginResponse
  );

  const loginInput = useSelector(
    (data) => data?.loginDetailsReducer?.loginInput
  );

  const validateOtpHandler = () => {
    dispatch(updateLoader(true));

    if (!isEmpty(isRegistered)) {
      ValidateRegister(isRegistered);
    } else {
      ValidateLogin();
    }
  };
  const ValidateRegister = (isRegistered) => {
    if (saveOtp === isRegistered.verificationCode) {
      const data = {
        transactionId: isRegistered.transactionId,
        langId: "E",
        otp: saveOtp,
      };
      LoginServices.confirmOTP(data).then((response) => {
        if (response.errorCode === "S") {
          ValidateRegisterLogin(loginInput);
        } else if (response.status === "V") {
          seterror(response.errorDescription);
          setapiResponse(true);
        } else {
          seterror(response.errorDescription);
          setapiResponse(true);
        }
      });
    } else {
      alert("please enter correct otp");
    }
  };
  const ValidateLoginOtp = (isLogedIN) => {
    const data = {
      mobileNumber: isLogedIN?.user_info?.mobile,
      langId: "E",
      otp: saveOtp,
    };

    LoginServices.verifyOtp(data).then((response) => {
      if (response.status === "1") {
        setIsVerifyOpen(false);
        setIsOpenLoginModel(false);
        dispatch(updateLoader(false));
        history.push("/dashboard");
      } else if (response.status === "2") {
        dispatch(updateLoader(false));
        seterror(response.errorDescription);
      } else {
        seterror(response.errorDescription);
      }
    });
  };
  const ValidateLogin = () => {
    dispatch(updateLoader(false));
    const data = {
      nationalId: loginInput.nationalId,
      yob: loginInput.yob,
      flag: "true",
      verifiationCode: saveOtp,
      loginType: "L",
    };

    LoginServices.customerLogin(data).then((response) => {
      if (response.errorCode === "S") {
        dispatch(setLoginResponse(response));
        setIsVerifyOpen(false);
        setIsOpenLoginModel(false);
        dispatch(updateLoader(false));
        dispatch(setOtpVerified(true));
        setapiResponse(true);
        if (coClaimsRedirect) {
          coUrl =
            externalLinks.common + "co-claims?" +
            "token1=" +
            encrypt(response?.user_info?.iqamahID) +
            "&" +
            "token2=" +
            encrypt(response?.user_info?.birthYear) +
            "&" +
            "lang=en";
            history.push(coUrl);
        } else if (coClaimsRedirectAr) {
          coUrl =
            externalLinks.common + "co-claims?" +
            "token1=" +
            encrypt(response?.user_info?.iqamahID) +
            "&" +
            "token2=" +
            encrypt(response?.user_info?.birthYear) +
            "&" +
            "lang=ar";
            history.push(coUrl);
        } else {
          history.push("/dashboard");
        }
      } else if (response.errorCode === "F") {
        dispatch(updateLoader(false));
        seterror(response.errorDescription);
        setapiResponse(true);
      } else {
        dispatch(updateLoader(false));
        seterror(response.errorDescription);
        setapiResponse(true);
      }
    });
  };

  const ValidateRegisterLogin = () => {
    dispatch(updateLoader(true));
    const data = {
      nationalId: loginInput.nationalId,
      yob: loginInput.yob,
      flag: "true",
      loginType: "R",
    };
    LoginServices.customerLogin(data).then((response) => {
      if (response.errorCode === "S") {
        dispatch(updateLoader(false));
        dispatch(setLoginResponse(response));
        dispatch(setOtpVerified(true));
        history.push("/dashboard");
      } else if (response.errorCode === "F") {
        dispatch(updateLoader(false));
        seterror(response.errorDescription);
        setapiResponse(true);
      } else {
        dispatch(updateLoader(false));
        seterror(response.errorDescription);
        setapiResponse(true);
      }
    });
  };

  const [counter, setCounter] = useState(60);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleResendClick = () => {
    setCounter(60);
    let data = {
      mobile: mobileNumber,
    };
    LoginServices.sendOTP(data).then((response) => {
      if (response.status === "1") {
        dispatch(updateLoader(false));
      } else {
        dispatch(updateLoader(false));
        seterror(response.statusDescritpion);
        setapiResponse(true);
      }
    });
  };

  function encrypt(value) {
    const idx = process.env.REACT_APP_EXTERNAL_IDX;
    const message = CryptoJS.AES.encrypt(value, idx);
    return message.toString();
  }

  return (
    <BottomPopup open={isVerifyOpen} setOpen={setIsVerifyOpen}>
      {loader && (
        <div className="loader-tawuniya">
          <img src={preloader} alt="preloader" />
        </div>
      )}
      <div className="login_page_mobile verify_page">
        <h5>{t("login_footer.veri")}</h5>
        <p>
          {t("login_footer.code")} {"****"}
          {mobileNumber?.slice(-4)}{" "}
        </p>
        <div className="">
          <PinInput
            length={4}
            initialValue=""
            onChange={(value, index) => {
              setSaveOtp(value);
            }}
            inputMode="numeric"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            inputStyle={{
              border: "none",
              backgroundColor: "#F2F3F5",
              borderRadius: "4px",
              width: "100%",
              marginRight: "2%",
              minHeight: "90px",
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </div>
        {apiResponse && <small className="p-error">{error}</small>}
        <NormalButton
          label={t("login_footer.Verify")}
          className="authContinueBtn font-Lato p-4 mt-4"
          onClick={validateOtpHandler}
        />
        <p className="fs-14 fw-400 resendText text-center pt-3">
          {t("login_footer.rese")} 00:{counter < 10 ? 0 : ""}
          {counter}
        </p>

        {counter === 0 && (
          <p className="fs-14 fw-400 noVerifyCodeMsg text-center m-0 ">
            {t("login_footer.dont")}{" "}
            <span
              className="resendAgainLink"
              onClick={() => handleResendClick()}
            >
              {t("login_footer.resend")}
            </span>
          </p>
        )}
      </div>
    </BottomPopup>
  );
};

export default VerifyMobile;
