import { JSEncrypt } from "jsencrypt";

export const encryptService = (data) => {
	const encrypt = new JSEncrypt();
	encrypt.setPublicKey(process.env.REACT_APP_ENC_VERSION);
	let encrypted;
	if (data.length <= 500) {
		encrypted = encrypt.encrypt(data);
	} else {
		encrypted = data;
	}
	return encrypted;
};
