import React, {useState} from "react";
import OtpInput from "react-otp-input";
import OtpPageWrapper from "./OtpPageStyle";
import {withTranslation} from "react-i18next";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import CountdownTimer from "./CountdownTimer";
import closeIcon from "assets/images/close-icon.svg";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {isEmpty} from "lodash";

const OtpPage = ({verifyOTP, sendOTP, closeOTP, phone, mobileNumber, hideClose}) => {
    const {t, i18n} = useTranslation();
    const [otp, setOtp] = useState("");
    const [resetCounter, updateResetCounter] = useState(5 * 60);
    const [timer, setTimer] = useState("not Yet");
    // const mobileNumber = useSelector(
    // 	(state) => state.visa.beneficiariesDetails.Mobile
    // );

    const resendOTP = () => {
        sendOTP();
        updateResetCounter(+resetCounter - 1);
        // setTimeout(() => {
        //   updateResetCounter(false);
        // }, 500);
    };

    return (
        <Dialog
            className="dialog inherit"
            visible={true}
            showHeader={false}
            closable={true}
            modal={true}
            maskClassName="test"
        >
            <OtpPageWrapper className={` ${i18n.language === "ar" ? "ar" : ""}`}>
                <div className="container-fluid ">
                    <div className="d-flex justify-content-center">
                        <div className="col-12 col-sm-12 p-4 mb-4 ">
                            {!hideClose && <div className="otp-verification-close">
                                <img src={closeIcon} alt="closeIcon" onClick={() => closeOTP()}/>
                            </div>}
                            <div className="d-flex mt-5 justify-content-center">
                                <div className="otp-verification-title">
                                    <h2 className="otpVerificationTitle mb-3">
                                        {t("OTP.verificationTitle")}
                                    </h2>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <p className="otpSentMessageStyle">
                                    {t("OTP.t-1")}

                                    <br/>
                                    {t("OTP.t-2")}
                                    <br/>
                                    <span className="fm-lato mt-2 mb-3">xxxxxxxx{!isEmpty(phone)
                                        ? phone.slice(-4)
                                        : mobileNumber && mobileNumber.slice(-4)}</span>

                                </p>
                            </div>
                            <div className="verificationCodeTitle-sub mt-3 d-flex justify-content-center">
                                {t("OTP.VERIFICATION_CODE")}
                            </div>
                            <div className="d-flex justify-content-center mt-3 mb-3" dir="ltr">
                                <OtpInput
                                    isInputNum={true}
                                    value={otp}
                                    onChange={(code) => setOtp(code)}
                                    placeholder="0000"
                                    focusStyle={{
                                        border: "1px solid #000",
                                        outline: "none",
                                        width: "60px",
                                        height: "60px",
                                    }}
                                    separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                                    numInputs={4}
                                    inputStyle="containerStyle"
                                />
                            </div>

                            <div className="d-flex justify-content-center verifyButtonDiv">
                                <Button
                                    className="otpVerifybutton"
                                    type="button"
                                    disabled={!otp || otp.length < 4}
                                    onClick={() => verifyOTP(otp)}
                                    label={t("OTP.verify")}
                                />
                            </div>

                            <h4 className="countdownTextStyle">
                                {t("OTP.RESEND_IN")} :
                                <span className="countdownStyle">
                  <CountdownTimer updatedOuterTimer={setTimer} count={resetCounter}/>
                </span>
                            </h4>
                            <h3 className="resendOtp text-center">
                                {t("OTP.DIDNT_REVICE_CODE")}
                                <Button
                                    disabled={timer !== "00:00"}
                                    className="d-block p-button-text newclass mx-auto my-2"

                                    label={t("OTP.RESEND")}
                                    onClick={() => resendOTP()}
                                />
                            </h3>
                        </div>
                    </div>
                </div>
            </OtpPageWrapper>
        </Dialog>
    );
};

export default withTranslation()(OtpPage);
