
import React from 'react';
import './styleExtention.scss'
export const ExtensionInnerCard = ({item}) => (
    <div className='extension-layout-wrapper'>
        <div className="extension-service-card">
            <div className="extensionIcon-wrapper">
                <img
                    src={item.icon}
                    className={`img-fluid extensionIcon`}
                    alt="icon"
                />
            </div>
            <p
                className={`extension-content`}
            >
                {item.subtitle}
            </p>
        </div>
    </div>
)


export default ExtensionInnerCard