import React, { Component } from "react";

export class NormalButton extends Component {
	render() {
		const {
			className = "",
			label = "",
			onClick,
			id,
			disabled = false,
			viewButtonHeader = false,
			viewButtonColorHeader = false,
			primary = false,
			src,
			adjustIcon,
			needBtnPic = false,
			needNewBtnIcon = false,
			newSrc,
			adjustNewIcon,
			alignBtn = false,
		} = this.props;

		return (
			<React.Fragment>
				{alignBtn ? (
					<button
						id={id}
						className={`btn cursor-pointer d-flex justify-content-start align-items-center
          ${primary ? "primary-btn" : ""}
          ${viewButtonHeader ? "viewButtonHeader" : ""}
          ${viewButtonColorHeader ? "viewButtonColorHeader" : ""}
          ${className}`}
						onClick={onClick}
						disabled={disabled}
					>
						{needNewBtnIcon && (
							<img src={newSrc} className={adjustNewIcon} alt="pic" />
						)}
						<span>{label}</span>

						{needBtnPic && <img className={adjustIcon} src={src} alt="pic" />}
					</button>
				) : (
					<button
						id={id}
						className={`btn cursor-pointer d-flex justify-content-center align-items-center
          ${primary ? "primary-btn" : ""}
          ${viewButtonHeader ? "viewButtonHeader" : ""}
          ${viewButtonColorHeader ? "viewButtonColorHeader" : ""}
          ${className}`}
						onClick={onClick}
						disabled={disabled}
					>
						{needNewBtnIcon && (
							<img src={newSrc} className={adjustNewIcon} alt="pic" />
						)}
						<span>{label}</span>

						{needBtnPic && <img className={adjustIcon} src={src} alt="pic" />}
					</button>
				)}
			</React.Fragment>
		);
	}
}
