import React, { useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton, AccordionContext } from "react-bootstrap";
import addIcon from "assets/svg/addIcon.svg";
import hideCollapse from "assets/svg/hideCollapse.svg";
import "./style.scss";
import { useTranslation } from "react-i18next";
import orangeArrow from "assets/svg/orangeArrow.svg";

function CustomToggle({ children, eventKey, callback }) {
	const { activeEventKey } = useContext(AccordionContext);

	const decoratedOnClick = useAccordionButton(
		eventKey,
		() => callback && callback(eventKey)
	);

	const isCurrentEventKey = activeEventKey === eventKey;

	return (
		<button type="button" className="addButton" onClick={decoratedOnClick}>
			<div className="d-flex flex-row align-items-center">
				<div>
					<img
						src={isCurrentEventKey ? hideCollapse : addIcon}
						className="accordionCollapseIcon"
						alt="icon"
					/>
				</div>
				<div>{children}</div>
			</div>
		</button>
	);
}

export const QuestionSection = ({
	questions = [],
	translation = false,
	fullWidth = false,
	heading = false,
	margin = '2rem 0'
}) => {
	const { t, i18n } = useTranslation();
	const isRTL = i18n.dir() === 'rtl'

	return (
		<div className={`questions_section ${isRTL ? "questions_section_ar" : ""}`} style={{ margin }}>
			{heading && <div className="questions_section_head">
				<h4>{t("medical_reimbursement.medical_faq.title")}</h4>
				<p className="questions_section_head_desk">
					{t("medical_reimbursement.medical_faq.description")}
				</p>
				<p className="questions_section_head_mob">
					{t("medical_reimbursement.medical_faq.description_mob")}
				</p>
			</div>}
			<div
				className="faqContainer nw-cover d-flex align-items-center"
			>
				<div className="row faqContainer_div">
					{questions.map((item, index) => {
						return (
							<div
								className={`${fullWidth ? "col-lg-12" : "col-lg-6"
									} col-md-12 col-12 acc-item`}
								key={index}
							>
								<div className="reportAccordionQuestion">
									<Accordion defaultActiveKey="1">
										<CustomToggle eventKey="0">
											<p className="fs-16 fw-400 reportAccordionQuestion_p">
												{translation ? t(item.question) : item.question}
											</p>
										</CustomToggle>
										<div>
											<Accordion.Collapse eventKey="0">
												<div className="accordion-ans" style={{ padding: "10px 0 0 0" }}>
													<p
														className="fs-16 fw-400 p-3 reportAccordionAnswer"
														style={{
															fontFamily: "Bliss 2",
															color: "#1A1C1F",
															fontSize: "0.9rem",
														}}
													>
														{translation ? t(item.answer) : item.answer}
														{' '}{item?.link && <span style={{ fontWeight: 'bold', cursor: 'pointer' }} className="reportAccordionAnswer_link" onClick={() => window.location.assign(item.link, '_blank')}>{item.link}</span>}
													</p>
													{item?.answersList && <ul className="accordian_ul">
														{item.answersList.map((ansItem, idx) => {
															return <li key={idx} className="accordian_ul_li">{translation ? t(ansItem.answer) : ansItem.answer}</li>
														})}
													</ul>}
												</div>
											</Accordion.Collapse>
										</div>
									</Accordion>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<div className="view_all_text_cont faq_mob">
				<span className="fs-12 fw-800 pr-2 text-uppercase">
					VIEW ALL QUESTIONS
				</span>
				<img
					src={orangeArrow}
					className="img-fluid orangeNewArrow"
					alt="arrow"
					width={10}
					height={10}
				/>
			</div>
		</div>
	);
};
