import {
  loader,
  policyInfo,
  medicalPolicyDetails,
  motorPolicyDetails,
  travelPolicyDetails,
  serviceDetails,
  medApprovalDetails,
  renewalPolicyDetail,
  accidentDetail,
  teleMedicalPolicyDetails,
  submitTelemedicine,
  memberHistory,
  policyInformationValue,
  motorPolicyDetailsValue,
  EligVouSerValue,
  memberShipValue,
  periodSubmit,
  updateMap,
  selectedCardData,
  updatemedicalProvider,
  updateGenerateDigitalCards,
  faxEligibility,
  updateClaimTrackDetails,
  updateMedicalClaimTrack,
  LOGOUT,
  SET_MEDICAL_MEMBER_LIST,
  SET_BENEFITS_OPEN,
  GET_DRIVE_STATUS,
  SET_POLICY_COUNT,
  OPEN_POLICY_DETAILS_INFO,
  OPEN_POLICY_DETAILS,
  OPEN_MOTOR_POLICY_DETAILS,
  SET_SELECTED_CARD_TYPE,
  SET_DIGITAL_DATA,
  DIGITAL_DATA_BOOLEAN,
  SET_MED_APPROVAL_DATA,
} from "service/actionType";
import { store } from "service/helpers";
import instance from "service/instance";

export const updateLoader = (data) => {
  return {
    type: loader,
    payload: data,
  };
};
export const setPolicyInformation = (data) => {
  return {
    type: policyInfo,
    payload: data,
  };
};
export const setAccidentDetail = (data) => {
  return {
    type: accidentDetail,
    payload: data,
  };
};
export const setSelectedCardData = (data) => {
  return {
    type: selectedCardData,
    payload: data,
  };
};
export const setMedApprovalDetails = (data) => {
  return {
    type: medApprovalDetails,
    payload: data,
  };
};
export const setMedicalPolicyDetails = (data) => {
  return {
    type: medicalPolicyDetails,
    payload: data,
  };
};
export const setMotorPolicyDetails = (data) => {
  return {
    type: motorPolicyDetails,
    payload: data,
  };
};
export const setTravelPolicyDetails = (data) => {
  return {
    type: travelPolicyDetails,
    payload: data,
  };
};
export const setServiceDetails = (data) => {
  return {
    type: serviceDetails,
    payload: data,
  };
};
export const setRenewalPolicyDetail = (data) => {
  return {
    type: renewalPolicyDetail,
    payload: data,
  };
};
export const updateMemberHistory = (payload) => {
  return {
    type: memberHistory,
    payload: payload,
  };
};
export const updateMedicalPolicyDetails = (payload) => {
  return {
    type: teleMedicalPolicyDetails,
    payload: payload,
  };
};
export const updateSubmitTelemedicine = (payload) => {
  return {
    type: submitTelemedicine,
    payload: payload,
  };
};
export const updatePolicyInformation = (payload) => {
  return {
    type: policyInformationValue,
    payload: payload,
  };
};
export const updateMotorPolicyDetails = (payload) => {
  return {
    type: motorPolicyDetailsValue,
    payload: payload,
  };
};

export const updateEligVouServ = (payload) => {
  return {
    type: EligVouSerValue,
    payload: payload,
  };
};

export const updateMemberShip = (payload) => {
  return {
    type: memberShipValue,
    payload: payload,
  };
};
export const updatePeriodSubmit = (payload) => {
  return {
    type: periodSubmit,
    payload: payload,
  };
};
export const updateMapValue = (payload) => {
  return {
    type: updateMap,
    payload: payload,
  };
};
export const updatemedicalProviderValue = (payload) => {
  return {
    type: updatemedicalProvider,
    payload: payload,
  };
};

export const updateGenerateDigitalCardsValue = (payload) => {
  return {
    type: updateGenerateDigitalCards,
    payload: payload,
  };
};

export const updateFaxEligibility = (payload) => {
  return {
    type: faxEligibility,
    payload: payload,
  };
};
export const updateMotorClaimTrackDetails = (payload) => {
  return {
    type: updateClaimTrackDetails,
    payload: payload,
  };
};
export const updateMediclaClaimTrackDetails = (payload) => {
  return {
    type: updateMedicalClaimTrack,
    payload: payload,
  };
};
export const clearReduxData = () => {
  return {
    type: LOGOUT,
  };
};
export const setMedicalMemberListInfo = (data) => {
  return {
    type: SET_MEDICAL_MEMBER_LIST,
    payload: data,
  };
};
export const setBenefitsOpen = (data) => {
  return {
    type: SET_BENEFITS_OPEN,
    payload: data,
  };
};

export const getDriveStatus = (data) => async (dispatch) => {
  const response = instance.post("/vitality/getDriverStatus", data.payload, {
    headers: {
      Authorization:
        "Bearer" +
        " " +
        store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
  });
  dispatch({
    type: GET_DRIVE_STATUS,
    payload: {
      policyNumber: data.policyNumber,
      chassisNumber: data.payload.chassisNumber,
      driverSegment: "N/A",
      ...response?.data?.getDriverStatusResponse,
    },
  });
};

export const setPolicyCount = (data) => {
  return {
    type: SET_POLICY_COUNT,
    payload: data,
  };
};

export const openPolicyDetailsInfo = (data) => {
  return {
    type: OPEN_POLICY_DETAILS_INFO,
    payload: data,
  };
};

export const openPolicyDetails = (data) => {
  return {
    type: OPEN_POLICY_DETAILS,
    payload: data,
  };
};

export const openMotorPolicyDetails = (data) => {
  return {
    type: OPEN_MOTOR_POLICY_DETAILS,
    payload: data,
  };
};

export const setSelectedCardType = (data) => {
  return {
    type: SET_SELECTED_CARD_TYPE,
    payload: data,
  };
};

export const setDigitalData = (data) => {
  return {
    type: SET_DIGITAL_DATA,
    payload: data,
  };
};

export const setDigitalDataBoolean = (data) => {
  return {
    type: DIGITAL_DATA_BOOLEAN,
    payload: data,
  };
};

export const setMedApprovalData = (data) => {
  return {
    type: SET_MED_APPROVAL_DATA,
    payload: data,
  };
};
