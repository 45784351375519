import React, { useState } from "react";
import { history } from "service/helpers";
import logo from "assets/svg/Tawuniya-Logo-light.svg";
import mainPanel from "assets/svg/dashboardIcons/_Dashboard Sidebar Item Master.svg";
import normalHome from "assets/svg/dashboardIcons/dashboardHomeIcon.svg";
import speaker from "assets/svg/dashboardIcons/_Dashboard Sidebar Item Master (1).svg";
import single from "assets/svg/dashboardIcons/_Dashboard Sidebar Item Master (2).svg";
import mail from "assets/svg/dashboardIcons/_Dashboard Sidebar Item Master (3).svg";
import calender from "assets/svg/dashboardIcons/_Dashboard Sidebar Item Master (4).svg";
import chart from "assets/svg/dashboardIcons/_Dashboard Sidebar Item Master (5).svg";
import filter from "assets/svg/dashboardIcons/_Dashboard Sidebar Item Master (6).svg";
import highightNotify from "assets/svg/dashboardIcons/highlightNotification.svg";
import highlightPolicy from "assets/svg/dashboardIcons/highlightPolicy.svg";
import highlightClaim from "assets/svg/dashboardIcons/highlightClaim.svg";
import highlightApproval from "assets/svg/dashboardIcons/highlightApproval.svg";
import highlightOffering from "assets/svg/dashboardIcons/highlightOffering.svg";
import highlightSupport from "assets/svg/dashboardIcons/highlightSupport.svg";
import settings from "assets/svg/settings.svg";
import "./style.scss";
import { useDispatch } from "react-redux";
import {
	openMotorPolicyDetails,
	openPolicyDetails,
	setBenefitsOpen,
} from "action/DashboardInformation";
import { useTranslation } from "react-i18next";

export const RoadAssisstantSideBar = () => {
	const [pillNo, setPillNo] = useState(0);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	
	const dashBoardHomeIconData = [
		{
			id: 0,
			icon: normalHome,
			highlightIcon: mainPanel,
			iconClass: "pt-2 pb-2",
			url: "/dashboard",
			title: t("myprofile.dashbaord"),
		},
		{
			id: 1,
			icon: mail,
			highlightIcon: highlightClaim,
			iconClass: "pb-2",
			url: "/dashboard/all-claims",
			title: t("Claims"),
		},
		{
			id: 2,
			icon: calender,
			highlightIcon: highlightApproval,
			iconClass: "pb-2",
			url: "/dashboard/approvals",
			title: t("YourReqApp"),
		},
		{
			id: 3,
			icon: chart,
			highlightIcon: highlightOffering,
			iconClass: "pb-2",
			url: "/dashboard/products-and-offerings",
			title: t("products&offering"),
		},
	];

	console.log(pillNo, "aka");

	return (
		<div className="row">
			<div className="col-12 p-0">
				<div className="road-sideBar">
					<div className="settingIconContainer d-flex justify-content-center">
						<img
							src={settings}
							className="img-fluid settingIcon"
							alt="logo"
							title="My Profile"
							onClick={() => history.push("/dashboard/profile")}
						/>
					</div>
					<div className="logo-sidebar-container">
						<img
							src={logo}
							className="img-fluid sidebarLogo cursor-pointer"
							onClick={() => history.push("/")}
							alt="logo"
						/>
						<div className="content-sidebar-container">
							<div className="pt-5 mt-5">
								<div className="d-flex flex-column">
									{dashBoardHomeIconData.map((item, index) => {
										dispatch(setBenefitsOpen(false));
										dispatch(openPolicyDetails(false));
										dispatch(openMotorPolicyDetails(false));
										return (
											<div
												className={item.iconClass}
												key={index}
												onClick={() => setPillNo(item.id)}
											>
												<img
													src={
														pillNo === item.id ? item.highlightIcon : item.icon
													}
													className="img-fluid mx-auto d-block road-sidebarIcons"
													alt="icon"
													onClick={() => {
														history.push(item.url);
													}}
													title={item.title}
												/>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
