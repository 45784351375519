import React from "react";
import FooterAboutCard from "../../common/FooterAboutCard";
import FooterAboutImgCard from "../../common/FooterAboutImgCard";
import UnionMini from "assets/svg/UnionMini.svg";
import "./style.scss";
import { useTranslation } from "react-i18next";
import simpleClaims from "assets/svg/simpleClaimsPrimary.svg";
import lovedCustomers from "assets/svg/lovedCustomersPrimary.svg";
import moreTlc from "assets/svg/moreTlcPrimary.svg";

export const FooterAbout = ({ needFooterIcon = false }) => {
  const { t } = useTranslation();
  const FooterAboutCardData = [
    {
      cardIcon: `${simpleClaims}`,
      cardTitle: t('footerAbout.simpleClaims'),
      cardDiscription: t('footerAbout.simpleClaimsDescription'),
    },
    {
      cardIcon: `${lovedCustomers}`,
      cardTitle: t('footerAbout.lovedCustomers'),
      cardDiscription: t('footerAbout.lovedCustomersDescription'),
    },
    {
      cardIcon: `${moreTlc}`,
      cardTitle: t('footerAbout.moreTLC'),
      cardDiscription: t('footerAbout.moreTLCDescription'),
    },
  ];
  return (
    <div className="FooterAboutContainer">
      {/* <div className="FooterAbout ">
        <div className="FootAbtLeft">
          <div className="FootAbtText w-100">
            <h5 className="FootAbtTitle mb-3">{t('footerAbout.title')}</h5>
          </div>
          <div className="FootAbtCard">
            <FooterAboutCard items={FooterAboutCardData} />
          </div>
        </div>
        <div className="FootAbtRight">
          <FooterAboutImgCard items={FooterAboutCardData} />
        </div>
      </div>
      {needFooterIcon && (
        <React.Fragment>
          <img
            alt="UnionMini"
            src={UnionMini}
            style={{ position: "absolute", bottom: "20%", left: "24%" }}
          />
          <img
            src={UnionMini}
            style={{
              position: "absolute",
              bottom: "58%",
              left: "61%",
              transform: "rotate(102.32deg)",
            }}
            alt="UnionMini"
          />
        </React.Fragment>
      )} */}
    </div>
  );
};
