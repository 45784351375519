import axios from "axios";
import { config } from "../config";
import { encryptService } from "./helpers/encrypt-service";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  "Access-Control-Allow-Headers":
    "Content-Type, Authorization, X-Requested-With",
  "Access-Control-Allow-Credentials": "true",
};

const instance = axios.create({
  baseURL: config.apiUrl,
  headers,
});

// export const preProdInstance = axios.create({
// 	baseURL: config.preProdWSUrl,
// 	headers,
// });

// export const preProdGatewayInstance = axios.create({
// 	baseURL: config.preProdGatewayUrl,
// 	headers,
// });

const excludeEncryptionUrls = [
  "/restful/preLogin/attachDocument",
  "/common/sendEmailLanding",
  "/restful/preLogin/submitDawaiRequest",
  "/restful/preLogin/submitReimbursementRequest",
  "/common/submitWFRequestLanding",
  "/restful/preLogin/complaintCaseCreation",
  "/domesticlaborinsurance/getQuotationDetailsLanding",
  "/domesticlaborinsurance/getSadadPaymentGeneration1Landing",
  "common/submitViolationLandingNew",
  "/restful/preLogin/getRegion",
  "/restful/preLogin/getCity",
  "/common/DW-LandingPageURL",
  "/common/submitViolationNewLanding",
  "/restful/preLogin/getBranchInformation"
];
const requestHandler = (request) => {
  const isExclude = excludeEncryptionUrls.includes(request.url);
  if (isExclude) return request;
  request.data = encryptService(JSON.stringify(request.data));
  return request;
};

instance.interceptors.request.use(requestHandler);

export default instance;
