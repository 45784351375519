import * as actions from "../action/roadAssistant";

const initialState = {
  requestSubmissionResult: {},
  servicesList: [],
  membershipId: null,
  carDetails: {},
}

export const RoadAssistantReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SUBMIT_ROAD_ASSISTANT_REQUEST: {
      return {...state, requestSubmissionResult: action.payload}
    }
    case actions.GET_ROAD_ASSISTANT_SERVICES: {
      return {...state, servicesList: action.payload?.servicesList}
    }
    case actions.SET_ACTIVE_CAR: {
      return {...state, carDetails: action.payload}
    }
    case actions.GET_MEMBERSHIP_ID: {
      return {...state, membershipId: action.payload?.membershipID}
    }
    default:
      return state;
  }
}
