import React from "react";
import appStore from "assets/images/appleStore.png";
import playStore from "assets/images/playStore.png";
import appGal from "assets/images/appGallery.png";
import logo from "assets/images/tawuniya-logo.svg";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
//import { history } from "service/helpers";

export const AuthFooter = () => {
    const { i18n, t } = useTranslation();
    const history = useHistory();
    const navigateBottomPage = (routeURL) => {
        routeURL && history.push(routeURL);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };
    return (
        <div className="row">
            <div className="col-lg-12 col-12 authFooterContainer px-5">
                <div className="d-flex justify-content-between">
                    <div className="leftAuthContent d-flex align-items-center">
                        <div className="footer-logo  mx-2">
                            <img src={logo} alt="logo" />
                        </div>
                        <div className="footer-links">
                            <p className="fs-14 fw-400 authCopyrightText mx-2">{t("footer.copyright")}</p>
                            <div className="d-flex flex-row">
                                {/* <div className="mx-2">
                                    <p
                                        className="fs-12 fw-400 authTerms cursor-pointer"
                                        onClick={() => navigateBottomPage("/privacy-policy")}
                                    >
                                        {t("footer.termsAndConditions")}{" "}
                                    </p>
                                </div> */}
                                <div className="mx-2">
                                    <p
                                        className="fs-12 fw-400 authTerms cursor-pointer"
                                        onClick={() => navigateBottomPage("/privacy-policy")}
                                    >
                                        {t("footer.privacyPolicy")}
                                    </p>
                                </div>
                                {/* <div className="mx-2">
                                    <p
                                        className="fs-12 fw-400 authTerms cursor-pointer"
                                        onClick={() => navigateBottomPage("/privacy-policy")}
                                    >
                                        {t("footer.cookiePolicy")}
                                    </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex flex-row rightAuthContent">
                            <div>
                                <img
                                    src={appStore}
                                    className="img-fluid authFooterBtnOne"
                                    alt="iconBtns"
                                    onClick={() =>
                                        window.location.assign(
                                            "https://apps.apple.com/us/app/%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%88%D9%86%D9%8A%D8%A9-tawuniya/id1639353191"
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <img
                                    src={playStore}
                                    className="img-fluid authFooterBtnTwo"
                                    alt="iconBtns"
                                    onClick={() =>
                                        window.location.assign(
                                            "https://play.google.com/store/apps/details?id=com.dxp.TawuniyaInsurance"
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
