import React, { useState } from "react";
import mouseScroll from "assets/svg/Mouse Scrolldown.svg";
import enterprise from "assets/svg/homeEnterprise.svg";
import { useTranslation } from "react-i18next";

import "./style.scss";
import { createTheme, ThemeProvider } from "@material-ui/core";

import normalSme from "assets/svg/smeNormal.svg";
import "./productsCatalog/products.scss";
import { ProductsCatalog } from "./productsCatalog";

const theme = createTheme({
	button: {
		color: "#FFFFFF",
	},
});

export const HomeProducts = ({ activeProducts = "individual" }) => {
	const { t } = useTranslation();
	const [pillIndex, setPillIndex] = useState(0);

	const scrollToDownload = () => {
		const section = document.querySelector('#productContainer');
		section.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};

	return (
		<ThemeProvider theme={theme}>
			<div className="mb-3">
				<img
					src={mouseScroll}
					className="img-fluid mx-auto d-block pb-4 mb-2 c-pointer"
					alt="scrollIcon"
					onClick={scrollToDownload}
				/>
				{activeProducts === "corporate" && (
					<>
						<div className="d-flex justify-content-center">
							<div>
								<div className="d-flex flex-row corporate-navtab-container-root">
									<div className="pr-3 ">
										<div
											className={
												pillIndex === 0
													? "corporate-highlight-navtab-container"
													: "corporate-normal-navtab-container"
											}
											onClick={() => setPillIndex(0)}
										>
											<div className="d-flex flex-row">
												<div className="pr-2">
													{pillIndex === 0 ? (
														<img
															src={enterprise}
															className="img-fluid"
															alt="enterprise"
														/>
													) : (
														<img
															src={normalSme}
															className="img-fluid normalCorporateIcon"
															alt="normalSme"
														/>
													)}
												</div>
												<div>
													<p className="corporate-highlight-navtab fs-16 fw-800 m-0 text-uppercase pt-1 pr-2">
														{t("products.enterprise")}
													</p>
												</div>
											</div>
										</div>
									</div>
									<div>
										<div
											className={
												pillIndex === 1
													? "corporate-highlight-navtab-container"
													: "corporate-normal-navtab-container"
											}
											onClick={() => setPillIndex(1)}
										>
											<div className="d-flex flex-row">
												<div className="pr-2">
													{pillIndex === 1 ? (
														<img
															src={enterprise}
															className="img-fluid"
															alt="enterprise"
														/>
													) : (
														<img
															src={normalSme}
															className="img-fluid normalCorporateIcon"
															alt="normalSme"
														/>
													)}
												</div>
												<div>
													<p className="corporate-highlight-navtab fs-16 fw-800 m-0 text-uppercase pt-1 pr-2">
														{t("products.sme")}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{pillIndex === 0 ? (
							<p className="text-center fs-16 fw-800 pt-2">
								{t(
									"products.For Cooperate Businesses with MORE than 250 employees"
								)}
							</p>
						) : (
							<p className="text-center fs-16 fw-800 pt-2">
								{t(
									"products.For Cooperate Businesses with LESS than 250 employees"
								)}
							</p>
						)}
					</>
				)}
			</div>
{/* 
			<ProductsCatalog
				activeProducts={activeProducts}
				SME={pillIndex === 1}
				enterprise={pillIndex === 0}
				id="productContainer"
			/> */}
		</ThemeProvider>
	);
};
