import React from "react";
import { Box, Typography, Card, Icon, Divider } from "@material-ui/core";
import serviceArrow from "assets/svg/Arrows/serviceArrow.svg";

import HealthInsurance from "assets/svg/HealthInsurance.svg";
import Customers from "assets/svg/Customers.svg";
import Diagnose from "assets/svg/Diagnose.svg";

function WctCard(props) {
	return (
		<Box
			sx={{
				width: 500,
				height: "fit-content",
				display: "flex",
				flexDirection: "column",
				padding: "35px",
				gap: "24px",
				borderRadius: 20,
				boxShadow: "0px 4px 10px rgba(9, 30, 66, 0.06)",
				backgroundColor: "#FFFFFF",
			}}
		>
			<Box
				sx={{
					height: "fit-content",
					width: 350,
				}}
			>
				<Typography
					style={{
						color: "#4C565C",
						fontSize: 15,
						fontWeight: 400,
						lineHeight: "140%",
					}}
				>
					To keep pace with the changes in customer needs, community demands,
					new technology and take an advantage of potential opportunities in
					line with recent trends in global markets, Tawuniya has developed its
					ambitious strategy for the next five years to strength its leadership
					position in the Kingdom, and MENA region.
				</Typography>
			</Box>
			<Divider />

			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					flexDirection: "column",
					height: 280,
					backgroundColor: "#fff",
					marginTop: 1,
					padding: "5px",
				}}
			>
				{props.WctCardData.map((item) => (
					<div className="FooterAbtFeature mt-2 mb-2">
						{item.cardIcon ? (
							<Icon style={{ width: "48px", height: "48px" }}>
								<img
									className="FooterAbtFeatureImg"
									src={item.cardIcon}
									alt="FooterAbtFeatureImg"
								/>
							</Icon>
						) : (
							""
						)}
						<div className="FooterAbtFetureInfo">
							<Typography className="FooterAbtFetureTitle">
								{item.cardTitle}
							</Typography>
							<Typography className="FooterAbtFetureSubtitile">
								{item.cardDiscription}
							</Typography>
						</div>
					</div>
				))}
			</Box>
		</Box>
	);
}

export default WctCard;
