import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import arSA from "./locales/ar/translation.json";
import enUS from "./locales/en/translation.json";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const resources = {
	en: {
		translation: enUS,
	},
	ar: {
		translation: arSA,
	},
};

i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
	resources,
	lng: "ar",
	supported: ["en", "ar"],
	fallbackLng: "en",
	detection: {
		order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
		caches: ['cookie'],
	},
	debug: false,
	whitelist: resources,
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
