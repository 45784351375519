import React from "react";
import "../style.scss";
import { useTranslation } from "react-i18next";
import CommonIndividualsForm from "./CommonIndividualsForm/CommonIndividualsForm";

export const CovidForm = () => {
	const btnPill = false;
	const { t, i18n } = useTranslation();
	return (
		<div className="recentFeed-SubForm mt-3">
			<div className="row">
				<div className="col-12">
					<CommonIndividualsForm
						sme={btnPill}
						application={"COVID"}
						url={`https://store.tawuniya.com.sa/${
							i18n.language === "ar" ? "ar" : "en"
						}/web/store/travel-covid19`}
					/>
					<p className="fs-9 fw-400 recentFeedTerms pt-2 m-0">
						{t("form_agreement")}
					</p>
				</div>
			</div>
		</div>
	);
};
