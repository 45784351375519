import React, { useEffect, useState } from "react";
import PinInput from "react-pin-input";
import { history } from "service/helpers";
import { NormalButton } from "component/common/NormalButton";
import BottomPopup from "../../../component/common/MobileReuseable/BottomPopup";
import "./style.scss";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import preloader from "assets/loader.gif";
import { Button } from "primereact/button";
import { updateLoader } from "action/DashboardInformation";
import * as LoginServices from "../../../service/login/loginservices";
import { setOtpVerified } from "action/LoginResponse";
import { SetNumber } from "./setNumber";
import abhisar from "assets/svg/Absher_Logosuffix.png";
import { Captcha } from "primereact/captcha";
import { useFormik } from "formik";
import * as globalUtils from "../../../helpers/utils";
import { ReactComponent as CreditCardIcon } from "assets/svg/credit-card.svg";
import { InputText } from "primereact/inputtext";
import {
	setLoginResponse,
	setLoginInput,
	setRegisterResponse,
	setCustomerValidation,
	setopenpopup,
	setLoginResetResponse,
} from "action/LoginResponse";
import { ReactComponent as Calender } from "assets/svg/menu/Vectorxalender.svg";

const ResetMobile = ({
	isResetOpen,
	setisResetOpen,
	setIsOpenLoginModel,
	newOpen = false,
}) => {
	const mobileNumber = useSelector(
		(state) => state?.loginDetailsReducer?.customerValidation?.mobile
	);

	const RestLoginVal = useSelector(
		(state) => state?.loginDetailsReducer?.loginRestResponse
	);

	const loader = useSelector(
		(state) => state.dashboardInformationReducer.loader
	);

	const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
	const { t } = useTranslation();
	const dispatch = useDispatch();


	const [counter, setCounter] = useState(60);
	const [error, seterror] = useState("");
	const [saveOtp, setSaveOtp] = useState("");
	const [apiResponse, setapiResponse] = useState(false);
	const [isOtpAcived, setIsOtpAcived] = useState(false);

	useEffect(() => {
		const timer =
			counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		return () => clearInterval(timer);
	}, [counter]);
	const handleResendClick = () => {
		setCounter(60);

		const value = {
			customerId: RestLoginVal.user_info.iqamahID,
			mobileNumber: RestLoginVal.user_info.mobile,
			eMail: RestLoginVal.user_info.EMail,
		};
		LoginServices.sendElm(value).then((response) => {
			setapiResponse(true);
			if (response.statusCode === "S") {
				setIsOtpAcived(true);
			} else {
				seterror(response.statusMessage);
			}
		});
	};

	const [isValidCaptcha, setIsValidCaptcha] = useState(false);

	const [isSetNumOpen, setisSetNumOpen] = useState(false);

	const validateOtpHandler = () => {
		const info = {
			customerId: RestLoginVal.user_info.iqamahID,
			verificationCode: saveOtp,
		};
		LoginServices.validateElm(info).then((response) => {
			if (response.statusCode === "S") {
				dispatch(setopenpopup(true));
				setisResetOpen(false);
			} else {
				seterror(response.statusMessage);
			}
		});
	};

	const formik = useFormik({
		initialValues: {
			nationalId: "",
			yob: "",
		},
		validate: (data) => {
			let errors = {};
			if (!data.nationalId) {
				errors.nationalId = t("login_footer.national_id");
			} else if (!globalUtils.isValidSaudiIDOrIQama(data.nationalId)) {
				errors.nationalId = t("login_footer.id_not_valid");
			}

			if (!data.yob) {
				errors.yob = t("login_footer.year_birth");
			} else if (
				(data.yob[0] === "1" && data.yob[1] !== "9") ||
				(data.yob[0] === "2" && data.yob[1] !== "0")
			) {
				errors.yob = t("login_footer.year_birth");
			}

			return errors;
		},
		onSubmit: (data) => {
			if (isValidCaptcha) {
				const info = {
					nationalId: data.nationalId,
					yob: data.yob,
					flag: "true",
					loginType: "R",
				};

				LoginServices.customerLogin(info).then((response) => {
					setapiResponse(true);
					if (response.errorCode === "S") {
						dispatch(setLoginResetResponse(response));

						TriggerSendElm(response);
					} else if (response.errorCode === "F") {
						seterror(response.errorDescription);
					} else {
						seterror(response.errorDescription);
					}
				});
				dispatch(setLoginInput(data));
				// dispatch(updateLoader(true));
			} else {
				seterror("Please Fill the Captcha");
				setapiResponse(true);
			}
		},
	});

	const TriggerSendElm = (response) => {
		const value = {
			customerId: response.user_info.iqamahID,
			mobileNumber: response.user_info.mobile,
			eMail: response.user_info.EMail,
		};

		LoginServices.sendElm(value).then((response) => {
			setapiResponse(true);
			if (response.statusCode === "S") {
				setIsOtpAcived(true);
			} else {
				seterror(response.statusMessage);
			}
		});
	};

	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};
	return (
		<BottomPopup
			open={isResetOpen}
			setOpen={setisResetOpen}
			closeResetpopup={true}
			newopen
		>
			{loader && (
				<div className="loader-tawuniya">
					<img src={preloader} alt="preloader" />
				</div>
			)}
			{isOtpAcived ? (
				<div className="login_page_mobile verify_page">
					<h5>{t("login_footer.veri")}</h5>
					<p>
						{t("login_footer.abhisar-code")} xxxx
						{RestLoginVal?.user_info?.mobile?.slice(-4)}
					</p>
					<div className="">
						<PinInput
							length={4}
							initialValue=""
							onChange={(value, index) => {
								setSaveOtp(value);
							}}
							inputMode="numeric"
							style={{
								display: "flex",
								justifyContent: "center",
							}}
							inputStyle={{
								border: "none",
								backgroundColor: "#F2F3F5",
								borderRadius: "4px",
								width: "100%",
								marginRight: "2%",
								minHeight: "90px",
							}}
							autoSelect={true}
							regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
						/>
					</div>
					{apiResponse && <small className="p-error">{error}</small>}
					<NormalButton
						label={t("login_footer.Verify")}
						className="authContinueBtn font-Lato p-4 mt-4"
						onClick={validateOtpHandler}
					/>
					<p className="fs-14 fw-400 resendText text-center pt-3">
						{t("login_footer.rese")} 00:{counter < 10 ? 0 : ""}
						{counter}
					</p>

					{counter === 0 && (
						<p className="fs-14 fw-400 noVerifyCodeMsg text-center m-0 ">
							{t("login_footer.dont")}{" "}
							<span
								className="resendAgainLink"
								onClick={() => handleResendClick()}
							>
								{t("login_footer.resend")}
							</span>
						</p>
					)}
				</div>
			) : (
				//take care of Login input

				<div className="login_page_mobile verify_page">
					<h5 className="absher">{t("login_footer.abhser")}</h5>
					<img src={abhisar} alt="abhisar" />
					<form
						onSubmit={formik.handleSubmit}
						className="p-fluid mt-4 formclass"
					>
						<div className="p-input-icon-left mt-4 field d-flex">
							<CreditCardIcon
								className={`${selectedLanguage === "ar" ? "card-rtl" : ""}`}
							/>
							<InputText
								name="nationalId"
								value={formik.values.nationalId}
								maxLength={10}
								onChange={formik.handleChange}
								className={`${selectedLanguage === "ar" ? "input-rtl" : ""
									}`}
								placeholder={t("login_footer.id")}
							/>
						</div>
						{getFormErrorMessage("nationalId")}
						<div className="p-input-icon-left mt-4 field d-flex">
							<Calender
								className={`${selectedLanguage === "ar" ? "card-rtl" : ""}`}
							/>
							<InputText
								name="yob"
								value={formik.values.yob}
								className={`${selectedLanguage === "ar" ? "input-rtl" : ""
									}`}
								placeholder={t("login_footer.birth")}
								onChange={formik.handleChange}
								keyfilter="int"
								maxLength={4}
							/>
						</div>
						{getFormErrorMessage("yob")}

						<div className="col-12 mt-3">
							{selectedLanguage === "en" && (
								<Captcha
									siteKey="6LepJyUhAAAAAIJRzeb3kqeRe0De3_lVYFvo7-PD"
									onResponse={(_token) => setIsValidCaptcha(true)}
									onExpire={() => setIsValidCaptcha(false)}
								/>
							)}
							{selectedLanguage === "ar" && (
								<Captcha
									siteKey="6LepJyUhAAAAAIJRzeb3kqeRe0De3_lVYFvo7-PD"
									onResponse={(_token) => setIsValidCaptcha(true)}
									onExpire={() => setIsValidCaptcha(false)}
									language="ar"
								/>
							)}
						</div>
						{apiResponse && (
							<small className="p-error mt-1 mb-1">{error}</small>
						)}
						<Button
							label={t("login_footer.conti")}
							className={"authContinueBtn font-Lato p-4 mt-4 "}
							onClick={formik.submitForm}
							type="button"
						/>
					</form>
				</div>
			)}
		</BottomPopup>
	);
};

export default ResetMobile;
