import {
  GET_MEDICAL_REIMBURSEMENT_DETAILS,
  GET_MEDICAL_POLICY_DETAILS,
  SUBMIT_MEDICAL_REIMBURSEMENT_REQUEST,
  SET_BANK_DETAILS,
  TOGGLE_RECENT_INTERACTION_LOADING,
  SET_EMBASSY_LETTER,
  SET_EMBASSY_LETTER_MEM_DETAILS,
} from "service/actionType";

const initialState = {
  previousRequest: {},
  members: [],
  reimbursementDetails: {},
  bankDetails: [],
  recentInteractionLoading: false,
  embassyLetter: null,
  embassyMemDetails: {
    name: '',
    embassy: '',
    currency: ''
  }
};

const medicalReimbursementReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEDICAL_REIMBURSEMENT_DETAILS:
      return { ...state, previousRequest: action.payload };
    case GET_MEDICAL_POLICY_DETAILS:
      return { ...state, members: action.payload };
    case SUBMIT_MEDICAL_REIMBURSEMENT_REQUEST:
      return { ...state, reimbursementDetails: action.payload };
    case SET_BANK_DETAILS:
      return { ...state, bankDetails: action.payload }
    case TOGGLE_RECENT_INTERACTION_LOADING:
      return { ...state, recentInteractionLoading: action.payload }
    case SET_EMBASSY_LETTER:
      return { ...state, embassyLetter: action.payload }
    case SET_EMBASSY_LETTER_MEM_DETAILS:
      return { ...state, embassyMemDetails: action.payload }
    default:
      return state;
  }
}

export default medicalReimbursementReducer;