import tawuniyaDrive from "assets/svg/tawuniyaDrivePrimary.svg";
import eligibility from "assets/svg/eligibilityPrimary.svg";
import roadSide from "assets/svg/roadSidePrimary.svg";
import ithra from "assets/svg/ithraPrimary.svg";
import Reimbursement from "assets/svg/Reimbursement.svg";
import Refill from "assets/svg/Refill.svg";
import chronic from "assets/svg/chronic.svg";
import motorInspect from "assets/svg/motorInspectPrimary.svg";
import freeCarMaintainence from "assets/svg/freeCarMaintainence.svg";
import endtoendClaim from "assets/svg/endtoendClaim.svg";
import covid from "assets/svg/TawuniyaServicesIcons/covidIcon.svg";
import vaccinate from "assets/svg/dashboardIcons/childVaccination.svg";
import pregnancy from "assets/svg/PregnancyProgram.svg";
import requestEligibilityIcon from "assets/images/request-eligibility-icon.svg";
import embassyLetter from "assets/svg/embassy_letter.svg";
import { externalLinks } from "helpers/constant";


export const HomeServicesData = [
    {
        Search: true,
        serviceTitle: "Tawuniya support is here to help",
        hrLine: true,
        serviceSubtitle: "Always available to answer your questions",
        Services: [
            {
                id: 0,
                pillName: "All Services",
                serviceCardData: [
                    // {
                    //     headingEl: "Tawuniya drive",
                    //     discrptionEl: "It mesures your driving behavior and rewards you weekly,",
                    //     iconE1: `${tawuniyaDrive}`,
                    //     url: "/individuals/loyalty-program/drive",
                    //     fullUrl: "",
                    // },

                    // {
                    //     headingEl: "Ithra",
                    //     discrptionEl:
                    //         "Special discounts on a wide range of services and products that are supplied by distinguished partners and famous brands in various sectors",
                    //     iconE1: `${ithra}`,
                    //     url: "/individuals/loyalty-program/ithra",
                    // },
                    // {
                    //     headingEl: "Medical Reimbursement",
                    //     discrptionEl: "Service to request replacement of medical expenses",
                    //     iconE1: `${Reimbursement}`,
                    //     url: "/individuals/products/health/medical-reimbursement",
                    // },
                    {
                        headingEl: "Refill of chronic diseases medication",
                        discrptionEl: "A service to receive your medication without visiting your doctor",
                        iconE1: `${Refill}`,
                        url: "/login",
                    },
                    {
                        headingEl: "Chronic disease management",
                        discrptionEl:
                            "Comprehensive health care that takes care of people who suffer from chronic diseases",
                        iconE1: `${chronic}`,
                        url: "/individuals/customer-service/chronic-disease-management",
                    },
                    {
                        headingEl: "Roadside assistance",
                        discrptionEl: "We provide drivers with the required assistance on the road",
                        iconE1: `${roadSide}`,
                        url: "/individuals/customer-service/road-assistance",
                        fullUrl: "",
                    },
                    {
                        headingEl: "Motor vehicle periodic inspection",
                        discrptionEl: "Transporting the vehicle to the periodic inspection  station.",
                        iconE1: `${motorInspect}`,
                        url: "/individuals/customer-service/periodic-inspection",
                    },
                    // {
                    //     headingEl: "Pregnancy follow-up program",
                    //     discrptionEl:
                    //         "A program that takes care of a pregrant mother and increases her awareness of all procedures",
                    //     iconE1: `${pregnancy}`,
                    //     url: "/individuals/customer-service/pregnancy-follow-up-program",
                    // },
                    // {
                    //     headingEl: "Eligibility letter",
                    //     discrptionEl: "A letter to confirm your right to get the necessary treatment",
                    //     iconE1: `${requestEligibilityIcon}`,
                    //     url: "/login",
                    // },
                    {
                        headingEl: "Embassy Letter",
                        discrptionEl:
                            "A letter confirming to the embassy that you have an insurance while traveling outside the kingdom",
                        iconE1: `${embassyLetter}`,
                        url: "/login",
                    },
                    {
                        headingEl: "Home child vaccination",
                        discrptionEl: "A service to request vaccination for your children at home",
                        iconE1: `${vaccinate}`,
                        url: "/individuals/customer-service/home-child-vaccination",
                    },
                    {
                        headingEl: "Travel insurance including Covid-19 cover",
                        discrptionEl: "It is a new insurance product for Citizens under the age of 18",
                        iconE1: `${covid}`,
                        url: "/login",
                    },
                ],
            },
            {
                id: 1,
                pillName: "Motor",
                serviceCardData: [
                    // {
                    //     headingEl: "Tawuniya drive",
                    //     discrptionEl: "It mesures your driving behavior and rewards you weekly,",
                    //     iconE1: `${tawuniyaDrive}`,
                    //     url: "/individuals/loyalty-program/drive",
                    //     fullUrl: "",
                    // },
                    {
                        headingEl: "Roadside assistance",
                        discrptionEl: "We provide drivers with the required assistance on the road",
                        iconE1: `${roadSide}`,
                        url: "/individuals/customer-service/road-assistance",
                        fullUrl: "",
                    },
                    {
                        headingEl: "Motor vehicle periodic inspection",
                        discrptionEl: "Transporting the vehicle to the periodic inspection  station.",
                        iconE1: `${motorInspect}`,
                        url: "/individuals/customer-service/periodic-inspection",
                    },

                    // {
                    //     headingEl: "Ithra",
                    //     discrptionEl:
                    //         "Special discounts on a wide range of services and products that are supplied by distinguished partners and famous brands in various sectors",
                    //     iconE1: `${ithra}`,
                    //     url: "/individuals/loyalty-program/ithra",
                    // },
                ],
            },
            {
                id: 2,
                pillName: "General insurance",
                serviceCardData: [
                    // {
                    //     headingEl: "Medical Reimbursement",
                    //     discrptionEl: "For Reimbursed your expenses outside the network",
                    //     iconE1: `${Reimbursement}`,
                    //     url: "/individuals/products/health/medical-reimbursement",
                    // },
                    // {
                    //     headingEl: "Refill of chronic diseases medication",
                    //     discrptionEl: "A service to receive your medication without visiting your doctor",
                    //     iconE1: `${Refill}`,
                    //     url: "/login",
                    // },
                    // {
                    //     headingEl: "Chronic disease management",
                    //     discrptionEl:
                    //         "Comprehensive health care that takes care of peoplewho suffer from chronic diseases",
                    //     iconE1: `${chronic}`,
                    //     url: "/individuals/customer-service/chronic-disease-management",
                    // },
                    // {
                    //     headingEl: "Pregnancy follow-up program",
                    //     discrptionEl:
                    //         "A program that takes care of a pregrant mother and increases her awareness of all procedures",
                    //     iconE1: `${pregnancy}`,
                    //     url: "/individuals/customer-service/pregnancy-follow-up-program",
                    // },
                    // {
                    //     headingEl: "Eligibility letter",
                    //     discrptionEl: "A letter to confirm your right to get the necessary treatment",
                    //     iconE1: `${requestEligibilityIcon}`,
                    //     url: "/login",
                    // },
                    {
                        headingEl: "Home child vaccination",
                        discrptionEl: "A service to request vaccination for your children at home",
                        iconE1: `${vaccinate}`,
                        url: "/individuals/customer-service/home-child-vaccination",
                    },
                    {
                        headingEl: "Embassy Letter",
                        discrptionEl:
                            "A letter confirming to the embassy that you have an insurance while traveling outside the kingdom",
                        iconE1: `${embassyLetter}`,
                        url: "/login",
                    },
                ],
            },
            {
                id: 3,
                pillName: "Health & Life",
                serviceCardData: [
                    {
                        headingEl: "Travel insurance including Covid-19 cover",
                        discrptionEl: "It is a new insurance product for Citizens under the age of of 18",
                        iconE1: `${covid}`,
                        url: '/individuals/products/property-casualty/international-travel'
                    },
                ],
            },
        ],
    },
];

export const HomeServicesDataAr = [
    {
        Search: true,
        serviceTitle: "دعم التعاونية هنا لمساعدتك",
        hrLine: true,
        serviceSubtitle: "دائما متاح للإجابة على أسئلتك",
        Services: [
            {
                id: 0,
                pillName: "جميع الخدمات",
                serviceCardData: [
                    // {
                    //     headingEl: "التعاونية درايف",
                    //     discrptionEl: "يقيس سلوكك في القيادة ويكافئك أسبوعيًا",
                    //     iconE1: `${tawuniyaDrive}`,
                    //     url: "/individuals/loyalty-program/drive",
                    //     fullUrl: "",
                    // },

                    // {
                    //     headingEl: "إثراء",
                    //     discrptionEl:
                    //         "خصومات خاصة على مجموعة كبيرة من الخدمات والمنتجات التي يقدمها شركاء مميزون وعلامات تجارية مشهورة في مختلف القطاعات",
                    //     iconE1: `${ithra}`,
                    //     url: "/individuals/loyalty-program/ithra",
                    // },
                    // {
                    //     headingEl: "استعاضة مصروفات صحية",
                    //     discrptionEl: "لسداد التكاليف عند علاجك خارج الشبكة",
                    //     iconE1: `${Reimbursement}`,
                    //     url: "/login",
                    // },
                    {
                        headingEl: "إعادة صرف أدوية الأمراض المزمنة",
                        discrptionEl: "خدمة لصرف أدويتك دون الحاجة لزيارة طبيبك",
                        iconE1: `${Refill}`,
                        url: "/login",
                    },
                    {
                        headingEl: "إدارة الأمراض المزمنة",
                        discrptionEl: "برنامج يقدم رعاية صحية شاملة لخدمة المصابين بالأمراض المزمنة",
                        iconE1: `${chronic}`,
                        url: "/individuals/customer-service/chronic-disease-management",
                    },
                    {
                        headingEl: "خدمة المساعدة على الطريق",
                        discrptionEl: "خدمة توفر لك العديد من الخدمات لمساعدتك أثناء تعطل مركبتك في الطريق ",
                        iconE1: `${roadSide}`,
                        url: "/individuals/customer-service/road-assistance",
                        fullUrl: "",
                    },
                    {
                        headingEl: "خدمة الفحص الدوري",
                        discrptionEl:
                            "تتيح لك هذه الخدمة نقل مركبتك مجانا إلى مراكز الفحص الدوري لإجراء الفحص الدوري وإعادتها لك مع التقرير",
                        iconE1: `${motorInspect}`,
                        url: "/individuals/customer-service/periodic-inspection",
                    },
                    // {
                    //     headingEl: "برنامج متابعة الحمل ",
                    //     discrptionEl: "برنامج يهتم بالأم الحامل ويهتم بزيادة وعيها بكافة الإجراءات",
                    //     iconE1: `${pregnancy}`,
                    //     url: "/individuals/customer-service/pregnancy-follow-up-program",
                    // },
                    // {
                    //     headingEl: "خطاب الأهلية",
                    //     discrptionEl: "إصدار خطاب من التعاونية يوضح استحقاقك للعلاج في حالة حدوث أي مشكلة ",
                    //     iconE1: `${requestEligibilityIcon}`,
                    //     url: "/login",
                    // },
                    {
                        headingEl: "تطعيمات الأطفال المنزلية",
                        discrptionEl: "خدمة لطلب تطعيم أطفالك من المنزل",
                        iconE1: `${vaccinate}`,
                        url: "/individuals/customer-service/home-child-vaccination",
                    },
                    {
                        headingEl: "تأمين السفر و كوفيد-19",
                        discrptionEl: "هو منتج تأمين جديد للمواطنين الذين تقل أعمارهم عن 18 عامًا",
                        iconE1: `${covid}`,
                        url: "https://tawuniya.com/products/travel",
                    },
                ],
            },
            {
                id: 1,
                pillName: "تأمين المركبات",
                serviceCardData: [
                    {
                        headingEl: "خدمة المساعدة على الطريق",
                        discrptionEl: "خدمة توفر لك العديد من الخدمات لمساعدتك أثناء تعطل مركبتك في الطريق",
                        iconE1: `${roadSide}`,
                        url: "/individuals/customer-service/road-assistance",
                        fullUrl: "",
                    },
                    // {
                    //     headingEl: "التعاونية درايف",
                    //     discrptionEl: "يقيس سلوكك في القيادة ويكافئك أسبوعيًا",
                    //     iconE1: `${tawuniyaDrive}`,
                    //     url: "/individuals/loyalty-program/drive",
                    //     fullUrl: "",
                    // },

                    {
                        headingEl: "خدمة الفحص الدوري",
                        discrptionEl:
                            "تتيح لك هذه الخدمة نقل مركبتك مجانا إلى مراكز الفحص الدوري لإجراء الفحص الدوري وإعادتها لك مع التقرير",
                        iconE1: `${motorInspect}`,
                        url: "/individuals/customer-service/periodic-inspection",
                    },

                    // {
                    //     headingEl: "إثراء",
                    //     discrptionEl:
                    //         "خصومات خاصة على مجموعة كبيرة من الخدمات والمنتجات التي يقدمها شركاء مميزون وعلامات تجارية مشهورة في مختلف القطاعات",
                    //     iconE1: `${ithra}`,
                    //     url: "/individuals/loyalty-program/ithra",
                    // },
                ],
            },
            {
                id: 2,
                pillName: "تأمين العام",
                serviceCardData: [
                    // {
                    //     headingEl: "استعاضة مصروفات صحية",
                    //     discrptionEl: "لسداد التكاليف عند علاجك خارج الشبكة",
                    //     iconE1: `${Reimbursement}`,
                    //     url: "/login",
                    // },
                    // {
                    //     headingEl: "إعادة صرف أدوية الأمراض المزمنة",
                    //     discrptionEl: "خدمة لصرف أدويتك دون الحاجة لزيارة طبيبك",
                    //     iconE1: `${eligibility}`,
                    //     url: "/login",
                    // },
                    // {
                    //     headingEl: "إداة الأمراض المزمنة",
                    //     discrptionEl: "برنامج يقدم رعاية صحية شاملة لخدمة المصابين بالأمراض المزمنة",
                    //     iconE1: `${chronic}`,
                    //     url: "/individuals/customer-service/chronic-disease-management",
                    // },
                    // {
                    //     headingEl: "برنامج متابعة الحمل",
                    //     discrptionEl: "برنامج يهتم بالأم الحامل ويهتم بزيادة وعيها بكافة الإجراءات",
                    //     iconE1: `${pregnancy}`,
                    //     url: "/individuals/customer-service/pregnancy-follow-up-program",
                    // },
                    // {
                    //     headingEl: "خطاب الأهلية",
                    //     discrptionEl: "إصدار خطاب من التعاونية يوضح استحقاقك للعلاج في حالة حدوث أي مشكلة ",
                    //     iconE1: `${requestEligibilityIcon}`,
                    //     url: "/login",
                    // },
                    {
                        headingEl: "تطعيمات الأطفال المنزلية",
                        discrptionEl: "خدمة لطلب تطعيم أطفالك من المنزل",
                        iconE1: `${vaccinate}`,
                        url: "/individuals/customer-service/home-child-vaccination",
                    },
                    {
                        headingEl: "خطاب السفارة",
                        discrptionEl: "خطاب للسفارة يأكد بأن لديك تأمينًا أثناء سفرك خارج المملكة",
                        iconE1: `${embassyLetter}`,
                        url: "/login",
                    },
                ],
            },
            {
                id: 3,
                pillName: "تأمين الصحة والحياة",
                serviceCardData: [
                    {
                        headingEl: "تأمين السفر و كوفيد-19",
                        discrptionEl: "هو منتج تأمين جديد للمواطنين الذين تقل أعمارهم عن 18 عامًا",
                        iconE1: `${covid}`,
                        url: '/individuals/products/property-casualty/international-travel'
                    },
                ],
            },
        ],
    },
];
