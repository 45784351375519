import React from "react";
import { useSelector } from "react-redux";
import "./NormalSearch.scss";
import ErrorComponent from "component/common/ErrorComponent";
import { useTranslation } from "react-i18next";

export const NormalSearch = ({
  placeholder = "",
  onChange = {},
  value = "",
  name = "",
  type = "text",
  handleInputSearch,
  className,
  leftIcon,
  needRightIcon = false,
  needLeftIcon = false,
  searchAligner,
  hideLeftIcon,
  errorMessage,
  iconPlace,
  fromMotor,
  maxLength,
  hover,
}) => {
  const getArabStore = localStorage.getItem("newTawuniyaLanguageParam");
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  const searchTrackEvent = (SearchInput) => {
    window.lmSMTObj.track("searchIconClicked", {
      SearchInput: SearchInput,
    });
  };
  return (
    <>
      <div className="normal-search">
        {needLeftIcon && (
          <React.Fragment>
            {getArabStore ? (
              <div className="rightIconBox">
                <div className={`rightIcon ${iconPlace && "icon-place"}`}>
                  <img
                    src={leftIcon}
                    className="img-fluid Inputicon"
                    alt="leftIcon"
                  />
                </div>
              </div>
            ) : (
              <div className="leftIconBox">
                <div className="leftIcon" id={hideLeftIcon}>
                  <img
                    src={leftIcon}
                    className="img-fluid Inputicon"
                    alt="leftIcon"
                  />
                </div>
              </div>
            )}
          </React.Fragment>
        )}

        <input
          className={`${className} searchBox ${isRTL ? "searchBox-rtl" : ""} ${
            needLeftIcon ? "padLeftIcon" : ""
          }`}
          id={getArabStore ? "userIdplaceHolderAlign" : ""}
          name={name}
          type={type}
          maxLength={maxLength || 50}
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            searchTrackEvent(e.target.value);
            if (fromMotor) return onChange(e);
            let body = {};
            let tempVal = e.target.value;

            body = {
              target: {
                name: e.target.name,
                value: tempVal,
              },
            };

            onChange(body);
          }}
          ref={hover}
        />
        {needRightIcon && (
          <span className={` ${isRTL ? "search-icon-rtl" : "search-icon"}`}>
            <i
              className="icon-search fs-20"
              id={searchAligner}
              onClick={handleInputSearch}
            />
          </span>
        )}
      </div>
      {console.log(errorMessage)}
      {errorMessage && <ErrorComponent message={errorMessage} />}
    </>
  );
};
