import React, { useEffect, useRef, useState } from "react";
// import { history } from "service/helpers";
import { useHistory } from "react-router-dom"
import { CovidForm, HealthForm, MotorForm, TravelForm } from "./RecentForms";
import { NormalSearch } from "../NormalSearch";
import { NormalButton } from "../NormalButton";
import { ServiceFeedBackForm } from "../ServiceFeedBackForm";
import { feedbackContentData } from "component/common/MockData/NewMockData";
import { emojiFiData } from "component/common/MockData/NewMockData";
import { ratingData } from "component/common/MockData/NewMockData";
import searchIcon from "assets/svg/headerSearchLight.svg";
import languageIcon from "assets/svg/languageIcon.svg";
import modeIcon from "assets/svg/modeIcon.svg";
import appStore from "assets/images/appleStore.png";
import playStore from "assets/images/playStore.png";
import appGal from "assets/images/appGallery.png";
import messenger from "assets/svg/Facebook messenger.svg";
import editor from "assets/svg/Edit File.svg";
import locatePin from "assets/svg/Location pin.svg";
import grids from "assets/svg/Grid.svg";
import phoneIcon from "assets/svg/phoneCircleIcon.svg";
import motor from "assets/svg/recentFeedMotor.svg";
import normalHealth from "assets/svg/recentFeedNormalHealth.svg";
import normalTravel from "assets/svg/recentFeedNormalTravel.svg";
import covidNormal from "assets/svg/covidNormalPill.svg";
import "./style.scss";
import {
    LanguageCard,
    SupportCard,
    GetStartedCard,
    EmergencyCard,
} from "component/HomePage/LandingComponent/NavbarCards";
import { useDispatch, useSelector } from "react-redux";
import { arabTranslate } from "action/LanguageAct";
import { clearReduxData } from "action/DashboardInformation";
import { NavbarDashboard } from "component/DashBoard/DashBoardHome/NavbarDashboard";
import { useTranslation } from "react-i18next";
import { DetectOutsideClicks } from "hooks";
import { staticSearchData } from "component/common/MockData";
import whiteCarMob from "assets/svg/whitecarmob.svg";
import whiteHeartMob from "assets/svg/whiteHeartMob.svg";
import blueHeartMob from "assets/images/healthBlue.svg";
import blueTravelMob from "assets/images/travelBlue.svg";
import blueCoronaMob from "assets/images/covidBlue.svg";

export const RecentFeeds = ({ isWhite = false }) => {
    const [search, setSearch] = useState("");
    const [langSpecificResults, setLangSpecificResults] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const history = useHistory()
    const arrayIcons = [
        {
            icon: appStore,
            url: "https://apps.apple.com/us/app/%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%88%D9%86%D9%8A%D8%A9-tawuniya/id1639353191",
        },
        {
            icon: playStore,
            url: "https://play.google.com/store/apps/details?id=com.dxp.TawuniyaInsurance",
        },
        // {
        // 	icon: appGal,
        // 	url: ""
        // },
    ];
    const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
    const isRTL = selectedLanguage === "ar" ? true : false;
    const { t, i18n } = useTranslation();
    const searchArea = useRef(null);
    const isClickOutside = DetectOutsideClicks(searchArea);
    const searchAreaEmergency = useRef(null);
    const isOutsideClickEmergency = DetectOutsideClicks(searchAreaEmergency);

    const arrayHelpIcons = [
        {
            id: 0,
            icon: messenger,
            label: t("dashboardHome.Chat"),
            link: "https://wa.me/00966920019990",
        },
        {
            id: 1,
            icon: editor,
            label: t("Complaints"),
            link: "/individuals/customer-service/support",
        },
        {
            id: 2,
            icon: locatePin,
            label: t("PolicyDetailsRight.Locate"),
            link: "/contactus",
        },
        {
            id: 3,
            icon: grids,
            label: t("PolicyDetailsRight.AllServices"),
            link: "/dashboard/service",
        },
    ];

    const [pillIndex, setPillIndex] = useState(0);

    const [update, setUpdate] = useState({
        needForm: true,
        qOne: true,
        qTwo: true,
        qThree: true,
    });

    const [toggle, setToggle] = useState(false);
    const [languageCard, setLanguageCard] = useState(false);
    const [supportCard, setSupportCard] = useState(false);
    const [getStartedCard, setGetStartedCard] = useState(false);
    const [emergencyCard, setEmergencyCard] = useState(false);
    const [productToggle, setProductToggle] = useState(false);
    const [arabToggle, setArabToggle] = useState(false);
    const dispatch = useDispatch();
    const [isSearchOpened, setIsSearchOpened] = useState(false);
    const { language } = useSelector((data) => data.languageReducer);

    const pillData = [
        {
            id: 0,
            normalPillIcon: whiteCarMob,
            highlightPillIcon: motor,
            pillLabel: t("Motor"),
            normalPillClass: "",
            higlightPillClass: "",
            content: <MotorForm />,
        },
        {
            id: 1,
            normalPillIcon: whiteHeartMob,
            highlightPillIcon: blueHeartMob,
            pillLabel: t("Health"),
            normalPillClass: "",
            higlightPillClass: "",
            content: <HealthForm />,
        },
        {
            id: 2,
            normalPillIcon: normalTravel,
            highlightPillIcon: blueTravelMob,
            pillLabel: t("Travel"),
            normalPillClass: "",
            higlightPillClass: "",
            content: <TravelForm />,
        },
    ];

    const handleToggler = () => {
        setToggle(true);
    };

    const translateHandler = () => {
        setArabToggle(true);
        selectedLanguage === "ar" ? dispatch(arabTranslate(false)) : dispatch(arabTranslate(true));
    };

    const closeToggler = () => {
        setToggle(false);
    };

    const toggleLanguageCard = () => {
        setLanguageCard(!languageCard);
    };

    const toggleSupportCard = () => {
        setSupportCard(!supportCard);
    };

    const toggleGetStartedCard = () => {
        setGetStartedCard(!getStartedCard);
    };

    const toggleEmergencyCard = () => {
        setEmergencyCard(!emergencyCard);
    };

    const handleLogoutClick = () => {
        dispatch(clearReduxData());
        history.push("/");
    };
    const [searchResultOpen, setSearchResultOpen] = useState(false);

    const handleActionClick = (url) => {
        setIsSearchOpened(false);
        setSearch("");
        history.push(url.replace('https://www.tawuniya.com', ''));
    };

    useEffect(() => {
        if (language) {
            if (language === "en") {
                const lSpecData = staticSearchData.filter((item) => item.lang === "EN");
                setLangSpecificResults(lSpecData);
            } else if (language === "ar") {
                const lSpecData = staticSearchData.filter((item) => item.lang === "AR");
                setLangSpecificResults(lSpecData);
            }
        }
    }, [language]);

    useEffect(() => {
        if (selectedLanguage === "ar") {
            document.getElementsByTagName("body")[0].classList.add("language-alignment-right");
            i18n.changeLanguage("ar");
        } else {
            document.getElementsByTagName("body")[0].classList.remove("language-alignment-right");
            i18n.changeLanguage("en");
        }
    }, [selectedLanguage]);

    const handleInputChange = (e) => {
        setSearch(e.target.value);
        setSearchResultOpen(true);
    };

    useEffect(() => {
        if (isSearchOpened) {
            isClickOutside && setIsSearchOpened(false);
        }
    }, [isClickOutside]);

    useEffect(() => {
        if (emergencyCard) {
            isOutsideClickEmergency && setEmergencyCard(false);
        }
    }, [isOutsideClickEmergency]);

    useEffect(() => {
        if (supportCard) {
            isClickOutside && setSupportCard(false);
        }
    }, [isClickOutside]);

    useEffect(() => {
        if (getStartedCard) {
            isClickOutside && setGetStartedCard(false);
        }
    }, [isClickOutside]);

    useEffect(() => {
        if (!search) return setSearchResults([]);

        const fSearch = langSpecificResults.filter((item) =>
            item.keywords.split(",").find((itm) => itm.includes(search))
        );
        setSearchResults(fSearch);
    }, [search]);

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-12 recentFeedContainer">
                    <div className="row">
                        <div className={"col-12 top-menu"}>
                            <NavbarDashboard
                                handleToggler={handleToggler}
                                toggleLanguageCard={toggleLanguageCard}
                                toggleSupportCard={toggleSupportCard}
                                toggleEmergencyCard={toggleEmergencyCard}
                                toggleGetStartedCard={toggleGetStartedCard}
                                isLogo={false}
                                isWhite={isWhite}
                            />
                        </div>
                        <div className="col-12 px-0 paddingContainer">
                            {languageCard ? (
                                <LanguageCard
                                    arabToggle={arabToggle}
                                    translateHandler={translateHandler}
                                    // desktopNavBar={true}
                                    toggleLangCard={toggleLanguageCard}
                                />
                            ) : null}
                            {supportCard ? (
                                <div ref={searchArea}>
                                    {" "}
                                    <SupportCard />{" "}
                                </div>
                            ) : null}
                            {getStartedCard ? (
                                <div ref={searchArea}>
                                    <GetStartedCard />{" "}
                                </div>
                            ) : null}
                            {emergencyCard ? (
                                <div ref={searchAreaEmergency}>
                                    {" "}
                                    <EmergencyCard setEmergencyCard={setEmergencyCard} />{" "}
                                </div>
                            ) : null}
                        </div>
                    </div>{" "}
                    <div className="col-lg-12 col-12 recent-main-container">
                        <div className="recentContainer">
                            <p className="fs-18 fw-800 recentFeed-getInsureLabel m-0 pb-2">
                                {t("motor-insurence.Get-insured")}
                            </p>
                            <div className="recentFeed-navpillFlow">
                                {pillData.map((item, index) => {
                                    return (
                                        <div className="pillBox" key={index}>
                                            <div
                                                className={item.id === pillIndex ? "recentFeedHighlightPill" : "recentFeedNormalPill"}
                                                onClick={() => {
                                                    setPillIndex(item.id);
                                                }}
                                            >
                                                <img
                                                    src={item.id === pillIndex ? item.highlightPillIcon : item.normalPillIcon}
                                                    className={`"img-fluid pillIcon ${isRTL ? "pl-1" : "pr-2"}`}
                                                    alt={item.pillLabel}
                                                />{" "}
                                                <span className="fs-12 fw-400 recentFeed-normalPillLabel">{item.pillLabel}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {pillData.map((item, index) => {
                                        return <div key={index}>{item.id === pillIndex && item.content}</div>;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="subDashboardContainer p-3">
                        <div className="searchDashboardContainer">
                            <p className="text-center fs-16 fw-800 helptext m-0 pb-2">{t("dashboardHome.HelpYou")}</p>
                            <div className="otherServiceNavCont" ref={searchArea}>
                                <React.Fragment>
                                    <NormalSearch
                                        className="headerSearch"
                                        name="search"
                                        value={search}
                                        placeholder={t("navbar.searchPlaceholder")}
                                        onChange={handleInputChange}
                                        needRightIcon={true}
                                    />
                                    {searchResultOpen && search ? (
                                        <div className="searchResultBox">
                                            <p className="fs-12 fw-800 search-ResultTitle">{t("navbar.results")}</p>
                                            {search.length === 0 ? (
                                                <p className="fs-10 fw-400 search-ResultPara">{t("navbar.noResultFound")}</p>
                                            ) : (
                                                <React.Fragment>
                                                    <p className="fs-10 fw-400 search-ResultPara">
                                                        {t("navbar.search.found")} {searchResults.length} {t("navbar.search.res_for")}
                                                        <span>“</span>
                                                        <span className="results_color">{search}</span>
                                                        <span>”</span>
                                                    </p>
                                                    {searchResults.map((item, idx) => {
                                                        return (
                                                            <div className="d-flex justify-content-between resultContainer" key={idx}>
                                                                <div className="py-2">
                                                                    <p className="fs-10 fw-400 result_Name m-0">{item.category}</p>
                                                                </div>
                                                                <div
                                                                    className="py-2 resultContainer_action"
                                                                    onClick={() => handleActionClick(item.webUrl)}
                                                                >
                                                                    <p className="fs-10 fw-400 result_Title m-0 cursor-pointer" style={{ color: "#6b47f5" }}>{item.action}</p>

                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            )}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </React.Fragment>
                            </div>
                            <div className="row pt-3 px-3">
                                {arrayHelpIcons.map((item, index) => {
                                    return (
                                        <div
                                            className="col-3 p-0 pr-1"
                                            onClick={() =>
                                                item.icon === messenger ? window.location.assign(item.link) : history.push(item.link)
                                            }
                                            key={index}
                                        >
                                            <div className="dashboard-helpIcons" key={index}>
                                                <img src={item.icon} className="img-fluid mx-auto d-block" alt="icon" />
                                                <p className="fs-9 fw-400 helpIconLabel text-center pt-2">{item.label}</p>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {/* <LandingProductsOfferings isDesktop={true} /> */}
                        <div className="d-flex flex-row dashboard-socialIconBox pt-3 pb-5">
                            {arrayIcons.map((items) => {
                                return (
                                    <div>
                                        <a title={items.url} href={items.url} target="_blank">
                                            <img src={items.icon} className="img-fluid socio-icons" alt="buttonIcon" />
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="col-12 pl-5">
                    <ServiceFeedBackForm
                        feedbackContentData={feedbackContentData}
                        emojiData={emojiFiData}
                        ratingData={ratingData}
                        update={update}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};
