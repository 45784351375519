import { useEffect, useState } from "react";

function DetectOutsideClicks(ref) {
	const [isClickOutside, setIsClickOutside] = useState(null);
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				setIsClickOutside(true);
			} else {
				setIsClickOutside(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);
	return isClickOutside;
}

export default DetectOutsideClicks;
