import React from "react";
import { Card } from "@material-ui/core";
import styles from "./style.module.scss";

export const CommonCard = ({ width, cardPosition, children }) => {
  return (
    <div className={cardPosition}>
      <Card className={styles.cardContainer} style={{ width: width }}>
        {children}
      </Card>
    </div>
  );
};
