import { languageTranslate } from "service/actionType";
export const arabTranslate = (data) => (dispatch) => {
	return new Promise(() => {
		dispatch({
			type: languageTranslate.languageArab,
			payload: data,
		});
	});
};
export const layoutHandler = (data) => (dispatch) => {
	return new Promise(() => {
		dispatch({
			type: languageTranslate.layoutPosition,
			payload: data,
		});
	});
};
export const requestedServiceSelector = (data) => (dispatch) => {
	return new Promise(() => {
		dispatch({
			type: languageTranslate.requestedService,
			payload: data,
		});
	});
};
export const updateLanguage = (data) => (dispatch) => {
	return new Promise(() => {
		const lang = (data === "english" || data === "en")? "en" : "ar";
		localStorage.setItem("language", lang);
		localStorage.setItem("newTawuniyaLanguageParam", lang);
		localStorage.setItem("lang", lang);
		dispatch({
			type: languageTranslate.language,
			payload: lang,
		});
	});
};
export const updateLayout = (data) => (dispatch) => {
	return new Promise(() => {
		dispatch({
			type: languageTranslate.updateLayout,
			payload: data,
		});
	});
};

export const toggleCanvasHandler = (data) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: languageTranslate.toggleCanvas,
			payload: data,
		});
	});
};

export const toggleValueHandler = (data) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: languageTranslate.toogleValue,
			payload: data,
		});
	});
};

export const toggleContentHandler = (data) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: languageTranslate.toggleContent,
			payload: data,
		});
	});
};

