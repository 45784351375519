import React from "react";
import "./productCard.scss";
import "./productCardAlternate.scss";
import { Button, Card, CardActions, CardContent } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const ProductCardAlternate = (product) => {
 const matchesMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
 const matchesLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { i18n, t } = useTranslation(); return (
  <div
   className={`product-container alternate ${matchesMd ? " lg" : ""} ${matchesLg ? " lg" : ""}`}
  >
   <Card className="product-card">
    <div className="bg-image">
     <img alt="Tawanya" src={product?.data?.backgroundImage} />
    </div>
    <CardContent>
     <div className="top">
      <span className="icon-container">
       <img alt="Tawanya" className="logo" src={product.data.logo} />
      </span>
     </div>
     <div className="bottom">
      <h4>{product.data.name}</h4>
      <h5>{product.data.brief}</h5>
     </div>
    </CardContent>
    <CardActions className="cardActions">
     <Button className="explore" size="small" variant="contained">
      <Link to={product.data.redirect} className="global_link global_link_flex">
       {t("Explore_More")}
       <ArrowForwardIosIcon />
      </Link>
     </Button>
    </CardActions>
   </Card>
  </div>
 );
};
