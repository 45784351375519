import React, { useEffect, useState } from "react";
import PinInput from "react-pin-input";
import { NormalButton } from "component/common/NormalButton";
import BottomPopup from "../../../component/common/MobileReuseable/BottomPopup";
import "./style.scss";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoginResponse } from "action/LoginResponse";
import preloader from "assets/loader.gif";
import { updateLoader } from "action/DashboardInformation";
import * as LoginServices from "../../../service/login/loginservices";
import { setOtpVerified } from "action/LoginResponse";
import { history } from "service/helpers";
const VerifyEmailOtp = ({
  isEmailVerifyOpen,
  setIsEmailVerifyOpen,
  setIsOpenLoginModel,
  email,
  id,
  setGccEmail = () => { },
  setEmailChange = () => { },
  emailFormik,
}) => {
  const mobileNumber = useSelector(
    (state) => state?.loginDetailsReducer?.customerValidation?.mobile
  );
  const loader = useSelector(
    (state) => state.dashboardInformationReducer.loader
  );
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  const [apiResponse, setapiResponse] = useState(false);
  const [error, seterror] = useState("");

  const [counter, setCounter] = useState(60);
  const [saveOtp, setSaveOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const { i18n, t } = useTranslation(); const dispatch = useDispatch();
  const [resendError, setResendError] = useState("");

  const validateOtpHandler = () => {
    let data = {
      emailId: email,
      otpCode: saveOtp,
      channel: "Portal",
      language: selectedLanguage.slice(0, 1).toUpperCase(),
    };
    dispatch(updateLoader(true));
    LoginServices.verifyEmailOtp(data)
      .then((response) => {
        if (response.status === "1") {
          dispatch(setOtpVerified(true));
          let updateEmailData = {
            idNumber: id,
            emailID: email,
            quotationNumber: "15679161",
            branchCode: "1",
            lang: selectedLanguage.slice(0, 1).toUpperCase(),
          };
          LoginServices.updateGccCustEmail(updateEmailData)
            .then((response) => {
              if (response.status === "S") {
                dispatch(setOtpVerified(true));
                history.push("/dashboard");
              } else {
                setResendError(response?.statusDescritpion);
              }
            })
            .finally(() => {
              dispatch(updateLoader(false));
            });
        } else {
          //debugger;
          setOtpError(response?.statusDescritpion);
        }
      })
      .finally(() => {
        dispatch(updateLoader(false));
      });
  };

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleResendClick = () => {
    setCounter(60);
    dispatch(updateLoader(true));
    let requestData = {
      emailID: email,
      channel: "Portal",
      languageCode: selectedLanguage.slice(0, 1).toUpperCase(),
      LOB: "GCCLogin",
    };
    LoginServices.sendEmailOtp(requestData)
      .then((response) => {
        if (response?.status === "1") {
          dispatch(updateLoader(false));
        }
      })
      .catch(() => { })
      .finally(() => {
        dispatch(updateLoader(false));
      });
  };

  const handleChangeEmailClick = () => {
    setIsEmailVerifyOpen(false);
    setIsOpenLoginModel(true);
    setGccEmail(true);
    setEmailChange(true);
    if (emailFormik) {
      emailFormik.setFieldValue("email", "");
    }
  };

  useEffect(() => {
    setOtpError("");
  }, [saveOtp]);

  return (
    <BottomPopup open={isEmailVerifyOpen} setOpen={setIsEmailVerifyOpen}>
      {loader && (
        <div className="loader-tawuniya">
          <img src={preloader} alt="preloader" />
        </div>
      )}
      <div className="login_page_mobile verify_page">
        <div className="d-flex flex-column">
          <h5 className="d-flex justify-content-center">
            {t("login_footer.veri")}
          </h5>
          <span className="text-center">{`${t("CodeSent")} ******${email
            .split("@")[0]
            .slice(-4)}@${email.split("@")[1]}`}</span>
          <span
            className="change-email-text d-flex justify-content-center mt-2 mb-2"
            onClick={() => handleChangeEmailClick()}
          >
            {t("ChangeEmail")}
          </span>
        </div>
        <div className="">
          <PinInput
            length={4}
            initialValue=""
            onChange={(value, index) => {
              setSaveOtp(value);
            }}
            inputMode="numeric"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            inputStyle={{
              border: "none",
              backgroundColor: "#F2F3F5",
              borderRadius: "4px",
              width: "100%",
              marginRight: "2%",
              minHeight: "90px",
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
        </div>
        {resendError.length > 0 && (
          <span className="email-error">{resendError}</span>
        )}{" "}
        {otpError.length > 0 && <span className="email-error">{otpError}</span>}{" "}
        <NormalButton
          label={t("login_footer.Verify")}
          className="authContinueBtn font-Lato p-4 mt-4"
          onClick={validateOtpHandler}
          disabled={saveOtp.length < 4}
        />
        <p className="fs-14 fw-400 resendText text-center pt-3">
          {t("login_footer.rese")} 00:{counter < 10 ? 0 : ""}
          {counter}
        </p>
        {counter === 0 && (
          <p className="fs-14 fw-400 noVerifyCodeMsg text-center m-0 ">
            {t("login_footer.dont")}{" "}
            <span
              className="resendAgainLink"
              onClick={() => handleResendClick()}
            >
              {t("login_footer.resend")}
            </span>
          </p>
        )}
      </div>
    </BottomPopup>
  );
};

export default VerifyEmailOtp;
