import { COMPLAINT_CASE_CREATION_REQUEST,
  COMPLAINT_CASE_CREATION_SUCCESS,
  COMPLAINT_ENQUIRY, LOGOUT} from "service/actionType";
  
const initialState = {
  complaintReqDetails: {},
  complaintResDetails: {},
  complaintEnqDetails: {},
};

const complaintReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPLAINT_CASE_CREATION_REQUEST:
      return {
        ...state,
        complaintReqDetails: action.payload,
      };
    case COMPLAINT_CASE_CREATION_SUCCESS:
      return {
        ...state,
        complaintResDetails: action.payload,
      };
    case COMPLAINT_ENQUIRY:
      return {
        ...state,
        complaintEnqDetails: action.payload,
      }

    case LOGOUT:
        return {};

    default:
      return state;
  }
};

export default complaintReducer;
  