import { loginResponse, LOGOUT } from "service/actionType";
import { loginInput } from "service/actionType";
import { registerInput } from "service/actionType";
import { registerResponse } from "service/actionType";
import { SET_OTP_VERIFIED } from "service/actionType";
import { OPEN_POPUP } from "service/actionType";
import { SET_RESET_LOGIN_RESPONSE } from "service/actionType";
import { SET_OPENED_MOBILE } from "service/actionType";
import {
  UPDATE_EMAIL,
  UPDATE_MOBILE,
  SET_CUSTOMER_VALIDATION,
  SET_OPEN_MOBILE_NUMBER_INPUT,
  SET_GCC_USER_INFO,
  SET_CO_CLAIMS_REDIRECT,
  SET_CO_CLAIMS_REDIRECT_AR,
} from "service/actionType";

const initialState = {
  loginResponse: {},
  loginInput: {},
  registerInput: {},
  registerResponse: {},
  isResetOpen: false,
  isOtpVerified: false,
  customerValidation: {},
  loginRestResponse: {},
  isMobileOpened: false,
  gccUserInfo: {},
  coClaimsRedirect: false,
  coClaimsRedirectAr: false,
};

const loginDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginResponse:
      return {
        ...state,
        loginResponse: action.payload,
      };
    case loginInput:
      return {
        ...state,
        loginInput: action.payload,
      };
    case registerInput:
      return {
        ...state,
        registerInput: action.payload,
      };
    case SET_OPENED_MOBILE:
      return {
        ...state,
        isMobileOpened: action.payload,
      };

    case registerResponse:
      return {
        ...state,
        registerResponse: action.payload,
      };
    case LOGOUT:
      return {};
    case SET_OTP_VERIFIED:
      return {
        ...state,
        isOtpVerified: action.payload,
      };
    case OPEN_POPUP:
      return {
        ...state,
        isResetOpen: action.payload,
      };

    case OPEN_POPUP:
      return {
        ...state,
        isResetOpen: action.payload,
      };

    case SET_RESET_LOGIN_RESPONSE:
      return {
        ...state,
        loginRestResponse: action.payload,
      };

    case UPDATE_MOBILE:
      return {
        ...state,
        loginResponse: {
          ...state.loginResponse,
          user_info: {
            ...state.loginResponse.user_info,
            mobile: action.payload,
          },
        },
      };
    case UPDATE_EMAIL:
      return {
        ...state,
        loginResponse: {
          ...state.loginResponse,
          user_info: {
            ...state.loginResponse.user_info,
            EMail: action.payload,
          },
        },
      };
    case SET_CUSTOMER_VALIDATION:
      return {
        ...state,
        customerValidation: action.payload,
      };
    case SET_GCC_USER_INFO:
      return {
        ...state,
        gccUserInfo: action.payload,
      };
    case SET_CO_CLAIMS_REDIRECT:
      return {
        ...state,
        coClaimsRedirect: action.payload,
      };
    case SET_CO_CLAIMS_REDIRECT_AR:
      return {
        ...state,
        coClaimsRedirectAr: action.payload,
      };
    default:
      return state;
  }
};

export default loginDetailsReducer;
