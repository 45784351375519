import {
  loginResponse,
  LOGOUT,
  SET_CUSTOMER_VALIDATION,
} from "service/actionType";
import { loginInput } from "service/actionType";
import { registerInput } from "service/actionType";
import { registerResponse } from "service/actionType";
import { SET_OTP_VERIFIED } from "service/actionType";
import { UPDATE_MOBILE, UPDATE_EMAIL } from "service/actionType";
import { OpenMobileNumberInput } from "service/actionType";
import { OPEN_POPUP } from "service/actionType";
import { SET_RESET_LOGIN_RESPONSE } from "service/actionType";
import { SET_OPENED_MOBILE } from "service/actionType";
import {
  SET_GCC_USER_INFO,
  SET_CO_CLAIMS_REDIRECT,
  SET_CO_CLAIMS_REDIRECT_AR,
} from "service/actionType";
export const setLoginResponse = (data) => {
  return {
    type: loginResponse,
    payload: data,
  };
};
export const setLoginInput = (data) => {
  return {
    type: loginInput,
    payload: data,
  };
};
export const setRegisterInput = (data) => {
  return {
    type: registerInput,
    payload: data,
  };
};
export const setRegisterResponse = (data) => {
  return {
    type: registerResponse,
    payload: data,
  };
};
export const logoutEvent = (data) => {
  return {
    type: LOGOUT,
    payload: data,
  };
};

export const setOtpVerified = (data) => {
  return {
    type: SET_OTP_VERIFIED,
    payload: data,
  };
};

export const updateMobile = (data) => {
  return {
    type: UPDATE_MOBILE,
    payload: data,
  };
};

export const updateEmail = (data) => {
  return {
    type: UPDATE_EMAIL,
    payload: data,
  };
};

export const setCustomerValidation = (data) => {
  return {
    type: SET_CUSTOMER_VALIDATION,
    payload: data,
  };
};

export const setopenpopup = (data) => {
  return {
    type: OPEN_POPUP,
    payload: data,
  };
};

export const setLoginResetResponse = (data) => {
  return {
    type: SET_RESET_LOGIN_RESPONSE,
    payload: data,
  };
};

export const setOpenedMobile = (data) => {
  return {
    type: SET_OPENED_MOBILE,
    payload: data,
  };
};

export const setGccUserInfo = (data) => {
  return {
    type: SET_GCC_USER_INFO,
    payload: data,
  };
};

export const setCoClaimsRedirect = (data) => {
  return {
    type: SET_CO_CLAIMS_REDIRECT,
    payload: data,
  };
};

export const setCoClaimsRedirectAr = (data) => {
  return {
    type: SET_CO_CLAIMS_REDIRECT_AR,
    payload: data,
  };
};
