import React from "react";
import "./style.scss";
import timer from "assets/svg/recentTimer.svg";
import hospitalicon from "assets/svg/hospitalicon.svg";
import DocumentIcon from "assets/svg/document-icon-grey.svg";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import viewDetails from "assets/images/view-details-right-chevron.svg";
import { setMedApprovalData } from "action/DashboardInformation";
import { history } from "service/helpers";

export const RecentPolicyCard = (props) => {
  const { i18n, t } = useTranslation();
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  const isRTL = selectedLanguage === "ar" ? true : false;
  const timeNow = moment(props.data?.t_RequestDate).format("DD/MM/YYYY");
  const dispatch = useDispatch();
  const handleViewClick = () => {
    dispatch(setMedApprovalData(props?.data));
    history.push("/dashboard/approval-details");
  };
  return (
    <div
      className={"recentPolicyContainer" + (props?.greyBg ? " grey-bg" : "")}
    >
      <div className="d-flex justify-content-between">
        <span className="fs-10 fw-400 timerText">
          {" "}
          <img src={timer} className="img-fluid pr-1" alt="icon" /> {timeNow}
        </span>
        <div className={props.data?.fileName ? "status-with-file-name" : ""}>
          {props.data?.fileName && (
            <span className="file-name fs-10 fw-400 ">
              <img src={DocumentIcon} alt="DocumentIcon" /> {props.data?.fileName}
            </span>
          )}
          <p
            className={
              "fs-12 fw-400 rpc-progressLabel m-0 " +
              (props.data?.s_Status === "Completed" ? "completed" : "")
            }
          >
            {props.data?.s_Status}
          </p>
        </div>
      </div>
      <div className="rpc-sub-container">
        <div className="d-flex flex-row">
          <div className={`policyIconContainer ${isRTL ? "pl-2" : "pr-2"}`}>
            <img
              src={hospitalicon}
              className="img-fluid policyIcon"
              alt="hospitalicon"
            />
          </div>
          <div className={`${isRTL ? "mr-2" : ""}`}>
            <span className="med-approval">{t("MedApproval")}</span>
            <p className="fs-12 fw-400 policytitle m-0">
              {props.data?.s_ProviderName}
            </p>
            <p className="fs14 fw-500 rpc-userName m-0">
              {props.data?.s_MemberName}
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end align-items-center pt-2 pb-2">
        <div
          className="cursor-pointer"
          onClick={() => {
            handleViewClick();
          }}
        >
          {" "}
          <span className="view-details">View Details</span>
          <img
            src={viewDetails}
            alt="viewDetails"
            className={`${selectedLanguage === "ar" ? "view-details-chevron" : "ml-2"
              }`}
          />
        </div>
      </div>
    </div>
  );
};
