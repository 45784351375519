import React from "react";
import Motorcar from "assets/svg/alshammelCarBlue.svg";
import Groupcar from "assets/svg/Groupcar.svg";
import liabilityCar from "assets/svg/liabilityCar.svg";
import breakDown from "assets/svg/carBreakdown.svg";
import sanad from "assets/svg/motorSanadBlue.svg";
import catalog from "assets/svg/motor/catalog.svg";
import sanadPlus from "assets/svg/motorSanadPlusBlue.svg";
import "./style.scss";
import { getI18n, useTranslation } from "react-i18next";
import { GenericButton } from "component/common/Buttons/GenericButton";
import { LandingSiteFilesLink } from "../../../helpers/constant";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export const MotorProductCard = () => {
  const history = useHistory();
  const { i18n, t } = useTranslation(); const motorProductCardData = [
    {
      id: 0,
      headerTitle: t("motorPageData.insuranceCard.firstSection.title"),
      headerIcon: Groupcar,
      progress: "individual-ProgressLevelOne",
      subHeader: t("motorPageData.insuranceCard.firstSection.subtitle"),
      subPara: t("motorPageData.insuranceCard.firstSection.para"),
      subParaClass: "motorCard-SubParaOne",
      newPara: t("motorPageData.insuranceCard.firstSection.availableProducts"),
      paddClass: "pt-4 mt-4",
      needAllProducts: true,
      newButtonConatinerData: [
        {
          id: 0,
          contentIcon: Motorcar,
          content: t("motorPageData.insuranceCard.firstSection.alshamal"),
        },
      ],
    },
    {
      id: 1,
      headerTitle: t("motorPageData.insuranceCard.secondSection.title"),
      headerIcon: liabilityCar,
      progress: "individual-ProgressLevelTwo",
      subHeader: t("motorPageData.insuranceCard.secondSection.subtitle"),
      subPara: t("motorPageData.insuranceCard.secondSection.para"),
      subParaClass: "motorCard-SubParaTwo",
      newPara: t("motorPageData.insuranceCard.secondSection.availableProducts"),
      paddClass: "pt-3",
      needAllProducts: true,
      newButtonConatinerData: [
        {
          id: 0,
          contentIcon: sanad,
          content: t("motorPageData.insuranceCard.secondSection.sanad"),
        },
        {
          id: 1,
          contentIcon: sanadPlus,
          content: t("motorPageData.insuranceCard.secondSection.sanadPlus"),
        },
      ],
    },
    // {
    //  id: 2,
    //  headerTitle: t("motorPageData.insuranceCard.thirdSection.mechanical_breakdown"),
    //  headerIcon: breakDown,
    //  progress: "individual-ProgressLevelOne",
    //  subHeader: t("motorPageData.insuranceCard.thirdSection.title"),
    //  subPara: t("motorPageData.insuranceCard.thirdSection.subtitle"),
    //  subParaClass: "motorCard-SubParaThree",
    //  newPara: t("motorPageData.insuranceCard.secondSection.availableProducts"),
    //  needAllProducts: false,
    // },
  ];
  const openInNewTab = (url) => {
    const openInNewTab = (url) => {
      document.getElementById("insuranceForm").scrollIntoView();
      // const newWindow = window.open(url1, "_blank", "noopener,noreferrer");
      // if (newWindow) newWindow.opener = null;
      // url && history.push(url)
      // url && history.push(url)
    };

    const downloadFile = () => {
      window.open(LandingSiteFilesLink.MechanicalBreakdownFile);
    };

    const currentLanguage = getI18n().language;
    let buyNowLink =
      currentLanguage === "ar"
        ? "https://www.tawuniya.com/ar/individuals"
        : "https://www.tawuniya.com/en/individuals";
    return (
      <div className="row motorProduct-CardContainer flex-property font-Lato py-5">
        {motorProductCardData.map((item, index) => {
          return (
            <div
              className={`${item.id === 1 && "pl-lg-0 pr-lg-0"} col-lg-4 col-10 pb-lg-0 pb-3`}
              key={index}
            >
              <div className="motorProduct-CardLayout">
                <div className="d-flex justify-content-between pb-3 align-items-center">
                  <p className="motorCard-headerContent">{item.headerTitle}</p>
                  <img className="motor-cardcarIcon img-fluid" src={item.headerIcon} alt="motor-cardcarIcon" />
                </div>
                <div className="motorCard-ProgressContainer">
                  <div className="progress-LevelMotor" id={item.progress}></div>
                </div>
                <p className="fs-16 fw-400 motorCard-SubHeader pt-3 m-0 pb-2">{item.subHeader}</p>
                <p
                  className={`${item.subParaClass} motorCard-SubParas fs-14 fw-400 m-0 line-height-19 text-break`}
                >
                  {item.subPara}
                </p>
                {item.needAllProducts && (
                  <div className={item.paddClass}>
                    <p className="available-motorProductTitle fs-14 fw-400 m-0">{item.newPara}</p>
                    {item?.newButtonConatinerData?.map((items, index) => {
                      return (
                        <div className="d-flex justify-content-between pb-2 availability" key={index}>
                          <img className="img-fluid contentMotorIcon" src={items.contentIcon} alt="contentMotorIcon" />
                          <span className="alShameel-Title">{items.content}</span>

                          <GenericButton
                            label={t("motorPageData.mechanicalBreak.headerText.buyNow")}
                            onClick={() => openInNewTab(buyNowLink)}
                            className="active justify-content-center custom-width"
                          />
                          {/* <NormalButton
													label={t('motorPageData.mechanicalBreak.headerText.buyNow')}
													className="buyNowMotorBtn"
													onClick={() =>
														openInNewTab(
															"https://beta.tawuniya.com.sa"
														)
													}
												/> */}
                        </div>
                      );
                    })}
                  </div>
                )}
                {item.id === 2 && (
                  <div className="d-flex flex-row breakdown-CardContainer justify-content-between">
                    {/*<div>
										<NormalButton
											label={t('motorPageData.mechanicalBreak.headerText.learnMore')}
											className="motorLearnBtn px-4"
										/>
									</div>*/}
                    <div className="pl-4">
                      {/* <GenericButton
           label={t("motorPageData.mechanicalBreak.headerText.buyNow")}
           onClick={() => history.push('/individuals/products/motor/mechanical-breakdown')}
           className="active justify-content-center custom-width"
          /> */}
                    </div>

                    <div className="pl-4">
                      <GenericButton
                        label={t("productCatalog")}
                        className="active justify-content-center custom-width"
                        onClick={downloadFile}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };
}
