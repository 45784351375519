import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { history } from "service/helpers";
import MenuPopup from "component/common/Navbar/MenuPopup";
import { NormalButton } from "component/common/NormalButton";
import { NormalSearch } from "component/common/NormalSearch";
import orangeArrow from "assets/svg/orangeArrow.svg";
import notifyIcon from "assets/svg/notifyIcon.svg";
import phoneIcon from "assets/svg/phoneCircleIcon.svg";
import mailIcon from "assets/svg/mail icon.svg";
import chatIcon from "assets/svg/chat icon.svg";
import searchIcon from "assets/svg/headerSearchLight.svg";
import WorldIcon from "assets/svg/world-icon.svg";
import "./navbar.scss";
import { useTranslation } from "react-i18next";
import { DetectOutsideClicks } from "../../../hooks";
import Avatar from "@mui/material/Avatar";
import userProfile from "assets/svg/userProfileIcon.svg";
import { clearReduxData } from "action/DashboardInformation";
import { isEmpty } from "lodash";
import { staticSearchData } from "component/common/MockData";
import logoutLogo from "assets/images/logout-icon.svg";
import { useHistory } from "react-router-dom"

export const NavbarDashboard = ({
    toggleLanguageCard,
    toggleSupportCard,
    toggleEmergencyCard,
    navContent,
    isWhite = false,
}) => {
    const [search, setSearch] = useState("");
    const history = useHistory()

    const [isSearchOpened, setIsSearchOpened] = useState(false);
    const [menuOpen, setMenuOpen] = useState(null);
    const [swap, setSwap] = useState(null);
    const changeImg = [notifyIcon, phoneIcon, mailIcon, chatIcon];
    const [transition, setTransition] = useState(0);
    const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
    const [isAvatarClicked, setIsAvatarClicked] = useState(false);
    const { i18n, t } = useTranslation();
    const isRTL = selectedLanguage === "ar" ? true : false;
    const loginResponse = useSelector((state) => state.loginDetailsReducer.loginResponse);
    const fullName = loginResponse?.user_info?.fullName;

    const handleInputSearch = (e) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        setSwap(changeImg[transition]);
    }, [transition]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (changeImg.length === transition + 1) {
                setTransition(0);
            } else {
                setTransition(transition + 1);
            }
        }, 1500);
        return () => clearTimeout(timer);
    }, [transition, changeImg]);

    const searchResultData = [
        {
            id: 0,
            name: t("AlShamel"),
            title: t("AlShamel"),
        },
        {
            id: 1,
            name: t("AlShamel"),
            title: t("MotorInsurance"),
        },
        {
            id: 2,
            name: t("AlShamel"),
            title: t("360Motor"),
        },
        {
            id: 3,
            name: t("AlShamel"),
            title: t("Motor Claim"),
        },
        {
            id: 4,
            name: t("AlShamel"),
            title: t("Sanad plus"),
        },
    ];

    const suggestionData = [
        {
            id: 0,
            titleOne: t("MotorInsurance"),
            titleTwo: t("HealthInsurance"),
        },
        {
            id: 1,
            titleOne: t("Covid-19"),
            titleTwo: t("TravelInsurance"),
        },
    ];

    const searchArea = useRef(null);
    const isClickOutside = DetectOutsideClicks(searchArea);
    const dispatch = useDispatch();
    const [searchResultOpen, setSearchResultOpen] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [langSpecificResults, setLangSpecificResults] = useState([]);

    const handleActionClick = (url) => {
        setIsSearchOpened(false);
        setSearch("");
        history.push(url.replace('https://www.tawuniya.com', ''));
    };

    useEffect(() => {
        if (!search) return setSearchResults([]);

        const fSearch = langSpecificResults.filter((item) =>
            item.keywords.split(",").find((itm) => itm.includes(search))
        );
        setSearchResults(fSearch);
    }, [search]);

    useEffect(() => {
        if (selectedLanguage) {
            if (selectedLanguage === "en") {
                const lSpecData = staticSearchData.filter((item) => item.lang === "EN");
                setLangSpecificResults(lSpecData);
            } else if (selectedLanguage === "ar") {
                const lSpecData = staticSearchData.filter((item) => item.lang === "AR");
                setLangSpecificResults(lSpecData);
            }
        }
    }, [selectedLanguage]);

    useEffect(() => {
        if (isSearchOpened) {
            isClickOutside && setIsSearchOpened(false);
        }
    }, [isClickOutside]);

    useEffect(() => {
        if (isAvatarClicked) {
            isClickOutside && setIsAvatarClicked(false);
        }
    }, [isClickOutside]);

    function stringAvatar(name) {
        if (!isEmpty(name)) {
            if (name?.includes(" ")) {
                return {
                    children: `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`,
                };
            } else {
                return {
                    children: `${name ? name[0] : ""}`,
                };
            }
        }
    }

    const handleAvatarClick = () => {
        setIsAvatarClicked(!isAvatarClicked);
    };

    const handleLogoutClick = () => {
        history.push("/");
        dispatch(clearReduxData());
    };

    const handleProfileClick = () => {
        history.push("/dashboard/profile");
    };

    const handleInputChange = (e) => {
        setSearch(e.target.value);
        setSearchResultOpen(true);
    };

    return (
        <div className={`${isWhite ? "navbar-white" : "navbar"}`} id="navbarDesktop">
            <div className="d-flex justify-content-between align-items-center w-100 h-100">
                <div className="d-flex justify-content-end w-100 align-items-center">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="pr-1">
                            <div className="currently-selected-language" onClick={toggleLanguageCard}>
                                {selectedLanguage === "ar" ? "Ar" : "En"}
                                <img src={WorldIcon} className="img-fluid languageBtnIcon" alt="WorldIcon" />
                            </div>
                        </div>
                        <div className="pr-lg-1" ref={searchArea}>
                            {isSearchOpened ? (
                                <React.Fragment>
                                    <NormalSearch
                                        className="headerSearch"
                                        name="search"
                                        value={search}
                                        placeholder={t("navbar.searchPlaceholder")}
                                        onChange={handleInputChange}
                                        needRightIcon={true}
                                    />
                                    {searchResultOpen && search ? (
                                        <div className="searchResultBox">
                                            <p className="fs-12 fw-800 search-ResultTitle">{t("navbar.results")}</p>
                                            {search.length === 0 ? (
                                                <p className="fs-10 fw-400 search-ResultPara">{t("navbar.noResultFound")}</p>
                                            ) : (
                                                <React.Fragment>
                                                    <p className="fs-10 fw-400 search-ResultPara">
                                                        {t("navbar.search.found")} {searchResults.length} {t("navbar.search.res_for")}
                                                        <span>“</span>
                                                        <span className="results_color">{search}</span>
                                                        <span>”</span>
                                                    </p>
                                                    {searchResults.map((item, idx) => {
                                                        return (
                                                            <div className="d-flex justify-content-between resultContainer" key={idx}>
                                                                <div className="py-2">
                                                                    <p className="fs-10 fw-400 result_Name m-0">{item.category}</p>
                                                                </div>
                                                                <div
                                                                    className="py-2 resultContainer_action"
                                                                    onClick={() => handleActionClick(item.webUrl)}
                                                                >
                                                                    <p className="fs-10 fw-400 result_Title m-0 cursor-pointer" style={{ color: "#6b47f5" }}>{item.action}</p>

                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            )}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </React.Fragment>
                            ) : (
                                <img
                                    src={searchIcon}
                                    className="img-fluid searchBtnIcon"
                                    alt="searchicon"
                                    onClick={() => setIsSearchOpened(!isSearchOpened)}
                                />
                            )}
                        </div>

                        <div className="pr-lg-2">
                            <img
                                src={swap}
                                className="img-fluid notifyBtnIcon"
                                onClick={toggleSupportCard}
                                alt="notifyicon"
                            />
                        </div>
                        <React.Fragment>
                            <div className="pr-lg-3">
                                <NormalButton
                                    label={t("navbar.emergency")}
                                    className="getStartBtn"
                                    onClick={toggleEmergencyCard}
                                />
                            </div>
                            <div className={`${isRTL ? "mr-3" : ""}`}>
                                <Avatar onClick={handleAvatarClick} className="avatar" {...stringAvatar(fullName)} />
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </div>
            {isAvatarClicked && (
                <div className={`${isRTL ? "avatar-dropdown-rtl" : "avatar-dropdown"}`} ref={searchArea}>
                    <p className="avatar-welome">
                        {t("dashboardHome.Welcome")}, {fullName}
                    </p>
                    <div className="avatar-logout d-flex align-items-center">
                        <img
                            src={userProfile}
                            className={`profile-logo ${selectedLanguage === "ar" ? "ml-2" : ""} `}
                            alt="profile-logo"
                            width={17}
                            height={20}
                        />
                        <span className="logout-text ml-2" onClick={handleProfileClick}>
                            {t("your_profile")}
                        </span>
                    </div>
                    <div className="avatar-logout d-flex align-items-center">
                        <img
                            src={logoutLogo}
                            className={`profile-logo ${selectedLanguage === "ar" ? "ml-2" : ""} `}
                            alt="logout-logo"
                            width={17}
                            height={20}
                        />
                        <span className="logout-text ml-2" onClick={handleLogoutClick}>
                            {t("logout")}
                        </span>
                    </div>
                </div>
            )}
            {menuOpen !== null && (
                <MenuPopup navContent={navContent} menuIndex={menuOpen} setMenuOpen={setMenuOpen} />
            )}
        </div>
    );
};
