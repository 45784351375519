import React from "react";
import { CommonCard } from "component/common/CommonCard";
import needHelp from "assets/svg/Need Help Icon.svg";
import needrequest from "assets/svg/Need Help Icon2.svg";
import whatsapp from "assets/svg/Need Help Icon3.svg";
import needCare from "assets/svg/Need Help Icon4.svg";
import "./style.scss";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const SupportCard = ({ isAlign = false }) => {
  const { i18n, t } = useTranslation(); 
  const handleLinkAction = (url) => {
    window.open(url);
  };

  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked);
  }

  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  return (
    <div className="support-card-container">
      <CommonCard
        width="37.5rem"
        cardPosition={isAlign ? "card-Support-Layout-forInsurance" : "card-Support-Layout"}
      >
        <div className="p-2">
          <p className="support-title fs-28 fw-800 my-1 line-height-30 mw-100">{t("contactSupport.HowCan")}</p>
          <p className="support-para fs-16 fw-400 pb-3">{t("contactSupport.ContactSupport")}</p>
          <div className="row">

            <div className="col-6">
              <div className={`${"needPadding pt-3"} d-flex flex-row`} id="needBorder borderTitle">
                <div>
                  <img src={needHelp} className="img-fluid" alt="supportIcon" />
                </div>
                <div
                  className={`${selectedLanguage === "en" ? "pl-3" : "pr-3"}`}
                  onClick={() => {handleLinkAction(`tel:009668001249990`);trackEvent(t("contactSupport.CallCenter")+" Clicked")}}
                >
                  <p className="supportCard-para fs-14 fw-400 m-0">{t("contactSupport.CallCenter")}</p>
                  <p className="supportCard-para-highlight fs-16 fw-800" style={i18n.dir() === 'rtl' ? {direction: 'ltr'}: null}>
                    800 124 9990
                    <span className="added-subTitle fs-12 fw-400 m-0"></span>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-6"
              onClick={() => {handleLinkAction(`mailto:Care@tawuniya.com`);trackEvent(t("contactSupport.EmailId")+" Clicked")}}
            >
              <div className={`${"needPadding pt-3"} d-flex flex-row`} id="needBorder borderTitle">
                <div>
                  <img src={needCare} className="" alt="needCare" />
                </div>
                <div className={`${selectedLanguage === "en" ? "pl-3" : "pr-3"}`}>
                  <p className="supportCard-para fs-14 fw-400 m-0">{t("contactSupport.Email")}</p>
                  <p className="supportCard-para-highlight fs-16 fw-800">
                    {t("contactSupport.EmailId")}
                    <span className="added-subTitle fs-12 fw-400 m-0"></span>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-6"
              onClick={() => {handleLinkAction(`tel:009668004340044`);trackEvent(t("contactSupport.SMEHealthSupport")+" Clicked")}}
            >
              <div className={`${"needPadding pt-3"} d-flex flex-row`} id="needBorder borderTitle">
                <div>
                  <img src={needHelp} className="" alt="needCare" />
                </div>
                <div className={`${selectedLanguage === "en" ? "pl-3" : "pr-3"}`}>
                  <p className="supportCard-para fs-14 fw-400 m-0">{t("contactSupport.SMEHealthSupport")}</p>
                  <p className="supportCard-para-highlight fs-16 fw-800" style={i18n.dir() === 'rtl' ? {direction: 'ltr'}: null}>
                    800 434 0044
                    <span className="added-subTitle fs-12 fw-400 m-0">{t("contactSupport.OnlyEmp")}</span>
                  </p>
                </div>
              </div>
            </div>

             <div
              className="col-6"
              onClick={() => {handleLinkAction(`https://wa.me/00966920019990`);trackEvent(t("contactSupport.Whatsapp")+" Clicked")}}
            >
              <div className={`${"needPadding pt-3"} d-flex flex-row`} id="needBorder borderTitle">
                <div>
                  <img src={whatsapp} className="img-fluid" alt="whatsapp" />
                </div>
                <div className={`${selectedLanguage === "en" ? "pl-3" : "pr-3"}`}>
                  <p className="supportCard-para fs-14 fw-400 m-0">{t("contactSupport.Whatsapp")}</p>
                  <p className="supportCard-para-highlight fs-16 fw-800" style={i18n.dir() === 'rtl' ? {direction: 'ltr'}: null}>
                    9200 19990
                    <span className="added-subTitle fs-12 fw-400 m-0">{t("contactSupport.chatonly")} </span>
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-6"
            // onClick={() => {
            // 	history.push("/individuals/customer-service/support");
            // }}
            >
              <div className={`${"needPadding pt-3"} d-flex flex-row`} id="needBorder borderTitle">
                <div>
                  <img src={needrequest} className="img-fluid" alt="needrequest" />
                </div>
                <div className={`${selectedLanguage === "en" ? "pl-3" : "pr-3"}`}>
                  <p className="supportCard-para fs-14 fw-400 m-0">{t("contactSupport.OpenSupport")}</p>
                  <p className="supportCard-para-highlight fs-16 fw-800">
                    <Link to="/individuals/customer-service/support" className="global_link" onClick={() =>{trackEvent(t("contactSupport.SubmitRequest")+" Clicked")}}>
                      {t("contactSupport.SubmitRequest")}
                    </Link>
                    <span className="added-subTitle fs-12 fw-400 m-0">{t("contactSupport.Anytime")} </span>
                  </p>
                </div>
              </div>
            </div>
           
            <div
              className="col-6"
              onClick={() => {handleLinkAction(`https://wa.me/00966920019990`);trackEvent(t("contactSupport.Whatsapp")+" Clicked")}}
            >
              <div className={`${"needPadding pt-3"} d-flex flex-row`} id="needBorder borderTitle">
                <div>
                  <img src={needHelp} className="img-fluid invisible" alt="needHelp" />
                </div>
                <div className={`${selectedLanguage === "en" ? "pl-3" : "pr-3"}`}>
                  <p className="supportCard-para fs-14 fw-400 m-0">{t("contactSupport.ChatExecutives")}</p>
                  <p className="supportCard-para-highlight fs-16 fw-800">
                    {t("contactSupport.StartChat")}
                    <span className="added-subTitle fs-12 fw-400 m-0"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonCard>
    </div>
  );
};
