import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import { useDispatch, useSelector } from "react-redux";

import { arabTranslate, updateLanguage } from "action/LanguageAct";

import Logo from "assets/svg/Tawuniya-Logo.svg";
import ArabFlag from "assets/svg/arab.svg";
import EnglishFlag from "assets/svg/english.svg";
import WorldIcon from "assets/svg/world-icon.svg";
import tawuniyaLogo from "assets/svg/Tawuniya-Logo-micro.svg";

import "./WelcomeKitLayout.scss";
import isEmpty from "lodash/isEmpty";

const languages = {
 en: { name: "English", subName: "World Wide", flag: EnglishFlag },
 ar: { name: "Arabic", subName: "In Arabic", flag: ArabFlag },
};

const WelcomeKitLayout = (props) => {
 const { t, i18n } = useTranslation();

 const dispatch = useDispatch();
 const {} = useSelector((state) => state);
 const valueLang = useSelector((state) => state?.languageReducer?.language)
 const selectedLanguage = isEmpty(localStorage.getItem("newTawuniyaLanguageParam")) ? valueLang : localStorage.getItem("newTawuniyaLanguageParam");

 const [anchorEl, setAnchorEl] = useState(null);
 const open = Boolean(anchorEl);

 useEffect(() => {
  if (selectedLanguage === "ar") {
   document.getElementsByTagName("body")[0].classList.add("language-alignment-right");
   i18n.changeLanguage("ar");
  } else {
   document.getElementsByTagName("body")[0].classList.remove("language-alignment-right");
   i18n.changeLanguage("en");
  }
 }, [selectedLanguage]);

 useEffect(() => {
  if (selectedLanguage === "ar") {
   document.getElementsByTagName("body")[0].classList.add("language-alignment-right");
   i18n.changeLanguage("ar");
  } else {
   document.getElementsByTagName("body")[0].classList.remove("language-alignment-right");
   i18n.changeLanguage("en");
  }
 }, [selectedLanguage]);


 const handleClick = (event) => {
  setAnchorEl(event.currentTarget);
 };
 const handleClose = () => {
  setAnchorEl(null);
 };

 const handleLanguageToggler = (lng) => {
  if (i18n.resolvedLanguage !== lng) {
   i18n.changeLanguage(lng);
   // don't know why we are dispatching (used in other layouts)
   if (lng === "ar") dispatch(arabTranslate(false));
   else dispatch(arabTranslate(true));
   dispatch(updateLanguage(lng === "ar" ? "ar" : "en"));
  }
  handleClose();
 };

 return (
  <div
   className={`welcome_kit_layout_main_wrapper ${
    i18n.resolvedLanguage === "ar" ? "welcome_kit_layout_main_wrapper_rtl" : ""
   }`}
  >
   {/* Header */}
   <div className="welcome_kit_layout_header">
    <div className="welcome_kit_layout_header_left">
     <img src={Logo} alt="logo" />
     {/* <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MEVBMTczNDg3QzA5MTFFNjk3ODM5NjQyRjE2RjA3QTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MEVBMTczNDk3QzA5MTFFNjk3ODM5NjQyRjE2RjA3QTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowRUExNzM0NjdDMDkxMUU2OTc4Mzk2NDJGMTZGMDdBOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowRUExNzM0NzdDMDkxMUU2OTc4Mzk2NDJGMTZGMDdBOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjjUmssAAAGASURBVHjatJaxTsMwEIbpIzDA6FaMMPYJkDKzVYU+QFeEGPIKfYU8AETkCYI6wANkZQwIKRNDB1hA0Jrf0rk6WXZ8BvWkb4kv99vn89kDrfVexBSYgVNwDA7AN+jAK3gEd+AlGMGIBFDgFvzouK3JV/lihQTOwLtOtw9wIRG5pJn91Tbgqk9kSk7GViADrTD4HCyZ0NQnomi51sb0fUyCMQEbp2WpU67IjfNjwcYyoUDhjJVcZBjYBy40j4wXgaobWoe8Z6Y80CJBwFpunepIzt2AUgFjtXXshNXjVmMh+K+zzp/CMs0CqeuzrxSRpbOKfdCkiMTS1VBQ41uxMyQR2qbrXiiwYN3ACh1FDmsdK2Eu4J6Tlo31dYVtCY88h5ELZIJJ+IRMzBHfyJINrigNkt5VsRiub9nXICdsYyVd2NcVvA3ScE5t2rb5JuEeyZnAhmLt9NK63vX1O5Pe8XaPSuGq1uTrfUgMEp9EJ+CQvr+BJ/AAKvAcCiAR+bf9CjAAluzmdX4AEIIAAAAASUVORK5CYII=" /> */}
    </div>
    <div className="welcome_kit_layout_header_right">
     <div className="welcome_kit_layout_header_right_lng" onClick={handleClick}>
      <p>{i18n.resolvedLanguage === "ar" ? "AR" : "EN"}</p>
      <img src={WorldIcon} alt="WorldIcon" />
     </div>
     <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
       "aria-labelledby": "basic-button",
      }}
      PaperProps={{
       elevation: 0,
       sx: {
        overflow: "visible",
        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
        mt: 1.5,
        "& .MuiAvatar-root": {
         width: 32,
         height: 32,
         ml: -0.5,
         mr: 1,
        },
        "&:before": {
         content: '""',
         display: "block",
         position: "absolute",
         top: 0,
         right: i18n.resolvedLanguage === "en" ? 14 : "unset",
         left: i18n.resolvedLanguage === "ar" ? 14 : "unset",
         width: 10,
         height: 10,
         bgcolor: "background.paper",
         transform: "translateY(-50%) rotate(45deg)",
         zIndex: 0,
        },
       },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
     >
      <div className="welcome_kit_layout_lng_menu">
       <div>
        <p>{t("translated-lang")}</p>
       </div>
       <div className="welcome_kit_layout_lng_menu_items">
        {Object.keys(languages).map((lng) => {
         return (
          <div
           key={lng}
           className={`welcome_kit_layout_lng_menu_item ${
            i18n.resolvedLanguage === lng
             ? "welcome_kit_layout_lng_menu_item_active"
             : "welcome_kit_layout_lng_menu_item_inactive"
           }`}
           onClick={() => handleLanguageToggler(lng)}
          >
           <div>
            <p className="welcome_kit_layout_lng_menu_item_txt">{languages[lng].name}</p>
            <p className="welcome_kit_layout_lng_menu_item_subtxt">{languages[lng].subName}</p>
           </div>
           <div>
            <img src={languages[lng].flag} />
           </div>
          </div>
         );
        })}
       </div>
      </div>
     </Menu>
    </div>
   </div>
   {/* Main Content */}
   {props.children}
   {/* Footer */}
   <div className="welcome_kit_footer">
    <div>
     <img src={tawuniyaLogo} alt="tawuniya Logo" />
    </div>
    <div className="welcome_kit_footer_cnt">
     <h6>{t("footer.copyright")}</h6>
    </div>
   </div>
  </div>
 );
};

export default WelcomeKitLayout;
