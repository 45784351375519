import React, { useEffect, useRef, useState, useTransition } from "react";
import { CommonCard } from "component/common/CommonCard";
import flag1 from "assets/svg/arab.svg";
import flag2 from "assets/svg/english.svg";
import flag3 from "assets/svg/turkey.svg";
import flag4 from "assets/svg/french.svg";
import flag5 from "assets/svg/urdu.svg";
import flag6 from "assets/svg/indo.svg";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateLanguage } from "action/LanguageAct";
import { useTranslation } from "react-i18next";
import { DetectOutsideClicks } from "../../../../hooks";
import { isEmpty } from "lodash/isEmpty";

export const LanguageCard = ({
	arabToggle,
	translateHandler,
	isAlign = false,
	isActive = true,
	toggleLangCard,
}) => {
	const cardData = [
		{
			id: 0,
			lang: "ar",
			cardTitle: "Arabic",
			cardPara: "العربية",
			cardFlag: flag1,
			needTitle: true,
			needTranslate: arabToggle,
			needToggle: translateHandler,
			higlightPill: "suggested-highlight-language-cards",
			normalPill: "suggested-language-cards",
		},
		{
			id: 1,
			lang: "en",
			cardTitle: "English",
			cardPara: "Worldwide",
			cardFlag: flag2,
			needTitle: false,
			needTranslate: null,
			needToggle: translateHandler,
			higlightPill: "suggested-highlight-language-cards",
			normalPill: "suggested-language-cards",
		},
	];

	const { i18n, t } = useTranslation();

	const dispatch = useDispatch();
	const [pillIndex, setPillIndex] = useState(0);
	const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
	const changeLanguage = (language) => {
		localStorage.setItem("isChosed", true)
		if (selectedLanguage !== language) {
			dispatch(updateLanguage(language));
		}
	};

	const languageSwitchContainer = useRef(null);
	const isClickOutside = DetectOutsideClicks(languageSwitchContainer);

	useEffect(() => {
		if (isActive) {
			isClickOutside && toggleLangCard && toggleLangCard(false);
		}
	}, [isClickOutside]);

	return (
		<div className="language-card-container" ref={languageSwitchContainer}>
			{isActive && (
				<CommonCard
					width="400px"
					cardPosition={
						isAlign
							? "card-Language-Layout-forInsurance"
							: "card-Language-Layout"
					}
				>
					<p className="fs-16 fw-800 language-card-container-heading">
						{t("translated-lang")}
					</p>
					<div className="row space">
						<div
							className="col-6 padding_10"
							onClick={() => {
								changeLanguage(cardData[0].cardTitle.toLowerCase());
								setPillIndex(cardData[0].id);
								toggleLangCard && toggleLangCard(false);
								translateHandler && translateHandler();
							}}
						>
							<div
								className={`${i18n.language == cardData[0].lang
									? "suggested-language-cards"
									: "suggested-highlight-language-cards"
									} d-flex justify-content-between mb-3 background-fill background-fill`}
							>
								<div>
									<p
										className="fs-14 fw-700 p-0 m-0 language-Name"
										onClick={() =>
											cardData[0]?.needToggle()
												? cardData[0]?.id === pillIndex &&
												cardData[0]?.needToggle()
												: ""
										}
									>
										{cardData[0].cardTitle}
									</p>
									<p className="fs-12 fw-400 m-0 p-0">{cardData[0].cardPara}</p>
								</div>
								<div>
									<img
										src={cardData[0].cardFlag}
										className="img-fluid"
										alt="flags"
									/>
								</div>
							</div>
						</div>

						<div
							className="col-6 padding_10"
							onClick={() => {
								changeLanguage(cardData[1].cardTitle.toLowerCase());
								setPillIndex(cardData[1].id);
								toggleLangCard && toggleLangCard(false);
								translateHandler && translateHandler();
							}}
						>
							<div
								className={`${i18n.language == cardData[1].lang
									? "suggested-language-cards"
									: "suggested-highlight-language-cards"
									} d-flex justify-content-between mb-3 background-fill background-fill`}
							>
								<div>
									<p
										className="fs-14 fw-700 p-0 m-0 language-Name"
										onClick={() =>
											cardData[1].id === pillIndex && cardData[1].needToggle()
										}
									>
										{cardData[1].cardTitle}
									</p>
									<p className="fs-12 fw-400 m-0 p-0">{cardData[1].cardPara}</p>
								</div>
								<div>
									<img
										src={cardData[1].cardFlag}
										className="img-fluid"
										alt="flags"
									/>
								</div>
							</div>
						</div>
					</div>
				</CommonCard>
			)}
		</div>
	);
};
