import { getStepButtonUtilityClass } from "@mui/material";
import moment from "moment";
import { store } from "service/helpers";

export const getPolicyInformationRequest = (data) => {
  return store.getState().loginDetailsReducer?.loginResponse?.user_info
    ?.MDuserType === "D"
    ? {
        userID: data.userID,
        langCode: data.langCode,
        MDuserType: "D",
        token:
          store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
      }
    : {
        userID: data.userID,
        langCode: data.langCode,
        token:
          store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
      };
};

export const getPolicyInformationResponse = (response) => {
  return response.data.return;
};

export const getMotorPolicyDetailsRequest = (
  element,
  userID,
  customerID,
  langId
) => {
  return {
    langCode: langId,
    policyNumber: element.s_PolicyNo.toString(),
    customerId: customerID,
    userID: userID,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getMotorPolicyResponse = (response) => {
  return response.data.return;
};

/**/
export const getMotorDetailRequest = (element, userID, customerID, langId) => {
  return {
    langCode: langId,
    policyNumber: element.toString(),
    customerId: customerID,
    userID: userID,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getMotorDetailResponse = (response) => {
  return response.data.return;
};

export const getMedicalPolicyDetailsRequest = (
  element,
  userID,
  iqamahID,
  medCustomerID,
  customerID,
  langId,
  token
) => {
  return store.getState().loginDetailsReducer?.loginResponse?.user_info
    ?.MDuserType === "D"
    ? {
        langCode: langId,
        policyNumber: element.s_PolicyNo.toString(),
        customerId: customerID,
        medicalCustomerId: medCustomerID ? medCustomerID : "",
        idNumber: iqamahID,
        userID,
        MDuserType: "D",
      }
    : {
        langCode: langId,
        policyNumber: element.s_PolicyNo.toString(),
        customerId: customerID,
        medicalCustomerId: medCustomerID ? medCustomerID : "",
        idNumber: iqamahID,
        userID,
      };
};

export const getMedicalPolicyDetailsResponse = (response) => {
  return response.data.return;
};

export const getTravelPolicyDetailsRequest = (
  element,
  userID,
  customerID,
  langId
) => {
  return {
    customerId: customerID,
    langCode: langId,
    lob: "TR",
    policyNumber: element.s_PolicyNo.toString(),
    userID: userID,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getTravelPolicyDetailsResponse = (response) => {
  return response.data.return;
};

export const servicesDetailsRequest = (element, langId) => {
  return {
    serviceId: "1",
    lang: langId,
    channel: "Mobile",
    policyNo: element.s_PolicyNo.toString(),
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const servicesDetailsResponse = (response) => {
  return response.data;
};

export const getMedApprovalDetailsRequest = (
  element,
  userID,
  iqamahID,
  langId,
  customerID,
  medCustomerID
) => {
  return store.getState().loginDetailsReducer?.loginResponse?.user_info
    ?.MDuserType === "D"
    ? {
        cardCode: "",
        customerId: customerID,
        dateFrom: "2021-12-10",
        dateTo: moment(new Date()).format("YYYY-MM-DD"),
        idNumber: iqamahID,
        medicalCustomerId: medCustomerID ? medCustomerID : "",
        medicalPolicyNumber: element.s_PolicyNo,
        langCode: langId,
        userID: userID,
        MDuserType: "D",
      }
    : {
        cardCode: "",
        customerId: customerID,
        dateFrom: "2021-12-10",
        dateTo: moment(new Date()).format("YYYY-MM-DD"),
        idNumber: iqamahID,
        medicalCustomerId: medCustomerID ? medCustomerID : "",
        medicalPolicyNumber: element.s_PolicyNo,
        langCode: langId,
        userID: userID,
      };
};

export const getMedApprovalDetailsResponse = (response) => {
  return response.data.return;
};

export const getRenewalPolicyDetailRequest = (idNumber, yob, langId) => {
  return {
    languageCode: langId,
    idNumber: idNumber,
    yob: yob,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getRenewalPolicyDetailResponse = (response) => {
  return response.data;
};

export const getAccidentDetailRequest = (element, userID, langId) => {
  return {
    policynumber: element.s_PolicyNo,
    language: langId,
    userID: userID,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getAccidentDetailResponse = (response) => {
  return response.data;
};

export const memberHistoryResponse = (response) => {
  return response.data;
};
export const memberHistoryRequest = (data) => {
  return {
    policyNumber: data.policyNumber,
    memberCode: data.memberCode,
    langId: data.langId,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const requestServiceResponse = (response) => {
  return response.data;
};
export const requestServiceRequest = (data) => {
  return {
    idNumber: data.idNumber,
    policyNumber: data.policyNumber,
    customerId: data.customerId,
    medicalCustomerId: data.medicalCustomerId,
    langCode: data.langCode,
    userID: data.userID,
    // token:
    // 	store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const submitTelemedicineResponse = (response) => {
  return response.data;
};
export const submitTelemedicineRequest = (data) => {
  return {
    langId: data.langId,
    question: data.question,
    memberInfo: {
      phone: data.memberInfo.phone,
      firstName: data.memberInfo.firstName,
      lastName: data.memberInfo.lastName,
      gender: data.memberInfo.gender,
      memberCode: data.memberInfo.memberCode,
      policyNumber: data.memberInfo.policyNumber,
    },
    channel: data.channel,
    status: data.status,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getPolicyInformartionValueRequest = (data) => {
  return {
    langCode: data.langCode,
    userID: data.userID,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getPolicyInformartionValueResponse = (response) => {
  return response;
};

export const getMotorPolicyDetailsValueRequest = (data) => {
  return {
    langCode: data.langCode,
    policyNumber: data.policyNumber.toString(),
    customerId: data.customerId,
    userID: data.userID,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getMotorPolicyDetailsValueResponse = (response) => {
  return response.data;
};

export const getEligVouServRequest = (data) => {
  return {
    policyNo: data.policyNo.toString(),
    lang: data.lang,
    channel: data.channel,
    //	token: store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getEligVouServResponse = (response) => {
  return response.data;
};

export const getMemberShipRequest = (data) => {
  return {
    langCode: data.langCode,
    policyNumber: data.policyNumber.toString(),
    chassiNumber: data.chassiNumber,
    userID: data.userID,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getMemberShipResponse = (response) => {
  return response.data;
};

export const getPeriodSubmitRequest = (data) => {
  return {
    service_id: data.service_id,
    customer_phone_number: data.customer_phone_number,
    pick_up_lat: data.pick_up_lat,
    pick_up_lng: data.pick_up_lng,
    membership_id: data.membership_id,
    lang: data.lang,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getPeriodSubmitResponse = (response) => {
  return response.data;
};

export const medicalProviderRequest = (data) => {
  return {
    idNumber: data.idNumber,
    langCode: data.langCode,
    location: data.location,
    policyNumber: data.policyNumber.toString(),
    provider: data.provider,
    //token: store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    userID: data.idNumber,
  };
};

export const medicalProviderResponse = (response) => {
  return response.data;
};

export const getClaimTrackRequest = (data) => {
  return {
    langCode: data.langCode,
    policyNumber: data.policyNumber.toString(),
    idNumber: data.idNumber,
    claimNumber: data.claimNumber,
    claimDetailNumber: data.claimDetailNumber,
    najmNumber: data.najmNumber,
    cardCode: data.cardCode,
    claimType: data.claimType,
  };
};

export const faxEligibilityResponse = (response) => {
  return response.data;
};
export const faxEligibilityRequest = (data) => {
  return {
    cardCode: data.cardCode,
    fax: data.fax,
    policyNumber: data.policyNumber.toString(),
    providerNumber: data.providerNumber,
    IDNumber: data.IDNumber,
    langCode: data.langCode,
    //token: store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getClaimTrackResponse = (response) => {
  return response.data;
};

export const checkClaimStatusRequest = (data, langId, userID) => {
  return {
    policynumber: data.toString(),
    language: langId,
    // token:
    // 	store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    // userID: userID,
  };
};

export const checkClaimStatusResponse = (response) => {
  return response.data;
};

export const sendeEmailRequest = (data) => {
  return {
    langCode: data.langCode,
    policyNumber: data.policyNumber.toString(),
    idNumber: data.idNumber,
    claimNumber: data.claimNumber,
    claimDetailNumber: data.claimDetailNumber,
    najmNumber: data.najmNumber,
    cardCode: data.cardCode,
    claimType: data.claimType,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const sendEmailResponse = (response) => {
  return response.data;
};

export const generateDigitalCardsRequest = (data, langId) => {
  return {
    policyNumber: data.policyNumber.toString(),
    idNumber: data.idNumber.toString(),
    source: "Digital",
    cardType: "eCard",
    lang: langId,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const generateDigitalCardsResponse = (response) => {
  return response.data;
};

export const getMedicalBenefitsRequest = (data, langId) => {
  return {
    policyNumber: data.policyNumber.toString(),
    idNumber: data.idNumber,
    langCode: langId,
    cardCode: data.cardCode,
    classNumber: "abcccaeee",
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getMedicalBenefitsResponse = (response) => {
  return response.data;
};

export const getBenefitsLimitRequest = (data, langId) => {
  return {
    policyNo: data.policyNumber.toString(),
    memberCode: data.cardCode,
    classNo: "1",
    lang: langId,
    token:
      store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
  };
};

export const getBenefitsLimitResponse = (response) => {
  return response.data;
};

export const reimbursmentRequest = (data) => {
  return {
    langCode: data.langCode,
    customerId: data.customerId,
    medicalCustomerId: data.medicalCustomerId,
    medicalPolicyNumber: data.medicalPolicyNumber,
    idNumber: data.idNumber,
    cardCode: data.cardCode,
    dateFrom: data.dateFrom,
    dateTo: data.dateTo,
    // token:
    // 	store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    userID: data.userId,
  };
};

export const reimbursmentResponse = (response) => {
  return response.data;
};

export const updateProfileRequest = (data) => {
  return data;
};

export const updateProfileResponse = (response) => {
  return response;
};

export const getPolicyInfoRequest = (data) => {
  return data;
};

export const getPolicyInfoResponse = (response) => {
  return response;
};

export const getServicesHistoryRequest = (data) => {
  return data;
};

export const getServicesHistoryResponse = (response) => {
  return response;
};
