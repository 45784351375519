import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { arabTranslate } from "action/LanguageAct";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { Navbar } from "component/common/Navbar";
import { NormalOffCanvas } from "component/common/NormalOffCanvas";
import {
  LanguageCard,
  SupportCard,
  GetStartedCard,
  EmergencyCard,
} from "component/HomePage/LandingComponent/NavbarCards";
import { AuthFooter } from "component/common/AuthFooter";
import { MoreProductCard } from "../component/HomePage/LandingComponent/NavbarCards";
import { useTranslation } from "react-i18next";
import { history } from "../service/helpers";
import { updateLanguage } from "action/LanguageAct";
import isEmpty from "lodash/isEmpty";

const theme = createTheme({
  palette: {
    common: {},
    primary: {
      main: "#6B47F5",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});

const LoginLayout = (props) => {
  const [toggle, setToggle] = useState(false);
  const { t, i18n } = useTranslation();
  const [languageCard, setLanguageCard] = useState(false);
  const [supportCard, setSupportCard] = useState(false);
  const [getStartedCard, setGetStartedCard] = useState(false);
  const [emergencyCard, setEmergencyCard] = useState(false);
  const [productToggle, setProductToggle] = useState(false);
  const [arabToggle, setArabToggle] = useState(false);
  const valueLang = useSelector((state) => state?.languageReducer?.language)
  const selectedLanguage = isEmpty(localStorage.getItem("newTawuniyaLanguageParam")) ? valueLang : localStorage.getItem("newTawuniyaLanguageParam");

  useEffect(() => {
    dispatch(updateLanguage(selectedLanguage));
  }, [selectedLanguage])
  const isLoggedIn = useSelector(
    (data) => data.loginDetailsReducer?.loginResponse?.user_info?.userID
  );
  const isOtpVerified = useSelector((state) => state.loginDetailsReducer?.isOtpVerified);

  const dispatch = useDispatch();

  const translateHandler = () => {
    setArabToggle(true);
    selectedLanguage === "ar" ? dispatch(arabTranslate(false)) : dispatch(arabTranslate(true));
  };

  const handleToggler = () => {
    setToggle(true);
  };

  const closeToggler = () => {
    setToggle(false);
  };

  useEffect(() => {
    if (isLoggedIn && isOtpVerified) {
      history.push("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (selectedLanguage === "ar") {
      document.getElementsByTagName("body")[0].classList.add("language-alignment-right");
      i18n.changeLanguage("ar");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("language-alignment-right");
      i18n.changeLanguage("en");
    }
  }, [selectedLanguage]);

  const toggleLanguageCard = () => {
    setLanguageCard(!languageCard);
  };

  const toggleSupportCard = () => {
    setSupportCard(!supportCard);
  };

  const toggleGetStartedCard = () => {
    setGetStartedCard(!getStartedCard);
  };

  const toggleEmergencyCard = () => {
    setEmergencyCard(!emergencyCard);
  };

  const navAuthContent = [
    {
      id: 0,
      navText: t("navbar.individuals"),
    },
    {
      id: 1,
      navText: t("navbar.corporate"),
    },
    {
      id: 2,
      navText: t("navbar.investor"),
    },
    {
      id: 3,
      navText: t("navbar.claims"),
    },
  ];

  return (
    <>
      <ThemeProvider theme={theme}>
        {toggle ? (
          <div className="row">
            <div className="col-12 tawuniyaCanvasContainer vh-100">
              <NormalOffCanvas closeToggler={closeToggler} />
            </div>
          </div>
        ) : (
          <React.Fragment>
            {productToggle ? <MoreProductCard /> : null}
            <div
              className={
                productToggle
                  ? "container-fluid blurBackground vh-100"
                  : "container-fluid authContainer vh-100"
              }
              onClick={
                productToggle
                  ? () => productToggle && setProductToggle(false)
                  : supportCard
                    ? () => supportCard && setSupportCard(false)
                    : languageCard
                      ? () => languageCard && setLanguageCard(false)
                      : getStartedCard
                        ? () => getStartedCard && setGetStartedCard(false)
                        : emergencyCard
                          ? () => emergencyCard && setEmergencyCard(false)
                          : null
              }
            >
              <div className="row auth-header">
                <div className="col-12 header-radius">
                  <Navbar
                    handleToggler={handleToggler}
                    toggleLanguageCard={toggleLanguageCard}
                    toggleSupportCard={toggleSupportCard}
                    toggleEmergencyCard={toggleEmergencyCard}
                    toggleGetStartedCard={toggleGetStartedCard}
                    navContent={navAuthContent}
                  />
                </div>
                <div className="col-12 px-0 paddingContainer">
                  {languageCard ? (
                    <LanguageCard arabToggle={arabToggle} translateHandler={translateHandler} />
                  ) : null}
                  {supportCard ? <SupportCard /> : null}
                  {getStartedCard ? <GetStartedCard /> : null}
                  {emergencyCard ? <EmergencyCard /> : null}
                  <div className="authBgContainer">{props.children}</div>
                </div>
              </div>
              {window.innerWidth > 600 && <AuthFooter />}

            </div>
          </React.Fragment>
        )}
      </ThemeProvider>
    </>
  );
};

export default LoginLayout;
