import * as actions from '../action/dawai';

const initialState = {
  medicalMemberList: [],
  medicineList: [],
  userInputMedicines: [],
  userInputMedicinesName: [],
  deliveryDetails: {},
  pharmaciesList: [],
  diagnosisList: [],
  medicineHistory: [],
  medicineMember: {},
  requestRefNumber: null,
  attachments: [],
  regions: []
}
const dawaiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_MEMBERS_LIST: {
      return {...state, medicalMemberList: action.payload?.medicalMemberList}
    }
    case actions.SET_MEDICINE_MEMBER: {
      return {...state, medicineMember: action.payload}
    }
    case actions.GET_MEDICINE_LIST: {
      return {...state, medicineList: action.payload?.medicineMap?.entry}
    }
    case actions.SET_MEDICINE_ITEM: {
      return {...state, userInputMedicines: [...state.userInputMedicines, action.payload]}
    }
    case actions.SET_MEDICINE_ITEM_NAME: {
      return {...state, userInputMedicinesName: [...state.userInputMedicinesName, action.payload]}
    }    
    case actions.GET_DAWAI_LOVS: {
      return {...state, pharmaciesList: action.payload?.pharmacies, diagnosisList: action.payload?.diagnosis}
    }
    case actions.GET_MEDICINE_HISTORY: {
      return {...state, medicineHistory: action.payload}
    }
    case actions.GET_REGIONS_LIST: {
      return {...state, regions: action.payload?.data?.getRegions ?? []}
    }
    case actions.DELETE_MEDICINE_ITEM: {
      let cloned = JSON.parse(JSON.stringify(state.userInputMedicines));
      cloned.splice(action.payload, 1);
      return {...state, userInputMedicines: cloned}
    }
    case actions.SET_MEDICAL_COLLECT_DETAILS: {
      return {...state, deliveryDetails: action.payload}
    }
    case actions.CLEAR_MEDICINE_LIST: {
      return {...state, userInputMedicines: []}
    }
    case actions.SET_ATTACHMENT_FILE: {
      return {...state, attachments: [...state.attachments, ...action.payload]}
    }

    case actions.DELETE_ATTACHMENT_FILE: {
      return {...state, attachments: state.attachments.filter(attachment => attachment.fileName !== action.payload.fileName)}
    }
    case actions.SUBMIT_DAWAI_REQUEST: {
      return {...state, requestRefNumber: action.payload.data.requestRefNumber}
    }
    default:
      return state;
  }
}

export default dawaiReducer;
