import { createTheme, ThemeProvider } from "@material-ui/core";
import { CommonBreadCrumb } from "component/common/CommonBreadCrumb";
import { CommonFooter } from "component/common/CommonFooter";
import ExtraHeaderDesktop from "component/common/ExtraHeader/ExtraHeaderDesktop";
import { Navbar } from "component/common/Navbar";
import { NormalOffCanvas } from "component/common/NormalOffCanvas";
import { CommonCanvasBody } from "component/DashBoard/DashBoardComponents/InsuranceCard/CommonCanvasBody";
import {
  EmergencyCard,
  GetStartedCard,
  LanguageCard,
  SupportCard,
} from "component/HomePage/LandingComponent/NavbarCards";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { MoreProductCard } from "../component/HomePage/LandingComponent/NavbarCards/MoreProductCard";
import { updateLanguage } from "action/LanguageAct";
import isEmpty from "lodash/isEmpty";
import { Helmet } from "react-helmet";

const theme = createTheme({
  palette: {
    common: {},
    primary: {
      main: "#6B47F5",
    },
    secondary: {
      main: "#FFFFFF",
    },
  },
});

const InsuranceLayout = (props) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const [toggle, setToggle] = useState(false);

  const [languageCard, setLanguageCard] = useState(false);

  const [supportCard, setSupportCard] = useState(false);

  const [emergencyCard, setEmergencyCard] = useState(false);

  const [getStartedCard, setGetStartedCard] = useState(false);

  const [productToggle, setProductToggle] = useState(false);

  const valueLang = useSelector((state) => state?.languageReducer?.language)
  const selectedLanguage = isEmpty(localStorage.getItem("newTawuniyaLanguageParam")) ? valueLang : localStorage.getItem("newTawuniyaLanguageParam");

  useEffect(() => {
    dispatch(updateLanguage(selectedLanguage));
  }, [selectedLanguage])

  const getToggleStatus = useSelector((val) => val.languageReducer.toggleCanvas);

  useEffect(() => {
    if (getToggleStatus) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  });

  useEffect(() => {
    if (selectedLanguage === "ar") {
      document.getElementsByTagName("body")[0].classList.add("language-alignment-right");
      i18n.changeLanguage("ar");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("language-alignment-right");
      i18n.changeLanguage("en");
    }
  }, [selectedLanguage]);

  const handleToggler = () => {
    setToggle(true);
  };

  const closeToggler = () => {
    setToggle(false);
  };

  const toggleLanguageCard = () => {
    setLanguageCard(!languageCard);
  };

  const toggleSupportCard = () => {
    setSupportCard(!supportCard);
  };

  const toggleEmergencyCard = () => {
    setEmergencyCard(!emergencyCard);
  };

  const toggleGetStartedCard = () => {
    setGetStartedCard(!getStartedCard);
  };

  const toggleProductCard = () => {
    setProductToggle(true);
  };

  const navHomeContent = [
    {
      id: 0,
      navText: t("navbar.individuals"),
    },
    {
      id: 1,
      navText: t("navbar.corporate"),
    },
    {
      id: 2,
      navText: t("navbar.investor"),
    },
    {
      id: 3,
      navText: t("navbar.claims"),
    },
  ];

  const historyLinks = [
    {
      id: 0,
      routeLink: "/products/motor",
    },
    {
      id: 1,
      routeLink: "/products/medicalmalpracticepage",
    },
    {
      id: 2,
      routeLink: "/individuals/products/property-casualty/home-insurance",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: selectedLanguage === "ar" ? "ar" : "en" }} />
      <ThemeProvider theme={theme}>
        {getToggleStatus ? (
          <div className="box-canvas">
            <CommonCanvasBody />
          </div>
        ) : null}

        {toggle ? (
          <div className="row">
            <div className="col-12 tawuniyaCanvasContainer vh-100">
              <NormalOffCanvas closeToggler={closeToggler} />
            </div>
          </div>
        ) : (
          <React.Fragment>
            {productToggle ? <MoreProductCard /> : null}
            <div
              className={
                productToggle
                  ? "mainContainer container-fluid blurBackground vh-100"
                  : "mainContainer container-fluid vh-100"
              }
              onClick={
                productToggle
                  ? () => productToggle && setProductToggle(false)
                  : supportCard
                    ? () => supportCard && setSupportCard(false)
                    : languageCard
                      ? () => languageCard && setLanguageCard(false)
                      : getStartedCard
                        ? () => getStartedCard && setGetStartedCard(false)
                        : emergencyCard
                          ? () => emergencyCard && setEmergencyCard(false)
                          : ""
              }
            >
              {/* {historyLinks.map(({ routeLink }) => {
                return (
                  <>
                    {history.location.pathname === routeLink && (
                      <ToggleBanner />
                    )}
                  </>
                );
              })} */}
              <div className="row">
                <div className="col-12 insuranceNavBg">
                  <Navbar
                    handleToggler={handleToggler}
                    toggleLanguageCard={toggleLanguageCard}
                    toggleSupportCard={toggleSupportCard}
                    toggleEmergencyCard={toggleEmergencyCard}
                    toggleGetStartedCard={toggleGetStartedCard}
                    navContent={navHomeContent}
                    isNavBg={true}
                  />

                  <ExtraHeaderDesktop translate={t} />

                  {/*
                  <InsuranceNavBar
                    handleToggler={handleToggler}
                    toggleLanguageCard={toggleLanguageCard}
                    toggleSupportCard={toggleSupportCard}
                    toggleEmergencyCard={toggleEmergencyCard}
                    toggleGetStartedCard={toggleGetStartedCard}
                    navContent={navHomeContent}
                  />
*/}
                </div>
                <div className="col-12 paddingContainer landingContainer">
                  {languageCard ? <LanguageCard isAlign={true} /> : null}
                  {supportCard ? <SupportCard isAlign={true} /> : null}
                  {emergencyCard ? <EmergencyCard isAlign={true} /> : null}
                  {getStartedCard ? <GetStartedCard isAlign={true} /> : null}

                  <div className="paddingContainer">
                    {console.log("historyLinks---", historyLinks)}
                    {/* {history.location.pathname === "/" && (
                      <>
                      <CommonBanner
                        header={t("Insure_yourself_within_minutes")}
                        para="Buying Motor, Medical, Travel and other insurance products for your
            needs has never been this easy"
                        toggleProductCard={toggleProductCard}
                      />
                      </>
                    )} */}
                    <CommonBreadCrumb />
                    {props.children}
                  </div>
                </div>
              </div>
              <CommonFooter />
            </div>
          </React.Fragment>
        )}
      </ThemeProvider>
    </>
  );
};

export default InsuranceLayout;
