import React from "react";
import "./productCard.scss";
import { Button, Card, CardActions, CardContent } from "@mui/material";
import { categories, categoriesAr } from "../Schema/productsData";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMediaQuery } from "@material-ui/core";
import { getI18n, useTranslation } from "react-i18next";
import { history } from "service/helpers";
import { Link } from "react-router-dom";
import { externalLinks } from "helpers/constant";

export const ProductCard = (product) => {
  const matchesMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const matchesLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const { i18n, t } = useTranslation();
  const currentLanguage = getI18n().language;
  const categoriesTranslated = currentLanguage === "en" ? categories : categoriesAr;

  const healthBuyNowClicked = (name, product) => {
    if (product.data.url) {
      window.location.assign(product.data.url);
    } else {
      window.location.assign(product.data.redirect);
    }
  };

  const propertyBuyNowClicked = (name) => {
    if (name === "Home insurance") {
      history.push('/products/individuals/homeinsurance')
    }
    if (name === "International Travel insurance") {
      history.push("/products/individuals/internationaltravel");
    }

    if (name === "Medical malpractice insurance") {
      window.location.assign(externalLinks.medmal_v3);
    } else {
      if (product.data.url) {
        window.open(product.data.url);
      } else {
        history.push(product.data.redirect);
      }
    }
  };


  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked, {
      "Product": product.data.category,
    });
  }

  return (
    <div className={`product-container  ${matchesMd ? " lg" : ""} ${matchesLg ? " lg" : ""}`}>
      <Card className="product-card">
        <CardContent>
          <h6>
            <span className="icon-container">
              <img className="icon" src={product.data.icon} alt="product data" />
            </span>
            {categoriesTranslated.find((x) => x.id === product.data.category).name}
          </h6>
          <h4>{product.data.name}</h4>
          <h5>{product.data.brief}</h5>
          <p>{product.data.description}</p>
        </CardContent>
        <CardActions className="cardActions">
          <Button className="explore" size="small" variant="text">
            <Link to={product.data.redirect} className="global_link global_link_flex" onClick={() => { trackEvent(product.data.name + "ExploreMoreClicked") }}>
              {t("Explore_More")}
              <ArrowForwardIcon />
            </Link>
          </Button>
          {product.data.category === "MOTOR" && (
            <Button
              className="buy"
              size="small"
              variant="outlined"
              onClick={(e) => { window.location.assign(product.data.url); trackEvent(product.data.name + "BuyNowClicked"); }}
            >
              {t("Buy now")}
            </Button>
          )}
          {product.data.category === "Health" && !product.data.HideBuyNow && (
            <Button
              className="buy"
              size="small"
              variant="outlined"
              onClick={(e) => { window.location.assign(product.data.url); trackEvent(product.data.name + "BuyNowClicked"); }}
            >
              {t("Buy now")}
            </Button>
          )}
          {product.data.category === "property" && !product.data.HideBuyNow && (
            <Button
              className="buy"
              size="small"
              variant="outlined"
              onClick={(e) => { window.location.assign(product.data.url); trackEvent(product.data.name + "BuyNowClicked"); }}
            >
              {t("Buy now")}
            </Button>
          )}
        </CardActions>
      </Card>
    </div>
  );
};
