import React, { useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import dateIcon from "assets/svg/datePickerIcon.svg";
import selectIcon from "assets/svg/selectArrowIcon.svg";
import "./style.scss";

export const DatePickerInput = ({
	placeHolder,
	needSelectIcon = false,
	alignDateIconTop,
	datePickerClass,
}) => {
	const [startDate, setStartDate] = useState("");

	const getArabStore = localStorage.getItem("newTawuniyaLanguageParam")

	return (
		<React.Fragment>
			{getArabStore ? (
				<React.Fragment>
					<div className="dateIconBoxRight" id={alignDateIconTop}>
						<div className="dateIconRight">
							<img src={dateIcon} className="img-fluid" alt="dateIcon" />
						</div>
					</div>
					{needSelectIcon && (
						<div className="selectIconBoxRight" id={alignDateIconTop}>
							<div className="selectIconRight">
								<img src={selectIcon} className="img-fluid" alt="dateIcon" />
							</div>
						</div>
					)}
				</React.Fragment>
			) : (
				<React.Fragment>
					<div className="dateIconBox" id={alignDateIconTop}>
						<div className="dateIcon">
							<img src={dateIcon} className="img-fluid" alt="dateIcon" />
						</div>
					</div>
					{needSelectIcon && (
						<div className="selectIconBox" id={alignDateIconTop}>
							<div className="selectIcon">
								<img src={selectIcon} className="img-fluid" alt="dateIcon" />
							</div>
						</div>
					)}
				</React.Fragment>
			)}
			<DatePicker
				selected={startDate}
				onChange={(date) => setStartDate(date)}
				dateFormat="dd/MM/yyyy"
				placeholderText={placeHolder}
				className={`${datePickerClass} loginDatePicker`}
				id={getArabStore && "loginDatePicker-placeHolderAlign"}
			/>
		</React.Fragment>
	);
};
