import React, { useState } from "react";
import { useMediaQuery } from "@material-ui/core";
import { categories, categoriesAr, products, productsAr } from "../Schema/productsData";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import "./products.scss";
import { ProductCard } from "../productCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CSSTransition } from "react-transition-group";
import { ProductCardPlaceholder } from "../productCard/placeholder";
import { ProductCardAlternate } from "../productCard/alternate";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getI18n, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const ProductsCatalog = (props) => {
  const isRTL = getI18n().dir() === "rtl";

  const [activeCategory, setActiveCategory] = useState("MOTOR");
  const { i18n, t } = useTranslation(); const matchesMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const matchesLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const matchesmdlg = useMediaQuery((theme) => theme.breakpoints.between(1280, 1440));
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");

  const getLink = () => {
    if (props.activeProducts === "corporate" && props.SME)
      return "/products/corporate/allproductssme";
    if (props.activeProducts === "corporate" && props.enterpris)
      return "/products/corporate/allproductsenterprise";
    return "/individuals/products/all-products";
  };

  const changeSelectedCategory = (categoryId) => {
    return setActiveCategory(categoryId);
  };

  const nextCategory = () => {
    const index = categories.findIndex((x) => x.id === activeCategory);
    const nextIndex = index === categories.length - 1 ? 0 : index + 1;
    trackEvent(categories[nextIndex].id+"Clicked");
    return setActiveCategory(categories[nextIndex].id);
  };

  const previousCategory = () => {
    const index = categories.findIndex((x) => x.id === activeCategory);
    const nextIndex = index === 0 ? categories.length - 1 : index - 1;
    trackEvent(categories[nextIndex].id+"Clicked");
    return setActiveCategory(categories[nextIndex].id);
  };

  const currentLanguage = getI18n().language;

  const categoriesTranslated = currentLanguage === "en" ? categories : categoriesAr;
  const productsTranslated = currentLanguage === "en" ? products : productsAr;

  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked);
  }

  return (
    <div className={`products-container ${matchesMd ? " lg" : ""} ${matchesLg ? " lg" : ""}`} id={props.id}>
      <div className="grid-percentage">
        <div className="content-container">
          <div className="heading-container">
            <div className={`artwork ${isRTL ? "rtl-artwork-scale" : ""}`}></div>
            <div className="content">
              <h2 className="mt-3">
                {categoriesTranslated.find((x) => x.id === activeCategory)?.heading &&
                  categoriesTranslated.find((x) => x.id === activeCategory).heading}
              </h2>
              <p className="subheading">
                {categoriesTranslated.find((x) => x.id === activeCategory)?.subHeading &&
                  categoriesTranslated.find((x) => x.id === activeCategory).subHeading}
              </p>
              <div className="categoriesLinks mt-3">
                <ul>
                  {categoriesTranslated.map((category, index) => {
                    return (
                      <li key={index} className={`category ${category.id === activeCategory && "active"}`}>
                        <Button
                          variant="text"
                          onClick={() => {
                            changeSelectedCategory(category.id);
                            trackEvent(category.id+"Clicked");
                          }}
                        >
                          <img src={category.icon} alt="category" />
                          {category.name}
                        </Button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={`featured-image ${matchesMd ? " lg" : ""} ${matchesLg ? " lg" : ""}`}>
          <div className={`${selectedLanguage === "ar" ? "nav-buttons" : "nav-buttons-rtl"}`}>
            <Button
              className="previous"
              variant="contained"
              onClick={() => {
                previousCategory();
              }}
            >
              <ArrowBackIcon />
            </Button>

            <Button
              className="next"
              variant="contained"
              onClick={() => {
                nextCategory();
              }}
            >
              <ArrowForwardIcon />
            </Button>
          </div>
          <img
            className="img-fluid"
            src={
              categoriesTranslated.find((x) => x.id === activeCategory)?.backgroundImage &&
              categories.find((x) => x.id === activeCategory).backgroundImage
            }
            alt="categories"
          />
        </div> */}

        <div
          className={`cards-container ${matchesMd ? " lg" : ""} ${matchesLg ? " lg" : ""} ${matchesmdlg ? " mdlg" : ""
            }`}
        >
          {productsTranslated.map((product, index) => {
            const visible =
              activeCategory === product.category &&
              ((props.activeProducts === "individual" && product.visibility.individual) ||
                (props.activeProducts === "corporate" && props.SME && product.visibility.corporate.SME) ||
                (props.activeProducts === "corporate" &&
                  props.enterprise &&
                  product.visibility.corporate.enterprise));
            let template;
            switch (product.template) {
              case "placeholder":
                template = <ProductCardPlaceholder key={product.name} data={product} />;
                break;
              case "alternate":
                template = <ProductCardAlternate key={product.name} data={product} />;
                break;
              default:
                template = <ProductCard key={product.name} data={product} />;
            }
            return (
              <CSSTransition in={visible} classNames="productsAnimation" timeout={0} key={index}>
                {() => visible && template}
              </CSSTransition>
            );
          })}
        </div>
        <div className="cards-footer-container">
          <Button className="viewAll" size="small" variant="text">
            <Link to={getLink()} className="global_link global_link_flex">
              {t("View_All_Products ")}
              <ArrowForwardIcon />
            </Link>
          </Button>
        </div>
      </div>
    </div>
  );
};
