import React, { useState } from "react";
import { NormalButton } from "component/common/NormalButton";
import expandIcon from "assets/svg/expandIcon.svg";
import closeIcon from "assets/images/toggleCloseIcon.png";
import deriveicon from "assets/svg/driveicon.png";
import previewbannera from "assets/svg/previewbannera.svg";
import previewbannerb from "assets/svg/previewbannerb.svg";
import { useTranslation } from "react-i18next";
import { history } from "service/helpers";

export const HomeBanner = () => {
  const [toggle, setToggle] = useState(true);
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  const { i18n, t } = useTranslation();
  return (
    <div className="row BannerContainer position-relative">
      <div className="col-lg-12 col-md-12 col-12 BannerBackground p-2">
        {toggle ? (
          <React.Fragment>
            <div className="d-flex justify-content-between px-4 mx-4">
              <div>
                <div className="d-flex pt-1">
                  <div>
                    <p className="toggleOpenHeading text-light fw-800 m-0">{t("topBar.messageLarge")}</p>
                  </div>
                  <div className="pl-3">
                    <p className="toggleOpenPara text-light m-0">{t("topBar.messageMedium")}</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex">
                  <div className="pr-5">
                    <NormalButton
                      label={t("topBar.downloadBtnText")}
                      className="download-tawuniya-open-drive-btn"
                      onClick={(e) => history.push("/individuals/loyalty-program/drive")}
                    />
                  </div>
                  <div>
                    <img
                      src={expandIcon}
                      className="img-fluid closeIconToggler pr-4 pt-2"
                      onClick={() => setToggle(false)}
                      alt="closeicon"
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <div className="row">
            <img className="previewbannerleft" src={previewbannera} alt="preview banner bg" />
            <img
              className="previewbannerrighta"
              src={previewbannerb}
              alt="preview banner baground inage"
            />
            {selectedLanguage === "en" ? <img className="previewbannerrightb" src={deriveicon} alt="Tawuniya drive Icon" />
              : <img className="previewbannerleftb" src={deriveicon} alt="Tawuniya drive Icon" />}
            <div className="col-11 offset-1">
              <div className="d-flex justify-content-between">
                <div>
                  <p className="toggleHeading fw-800 m-0">{t("topBar.messageLarge")}</p>
                  <p className="togglePara fs-22 fw-400 m-0">{t("topBar.messageInside")}</p>
                  <div className="py-2">
                    <NormalButton
                      label={t("topBar.downloadBtnText")}
                      className="download-tawuniya-close-drive-btn p-3"
                      onClick={(e) => history.push("/individuals/loyalty-program/drive")}
                    />
                  </div>
                </div>
                <div className="pt-3">
                  <img
                    src={closeIcon}
                    className="img-fluid closeIconToggler pr-2"
                    onClick={() => setToggle(true)}
                    alt="closeicon"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
