import React from "react";

import faboutImgCard1 from "assets/images/faboutImgCard1.png";
import faboutImgCard2 from "assets/images/faboutImgCard2.png";
import faboutImgCard3 from "assets/images/faboutImgCard3.png";
import "./style.scss";

function FooterAboutImgCard({ items }) {
	const images = [faboutImgCard1, faboutImgCard2, faboutImgCard3];
	return (
		<div className="faboutImgCardContainer faboutImgCard">
			{items.map((item, index) => {
				return (
					<div key={index} className={`faboutImgCc mr${index + 1}`}>
						<img src={images[index]} alt={item.cardTitle} />
						<p className="faboutImgTitlea">{item.cardTitle}</p>
					</div>
				);
			})}
		</div>
	);
}

export default FooterAboutImgCard;
