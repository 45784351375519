import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import Close from "assets/images/mobile/close1.png";
import "./style.scss";

export default function NormalPopup({
	isSearchModel,
	children,
	setIsSearchModel,
	fullBg = false,
}) {
	const [show, setShow] = useState(true);

	const handleClose = () => setShow(!show);

	return (
		<>
			<Modal
				show={isSearchModel}
				onHide={handleClose}
				className={`normal-popup ${fullBg && "full_bg"}`}
			>
				<div className="d-flex header">
					<img
						src={Close}
						alt="Close"
						onClick={() => {
							setIsSearchModel(false);
						}}
					></img>
				</div>
				{children}
			</Modal>
		</>
	);
}
