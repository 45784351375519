import { store } from "service/helpers";
import instance, { preProdInstance } from "service/instance";

export const complaintCaseCreation = async (reqData) => {
	try {
		const { data } = await instance.post(
			"/restful/preLogin/complaintCaseCreation",
			reqData,
			{
				headers: {
					Authorization:
						"Bearer" +
						" " +
						store.getState().loginDetailsReducer?.loginResponse?.user_info
							?.token,
					ApplicationType: "WebsitePreLogin"
				},
			}
		);
		if (data && data.return) return data.return;
		return {};
	} catch (error) {
		return Promise.reject("error");
	}
};

export const complaintEnquiry = async (reqData) => {
	try {
		const { data } = await instance.post(
			"/restful/preLogin/doComplaintEnquiry",
			reqData,
			{
				headers: {
					Authorization:
						"Bearer" +
						" " +
						store.getState().loginDetailsReducer?.loginResponse?.user_info
							?.token,
				},
			}
		);
		if (data && data.return && data.return.data) return data.return.data;
		else return {};
	} catch (error) {
		return Promise.reject("error");
	}
};

export const submitWFRequest = async (reqData) => {
	try {
		const { data } = await instance.post('/common/submitWFRequestLanding', reqData)
		if (data) return data
		else throw new Error()
	} catch (error) {
		return Promise.reject("error");
	}
}