import React, { useState } from "react";
import "./style.scss";
import DownArrowImage from "assets/images/down-arrow.svg";
import ErrorComponent from "component/common/ErrorComponent";
const PhoneNumberInput = ({
	value,
	onChange = () => "",
	placeholder = "ex: 5xxxxxxxx",
	name,
	dialingCodes,
	selectedCode,
	setSelectedCode = () => "",
	className,
	selectInputClass,
	selectInputFlexType,
	isCodeFalse = false,
	errorMessage,
}) => {
	let [dropDown, setDropDown] = useState(false);
	return (
		<>
			<div
				className={
					"phone-number-input-common " + (dropDown ? "dropdown-open" : "")
				}
			>
				<div className="select-input" id={selectInputClass}>
					<div
						className="input"
						id={selectInputFlexType}
						
					>
						<div className="image pr-1">
							<img src={selectedCode?.image} alt="selectedCode" />
						</div>
						{!isCodeFalse && (
							<div className="code pr-2">{selectedCode?.code}</div>
						)}

						{/* <div id="downarrow" className="downarrow">
							<img src={DownArrowImage} alt="DownArrowImage" />
						</div> */}
					</div>
					<div
						className={"overlay " + (dropDown ? "open" : "")}
						onClick={() => setDropDown(false)}
					></div>
					<div className={"options " + (dropDown ? "open" : "")}>
						{dialingCodes?.map((item, index) => {
							let { code, image } = item;
							return (
								<div
									className="option"
									key={index.toString()}
									onClick={() => {
										setSelectedCode(item);
										setDropDown(false);
									}}
								>
									<div className="image">
										<img src={image} alt="image" />
									</div>
									<div className="code">{code}</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="number-input">
					<input
						type="number"
						className={className}
						name={name}
						value={value}
						maxLength={9}
						onChange={(event) => {
							if (event.target.value.length > 10) return;
							onChange(event);
						}}
						placeholder={placeholder}
					/>
				</div>
			</div>
			{errorMessage && <ErrorComponent message={errorMessage} />}
		</>
	);
};

export default PhoneNumberInput;
