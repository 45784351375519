import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import CardComp from "../../../common/HomeServiceCard/index";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { makeStyles } from "@material-ui/core";
import headerSearchIcon from "assets/svg/headerSearchIcon.svg";
import ArrowForward from "assets/svg/HomeServiceFroward1Arrow.svg";
import ArrowBack from "assets/svg/HomeServiceBackArrow.svg";
import { getI18n, useTranslation } from "react-i18next";
import { HomeServicesData, HomeServicesDataAr } from "../Schema/HomeServicesData";

import colors from "assets/scss/abstracts/colors.scss";

const useStyles = makeStyles((theme) => ({
  dots: {
    bottom: -63,
    "& li.slick-active button::before": {
      color: colors["bright-purple"],
    },
    "& li": {
      width: "12px",
      "& button::before": {
        fontSize: theme.typography.pxToRem(9),
        color: "#4C565C",
      },
    },
  },
}));

export const HomeServiceCard = (props) => {
  const [navPill, setNavPill] = useState(0);

  const classes = useStyles();

  const sliderRef = useRef(null);

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    dotsClass: `slick-dots ${classes.dots}`,
    infinite: false,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  const currentLanguage = getI18n().language;

  const HomeServicesDataTranslated =
    currentLanguage === "en" ? HomeServicesData : HomeServicesDataAr;
  const { i18n, t } = useTranslation(); const getPillIndex = useSelector((val) => val.languageReducer.layoutPosition);

  return (
    <>
      <div className="serviceContainer">
        {!props.header ? (
          <></>
        ) : (
          <>
            {HomeServicesDataTranslated[0].Search && !props.dontShowSearch ? (
              <div className="serviceContainerFlex">
                <div className="serviceCon	tainerText">
                  <h1 className="HomeServiceHeading1">{HomeServicesDataTranslated[0].serviceTitle}</h1>

                  <p className="homeservicePar1">{HomeServicesDataTranslated[0].serviceSubtitle}</p>
                </div>

                <div className="otherServiceNavCont">
                  <form className="navSearchContainer">
                    <input className="inputEl" type="search" placeholder="What are you looking for?" />
                    <img className="homeserviceSearchIcon" src={headerSearchIcon} alt="home service Search Icon" />
                  </form>
                </div>
              </div>
            ) : (
              <>
                <h1 className="HomeServiceHeading1 text-center">
                  {HomeServicesDataTranslated[0].serviceTitle}
                </h1>

                <p className="homeservicePar1 text-center">
                  {HomeServicesDataTranslated[0].serviceSubtitle}
                </p>
              </>
            )}
          </>
        )}

        {props.isPillNeeded && (
          <React.Fragment>
            {HomeServicesDataTranslated[0].Services ? (
              <div className="ServiceNavCont">
                <div className="ServiceNavEl">
                  {HomeServicesDataTranslated[0].Services
                    ? HomeServicesDataTranslated[0].Services.map((item, i) => {
                      return (
                        <p
                          key={i}
                          className={navPill === item.id ? "navEl-highlight" : "navEl"}
                          onClick={() => setNavPill(item.id)}
                        >
                          {item.pillName}
                        </p>
                      );
                    })
                    : ""}
                </div>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        )}

        {!props.header || props.dontShowLine ? (
          <></>
        ) : (
          <>
            {HomeServicesDataTranslated[0].hrLine ? (
              <hr className="hrLine" />
            ) : (
              <hr className="invisiblehrLine" />
            )}
          </>
        )}
        <React.Fragment>
          {HomeServicesDataTranslated.map((item, i) => {
            return (
              <div className={`${props.dontShowLine ? "marginCommonTop" : ""}`}>
                {item?.Services?.map((items, i) => {
                  return (
                    <>
                      {props.isPillNeeded ? (
                        <React.Fragment>
                          {navPill === items.id && (
                            <Slider ref={sliderRef} {...settings} key={i}>
                              {items?.serviceCardData?.map((val, i) => {
                                return (
                                  <div className="slider-card-root">

                                    {!val.hideCard && <CardComp item={val} key={i} routeURL={val.url} />}
                                  </div>
                                );
                              })}
                            </Slider>
                          )}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {items.id === props.pillNumber && (
                            <Slider ref={sliderRef} {...settings} key={i}>
                              {items?.serviceCardData?.map((val, i) => {
                                return (
                                  <div className="slider-card-root">
                                    {!val.hideCard && <CardComp item={val} key={i} />}
                                  </div>
                                );
                              })}
                            </Slider>
                          )}
                        </React.Fragment>
                      )}
                    </>
                  );
                })}
              </div>
            );
          })}
        </React.Fragment>

        {/* <div className="arrowDotContainer">
     <div className="arrowContainer" onClick={() => sliderRef.current.slickPrev()}>
      <img alt="Tawanya" src={ArrowBack} />
     </div>

     <div className="dotContainer"></div>

     <div className="arrowContainer" onClick={() => sliderRef.current.slickNext()}>
      <img alt="Tawanya" src={ArrowForward} />
     </div>
    </div> */}
      </div>
    </>
  );
};
