import { BRANCH_DETAILS, HOSPITAL_DETAILS, WORKSHOP_DETAILS } from "service/actionType";

const initialState = {
  branches: [],
  hospitals: [],
  workShops: []
};

const contactUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case BRANCH_DETAILS:
      return {
        ...state,
        branches: action.payload,
      };
    case HOSPITAL_DETAILS:
      return {
        ...state,
        hospitals: action.payload,
      };
    case WORKSHOP_DETAILS:
      return {
        ...state,
        workShops: action.payload,
      };
    default:
      return state;
  }
};

export default contactUsReducer;