import React, { useEffect, useRef, useState } from "react";
import PinInput from "react-pin-input";
import { history } from "service/helpers";
import { NormalButton } from "component/common/NormalButton";
import BottomPopup from "../../../component/common/MobileReuseable/BottomPopup";
import "./style.scss";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import { setLoginResponse } from "action/LoginResponse";
import preloader from "assets/loader.gif";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import ResetMobileNumber from "./resetNumber";
import { updateLoader } from "action/DashboardInformation";
import * as LoginServices from "../../../service/login/loginservices";
import { setOtpVerified } from "action/LoginResponse";
import { useFormik } from "formik";
import { ReactComponent as CreditCardIcon } from "assets/svg/credit-card.svg";
import { InputText } from "primereact/inputtext";
import * as globalUtils from "../../../helpers/utils";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import KSAFlagImage from "assets/images/ksa_flag.png";
import { updateProfile } from "service/dashboard/dashboardService";
import { Toast } from "primereact/toast";

import { config as globalConfig } from "../../../helpers/config";

export const SetNumber = ({
	isSetNumOpen,
	setisSetNumOpen,
	setIsOpenLoginModel,
	setisResetOpen,
}) => {
	const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");

	const mobileNumber = useSelector(
		(state) => state?.loginDetailsReducer?.customerValidation?.mobile
	);

	const RestLoginVal = useSelector(
		(state) => state?.loginDetailsReducer?.loginRestResponse
	);

	const loader = useSelector(
		(state) => state.dashboardInformationReducer.loader
	);

	const { t } = useTranslation();
	const dispatch = useDispatch();



	const [counter, setCounter] = useState(60);
	const [error, seterror] = useState("");
	const [saveOtp, setSaveOtp] = useState("");
	const [apiResponse, setapiResponse] = useState(false);
	const [isOtpAcived, setIsOtpAcived] = useState(false);
	const [newMobileNumber, setMobileNumber] = useState("");
	const validateOtpHandler = () => {
		triggerVilateOTP();
	};
	useEffect(() => {
		// setisResetOpen(false);
		// setisResetOpen(false);

		const timer =
			counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		return () => clearInterval(timer);
	}, [counter, setisResetOpen]);
	const handleResendClick = () => {
		setCounter(60);
		let data = {
			mobile: mobileNumber,
		};
		LoginServices.sendOTP(data).then((response) => {
			if (response.status === "1") {
				dispatch(updateLoader(false));
			} else {
				dispatch(updateLoader(false));
				seterror(response.statusDescritpion);
				setapiResponse(true);
			}
		});
	};

	const formik = useFormik({
		initialValues: {
			mobile: "",
		},
		validate: (data) => {
			let errors = {};
			// if (!data.nationalId) {
			// 	errors.nationalId = t("login_footer.national_id");
			// } else if (!globalUtils.isValidSaudiIDOrIQama(data.nationalId)) {
			// 	errors.nationalId = t("login_footer.id_not_valid");
			// }
			//
			// if (!data.yob) {
			// 	errors.yob = t("login_footer.year_birth");
			//
			// }else if( (data.yob[0] ===  "1" && data.yob[1] !== "9") || (data.yob[0] ===  "2" && data.yob[1] !== "0")){
			// 	errors.yob = t("login_footer.year_birth");
			// }

			if (!data.mobile) {
				errors.mobile = t("login_footer.mob_require");
			} else if (!globalUtils.isValidSaudiPhoneNumber(data.mobile)) {
				errors.mobile = t("login_footer.mob_notrequire");
			}
			return errors;
		},
		onSubmit: (data) => {
			setMobileNumber(data.mobile);
			triggerSendOTP(data);
		},
	});

	const triggerSendOTP = (data) => {
		const newValue = {
			mobile: "+966" + data.mobile,
		};

		LoginServices.sendOTP(newValue).then((response) => {
			setapiResponse(true);
			if (response.status === "1") {
				dispatch(updateLoader(false));
				setIsOtpAcived(true);
			} else if (response.status === "2") {
				dispatch(updateLoader(false));
				seterror(response.statusDescritpion);
				setapiResponse(true);
			} else {
				dispatch(updateLoader(false));
				seterror(response.statusDescritpion);
				setapiResponse(true);
			}
		});
	};

	const triggerVilateOTP = (data) => {
		const newVal = {
			mobileNumber: "+966" + newMobileNumber,
			otp: saveOtp,
		};

		LoginServices.verifyOtp(newVal).then((response) => {
			setapiResponse(true);
			if (response.status === "1") {
				trigerupdateProfile(data);
			} else if (response.status === "2") {
				dispatch(updateLoader(false));
				seterror(response.errorDescription);
			} else {
				seterror(response.errorDescription);
			}
		});
	};

	const trigerupdateProfile = () => {
		setapiResponse(true);
		let data = {
			fax: "",
			langCode: selectedLanguage[0].toUpperCase(),
			mobileNumber: "+966" + newMobileNumber,
			phone: "+966" + newMobileNumber,
			prefCommunicationMethod: RestLoginVal.user_info.preferredLanguage,
			prefLanguage: RestLoginVal.user_info.preferredContact,
			eMail: RestLoginVal?.user_info?.EMail,
			userID: RestLoginVal?.user_info?.userID,
			// token: RestLoginVal?.user_info?.token,
		};
		updateProfile(data).then((response) => {
			if (response?.data?.return?.data?.resultCode === 0) {
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "Your request has been successfully submitted",
					life: 3000,
				});
				setIsOpenLoginModel(true);
			} else {
				toast.current.show({
					severity: "error",
					summary: "Failed",
					detail: "Your request could not be submitted",
					life: 3000,
				});
			}
		});
	};

	const getValueTemplate = (rowData) => {
		return (
			<div className="d-flex justify-content-between">
				<img src={rowData.flag} alt="flag" width="20" />
				<span>{rowData.label}</span>
			</div>
		);
	};

	const isFormFieldValid = (name) =>
		!!(formik.touched[name] && formik.errors[name]);
	const getFormErrorMessage = (name) => {
		return (
			isFormFieldValid(name) && (
				<small className="p-error">{formik.errors[name]}</small>
			)
		);
	};

	const isResetNumOpen = useSelector(
		(state) => state.loginDetailsReducer?.isResetOpen
	);

	const toast = useRef(null);

	return (
		<BottomPopup open={isResetNumOpen} setOpen={setisSetNumOpen} isReset={true}>
			{loader && (
				<div className="loader-tawuniya">
					<img src={preloader} alt="preloader" />
				</div>
			)}
			<Toast ref={toast} />

			{isOtpAcived ? (
				<div className="login_page_mobile verify_page">
					<h5>{t("login_footer.veri")}</h5>
					<p>
						{t("login_footer.code")} {"****"}
						{mobileNumber?.slice(-4)}{" "}
					</p>
					<div className="">
						<PinInput
							length={4}
							initialValue=""
							onChange={(value, index) => {
								setSaveOtp(value);
							}}
							inputMode="numeric"
							style={{
								display: "flex",
								justifyContent: "center",
							}}
							inputStyle={{
								border: "none",
								backgroundColor: "#F2F3F5",
								borderRadius: "4px",
								width: "100%",
								marginRight: "2%",
								minHeight: "90px",
							}}
							autoSelect={true}
							regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
						/>
					</div>
					{apiResponse && <small className="p-error">{error}</small>}
					<NormalButton
						label={t("login_footer.Verify")}
						className="authContinueBtn font-Lato p-4 mt-4"
						onClick={validateOtpHandler}
					/>
					<p className="fs-14 fw-400 resendText text-center pt-3">
						{t("login_footer.rese")} 00:{counter < 10 ? 0 : ""}
						{counter}
					</p>

					{counter === 0 && (
						<p className="fs-14 fw-400 noVerifyCodeMsg text-center m-0 ">
							{t("login_footer.dont")}{" "}
							<span
								className="resendAgainLink"
								onClick={() => handleResendClick()}
							>
								{t("login_footer.resend")}
							</span>
						</p>
					)}
				</div>
			) : (
				<div className="login_page_mobile verify_page">
					<h5>{t("login_footer.RESET-NO")}</h5>

					<span>
						{t("login_footer.YOUR-NO")} {RestLoginVal?.user_info?.mobile}
					</span>

					<form
						onSubmit={formik.handleSubmit}
						className="p-fluid mt-4 formclass"
					>
						<div className="field mt-3 phone-input">
							<span className="p-input-icon-left d-flex">
								<Dropdown
									options={[
										{
											label: "+966",
											flag: KSAFlagImage,
											value: "+966",
										},
									]}
									disabled
									value="+966"
									style={{ color: "#455560" }}
									valueTemplate={getValueTemplate}
								/>

								<InputText
									name="mobile"
									value={formik.values.mobile}
									onChange={formik.handleChange}
									className={classNames({
										values: true,
										"p-invalid": isFormFieldValid("mobile"),
									})}
									placeholder="ex: 5xxxxxxxx"
								/>
							</span>
						</div>
						{getFormErrorMessage("mobile")}
						{getFormErrorMessage("Technical Error. Please try again later")}

						{apiResponse && (
							<small className="p-error mt-1 mb-1">{error}</small>
						)}

						<Button
							label={t("login_footer.conti")}
							className={"authContinueBtn font-Lato p-4 mt-4 "}
							onClick={formik.submitForm}
							type="button"
						/>
					</form>
				</div>
			)}
			<ResetMobileNumber setisResetOpen={setisResetOpen} />
		</BottomPopup>
	);
};

export default SetNumber;
