import { store } from "service/helpers";

export const getcustomerValidationRequest = (data) => {
  return {
    iqamaId: data.nationalId,
    langId: store
      .getState()
      .languageReducer?.language.slice(0, 1)
      .toUpperCase(),
    yob: data.yob,
  };
};

export const getcustomerValidationResponse = (response) => {
  return response.data;
};

export const customerRegistrationRequest = (data, responseData) => {
  return {
    password: "",
    iqamaId: data.nationalId,
    mobileNumber: "966" + data.mobile,
    "re-enterPassword": "",
    langId: store
      .getState()
      .languageReducer?.language.slice(0, 1)
      .toUpperCase(),
    eMail: data.eMail,
    moduleName: "",
    businessService: "",
  };
};

export const customerRegistrationResponse = (response) => {
  return response.data;
};

export const customerLoginRequest = (data) => {
  return {
    username: data.nationalId,
    yearOfBirth: data.yob,
    langId: store
      .getState()
      .languageReducer?.language.slice(0, 1)
      .toUpperCase(),
    channel: "MOBILE",
    flag: data.flag,
    verifiationCode: data.verifiationCode,
    loginType: data.loginType,
  };
};

export const customerLoginResponse = (response) => {
  return response.data;
};

export const confirmOTPRequest = (data) => {
  return {
    transactionId: data.transactionId,
    langId: store
      .getState()
      .languageReducer?.language.slice(0, 1)
      .toUpperCase(),
    otp: data.otp,
  };
};

export const confirmOTPResponse = (response) => {
  return response.data;
};

export const sendOTPRequest = (data) => {
  return {
    mobile: data.mobile,
    channel: "Portal",
    businessService: "TRAVEL",
    language:
      store.getState().languageReducer?.language.slice(0, 1).toUpperCase() ===
      "A"
        ? "2"
        : "1",
  };
};

export const sendOTPResponse = (response) => {
  return response.data;
};

export const verifyOtpRequest = (data) => {
  return {
    mobile: data.mobileNumber,
    channel: "Portal",
    otpCode: data.otp,
    language:
      store.getState().languageReducer?.language.slice(0, 1).toUpperCase() ===
      "A"
        ? "2"
        : "1",
  };
};

export const verifyOtpResponse = (response) => {
  return response.data;
};

export const validateElmResponse = (response) => {
  return response.data;
};
export const sendElmResponse = (response) => {
  return response.data;
};

export const validateElmRequest = (data) => {
  return {
    customerId: data.customerId,
    verificationCode: data.verificationCode,
    channel: "Portal",
    languageCode: store
      .getState()
      .languageReducer?.language.slice(0, 1)
      .toUpperCase(),
  };
};

export const sendElmRequest = (data) => {
  return {
    customerId: data.customerId,
    operatorId: data.customerId,
    reason: "request",
    channel: "Portal",
    mobileNumber: data.mobileNumber,
    eMail: data.eMail,
    languageCode: store
      .getState()
      .languageReducer?.language.slice(0, 1)
      .toUpperCase(),
  };
};

export const gccCustomerLoginRequest = (data) => {
  return data;
};

export const gccCustomerLoginResponse = (response) => {
  return response.data;
};

export const sendEmailOtpRequest = (data) => {
  return data;
};

export const sendEmailOtpResponse = (response) => {
  return response.data;
};

export const verifyEmailOtpRequest = (data) => {
  return data;
};

export const verifyEmailOtpResponse = (response) => {
  return response.data;
};

export const updateGccCustEmailRequest = (data) => {
  return data;
};

export const updateGccCustEmailResponse = (response) => {
  return response.data;
};
