import { updateLoader } from "action/DashboardInformation";
import { useDispatch } from "react-redux";
import { history, store } from "service/helpers";
import instance from "service/instance";
import * as utils from "./dashboardUtils";

export const getPolicyInformation = async (data) => {
 try {
  const response = await instance.post(
   "/restful/postLogin/getPolicyInformationNew",
   utils.getPolicyInformationRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.getPolicyInformationResponse(response);
 } catch (err) {
  alert("Something went wrong");
  window.location.reload("/");
  return Promise.reject("error");
 }
};

export const getMotorPolicyDetails = async (element, userID, customerID, langId, token) => {
 try {
  const response = await instance.post(
   "/restful/postLogin/getMotorPolicyDetailsNew",
   utils.getMotorPolicyDetailsRequest(element, userID, customerID, langId, token),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.getMotorPolicyResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getMotorStatusDetail = async (element, userID, customerID, langId) => {
 try {
  const response = await instance.post(
   "/restful/postLogin/getMotorPolicyDetailsNew",
   utils.getMotorDetailRequest(element, userID, customerID, langId),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return utils.getMotorDetailResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getMedicalPolicyDetails = async (
 element,
 userID,
 iqamahID,
 medCustomerID,
 customerID,
 langId,
 token
) => {
 try {
  const response = await instance.post(
   "/restful/postLogin/getMedicalPolicyDetailsNew",
   utils.getMedicalPolicyDetailsRequest(
    element,
    userID,
    iqamahID,
    medCustomerID,
    customerID,
    langId,
    token
   ),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.getMedicalPolicyDetailsResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getTravelPolicyDetails = async (element, userID, customerID, langId, token) => {
 try {
  const response = await instance.post(
   "/restful/postLogin/getTravelPolicyDetailsNew",
   utils.getTravelPolicyDetailsRequest(element, userID, customerID, langId, token),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.getTravelPolicyDetailsResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getServicesDetails = async (element, userID, langId, token) => {
 try {
  const response = await instance.post(
   "/vasMotor/servicesDetails",
   utils.servicesDetailsRequest(element, langId, token),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.servicesDetailsResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getMedApprovalDetails = async (
 element,
 userID,
 iqamahID,
 langId,
 customerID,
 medCustomerID,
 token
) => {
 try {
  const response = await instance.post(
   "/restful/postLogin/getMedApprovalDetailsNew",
   utils.getMedApprovalDetailsRequest(
    element,
    userID,
    iqamahID,
    langId,
    customerID,
    medCustomerID,
    token
   ),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.getMedApprovalDetailsResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getRenewalPolicyDetail = async (idNumber, yob, langId, token) => {
 try {
  const response = await instance.post(
   "/motorRetail/getRenewalList",
   utils.getRenewalPolicyDetailRequest(idNumber, yob, langId, token),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.getRenewalPolicyDetailResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getAccidentDetail = async (element, userID, langId, token) => {
 try {
  const response = await instance.post(
   "/motorCOClaimSubmission/accidentList",
   utils.getAccidentDetailRequest(element, userID, langId, token),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.getAccidentDetailResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getMemberHistory = async (data) => {
 try {
  const response = await instance.post(
   "/telemedicine/memberHistory",
   utils.memberHistoryRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.memberHistoryResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getRequestService = async (data) => {
 try {
  const response = await instance.post(
   "/restful/postLogin/getMedicalPolicyDetailsNew",
   utils.requestServiceRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.requestServiceResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getSubmitTelemedicine = async (data) => {
 try {
  const response = await instance.post(
   "/telemedicine/consultation",
   utils.submitTelemedicineRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.submitTelemedicineResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getPolicyInformartionValue = async (data) => {
 try {
  const response = await instance.post(
   "/restful/postLogin/getPolicyInformationNew",
   utils.getPolicyInformartionValueRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.getPolicyInformartionValueResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getMotorPolicyDetailsValue = async (data) => {
 try {
  const response = await instance.post(
   "/restful/postLogin/getMotorPolicyDetailsNew",
   utils.getMotorPolicyDetailsValueRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.getMotorPolicyDetailsValueResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getEligVouServ = async (data) => {
 try {
  const response = await instance.post("/vasMotor/eligVouServ", utils.getEligVouServRequest(data), {
   headers: {
    Authorization:
     "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
   },
  });

  return await utils.getEligVouServResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getMemberShip = async (data) => {
 try {
  const response = await instance.post(
   "/vasMotor/morniMemberID",
   utils.getMemberShipRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.getMemberShipResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getPeriodSubmit = async (data) => {
 try {
  const response = await instance.post(
   "/vasMotor/createRSARequest",
   utils.getPeriodSubmitRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.getPeriodSubmitResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getClaimTrakerDetails = async (data) => {
 try {
  const response = await instance.post(
   "/restful/preLogin/getClaimTrakerDetailsNew",
   utils.getClaimTrackRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );
  return await utils.getClaimTrackResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const checkClaimStatus = async (data, langId, userID) => {
 try {
  const response = await instance.post(
   "/motorCOClaimSubmission/checkClaimStatusNew",
   utils.checkClaimStatusRequest(data, langId, userID),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );
  return await utils.checkClaimStatusResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getmedicalProvider = async (data) => {
 try {
  const response = await instance.post(
   "/restful/preLogin/getMedicalProviders",
   utils.medicalProviderRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );
  return await utils.medicalProviderResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getFaxeligibility = async (data) => {
 try {
  //console.log("faxreq", data);
  const response = await instance.post(
   "/restful/preLogin/faxEligibilityLetter",
   utils.faxEligibilityRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.faxEligibilityResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getSendEmail = async (data) => {
 try {
  const response = await instance.post("/common/sendEmailLanding", data, {
   headers: {
    Authorization:
     "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
   },
  });
  return await utils.sendEmailResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const generateDigitalCards = async (data, langId) => {
 try {
  const response = await instance.post(
   "/common/generateDigitalCards",
   utils.generateDigitalCardsRequest(data, langId),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );
  return await utils.generateDigitalCardsResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getMedicalBenefits = async (data, langId) => {
 try {
  const response = await instance.post(
   "/restful/preLogin/getMedicalBenefits",
   utils.getMedicalBenefitsRequest(data, langId),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );
  return await utils.getMedicalBenefitsResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getBenefitsLimit = async (data, langId) => {
 try {
  const response = await instance.post(
   "/login/getBenefitsLimits",
   utils.getBenefitsLimitRequest(data, langId),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );
  return await utils.getBenefitsLimitResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getMedReimbursementDetails = async (data) => {
 try {
  const response = await instance.post(
   "/restful/preLogin/getMedReimbursementDetails",
   utils.reimbursmentRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );
  return await utils.reimbursmentResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const updateProfile = async (data) => {
 try {
  const response = await instance.post(
   "/restful/postLogin/doUpdateProfile",
   utils.updateProfileRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );

  return await utils.updateProfileResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getPolicyInfo = async (data) => {
 try {
  const response = await instance.post("/common/getPolicyInfo", utils.getPolicyInfoRequest(data), {
   headers: {
    Authorization:
     "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
   },
  });
  return await utils.getPolicyInfoResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};

export const getServicesHistory = async (data) => {
 try {
  const response = await instance.post(
   "/vasMotor/servicesHistory",
   utils.getServicesHistoryRequest(data),
   {
    headers: {
     Authorization:
      "Bearer" + " " + store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
    },
   }
  );
  return await utils.getServicesHistoryResponse(response);
 } catch (err) {
  return Promise.reject("error");
 }
};
