import React from "react";
import { useTranslation } from "react-i18next";

const ErrorComponent = ({ message }) => {
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  return (
    <div className={`${isRTL ? "text-right" : "text-left"}`}>
      <span className="fs-14 text-danger">{message}</span>
    </div>
  )
};

export default ErrorComponent;
