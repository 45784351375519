import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import "./style.scss";
import {
	makeStyles,
	createTheme,
	ThemeProvider,
} from "@material-ui/core/styles";

const useStyles = makeStyles({
	title: {},
	dialog: {
		margin: 0,
		"& .MuiDialog-paper-7": {
			width: "100%",
		},
	},
});

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AnimatedBotton({
	children,
	open,
	setOpen,
	minHeight = "270px",
	maxHeight = "",
}) {
	const theme = createTheme({
		shape: {
			borderRadius: "20px 20px 0 0",
		},
		overrides: {
			MuiDialog: {
				paper: {
					margin: 0,
					width: " 100%",
				},
				title: {
					position: "relative",
				},
				scrollPaper: {
					"&::after": {
						verticalAlign: "bottom",
					},
					alignItems: "flex-end",
					verticalAlign: "bottom",
					position: "absolute",
					bottom: "0",
					width: " 100%",
				},
			},
			MuiDialogContent: {
				dividers: {
					borderTop: 0,
					minHeight: minHeight,
					padding: "16px",
					position: "relative",
					width: "100%",
					maxHeight: maxHeight,
				},
			},
		},
	});

	const scroll = "paper";

	const handleClose = () => {
		setOpen(false);
	};

	const descriptionElementRef = useRef(null);

	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	useEffect(() => {
		setOpen(true);
	}, []);

	const classes = useStyles();

	return (
		<div>
			<ThemeProvider theme={theme}>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					onClose={handleClose}
					className={classes.dialog}
					scroll={"paper"}
					aria-labelledby="scroll-dialog-title"
					aria-describedby="scroll-dialog-description"
				>
					<DialogContent dividers={scroll === "paper"}>
						<DialogContentText
							id="scroll-dialog-description"
							ref={descriptionElementRef}
							tabIndex={-1}
						>
							{children}
						</DialogContentText>
					</DialogContent>
				</Dialog>
			</ThemeProvider>
		</div>
	);
}
