import React from "react";
import BannerCanvas from "./BannerCanvas/BannerCanvas";

export const NormalOffCanvas = ({ closeToggler }) => {
	return (
		<React.Fragment>
			<BannerCanvas closeToggler={closeToggler} />
		</React.Fragment>
	);
};
