import React from "react";
import HeaderCloseNav from "component/common/MobileReuseable/HeaderCloseNav";
import "./style.scss";
import { useTranslation } from "react-i18next";


export const Myfamilyinsurance = ({ productName }) => {
  const { t } = useTranslation();
  const benefitDetails = [
    {
      id: 1,
      benefits: "Maximum policy benefit limit per person every year",
      gold: "SR 50,000",
      silver: "SR 30,000",
      platinum: "SR 100,000",
      diamond: "SR 250,000",
    },
    {
      id: 2,
      benefits:
        "Room & board limit: At preferred provider network At non preferred provider network (emergency only)",
      gold: "Shared Room SR 350",
      silver: "Shared Room SR 350",
      platinum: "Private Room SR 600",
      diamond: "Private Room SR 600",
    },
    {
      id: 3,
      benefits: "Out-patient doctors fees at preferred provider network",
      gold: "SR 100",
      silver: "SR.75",
      platinum: "SR 150",
      diamond: "SR 200",
    },
    {
      id: 4,
      benefits:
        "Maximum pre-existing benefit sub-limit per person every year (no waiting period)",
      gold: "SR 50,000",
      silver: "SR 30,000",
      platinum: "SR 100,000",
      diamond: "SR 250,000",
    },
    {
      id: 5,
      benefits: "Local ambulance services",
      gold: "Full Cover",
      silver: "Full Cover",
      platinum: "Full Cover",
      diamond: "Full Cover",
    },
    {
      id: 6,
      benefits: "Circumcision for newborn male babies",
      gold: "Full Cover",
      silver: "Full Cover",
      platinum: "Full Cover",
      diamond: "Full Cover",
    },
    {
      id: 7,
      benefits: "Ear piercing for newborn female babies",
      gold: "Full Cover",
      silver: "Full Cover",
      platinum: "Full Cover",
      diamond: "Full Cover",
    },
    {
      id: 8,
      benefits: "Vaccinations for children as per MOH specification",
      gold: "Full Cover",
      silver: "Full Cover",
      platinum: "Full Cover",
      diamond: "Full Cover",
    },
    {
      id: 9,
      benefits: "Physiotherapy treatment",
      gold: "Covered (12 Sessions)",
      silver: "Covered (12 Sessions)",
      platinum: "Covered (12 Sessions)",
      diamond: "Covered (12 Sessions)",
    },
    {
      id: 10,
      benefits: "Hearing aids / other aids / equipments",
      gold: "SR. 1,000",
      silver: "SR. 1,000",
      platinum: "SR. 1,000",
      diamond: "SR. 1,000",
    },
    {
      id: 11,
      benefits: "Geographical limits :Within KSA Outside KSA",
      gold: "Within KSA : Covered Outside KSA: Not Covered",
      silver: "Within KSA : Covered Outside KSA: Not Covered",
      platinum: "Within KSA : Covered Outside KSA: Not Covered",
      diamond: "Within KSA : Covered Outside KSA: Not Covered",
    },
  ];
  const opticals = [
    {
      id: 12,
      benefits: "Maximum optical benefit limit Per Person Per Year",
      gold: "SR. 300",
      silver: "SR. 200",
      platinum: "SR. 500",
      diamond: "SR. 1,000",
    },
    {
      id: 13,
      benefits: "Optical examination",
      gold: "Covered",
      silver: "Covered",
      platinum: "Covered",
      diamond: "Covered",
    },
    {
      id: 14,
      benefits: "lenses once per person per year",
      gold: "Covered",
      silver: "Covered",
      platinum: "Covered",
      diamond: "Covered",
    },
  ];
  const matirnityCover = [
    {
      id: 15,
      benefits: "Maximum maternity benefit limit per spouse per year (PSPY)",
      gold: "SR. 7,000",
      silver: "SR. 5,000",
      platinum: "SR. 10,000",
      diamond: "SR. 15,000",
    },
    {
      id: 16,
      benefits: "Normal delivery Caesarean section/complications",
      gold: "Covered",
      silver: "SR. 5,000",
      platinum: "Covered",
      diamond: "Local road ambulance service",
    },
    {
      id: 17,
      benefits:
        "Legal Abortion/Miscarriage(Pre and post natal care covered under basic limit)",
      gold: "SR. 7,000	",
      silver: "SR. 5,000",
      platinum: "SR. 10,000",
      diamond: "SR. 15,000",
    },
  ];

  const dentalCover = [
    {
      id: 18,
      benefits: "Maximum dental benefit limit PPPY (basic cover only)",
      gold: "SR. 1,500",
      silver: "SR. 1,000",
      platinum: "SR. 2,000",
      diamond: "SR. 3,000",
    },
    {
      id: 19,
      benefits: "Dental consultation",
      gold: "Covered",
      silver: "Covered",
      platinum: "Covered",
      diamond: "Covered",
    },
    {
      id: 20,
      benefits: "Amalgam/composite (non-cosmetic) filling",
      gold: "Covered",
      silver: "Covered",
      platinum: "Covered",
      diamond: "Covered",
    },
    {
      id: 21,
      benefits: "Root canal treatment	",
      gold: "Covered",
      silver: "Covered",
      platinum: "Covered",
      diamond: "Covered",
    },
    {
      id: 22,
      benefits: "Gum treatment",
      gold: "Covered",
      silver: "Covered",
      platinum: "Covered",
      diamond: "Covered",
    },
    {
      id: 23,
      benefits: "X-rays & prescribed medication in respect of above covers",
      gold: "Covered",
      silver: "Covered",
      platinum: "Covered",
      diamond: "Covered",
    },
  ];
  const deductibleCover = [
    {
      id: 24,
      benefits: "Each and every out-patient claim",
      gold: "20%",
      silver: "20%",
      platinum: "20%",
      diamond: "20%",
    },
    {
      id: 24,
      benefits: "Each and every out-patient claim",
      gold: "20%",
      silver: "20%",
      platinum: "20%",
      diamond: "20%",
    },
  ];

  return (
    <>
      {productName !== "my-family-insurance" && <div>
        <HeaderCloseNav
          pageName="Health Plans"
          title="360° Health Insurance"
          isBottom={true}
        />
      </div>}
      <div className="family_tables">
        <h5 className="font-weight-bold text-dark ml-3">
          Plans For Your Future
        </h5>
        <p className="plans_para ml-3">
          Simple prices, No hidden fees, Advanced features for your business
        </p>
        <table>
          <tr>
            <th className="font-weight-bold text-dark text-center">Benifits</th>
            <th className="font-weight-bold text-dark text-center">Gold</th>
            <th className="silver_fonts text-center">Silver</th>
            <th className="font-weight-bold text-dark text-center">Platinum</th>
            <th className="font-weight-bold text-dark text-center">Diamond</th>
          </tr>
          {benefitDetails.map((benefitDetail) => (
            <tr>
              <td className="benefits_fonts">{benefitDetail.benefits} </td>
              <td className="gold_fonts">{benefitDetail.gold}</td>
              <td className="silver_fonts">{benefitDetail.silver}</td>
              <td className="gold_fonts">{benefitDetail.platinum}</td>
              <td className="gold_fonts">{benefitDetail.diamond}</td>
            </tr>
          ))}
          <p className="mt-5 mb-5 font-weight-bold text-dark ml-3">
            Optical Cover Benefits Limit
          </p>
          {opticals.map((optical) => (
            <tr>
              <td className="benefits_fonts">{optical.benefits} </td>
              <td className="gold_fonts">{optical.gold}</td>
              <td className="silver_fonts">{optical.silver}</td>
              <td className="gold_fonts">{optical.platinum}</td>
              <td className="gold_fonts">{optical.diamond}</td>
            </tr>
          ))}
          <p className="mt-5 mb-5 font-weight-bold text-dark ml-3">
            Matirnity/Obstetrical Cover
          </p>
          {matirnityCover.map((matirnity) => (
            <tr>
              <td className="benefits_fonts">{matirnity.benefits} </td>
              <td className="gold_fonts">{matirnity.gold}</td>
              <td className="silver_fonts">{matirnity.silver}</td>
              <td className="gold_fonts">{matirnity.platinum}</td>
              <td className="gold_fonts">{matirnity.diamond}</td>
            </tr>
          ))}
          <p className="mt-5 mb-5 font-weight-bold text-dark ml-3">
            Dental cover benefits limit
          </p>
          {dentalCover.map((dental) => (
            <tr>
              <td className="benefits_fonts">{dental.benefits} </td>
              <td className="gold_fonts">{dental.gold}</td>
              <td className="silver_fonts">{dental.silver}</td>
              <td className="gold_fonts">{dental.platinum}</td>
              <td className="gold_fonts">{dental.diamond}</td>
            </tr>
          ))}
          <p className="mt-5 mb-5 font-weight-bold text-dark ml-3">
            Deductible coinsurance
          </p>
          {deductibleCover.map((deductible) => (
            <tr>
              <td className="benefits_fonts">{deductible.benefits} </td>
              <td className="gold_fonts">{deductible.gold}</td>
              <td className="silver_fonts">{deductible.silver}</td>
              <td className="gold_fonts">{deductible.platinum}</td>
              <td className="gold_fonts">{deductible.diamond}</td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
};
