import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import popUp from "assets/svg/popup_img.svg";
import "./style.scss";
import AnimatedBottomPopup from "component/common/MobileReuseable/AnimatedBottomPopupHome";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const DialogClose = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

DialogClose.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const PopUpComp = ({ title, content, btnText, openPopup, closePopup }) => {
    const [open, setOpen] = React.useState(true);
    const width = window.innerWidth;
    const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
    const { t, i18n } = useTranslation();

    // const handleClickOpen = () => {
    //   setOpen(true);
    // };
    // const handleClose = () => {
    //   setOpen(false);
    // };

    const handleBtnClick = () => {
        closePopup();
        if (btnText === "Buy My family" || btnText === "Upgrade Now") {
            window.location.replace("https://smeonline.tawuniya.com.sa/");
        }
        if (btnText === "Buy Motor Plolicy" || btnText === "اشتر وثيقة المركبات") {
            window.location.assign("/individuals/products/motor");
        }
        if (btnText === "My Dashboard" || btnText === "لوحة التحكم الخاص بي") {
            window.location.assign("/dashboard");
        }
    };
    useEffect(() => {
        if (selectedLanguage === "ar") {
            document.getElementsByTagName("body")[0].classList.add("language-alignment-right");
            i18n.changeLanguage("ar");
        } else {
            document.getElementsByTagName("body")[0].classList.remove("language-alignment-right");
            i18n.changeLanguage("en");
        }
    }, [selectedLanguage]);

    return (
        <div>
            {width > 600 ? (
                <BootstrapDialog
                    onClose={closePopup}
                    aria-labelledby="customized-dialog-title"
                    open={openPopup}
                    className="popup-container"
                >
                    <DialogClose id="customized-dialog-title" onClose={closePopup}></DialogClose>
                    <DialogContent>
                        <img src={popUp} alt="popup-img" />
                        <Typography variant="h5" className="heading">
                            {title ? title : ""}
                        </Typography>
                        <Typography gutterBottom className="para-txt">
                            {content ? content : ""}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        {btnText ? (
                            <Button autoFocus onClick={handleBtnClick} className="btn-content">
                                {btnText}
                            </Button>
                        ) : (
                            ""
                        )}
                    </DialogActions>
                </BootstrapDialog>
            ) : (
                <div className="popup-container-mobile">
                    <AnimatedBottomPopup open={openPopup} setOpen={setOpen} maxHeight={"450px"}>
                        <div className="clas_img d-flex align-items-center justify-content-center">
                            <img
                                src={popUp}
                                alt="ConfirmationWoman"
                                className="position-fixed img-change"
                                width={250}
                                height={250}
                            />
                        </div>
                        <div className="txt-container d-flex align-items-center flex-column">
                            <span className="heading">{title ? title : ""}</span>
                            <span className="para-txt">{content ? content : ""}</span>
                        </div>
                        <div className="btn-container d-flex">
                            {btnText ? (
                                <Button autoFocus onClick={handleBtnClick} className="btn-content">
                                    {btnText}
                                </Button>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="close-container d-flex align-items-center">
                            <span className="close-text" onClick={closePopup}>
                                Close
                            </span>
                        </div>
                    </AnimatedBottomPopup>
                </div>
            )}
        </div>
    );
};

export default PopUpComp;
