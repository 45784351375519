export const languageTranslate = {
  languageArab: "LANGUAGE_ARAB",
  layoutPosition: "LAYOUT_POSITION",
  requestedService: "REQUESTED_SERVICE",
  language: "LANGUAGE",
  toggleCanvas: "TOGGLE_CANVAS",
  toogleValue: "TOGGLE_VALUE",
  toggleContent: "TOGGLE_CONTENT",
};

export const policyInfo = {
  policyInfo: "SET_POLICY_INFO",
};

export const loader = {
  loader: "UPDATE_LOADER",
};

export const medicalPolicyDetails = {
  medicalPolicyDetails: "SET_MEDICAL_POLICY_DETAILS",
};

export const travelPolicyDetails = {
  travelPolicyDetails: "SET_TRAVEL_POLICY_DETAILS",
};

export const motorPolicyDetails = {
  motorPolicyDetails: "SET_MOTOR_POLICY_DETAILS",
};

export const serviceDetails = {
  serviceDetails: "SET_SERVICE_DETAILS",
};

export const medApprovalDetails = {
  medApprovalDetails: "SET_MED_APPROVAL_DETAILS",
};

export const accidentDetail = {
  accidentDetail: "SET_MED_ACCIDENT_DETAILS",
};

export const renewalPolicyDetail = {
  renewalPolicyDetail: "SET_MED_RENEWAL_POLICY_DETAILS",
};

export const loginResponse = {
  loginResponse: "SET_LOGIN_RESPONSE",
};
export const loginInput = {
  loginInput: "SET_LOGIN_INPUT",
};
export const registerInput = {
  registerInput: "SET_REGISTER_INPUT",
};
export const registerResponse = {
  registerResponse: "SET_REGISTER_RESPONSE",
};

export const memberHistory = {
  memberHistory: "UPDATE_MEDMBER_HISTORY",
};

export const teleMedicalPolicyDetails = {
  teleMedicalPolicyDetails: "UPDATE_MEDICAL_POLICY_DETAILS",
};

export const submitTelemedicine = {
  submitTelemedicine: "UPDATE_SUBMIT_TELEMEDICINE",
};

export const policyInformationValue = {
  policyInformationValue: "UPDATE_POLICY_INFORMATION_VALUE",
};

export const motorPolicyDetailsValue = {
  motorPolicyDetailsValue: "UPDATE_MOTOR_POLICY_DETAILS_VALUE",
};
export const EligVouSerValue = {
  EligVouSerValue: "UPDATE_ELIGIBLE_SERVICE_VALUE",
};

export const memberShipValue = {
  memberShipValue: "UPDATE_MEMBERSHIP_VALUE",
};

export const periodSubmit = {
  periodSubmit: "UPDATE_PERIOD_SUBMIT",
};

export const updateMap = {
  updateMap: "UPDATE_MAP",
};

export const selectedCardData = {
  setSelectedCardData: "SET_SELECTED_CARD_DATA",
};

export const updateClaimTrackDetails = {
  updateClaimTrackDetails: "UPDATE_CLAIM_TRACK_DETAILS",
};

export const updateMedicalClaimTrack = {
  updateMedicalClaimTrack: "UPDATE_MEDICAL_CLAIM_TRACK",
};

export const updatemedicalProvider = {
  updatemedicalProvider: "UPDATE_MEDICAL_PROVIDER",
};

export const updateGenerateDigitalCards = {
  updateGenerateDigitalCards: "UPDATE_gENERATE_DIGITAL_CARDS",
};

export const faxEligibility = {
  faxEligibility: "UPDATE_FAX_ELIGIBILITY",
};

export const COMPLAINT_CASE_CREATION_REQUEST =
  "COMPLAINT_CASE_CREATION_REQUEST";
export const COMPLAINT_CASE_CREATION_SUCCESS =
  "COMPLAINT_CASE_CREATION_SUCCESS";
export const COMPLAINT_ENQUIRY = "COMPLAINT_ENQUIRY";

export const LOGOUT = "LOGOUT";

export const SET_MEDICAL_MEMBER_LIST = "SET_MEDICAL_MEMBER_LIST";

export const SET_BENEFITS_OPEN = "SET_BENEFITS_OPEN";
export const GET_DRIVE_STATUS = "GET_DRIVE_STATUS";

export const BRANCH_DETAILS = "BRANCH_DETAILS";
export const HOSPITAL_DETAILS = "HOSPITAL_DETAILS";
export const WORKSHOP_DETAILS = "WORKSHOP_DETAILS";
export const SET_EMBASSY_LETTER = "SET_EMBASSY_LETTER";
export const SET_EMBASSY_LETTER_MEM_DETAILS = "SET_EMBASSY_LETTER_MEM_DETAILS";
export const GET_MEDICAL_REIMBURSEMENT_DETAILS =
  "GET_MEDICAL_REIMBURSEMENT_DETAILS";
export const GET_MEDICAL_POLICY_DETAILS = "GET_MEDICAL_POLICY_DETAILS";
export const SUBMIT_MEDICAL_REIMBURSEMENT_REQUEST =
  "SUBMIT_MEDICAL_REIMBURSEMENT_REQUEST";
export const TOGGLE_RECENT_INTERACTION_LOADING =
  "TOGGLE_RECENT_INTERACTION_LOADING";
export const SET_BANK_DETAILS = "SET_BANK_DETAILS";
export const SET_OTP_VERIFIED = "SET_OTP_VERIFIED";
export const TYPE_OF_REPORT = "TYPE_OF_REPORT";
export const SET_POLICY_COUNT = "SET_POLICY_COUNT";
export const UPDATE_MOBILE = "UPDATE_MOBILE";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const OPEN_POLICY_DETAILS_INFO = "OPEN_POLICY_DETAILS_INFO";
export const OPEN_POLICY_DETAILS = "OPEN_POLICY_DETAILS";
export const OPEN_MOTOR_POLICY_DETAILS = "OPEN_MOTOR_POLICY_DETAILS";
export const SET_CUSTOMER_VALIDATION = "SET_CUSTOMER_VALIDATION";
export const SET_SELECTED_CARD_TYPE = "SET_SELECTED_CARD_TYPE";
export const SET_DIGITAL_DATA = "SET_DIGITAL_DATA";
export const SET_OPEN_MOBILE_NUMBER_INPUT = "SET_OPEN_MOBILE_NUMBER_INPUT";
export const OPEN_POPUP = "OPEN_POPUP";
export const SET_RESET_LOGIN_RESPONSE = "SET_RESET_LOGIN_RESPONSE";
export const SET_OPENED_MOBILE = "SET_OPENED_MOBILE";
export const DIGITAL_DATA_BOOLEAN = "DIGITAL_DATA_BOOLEAN";
export const welcomekit = {
  getLandingUrls: "GET_LANDING_URLS",
  getTollfreeNum: "GET_TOLLFREE_NUM",
  getWhatsapplink: "GET_WHATSAPP_LINK",
  getLandingLogo: "GET_LANDING_LOGO",
  setLanguage: "SET_LANGUAGE",
  clearLandingUrls: "CLEAR_LANDING_URLS",
  getDigitalCard: "GET_DIGITAL_CARD",
};
export const SET_SERVICE_INSURANCE_URL = "SET_SERVICE_INSURANCE_URL";
export const SET_GCC_USER_INFO = "SET_GCC_USER_INFO";
export const SET_MED_APPROVAL_DATA = "SET_MED_APPROVAL_DATA";
export const SET_CO_CLAIMS_REDIRECT = "SET_CO_CLAIMS_REDIRECT";
export const SET_CO_CLAIMS_REDIRECT_AR = "SET_CO_CLAIMS_REDIRECT_AR";
