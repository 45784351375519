import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import Routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "service/helpers";
import "assets/scss/index.scss";
import "react-notifications/lib/notifications.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import "assets/fonts/tawn/Tawuniya-Medium.otf";
import "assets/fonts/tawn/Tawuniya-Regular.otf";
import './i18n';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import 'assets/css/main.scss';

import { externalLinks } from "./helpers/constant";

import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import SmartAppBanner from "component/SmartAppBanner/SmartAppBanner";

ReactDOM.createRoot(
  document.getElementById("root")
).render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {/* <SmartAppBanner /> */}
      <Routes />
    </ThemeProvider>
  </Provider>
)
serviceWorker.unregister();
