import React from "react";
import { CommonCard } from "component/common/CommonCard";
import emergencyBanner from "assets/images/What_to_Do_in_an_Emergency.png";
import road from "assets/svg/emergencyIcons/roadsideemergency.svg";
import accident from "assets/svg/emergencyIcons/accidentEmergency.svg";
import telemed from "assets/svg/emergencyIcons/telemedEmergency.svg";
import america from "assets/svg/emergencyIcons/assistAmericaEmergency.svg";
import flightDelay from "assets/svg/emergencyIcons/flighdelayEmergency.svg";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const EmergencyCard = ({ isAlign = false, setEmergencyCard = () => { } }) => {
    const { i18n, t } = useTranslation();
    const isRTL = i18n.dir() === "rtl";

    const loggedProfile = useSelector((state) => state?.loginDetailsReducer?.loginResponse);
    const userID = loggedProfile?.user_info?.userID;
    const language = localStorage.getItem("newTawuniyaLanguageParam");

    const translationPrefix = "emergencySupport";

    const emergencyData = [
        {
            id: 0,
            title: t(`${translationPrefix}.motor`),
            buttons: [
                {
                    label: t(`${translationPrefix}.roadSideAssistance`),
                    icon: road,
                    url1: "/dashboard/service/road-assistance",
                    url2: "/individuals/customer-service/road-assistance",
                    action: () => setEmergencyCard(false),
                },
                {
                    label: t(`${translationPrefix}.accidentAssistance`),
                    icon: accident,
                    url1: "/individuals/car-accident",
                    url2: "/individuals/car-accident",
                    action: () => setEmergencyCard(false),
                },
            ],
        },
        {
            id: 1,
            title: t(`${translationPrefix}.medical`),
            cardLines: "sub-emergency-conatiner",
            buttons: [
                {
                    label: t(`${translationPrefix}.telemedicineDoctorConsultation`),
                    icon: telemed,
                    url1: "/dashboard/service/request-telemedicine",
                    url2: "/individuals/products/health/tele-medicine",
                    action: () => setEmergencyCard(false),
                },
                {
                    label: t(`${translationPrefix}.assistAmerica`),
                    icon: america,
                    url1: "/dashboard/service/assist-america",
                    url2: "/individuals/customer-service/assist-america",
                    action: () => setEmergencyCard(false),
                },
            ],
        },
        // {
        //     id: 2,
        //     title: t(`${translationPrefix}.travel`),
        //     buttons: [
        //         {
        //             label: t(`${translationPrefix}.flightDelayAssistance`),
        //             icon: flightDelay,
        //             url1: "/individuals/flight-delay-assistance",
        //             url2: "/individuals/flight-delay-assistance",
        //             action: () => setEmergencyCard(false),
        //         },
        //     ],
        // },
    ];

    const trackEvent = (tabClicked, title) => {
        window.lmSMTObj.track(tabClicked, {
            "Product":title
        });
      }

    return (
        <div className="emergency-card-Container">
            <CommonCard
                width="29em"
                cardPosition={isAlign ? "card-Support-Layout-forInsurance" : "card-Support-Layout"}
            >
                <div className="pt-1 pb-2 m-2 emergenyContainer">
                    <div className="emergencyContentImage">
                        <img src={emergencyBanner} className="img-fluid" alt="bannerPic" />
                        <p className={`Change ${language === "ar" ? "mr-4" : ""}`}>
                            {" "}
                            {t("emergencySupport.Emergency_desk")}
                        </p>
                    </div>
                </div>
                <div className="pt-1 pb-2 px-3">
                    <div className="row">
                        {emergencyData.map((item, index) => {
                            return (
                                <div key={index} className={`${item.cardLines} col-6 pt-2 pb-2 `}>
                                    <p className="m-0 fs-20 fw-800 py-1 emergencyTitle">{item.title}</p>
                                    {item.buttons.map((button) => (
                                        <Button key={button.label} className="emergencyItemButton" onClick={button.action}>
                                            <Link
                                                className="global_link global_link_flex"
                                                to={isEmpty(userID) ? button.url2 : button.url1}
                                                onClick={() => {trackEvent(button.label+"TabClicked", item.title)}}
                                            >
                                                <img src={button.icon} className="px-2" alt="icon" />
                                                <Typography
                                                    variant="button"
                                                    color="black"
                                                    className={`emergencyItemButton-text ${isRTL ? "emergencyItemButton-text-right" : ""}`}
                                                >
                                                    {button.label}
                                                </Typography>
                                            </Link>
                                        </Button>
                                    ))}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </CommonCard>
        </div>
    );
};
