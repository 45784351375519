import React from "react";
import "../style.scss";
import { useTranslation } from "react-i18next";
import CommonIndividualsForm from "./CommonIndividualsForm/CommonIndividualsForm";
import { externalLinks } from "helpers/constant";


export const TravelForm = () => {
 const btnPill = false;
 const { i18n, t } = useTranslation();
 return (
  <div className="recentFeed-SubForm mt-3 mb-5">
   <div className="row">
    <div className="col-12">
     <CommonIndividualsForm
      sme={btnPill}
      application={"TRAVEL"}
      url={externalLinks.Renewal + "travel"}
     />
     <p className="fs-9 fw-400 recentFeedTerms pt-2 m-0">{t("form_agreement")}</p>
    </div>
   </div>
  </div>
 );
};
