import React from "react";
import "./productCard.scss"
import {Card} from "@mui/material";
import {useMediaQuery} from "@material-ui/core";

export const ProductCardPlaceholder = (product) => {
    const matchesMd = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const matchesLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <div className={`product-container placeholder  ${matchesMd ? ' lg' : ''} ${matchesLg ? ' lg' : ''}`}>
            <Card className="product-card" style={{visibility: 'hidden'}}>

            </Card>
        </div>

    )
}
