import React from 'react'
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import backTop from "assets/svg/backTop.svg";
import TawuniyaLogo from "assets/svg/LogoIcon.svg";
import appStore from "assets/images/appleStoreFooter.png";
import playStore from "assets/images/playStoreFooter.png";
import { NormalButton } from 'component/common/NormalButton';
import { LandingSiteFilesLink } from 'helpers/constant';
import './footerStyle.scss'

const PageNotFoundLayoutFooter = () => {
  const currentLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const appData = [appStore, playStore];

  const handleBackTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };


  const handleDownloadFile = () => {
    if(i18n.language == "ar"){
      window.open(LandingSiteFilesLink.CustomerProtectionFilear);
    }else{
      window.open(LandingSiteFilesLink.CustomerProtectionFile);
  
    }
  };

  const calLemAndRedirect = (url) => {
    window.lmSMTObj.page(url, {
        path: document.location.pathname,
        language: currentLanguage == 'ar' ? "Arabic" : "English",
        referrer: document.referrer,
        title: document.title,
        url: document.URL,
        search: location.search,
    });
    window.location.assign(
      `https://www.ia.gov.sa/${currentLanguage}`
    )
  };

  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked);
  }

  return (
      <div className="col-12 footerLastContainer">
          <div className="row footerContentBox">
            <div className="footerButtonPositon">
              <div className="col-12 footerButtonContainer">
                <NormalButton
                  label={t("footer.backToTop")}
                  className={`${currentLanguage === "en" ? "backTopButton" : "backTopButton-rtl"
                    } mx-auto d-block`}
                  onClick={handleBackTop}
                  needBtnPic={true}
                  src={backTop}
                  adjustIcon={`${currentLanguage === "en" ? "pl-lg-2" : "pr-lg-2"}`}
                />
              </div>
            </div>
            <div className="col-lg-8 col-12 footerBottomLeftSpacing">
              <img src={TawuniyaLogo} alt="TawuniyaLogo" />
              <p className="copyrightText fs-14 fw-400">{t("footer.copyright")}</p>
            </div>
            <div className="col-lg-4 col-12 p-md-0 footerBottomRightSpacing">
              <div className="footerPositionIcons">
                <div
                  className="d-flex flex-lg-row flex-md-row flex-column pb-lg-0 pb-3 classchanges"
                  style={{ cursor: "pointer" }}
                >
                  {appData.map((i, index) => {
                    return (
                      <a
                        title={index === 0 ? "APP Store" : "Google Play"}
                        href={
                          index === 0
                            ? "https://apps.apple.com/us/app/%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%88%D9%86%D9%8A%D8%A9-tawuniya/id1639353191"
                            : "https://play.google.com/store/apps/details?id=com.dxp.TawuniyaInsurance"
                        }
                        target={'_blank'}
                        onClick={() => trackEvent(index === 0 ? "APP Store" : "Google Play"+"TabClicked")}
                        key={index}>
                        <img src={i} className="img-fluid pr-lg-3 pr-md-3" alt="socialapp" />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 footerLining"></div>
          <div className="row pt-3">
            <div className="col-lg-5 col-12">
              <div className="d-flex flex-row">
                {/* <div>
                  <Link
                    to={"/privacy-policy"}
                    title={t("footer.privacyPolicy")}
                    className="fs-12 fw-400 footerInlineText"
                    onClick={() => {trackEvent(t("footer.termsAndConditions")+"TabClicked")}}
                  >
                    {t("footer.termsAndConditions")}
                  </Link>
                </div> */}
                <div className="pl-lg-3 pl-3">
                  <Link
                    to={"/privacy-policy"}
                    title={t("footer.termsAndConditions")}
                    className="fs-12 fw-400 footerInlineText"
                    onClick={() => {trackEvent(t("footer.privacyPolicy")+"TabClicked")}}
                  >
                    {t("footer.privacyPolicy")}
                  </Link>
                </div>
                {/* <div className="pl-lg-3 pl-3">
                  <Link
                    to={"/privacy-policy"}
                    title={t("footer.cookiePolicy")}
                    className="fs-12 fw-400 footerInlineText"
                    onClick={() => {trackEvent(t("footer.cookiePolicy")+"TabClicked")}}
                  >
                    {t("footer.cookiePolicy")}
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-lg-7 col-12 pl-0">
              <div className="d-flex justify-content-lg-end justify-content-md-start">
                <div className="d-flex flex-lg-row">
                  <div>
                  <Link
                    to={"/tawuniya-auction"}
                   
                    className="fs-12 fw-400 footerInlineText"
                    onClick={() => {trackEvent(t("footer.link1")+"TabClicked")}}
                  >
                    {t("footer.link1")}
                  </Link>
                  </div>
                  <div className="pl-3 pt-1">
                    <p className="fs-12 fw-400 footerInlineText" onClick={() => {handleDownloadFile();trackEvent(t("footer.link2")+"TabClicked")}}>
                      {t("footer.link2")}
                    </p>
                  </div>
                  {/* Site Link is there in constant file*/}
                  {/* <div className="pl-lg-3 pl-3">
          <a
           href="https://www.sama.gov.sa/Pages/PageNotFoundError.aspx?requestUrl=http://www.sama.gov.sa/en-US/Laws/InsuranceRulesAndRegulations/IIR_4600_%E2%80%8EOnline_IEN.pdf"
           title={t("footer.link3")}
           className="fs-12 fw-400 footerInlineText"
           onClick={() =>
            window.location.assign(
             "https://www.sama.gov.sa/Pages/PageNotFoundError.aspx?requestUrl=http://www.sama.gov.sa/en-US/Laws/InsuranceRulesAndRegulations/IIR_4600_%E2%80%8EOnline_IEN.pdf"
            )
           }
          >
           {t("footer.link3")}
          </a>
         </div>*/}
                  <div className="pl-lg-3 pl-3">
                    <a
                      href={`https://www.ia.gov.sa/${currentLanguage}`}
                      title={t("footer.link4")}
                      className="fs-12 fw-400 footerInlineText"
                      onClick={() => { calLemAndRedirect(`https://www.ia.gov.sa/${currentLanguage}`);trackEvent(t("footer.link4")+"TabClicked")}                  
                      }
                    >
                      {t("footer.link4")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  )
}

export default PageNotFoundLayoutFooter