import React from "react";
import { history } from "service/helpers";
import { Card } from "react-bootstrap";
import { NormalButton } from "component/common/NormalButton";
import Phone from "assets/svg/phone.svg";
import Chat from "assets/svg/chaticon.svg";
import Communication from "assets/svg/communication.svg";
import "./style.scss";

export const SubReportViolation = () => {

 return null;
};
