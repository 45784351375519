import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next'
import Select, { components } from "react-select";
import ErrorComponent from "component/common/ErrorComponent";
import "./select.scss";

export const NormalSelect = (props) => {
	const { t } = useTranslation()
	let {
		className = "select-form-control w-100",
		onChange,
		options = [],
		value = "",
		name = "",
		placeholder = t("Select"),
		disabled = false,
		label = "",
		isMulti = false,
		isClearable = false,
		isSearchable = true,
		errorMessage,
		selectArrow,
		selectControlHeight,
		selectFontWeight,
		arrowVerticalAlign,
		phColor,
		fontSize,
		paddingLeft,
		isPadding,
		id,
		needRightIcon = false,
		needLeftIcon = false,
		iconPlace,
		leftIcon,
		hideLeftIcon,
		adjust,
		isInnerPadding
	} = props;

	const getArabStore = localStorage.getItem("newTawuniyaLanguageParam")
	const { i18n } = useTranslation();
	const isRTL = i18n.dir() === "rtl";

	const DropdownIndicator = (props) => {
		return (
			components.DropdownIndicator && (
				<components.DropdownIndicator {...props}>
					<img
						src={selectArrow}
						className="img-fluid selectArrowIcon"
						id={arrowVerticalAlign}
						alt="icon"
					/>
				</components.DropdownIndicator>
			)
		);
	};
	const customStyles = {
		placeholder: (base) => ({
			...base,
			fontSize: fontSize,
			color: phColor,
			fontWeight: selectFontWeight,
			paddingLeft: paddingLeft,
		}),
		indicatorSeparator: (base) => ({
			...base,
			display: "none",
		}),
		dropdownIndicator: (base) => ({
			...base,
			padding: 0,
			height: "24px",
			width: "24px",
			color: "#6B47F5",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}),
		singleValue: (base) => ({
			...base,
			color: "#4C565C",
		}),
		control: (base) => ({
			...base,
			borderRadius: 4,
			border: "none",
			height: selectControlHeight,
			outline: "0 ",
			background: "#FFFFFF",
		}),
	};
	return (
		<>
			<div className={"select-section w-100"}>
				{label !== "" ? (
					<div>
						<label className="font-weight-normal mb-1">{label}</label>
					</div>
				) : null}
				{isMulti ? (
					<Select
						className={`${className} ${isPadding && 'is-padding'}`}
						classNamePrefix="Select"
						isDisabled={disabled}
						isClearable={isClearable}
						isSearchable={isSearchable}
						name={name}
						options={options}
						onChange={onChange}
						isMulti={true}
						placeholder={placeholder}
						styles={customStyles}
						value={value}
						components={{ DropdownIndicator }}
						id={id}
					/>
				) : (
					<>
						{needLeftIcon && (
							<React.Fragment>
								{getArabStore ? (
									<div className={`rightIconBox`}>
										<div className={`rightIcon ${iconPlace && 'icon-place'} ${adjust ? "adjust-icon" : ""}`}>
											<img
												src={leftIcon}
												className="img-fluid Inputicon"
												alt="leftIcon"
											/>
										</div>
									</div>
								) : (
									<div className="leftIconBox">
										<div className="leftIcon" id={hideLeftIcon}>
											<img
												src={leftIcon}
												className="img-fluid Inputicon"
												alt="leftIcon"
											/>
										</div>
									</div>
								)}
							</React.Fragment>
						)}
						<Select
							className={`${className} ${isPadding && 'is-padding'} ${props.isNoOuterPadding && 'no-outer-padding'}`}
							classNamePrefix="Select"
							isDisabled={disabled}
							isClearable={isClearable}
							isSearchable={isSearchable}
							name={name}
							id={id}
							options={options}
							onChange={
								(item) =>
									props.controlHandelChangeValues ?
										props?.setFormInput({ ...props.formInput, businessType: [{ value: item?.value, label: item?.label }] })
										: props?.setFieldValue(props?.id, item?.value)

							}
							isMulti={isMulti}
							placeholder={placeholder}
							styles={customStyles}
							value={
								// props.value != null ? props?.value[0] : // this line added for make the value we chose show of inside the input after we make our choice
									!!options && options.length > 0
										? options.find((data) => data.value === value)
											? options.find((data) => data.value === value)
											: null
										: null
							}
							components={{ DropdownIndicator }}
						/>
					</>
				)}
			</div>
			{errorMessage && errorMessage !== "" && (
				<ErrorComponent message={errorMessage} />
			)}
		</>
	);
};
