import React from "react";
import "./Style.scss";
// import { history } from "service/helpers";
import closeIcon from "assets/images/mobile/Close.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const HeaderCloseNav = ({ pageName, title, isBottom = true }) => {
	const history = useHistory();
	const handleClose = () => {
		history.goBack();
	};
	const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");

	return (
		<div
			className={`${isBottom && "is-bottom"} header_close_nav_container-new mb-5 p-4`}
		>
			<img
				src={closeIcon}
				alt="close"
				onClick={handleClose}
				className={`${selectedLanguage === "en" ? "close" : "close-rtl"}`}
			/>
			<p className="pt-3">{pageName}</p>
			<h6>{title}</h6>
		</div>
	);
};

export default HeaderCloseNav;
