import React from "react";
import WctCard from "./WctCard";
import wctCardImg1 from "assets/images/WctCardImg1.png";
import wctCardImg2 from "assets/images/WctCardImg2.png";
import "./style.scss";

const wctCardData = [
	{
		cardTitle: "Super-Simple Claims",
		cardDiscription:
			"Smartphone enabled self-inspection processes takes minutes!",
	},
	{
		cardTitle: "Loved by Customers",
		cardDiscription: "We are trusted by customers in our years of operations!",
	},
	{
		cardTitle: "More TLC, Less T&C",
		cardDiscription:
			"We provide simpler and more efficient services to provide the experience you seek.",
	},
];

export const WhyChooseTawuniya = () => {
	return (
		<div className="wctContainer">
			<div className="wctLeft">
				<div className="wctLeftHead">
					<h5>Why Choose Tawuniya</h5>
					<p>We Provide the brest and trusted products to our customers</p>
				</div>
				<WctCard WctCardData={wctCardData} />
			</div>
			<div className="wctRight">
				<img className="wctImg1" src={wctCardImg1} alt="Super-Simple Claims" />
				<img className="wctImg2" src={wctCardImg2} alt="Loved by Customers" />
			</div>
		</div>
	);
};
