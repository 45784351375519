import ksaFlag from "assets/svg/ksa-flag.svg";
export const config = {
  apiUrl: "http://localhost:8080",
  redirectDomainUrl: process.env.REACT_APP_REDIRECT_URL,
  phoneKeysList: [{ label: "+966", flag: ksaFlag, value: "+966" }],
  plateCharchtersMapper: {
    ا: "A",
    ب: "B",
    ح: "J",
    د: "D",
    ر: "R",
    س: "S",
    ص: "X",
    ط: "T",
    ع: "E",
    ق: "G",
    ك: "K",
    ل: "L",
    م: "Z",
    ن: "N",
    ه: "H",
    و: "U",
    ى: "V",
  },
};
const getDateListYears = (currentYear) => {
	return Array(100)
		.fill()
		.map((ele, i) => {
			const value = i + currentYear - 99;
			return {
				key: value,
				label: "" + value,
				value: "" + value,
			};
		})
		.sort((a, b) => b.key - a.key);
};

export const getGregYearOfBirthList = () => {
	const gregYear = new Date().getFullYear();
	return getDateListYears(gregYear);
};

