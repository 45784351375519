import styled from "styled-components";

const OtpPageWrapper = styled.div`
  .otpVerifybutton {
    width: 80%;
    height: 60px !important;
    background: #6B47F5;
    color: #fff;
    border: #6B47F5;
    font-size: 1.5rem;
    letter-spacing: 1px;

    &:hover {
      background-color: #bd7f35;
      border-color: #bd7f35;
    }
  }


  .p-dialog .p-dialog-content .inherit{
    background: #FFFFFF  !important;
    box-shadow: 0px 35px 60px rgb(9 30 66 / 15%)  !important;
    border-radius: 8px !important;
  }

  .otp-verification-close {
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .p-dialog-content {
    background: #ffffff;
    color: #495057;
    padding: 10px 10px 15x 10px !important;
  }
  .otpVerificationTitle {
    font-style: normal;
    font-weight: 800;
    font-size: 34px;
    color: #455560;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    text-align: center;
    letter-spacing: -0.02em;
  }

  .newclass{
    color: #6b47f5;
    text-decoration: none !important;
    font-size: 1rem;
    font-weight: 400;

  }
  .verificationCodeTitle-sub {
    color: #455560;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;

  }
  .otpSentMessageStyle {
    font-size: 1rem;
    font-weight: 500;
    color: #4c565c;
    padding-top: 3px;
    text-align: center;
  }
  .verificationCodeTitle {
    font-size: 1.2rem;
    font-weight: 800;
    color: #4c565c;
    padding-bottom: 1.5rem;
  }
  .containerStyle {
    width: 60px !important;
    height: 100px !important;
    border: none !important;
    font-weight: 800;
    font-size: 40px;
    background: #f2f3f5;
  }
  .p-dialog {
    width: 35%;
  }
  .countdownTextStyle {
    font-size: 1rem;
    font-weight: 400;
    padding-top: 2rem;
    text-align: center;
    padding-bottom: 15px;
  }
  .verifyButtonDiv {
    padding-top: 30px;
  }
  .resendOtp {
    padding-top: 1rem;
    font-size: 1rem;
    font-weight: 400;
    display: inline-block;
    width: 100%;
  }

  .countdownStyle {
    color: #4c565c;
    font-size: 1rem;
    font-weight: 400;
  }
  .p-button.p-component.d-block.p-button-text.mx-auto.my-2 {
    margin: 0 5px !important;
    display: inline-block !important;
    padding: 0;
    text-decoration: underline;
  }

  @media only screen and (max-width: 600px) {
    .p-dialog {
      width: 80%;
      height: 100% !important;
    }
  }
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
  }

  @media only screen and (max-width: 576px) {
    .containerStyle {
      width: 100% !important;
      height: 100% !important;
      border: none !important;

      background: #f2f3f5;
      input {
        font-size: 35px;
      }
    }
    .otpVerifybutton {
      width: 100%;
      font-size:1rem;
    }
    element.style {
      padding: 0px 28px;
    }
    .otpVerificationTitle {
      font-size: 1.3rem;
    }
    .verificationCodeTitle {
      font-size: 1rem;
    }
    .otpSentMessageStyle {
      font-size: 0.8rem;
      margin: 10px 0;
    }
    .countdownTextStyle{
      // text-align: left;
      font-size: 0.8rem;
      padding: 1rem 0;
    }
    .resendOtp {
      font-size: 0.8rem;
      // text-align: left !important;
      padding-top: 0 !important;
    }
    .verifyButtonDiv {
      padding-top: 15px;
    }
    .p-dialog-content {
      padding: 0 0.5rem 2rem 0.5rem !important;
    }
  }

  &.ar{
    p, label ,.p-button, h2, h3, h4{
      font-family: "tawuniya";
    }
  }
`;

export default OtpPageWrapper;
