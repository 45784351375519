import React from "react";
import motor_img from "assets/svg/motor/motor_img.png";
import { useTranslation } from "react-i18next";
import { history } from "service/helpers";

export const AppBanner = () => {
  const { i18n, t } = useTranslation();
 const handleButtonClick = () => {
    history.push("/individuals/loyalty-program/drive");
 };

 return (
  <>
   <div className="motor-banner">
    <div className="motor_banner_main">
     <figure>
      <img src={motor_img} alt="mnotor img" />
     </figure>
     <figcaption className="motor_banner_content">
      <h3>{t("motorPageData.bannerData.bannerTitle")}</h3>
      <p>{t("motorPageData.bannerData.bannerTagline")}</p>
      <div className="motor_banner_btn">
       <button onClick={handleButtonClick}>{t("motorPageData.bannerData.downloadTawania")}</button>
       <span>{t("motorPageData.bannerData.poweredBy")}</span>
      </div>
     </figcaption>
    </div>
   </div>
  </>
 );
};
