import React, { useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import CloseIcon from "assets/images/mobile/Close.png";
import { setopenpopup } from "action/LoginResponse";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";

const useStyles = makeStyles({
  title: {},
  dialog: {
    margin: 0,
    "& .MuiDialog-paper-7": {
      width: "100%",
    },
    "& .MuiDialogContent-root:first-child": {
      padding: 0,
    },
    "& .paddingBox": {
      padding: 0,
    },
    "& .gray": {
      background: "#F2F3F5",
      padding: 0,
    },
  },
});
const theme1 = createTheme({
  shape: {
    borderRadius: "20px 20px 0 0",
  },
  overrides: {
    MuiDialog: {
      paper: {
        margin: 0,
      },
      title: {
        position: "relative",
      },
      scrollPaper: {
        "&::after": {
          verticalAlign: "bottom",
        },
        alignItems: "flex-end",
        verticalAlign: "bottom",
      },
    },
    MuiDialogContent: {
      dividers: {
        borderTop: 0,
        // minHeight: "100vh",
        width: "100vw",
        padding: "0px",
        position: "relative",
      },
    },
  },
});

const theme2 = createTheme({
  shape: {
    borderRadius: "20px 20px 0 0",
  },
  overrides: {
    MuiDialog: {
      paper: {
        margin: 0,
      },
      title: {
        position: "relative",
      },
      scrollPaper: {
        "&::after": {
          verticalAlign: "bottom",
        },
        alignItems: "flex-end",
        verticalAlign: "bottom",
      },
    },
    MuiDialogContent: {
      dividers: {
        borderTop: 0,
        width: "100vw",
        padding: "0px",
        position: "relative",
      },
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BottomPopup = ({
  children,
  open,
  setOpen,
  bg = "",
  isBottom = false,
  fullScreen = false,
  controlledHeight = false,
  closeResetpopup = false,
  isReset = false,
  setGccEmail = () => { },
  setActiveIndex = () => { },
  setEmailChange = () => { },
  emailFormik,
}) => {
  const scroll = "paper";
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";

  const isResetNumOpen = useSelector(
    (state) => state.loginDetailsReducer?.isResetOpen
  );

  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");

  const handleClose = () => {
    if (isReset) {
      dispatch(setopenpopup(false));
    } else {
      setOpen(false);
    }
    if (closeResetpopup) {
      if (isResetNumOpen) {
        setOpen(false);
      }
    }
    setGccEmail(false);
    setActiveIndex(0);
    setEmailChange(false);
    if (emailFormik) {
      emailFormik.setFieldValue("email", "");
    }
  };

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const classes = useStyles();

  useEffect(() => {
    if (selectedLanguage === "ar") {
      document
        .getElementsByTagName("body")[0]
        .classList.add("language-alignment-right");
      i18n.changeLanguage("ar");
      document.dir = "rtl";
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("language-alignment-right");
      i18n.changeLanguage("en");
      document.dir = "ltr";
    }
  }, [selectedLanguage]);

  return (
    <div className="bottompopup_wrapper">
      <ThemeProvider theme={controlledHeight ? theme2 : theme1}>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          TransitionComponent={Transition}
          onClose={handleClose}
          className={classes.dialog}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          dir={selectedLanguage === "ar" ? "rtl" : "ltr"}
        >
          <DialogContent dividers={scroll === "paper"} className={bg}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <div
                className={`${isBottom && "is-bottom"} bottom_popup_container ${isRTL ? "bottompopup_wrapper_ar" : ""
                  }`}
              >
                <img
                  src={CloseIcon}
                  alt="Close"
                  className="close_icon"
                  onClick={handleClose}
                />
                {children}
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default BottomPopup;
