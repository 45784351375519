import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import phoneSvg from "assets/svg/myservices_popup_phone.svg";
import appStore from "assets/images/applestore.svg";
import playStore from "assets/images/playStoreFooter.png";
import claimSaudi from "assets/svg/claim-saudi.svg";
import fileClose from "assets/svg/file-close.svg";
import AdditionalCoverage from "assets/svg/AdditionalCoverage.svg";
import fileDownlad from "assets/svg/file-download.svg";

export const GetStartedCard = () => {
  const { i18n, t } = useTranslation();
  const appData = [appStore, playStore];

  const openInNewTab = (url1) => {
    const newWindow = window.open(url1, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const serviceStartedData = [
    {
      id: 0,
      icon: claimSaudi,
      serviceTitle: t("my-services.submit"),
    },
    {
      id: 1,
      icon: fileClose,
      serviceTitle: t("my-services.cancel"),
    },
    {
      id: 2,
      icon: fileDownlad,
      serviceTitle: t("my-services.print"),
    },
    {
      id: 3,
      icon: AdditionalCoverage,
      serviceTitle: t("my-services.benefits"),
    },
  ];

  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked);
  };

  const handleSmeButtonClick = () => {
    window.open("https://tawuniya.com/sme-eservice/auth/login", "_blank");
  };

  const handleCorporateButtonClick = () => {
    window.open(
      "https://online.tawuniya.com.sa/Tawuniya/portal/home.action?langId=1&_gl=1*",
      "_blank"
    );
  };

  return (
    <div className="getStartedContainer card-GetStarted-Layout">
      <div className="row complaince-row">
        <div className="getStartedLayerTwo col-lg-6 col-12 px-4 pt-4">
          <div className="d-flex flex-column gap-5">
            <div>
              <h5 className="my-services-popup-left-title">
                {t("my-services.downApp")}
              </h5>
              <p className="my-services-popup-left-para">
                {t("my-services.key")}
              </p>
              <div className="key-services">
                {serviceStartedData.map((card, index) => {
                  return (
                    <div className="complaince-card" key={index}>
                      <img src={card.icon} alt="icon-small" />
                      <p className="complaince-text">{card.serviceTitle}</p>
                    </div>
                  );
                })}
              </div>
              <div className="footerPositionIcons mb-3">
                <div
                  className="d-flex flex-lg-row flex-md-row flex-column pb-lg-0 pb-3 classchanges"
                  style={{ cursor: "pointer" }}
                >
                  {appData.map((i, index) => {
                    return (
                      <a
                        title={index === 0 ? "APP Store" : "Google Play"}
                        href={
                          index === 0
                            ? "https://apps.apple.com/us/app/%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%88%D9%86%D9%8A%D8%A9-tawuniya/id1639353191"
                            : "https://play.google.com/store/apps/details?id=com.dxp.TawuniyaInsurance"
                        }
                        target={"_blank"}
                        onClick={() =>
                          trackEvent(
                            index === 0
                              ? "APP Store"
                              : "Google Play" + "TabClicked"
                          )
                        }
                        key={index}
                      >
                        <img
                          src={i}
                          className="img-fluid pr-lg-3 pr-md-3"
                          alt="socialapp"
                        />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="flex-fill text-center complaince-images">
              <img src={phoneSvg} alt="mobilephone" width="260" />
            </div>
          </div>
        </div>

        <div className="getStartedLayerOne col-lg-6 col-12 p-4">
          <div className="my-services-right-container">
            <div className="d-flex flex-column flex-fill banner-parent">
              <div className="my-services-banner-container-sme">
                <p className="my-services-banner-text">
                  {t("my-services.sme")}
                </p>
              </div>

              <div className="banner-details">
                <h6>{t("my-services.corporate-services")}</h6>
                <p>
                  {t("my-services.sme-content")}{" "}
                  <strong>{t("my-services.sme-portal")}</strong>
                </p>
                <button
                  onClick={handleSmeButtonClick}
                  class="btn cursor-pointer d-flex justify-content-center align-items-center my-services-login-btn p-3"
                >
                  <span>{t("my-services.sme-login")} &nbsp;&nbsp; &gt;</span>
                </button>
              </div>
            </div>

            <div className="d-flex flex-column  flex-fill banner-parent">
              <div className="my-services-banner-container-corporate">
                <p className="my-services-banner-text">
                  {t("my-services.corporate")}
                </p>
              </div>
              <div className="banner-details">
                <h6>{t("my-services.corporate-services")}</h6>
                <p>
                  {t("my-services.corporate-content")}{" "}
                  <strong>{t("my-services.corporate-portal")}</strong>
                </p>
                <button
                  onClick={handleCorporateButtonClick}
                  class="btn cursor-pointer d-flex justify-content-center align-items-center my-services-login-btn p-3"
                >
                  <span>
                    {t("my-services.corporate-login")} &nbsp;&nbsp; &gt;
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
