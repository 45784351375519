import React from "react";
import "./style.scss";
import { Button } from "@material-ui/core";
import { history } from "service/helpers";
import { useTranslation } from "react-i18next";
import colors from "assets/scss/abstracts/colors.scss";
import { Link, useLocation } from "react-router-dom";
import { externalLinks } from "helpers/constant";


export const HomeServiceImgCard = () => {
  const { i18n, t } = useTranslation();
  const openInNewTab = (url1) => {
    const newWindow = window.open(url1, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const location = useLocation();
  const selectedLanguage = localStorage.getItem("lang");
  const calLem = (url) => {
    window.lmSMTObj.page(url, {
        path: document.location.pathname,
        language: selectedLanguage == 'ar' ? "Arabic" : "English",
        referrer: document.referrer,
        title: document.title,
        url: document.URL,
        search: location.search,
    });
};

const trackEvent = (tabClicked) => {
  window.lmSMTObj.track(tabClicked);
}
  return (
    <div className="serviceBlogsImgContainer">
      {/* <div className="serviceBlogsImga">
        <div className="serviceBlogsImgText1">
          <h5>{t("International Travel insurance..")}</h5>
          <span>{t("Comprehensive protection from risks related to travel outside the Kingdom.")}</span>
          <div className="serviceBlogsBtn">
            <Button
              style={{
                textTransform: "none",
                backgroundColor: colors["bright-purple"],
                color: colors["white"],
              }}
              size="medium"
              variant="contained"
              disableElevation
              disableRipple
              onClick={() => {trackEvent(t("Buy now")+"TabClicked")}}
            >
              {i18n.language.slice(0, 1).toUpperCase() == "A" ?
                <a href={externalLinks.Renewal + "travel?lang=ar"} target="_blank" className="global_link" onClick={() =>  calLem(externalLinks.Renewal + "travel?lang=ar")}>
                  {t("Buy now")}
                </a>
                : <a href={externalLinks.Renewal + "travel?lang=en"} target="_blank" className="global_link" onClick={() =>  calLem(externalLinks.Renewal + "travel?lang=en")}>
                  {t("Buy now")}
                </a>}
            </Button>
            <Button
              style={{ textTransform: "none" }}
              size="medium"
              color="secondary"
              variant="outlined"
              disableElevation
              disableRipple
              onClick={() => {trackEvent(t("Learn more")+"TabClicked")}}
            >
              <Link to="/individuals/products/property-casualty/international-travel" target="_blank" className="global_link">
                {t("Learn more")}
              </Link>
            </Button>
          </div>
        </div>
      </div>
      <div className="serviceBlogsImgb">
        <div className="serviceBlogsImgText2">
          <h5>{t("Insuring your car become easier!")}</h5>
          <span>{t("Innovative Motor products and solutions")}</span>
          <div className="serviceBlogsBtn">
            <Button
              style={{
                textTransform: "none",
                backgroundColor: colors["bright-purple"],
                color: colors["white"],
              }}
              size="medium"
              color="primary"
              variant="contained"
              disableElevation
              disableRipple
              onClick={() => {trackEvent(t("Buy now")+"TabClicked")}}
            >
              {i18n.language.slice(0, 1).toUpperCase() == "A" ?
                <a href={externalLinks.common + "motor?lang=ar"} target="_blank" className="global_link" onClick={() =>  calLem(externalLinks.Renewal + "motor?lang=ar")}>
                  {t("Buy now")}
                </a>
                : <a href={externalLinks.common + "motor?lang=en"} target="_blank" className="global_link" onClick={() =>  calLem(externalLinks.Renewal + "motor?lang=en")}>
                  {t("Buy now")}
                </a>}
            </Button>
            <Button
              style={{ textTransform: "none" }}
              size="medium"
              color="secondary"
              variant="outlined"
              disableElevation
              disableRipple
              onClick={() => {trackEvent(t("Learn more")+"TabClicked")}}
            >
              <Link to="/individuals/products/motor" className="global_link">
                {t("Learn more")}
              </Link>
            </Button>
          </div>
        </div>
      </div> */}
    </div>
  );
};
