import React from "react";
import "./style.scss";
import closeIcon from "assets/svg/close_icon.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openPolicyDetails } from "action/DashboardInformation";
import { history } from "service/helpers";
import medicalNetworkImg from "assets/images/network-health.svg";
import identityImg from "assets/images/identity.svg";
import policyNumberImg from "assets/images/policy-number-health.svg";
import classImg from "assets/images/class.svg";
import genderImg from "assets/images/gender.svg";
import rateImg from "assets/images/rate.svg";
import uploadImg from "assets/images/upload-date-health.svg";
import expiryImg from "assets/images/exp-date.svg";
import beneficiaryNumberImg from "assets/images/number-health.svg";
import phone from "assets/images/mobile/phone.png";
import whatsapp from "assets/images/mobile/whatsapp.png";
import mail from "assets/images/mobile/mail.png";
import moment from "moment";

const PolicyDetailsSec = ({ isMobile = false, setHealthDetailsOpen }) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  const openPolicyDetailsInfo = useSelector(
    (state) => state?.dashboardInformationReducer?.openPolicyDetailsInfo
  );

  const dispatch = useDispatch();
  const handleCloseClick = () => {
    window.innerWidth < 950 ? setHealthDetailsOpen(false) : dispatch(openPolicyDetails(false));
  };
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  const policyInfo = useSelector(
    (state) => state?.dashboardInformationReducer?.openPolicyDetailsInfo
  );
  return (
    <div className="policy-details">
      <div className="policy-details-top">
        <div className="d-flex justify-content-end">
          <img
            src={closeIcon}
            alt="Close Icon"
            className={`close-icon ${isRTL ? "mt-3 ml-3" : "mt-3 mr-3"}`}
            onClick={handleCloseClick}
          />
        </div>
        <div className="policy-details-top-text-sec">
          <span className="top-header">{t("heal_policy.health_ins")}</span>
          <span className="top-title">{t("heal_policy.policy_detail")}</span>
        </div>
      </div>
      <div className="info">
        <div className="d-flex align-items-center">
          <img
            src={medicalNetworkImg}
            alt="Medical Network"
            className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
          />
          <div className="d-flex flex-column">
            <span className="item-header">{t("heal_policy.med_network")}</span>
            <span className="item-text">{openPolicyDetailsInfo?.digitalData?.networkGold}</span>
          </div>
        </div>
        <hr />

        <div className="d-flex align-items-center">
          <img
            src={identityImg}
            alt="identity Img "
            className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
          />
          <div className="d-flex flex-column">
            <span className="item-header">{t("heal_policy.id_number")}</span>
            <span className="item-text">{openPolicyDetailsInfo?.medicalMember?.s_MemberCode}</span>
          </div>
        </div>
        <hr />

        <div className="d-flex align-items-center">
          <img
            src={policyNumberImg}
            alt="policy Number Img"
            className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
          />
          <div className="d-flex flex-column">
            <span className="item-header">{t("heal_policy.policy_no")}</span>
            <span className="item-text">{openPolicyDetailsInfo?.digitalData?.policyNumber}</span>
          </div>
        </div>
        <hr />

        <div className="d-flex align-items-center">
          <img
            src={classImg}
            alt="class Img"
            className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
          />
          <div className="d-flex flex-column">
            <span className="item-header">{t("heal_policy.class")}</span>
            <span className="item-text">{openPolicyDetailsInfo?.medicalMember?.s_ClassNo}</span>
          </div>
        </div>
        <hr />

        <div className="d-flex align-items-center">
          <img
            src={genderImg}
            alt="gender Img"
            className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
          />
          <div className="d-flex flex-column">
            <span className="item-header">{t("heal_policy.gender")}</span>
            <span className="item-text">
              {openPolicyDetailsInfo?.medicalMember?.s_Gender === "M"
                ? t("heal_policy.male")
                : t("heal_policy.female")}
            </span>
          </div>
        </div>
        <hr />

        <div className="d-flex align-items-center">
          <img
            src={rateImg}
            alt="rate Img"
            className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
          />
          <div className="d-flex flex-column">
            <span className="item-header">{t("heal_policy.deduct_rate")}</span>
            <span className="item-text">
              {openPolicyDetailsInfo?.digitalData?.deductibleLabel?.mpn
                ? openPolicyDetailsInfo?.digitalData?.deductibleLabel?.mpn
                : ""}
              <br />
              {openPolicyDetailsInfo?.digitalData?.deductibleLabel?.ocn
                ? openPolicyDetailsInfo?.digitalData?.deductibleLabel?.ocn
                : ""}
              <br />
              {openPolicyDetailsInfo?.digitalData?.deductibleLabel?.ohn
                ? openPolicyDetailsInfo?.digitalData?.deductibleLabel?.ohn
                : ""}
            </span>
          </div>
        </div>
        <hr />

        <div className="d-flex align-items-center">
          <img
            src={rateImg}
            alt="rate Img"
            className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
          />
          <div className="d-flex flex-column">
            <span className="item-header">{t("heal_policy.max_limit")}</span>
            <span className="item-text">
              {openPolicyDetailsInfo?.digitalData?.deductibleLabel?.ohn
                ? openPolicyDetailsInfo?.digitalData?.deductibleLabel?.ohn.split(" ").pop()
                : ""}
            </span>
          </div>
        </div>
        <hr />

        <div className="d-flex align-items-center">
          <img
            src={uploadImg}
            alt="upload Img"
            className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
          />
          <div className="d-flex flex-column">
            <span className="item-header">{t("heal_policy.upload_date")}</span>
            <span className="item-text">
              {moment(openPolicyDetailsInfo?.medicalMember?.t_InceptionData).format("DD-MM-YYYY")}
            </span>
          </div>
        </div>
        <hr />

        <div className="d-flex align-items-center">
          <img
            src={expiryImg}
            alt="expiry Img"
            className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
          />
          <div className="d-flex flex-column">
            <span className="item-header">{t("heal_policy.exp_date")}</span>
            <span className="item-text">{openPolicyDetailsInfo?.digitalData?.expiryDate}</span>
          </div>
        </div>
        <hr />

        <div className="contact-sec">
          <span className="contact">{t("heal_policy.contact_daman")}</span>
          <div className="support-request-helper-policy">
            <div className="support-request-callcenter">
              <img className="phone-img" src={phone} alt="phone" />
              <span className="call-center">{t("heal_policy.call_center")}</span>
              <div className="call-number">800 124 9990</div>
            </div>
            <div className="support-request-whatsapp">
              <img className="whatsapp-img" src={whatsapp} alt="whatsapp" />
              <span className="whatsapp">{t("heal_policy.whatsapp")}</span>
              <div className="whatsapp-number">9200 19990</div>
              <div className="whatsapp-chat">{t("heal_policy.chat_no")}</div>
            </div>

            <div className="support-request-mail">
              <img className="mail-img" src={mail} alt="mail" />
              <span className="support-email">{t("heal_policy.email")}</span>
              <div className="support-email-care">{t("heal_policy.email_id")}</div>
            </div>
            <h2 className="hr-lines">{t("heal_policy.or")}</h2>
            <div className="support-button">
              <button
                className="support-request-button"
                onClick={() => {
                  history.push("/all-service", { idx: 1 });
                }}
              >
                {t("heal_policy.open_req")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyDetailsSec;
