import React from "react";
import ArrowRight from "assets/images/mobile/right_arrow.png";
import "./mobilemenupopup.scss";
import { history } from "service/helpers";
import { useTranslation } from "react-i18next";

const SearchBoxMobile = ({ data = [], searchText }) => {
	
	const { t } = useTranslation();

	const handleClick = (link) => {
		if (link.includes("https")) return window.open(link);
		history.push(link);
	};
	const trackEvent = (tabClicked) => {
		window.lmSMTObj.track(tabClicked);
	  }
	return (
		<div className="container search_box_mob">
			<h5>{t("navbar.search.results")}</h5>
			<span>
				{t("navbar.search.found")} {data.length} {t("navbar.search.res_for")} “
			</span>
			<span className="results_color">{searchText}</span>
			<span>”</span>
			{data.map((item, idx) => {
				return (
					<div key={idx}>
						<div className="mt-4 d-flex justify-content-between">
							<div>
								<p>{item.category}</p>
							</div>
							<div
								className="results_color"
								onClick={() => {handleClick(item.webUrl); trackEvent(item.action+"Clicked")}}
							>
								{item.action}
							</div>
						</div>
						<hr />
					</div>
				);
			})}
		</div>
	);
};

export default SearchBoxMobile;
