import React, { useState, useEffect, useMemo } from "react";
import { RoadAssisstantSideBar } from "component/common/RoadAssisstantSideBar";
import { NeedHelpContainer } from "component/common/NeedHelpContainer";
import { CommonBreadCrumb } from "component/common/CommonBreadCrumb";
import { RecentFeeds } from "component/common/RecentFeeds";
import { medRemSidebarQAs, embassyLetterQAs } from "component/common/MockData";
import { useTranslation } from "react-i18next";
import preloader from "assets/loader.gif";
import { PolicyDetailsRightSec } from "component/common/DashboardPolicyDetailsRightSection/PolicyDetailsRightSec";
import { Box } from "@mui/material";
import { getDriveStatus } from "../action/DashboardInformation";
import { clearReduxData } from "../action/DashboardInformation";
import { logoutEvent } from "action/LoginResponse";

import { Navbar } from "component/common/Navbar";
import { NormalOffCanvas } from "component/common/NormalOffCanvas";
import { HomeBanner } from "component/HomePage/LandingComponent";
import {
  EmergencyCard,
  LanguageCard,
  SupportCard,
  GetStartedCard,
} from "component/HomePage/LandingComponent/NavbarCards";
import { CommonFooter } from "component/common/CommonFooter";
import home from "assets/svg/menu/Unionhome.png";
import file from "assets/svg/menu/Fileclaim.png";
import bag from "assets/svg/menu/Bag.png";
import user from "assets/svg/menu/Userprofile.png";
import greenball from "assets/svg/menu/rename.png";
import "./MyLayoutStyle.scss";
import LoginPageMobile from "component/Auth/LoginPageMobile";
import { useDispatch, useSelector } from "react-redux";
import { arabTranslate } from "action/LanguageAct";
import AnimatedBottomPopup from "component/common/MobileReuseable/AnimatedBottomPopupHome";
import phoneIcon from "assets/svg/phoneCircleIcon.svg";
import mailIcon from "assets/svg/menu/mailmessage.svg";
import "./app.css";
import chatIcon from "assets/svg/menu/Vectorchat.svg";
import isEmpty from "lodash/isEmpty";
import PlayStore from "assets/svg/menu/Storeplaystore.svg";
import Close from "assets/svg/menu/Closeclose.svg";
import { AppBanner } from "../component/MotorPage/MotorInsuranceComponent";
import ExtraHeaderDesktop from "component/common/ExtraHeader/ExtraHeaderDesktop";
import { history } from "service/helpers";
import MockupLanding from "assets/images/Landing/MockupLanding.png";
import FooterMobile from "component/common/MobileReuseable/FooterMobile";
import { updateLanguage } from "action/LanguageAct";
const RoadAssisstanceLayout = (props) => {

  const { i18n, t } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  const [emergencyCard, setEmergencyCard] = useState(false);
  const [getStartedCard, setGetStartedCard] = useState(false);
  const [isOpenLoginModel, setIsOpenLoginModel] = useState(false);
  const valueLang = useSelector((state) => state?.languageReducer?.language)
  const selectedLanguage = isEmpty(localStorage.getItem("newTawuniyaLanguageParam")) ? valueLang : localStorage.getItem("newTawuniyaLanguageParam");

  useEffect(() => {
    dispatch(updateLanguage(selectedLanguage));
  }, [selectedLanguage])
  const loginResponse = useSelector((state) => state?.loginDetailsReducer?.loginResponse);
  const loader = useSelector((state) => state.dashboardInformationReducer.loader);
  const userInfo = loginResponse?.user_info;
  const [languageCard, setLanguageCard] = useState(false);
  const [open, setOpen] = useState(false);
  const [supportCard, setSupportCard] = useState(false);
  const isOtpVerified = useSelector((state) => state.loginDetailsReducer?.isOtpVerified);

  const policyInformation = useSelector(
    (state) => state.dashboardInformationReducer?.policyInformation?.data?.policyList
  );
  const motorPolicyDetails = useSelector(
    (state) => state.dashboardInformationReducer?.motorPolicyDetails
  );
  const toggleLanguageCard = () => {
    setLanguageCard(!languageCard);
  };

  const toggleSupportCard = () => {
    setSupportCard(!supportCard);
  };

  const toggleEmergencyCard = () => {
    setEmergencyCard(!emergencyCard);
  };

  const toggleGetStartedCard = () => {
    setGetStartedCard(!getStartedCard);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    motorPolicyDetails?.length &&
      motorPolicyDetails.map((policy) => {
        return policy?.data?.vehiclesList?.map((vehicle) => {
          const policyDetails = policyInformation?.filter(
            (_policyDetails) =>
              _policyDetails?.s_Glob == "MR" &&
              _policyDetails?.s_Lob == "CO" &&
              _policyDetails?.s_Premium == vehicle?.d_PremiumAmount
          )[0];
          const data = {
            policyNumber: policyDetails?.s_PolicyNo,
            payload: {
              chassisNumber: vehicle?.s_ChassisNo,
              languageCode: "E",
            },
          };
          return dispatch(getDriveStatus(data));
        });
      });
  }, [motorPolicyDetails]);

  const footerData = [
    {
      name: t("footer.termsAndConditions"),
      link: "#",
    },
    {
      name: t("footer.privacyPolicy"),
      link: "https://tawuniya.com/privacy-policy",
    },
    {
      name: t("footer.cookiePolicy"),
      link: "#",
    },
    ,
  ];

  const layerOne = <RecentFeeds />;

  const layerTwo = <NeedHelpContainer />;

  const layerThree = <PolicyDetailsRightSec />;

  const dashBoardLayerLink = [
    {
      id: 0,
      link: [
        "/dashboard",
        "/dashboard/service",
        "/dashboard/all-claims",
        "/dashboard/approvals",
        "/dashboard/profile",
      ],
      comp: <RecentFeeds isWhite={true} />,
    },
    {
      id: 1,
      link: [
        "/dashboard/service/tele-medicine",
        "/dashboard/service/request-telemedicine",
        "/dashboard/service/tele-medicine/new-request",
        "/dashboard/service/tele-medicine/request-confirmation/1",
      ],
      comp: <NeedHelpContainer isWhite={true} isTele={true} />,
    },
    {
      id: 2,
      link: ["/dashboard/tele-medicine/request-detail"],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 3,
      link: ["/dashboard/tele-request"],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 4,
      link: [
        "/dashboard/service/road-assistance",
        "/dashboard/service/road-assistance/new-request",
        "/dashboard/service/road-assistance/summary",
      ],
      comp: <NeedHelpContainer isWhite={true} isRoadSide={true} />,
    },
    {
      id: 5,
      link: ["/dashboard/products-and-offerings"],
      comp: <RecentFeeds isWhite={true} />,
    },
    {
      id: 6,
      link: [
        "/dashboard/service/periodic-inspection",
        "/dashboard/service/periodic-inspection/new-request",
        "/dashboard/service/periodic-inspection/request-confirmation/1",
      ],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 7,
      link: ["/dashboard/service/car-wash"],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 8,
      link: [
        "/dashboard/service/car-maintainance",
        "/dashboard/service/car-maintainance/new-request",
        "/dashboard/service/car-maintainance/request-confirmation/1",
      ],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 9,
      link: ["/dashboard/service/pregnancy-program"],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 10,
      link: ["/dashboard/service/medical-reimbursement"],
      comp: <NeedHelpContainer isWhite={true} isRecentFeed faqs={medRemSidebarQAs} viewAll />,
    },
    {
      id: 11,
      link: [
        "/dashboard/service/medical-reimbursement/new-request",
        "/dashboard/service/medical-reimbursement/request-status",
      ],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 12,
      link: ["/dashboard/support-center"],
      comp: <RecentFeeds isWhite={true} />,
    },

    {
      id: 13,
      link: ["/individuals/products/motor/claims"],
      comp: layerOne,
    },
    {
      id: 14,
      link: ["/individuals/products/health/medical-claims"],
      comp: layerOne,
    },
    {
      id: 15,
      link: ["/claims/motor"],
      comp: layerOne,
    },
    {
      id: 16,
      link: ["/dashboard/all-claims"],
      comp: <RecentFeeds isWhite={true} />,
    },
    {
      id: 17,
      link: ["/dashboard/requests-and-approvals"],
      comp: <RecentFeeds isWhite={true} />,
    },
    {
      id: 18,
      link: ["/dashboard/notifications"],
      comp: <RecentFeeds isWhite={true} />,
    },
    {
      id: 19,
      link: [
        "/dashboard/service/eligibility-letter",
        "/dashboard/service/eligibility-letter/new-request",
        "/dashboard/service/eligibility-letter/request-confirmation/1",
      ],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 20,
      link: ["/dashboard/your-policies"],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 21,
      link: ["/dashboard/policydetails/health"],
      comp: <RecentFeeds isWhite={true} />,
    },
    {
      id: 22,
      link: ["/dashboard/policydetails/motor"],
      comp: <RecentFeeds isWhite={true} />,
    },
    {
      id: 23,
      link: ["/dashboard/policydetails/travel"],
      comp: <RecentFeeds isWhite={true} />,
    },
    {
      id: 24,
      link: ["/dashboard/eligibility-letter"],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 25,
      link: ["/dashboard/policy-detail"],
      comp: layerThree,
    },
    {
      id: 26,
      link: [
        "/dashboard/service/pregnancy-follow-up-program",
        "/dashboard/service/pregnancy-follow-up-program/new-request",
        "/dashboard/service/pregnancy-follow-up-program/request-confirmation",
      ],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 27,
      link: [
        "/dashboard/service/home-child-vaccination",
        "/dashboard/service/home-child-vaccination/new-request",
        "/dashboard/service/home-child-vaccination/request-confirmation",
      ],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 28,
      link: [
        "/dashboard/service/chronic-disease-management",
        "/dashboard/service/chronic-disease-management/new-request",
        "/dashboard/service/chronic-disease-management/request-confirmation",
      ],
      comp: layerTwo,
    },
    {
      id: 29,
      link: [
        "/dashboard/service/dawai",
        "/dashboard/service/dawai-request",
        "/dashboard/service/dawai-summary",
      ],
      comp: layerTwo,
    },
    {
      id: 30,
      link: ["/dashboard/service/assist-america"],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 31,
      link: ["/dashboard/network-coverage"],
      comp: <NeedHelpContainer isWhite={true} />,
    },
    {
      id: 33,
      link: [
        "/dashboard/service/embassy-letter",
        "/dashboard/service/embassy-letter/new-request",
        "/dashboard/service/embassy-letter/new-request/status",
      ],
      comp: <NeedHelpContainer isWhite={true} faqs={embassyLetterQAs} viewAll />,
    },
  ];
  const navHomeContent = [
    {
      id: 0,
      navText: t("navbar.individuals"),
      link: "/individuals",
    },
    {
      id: 1,
      navText: t("navbar.corporate"),
      link: "/corporate",
    },
    {
      id: 2,
      navText: t("navbar.investor"),
      link: "/investor",
    },
    {
      id: 3,
      navText: t("navbar.claims"),
    },
  ];

  const historyLinks = ["/", "/motorpage"];

  useEffect(() => {
    if (selectedLanguage === "ar") {
      document.getElementsByTagName("body")[0].classList.add("language-alignment-right");
      i18n.changeLanguage("ar");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("language-alignment-right");
      i18n.changeLanguage("en");
    }
  }, [selectedLanguage]);
  const [toggle, setToggle] = useState(false);

  const closeToggler = () => {
    setToggle(false);
  };

  const handleToggler = () => {
    setToggle(true);
  };

  const handleProfileClick = () => {
    if (!isOtpVerified) {
      return setIsOpenLoginModel(true);
    } else {
      history.push("/dashboard/myprofile");
    }
  };

  const handleSupportClick = () => {
    history.push("/individuals/customer-service/support");
  };

  const handleHomeClick = () => {
    if (isOtpVerified) {
      history.push("/dashboard");
    } else {
      history.push("/");
    }
  };

  const handleClaimClick = () => {
    if (isEmpty(userInfo)) {
      history.push("/individuals/products/motor/claims");
    } else {
      history.push("/dashboard/all-claims");
    }
  };

  const pushPlaystore = () => {
    window.location.assign(
      "https://apps.apple.com/us/app/tawuniya-%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%88%D9%86%D9%8A%D8%A9/id573176914"
    );
  };

  const [swap, setSwap] = useState(null);

  const changeImg = [chatIcon, mailIcon, phoneIcon];

  const [transition, setTransition] = useState(0);

  useEffect(() => {
    setSwap(changeImg[transition]);
  }, [transition]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (transition + 1 === changeImg.length) {
        setTransition(0);
      } else {
        setTransition((item) => item + 1);
      }
    }, 3000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 300000);
    return () => clearTimeout(timer);
  });

  const translateHandler = () => {
    selectedLanguage === "ar" ? dispatch(arabTranslate(false)) : dispatch(arabTranslate(true));
  };

  useEffect(() => {

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location]);

  useEffect(() => {
    setTimeout(() => {
      console.log(localStorage.getItem("loginToken"));
      localStorage.removeItem("loginToken");
      dispatch(clearReduxData());
      dispatch(logoutEvent());
      history.push("/");
    }, 1000 * 60 * 20);
  }, []);

  return (
    <>
      {window.innerWidth > 950 ? (
        <Box className={`mainContainer vh-100 pr-0 ${i18n.language === "ar" ? "ar" : ""}`}>
          {loader && (
            <Box className="loader-tawuniya">
              <img src={preloader} alt="preloader" />
            </Box>
          )}
          <Box className={`mainContainer vh-100 pr-0 ${i18n.language === "ar" ? "ar" : ""}`}>
            <Box className="d-flex flex-row dashboard-RightContainer">
              <Box className="flexDashboardOne" xs={{ display: "none" }}>
                <RoadAssisstantSideBar />
              </Box>
              <Box className="flexDashboardTwo">
                <CommonBreadCrumb />
                {props.children}
                <Box className="row">
                  <Box className="col-12 footer-road-container">
                    <Box className="d-flex justify-content-start pb-4">
                      <Box>
                        <span className="fs-12 fw-400 road-copyrights">{t("footer.copyright")}</span>
                        <Box className="d-flex flex-row">
                          {footerData.map((items, index) => {
                            return (
                              <Box className="pr-2" key={index}>
                                <span
                                  className="fs-12 fw-400 road-copyrights cursor-pointer"
                                  onClick={() => window.location.assign(items.link)}
                                >
                                  {items.name}
                                </span>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="flexDashboardThree" xs={{ display: "none" }}>
                {dashBoardLayerLink?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {item?.link?.map((i) => {
                        return <div key={i}>{history?.location?.pathname === i && item?.comp}</div>;
                      })}
                    </React.Fragment>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          {props.children}

          {/* <FooterMobile /> */}
          <LoginPageMobile
            isOpenLoginModel={isOpenLoginModel}
            setIsOpenLoginModel={setIsOpenLoginModel}
          />
        </>
      )}
    </>
  );
};

export default RoadAssisstanceLayout;
