import React from "react";
import { useTranslation } from "react-i18next";

import depreciation from "assets/svg/depreciation.svg";
import hire from "assets/svg/hire.svg";
import geo from "assets/svg/geo.svg";
import accident from "assets/svg/carAccidents.svg";
import agencyRepair from "assets/svg/agencyRepair.svg";
import "./style.scss";
import ExtensionInnerCard from "component/common/ExtensionInnerCard/ExtentionInnerCard";

export const ExtensionCard = ({title}) => {
	const { i18n } = useTranslation();
	const isRTL = i18n.dir() === "rtl";
	const { t } = useTranslation();
	const extensionData = [
		{
			id: 0,
			icon: depreciation,
			class: `extension-layout pb-lg-5 pb-3 ${isRTL ? 'pl-lg-3 pl-md-2' : ''}`,
			subtitle: t('motorPageData.insuranceDeclarationSection.cardtitle1'),
			extensionIconClass: "extensionIconOne",
		},
		{
			id: 1,
			icon: hire,
			class: "extension-layout pl-lg-3 pl-md-2 pb-lg-5 pb-3",
			subtitle: t('motorPageData.insuranceDeclarationSection.cardtitle2'),
			addedClass: "",
			extensionIconClass: "extensionIconTwo",
		},
		{
			id: 2,
			icon: geo,
			class: "extension-layout pl-lg-3 pl-md-2 pb-lg-5 pb-3",
			subtitle: t('motorPageData.insuranceDeclarationSection.cardtitle3'),
			extensionIconClass: "extensionIconThree",
		},
		{
			id: 3,
			icon: accident,
			class: "extension-layout pl-lg-3 pl-md-2 pb-lg-5 pb-3",
			subtitle: t('motorPageData.insuranceDeclarationSection.cardtitle4'),
			addedClass: "",
			extensionIconClass: "extensionIconFour",
		},
		{
			id: 4,
			icon: agencyRepair,
			class: `extension-layout pb-lg-5 pb-3 ${isRTL ? '' : 'pl-lg-3 pl-md-2'}`,
			subtitle: t('motorPageData.insuranceDeclarationSection.cardtitle5'),
			addedClass: "",
			extensionIconClass: "extensionIconFive",
		},
	];

	return (
		<div className="row extensionCardContainer pt-5">
			<div className="col-lg-12 col-12">
				<p className="fw-800 additionExtensionHeader">
					{
						title ? title:
						<>{t('motorPageData.insuranceDeclarationSection.title')}</>
					}
				</p>
				{
					window.innerWidth > 950 &&
					<p className="fs-18 fw-400  additionExtensionPara">
						{t('motorPageData.insuranceDeclarationSection.desc')}
					</p>
				}
			</div>
			<div className="col-lg-12 col-12">
				<div className="extension-outer-wrapper">
					<div className="d-flex extention-cards-wrapper">
						{extensionData.map((item, index) => {
							return (
								<ExtensionInnerCard key={index} item={item} />
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};
