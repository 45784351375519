import React, { useState, useEffect } from "react";
import { NormalButton } from "component/common/NormalButton";
import NewsOne from "assets/images/newsCardOne.png";
import FooterImg1 from "assets/images/FooterImg1.png";
import footerImg3 from "assets/images/footerImg3.png";
import footerImg2 from "assets/images/homeproducts1.png";
import footerImg4 from "assets/images/homeproducts3.png";
import arrow from "assets/svg/orangeArrow.svg";
import "./style.scss";
import { history } from "service/helpers";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatToDMY } from "../../../../service/utilities";

export const FooterCard = () => {
    const currentLanguage = localStorage.getItem("newTawuniyaLanguageParam");
    const { i18n, t } = useTranslation();
    const { newsUpdates } = useSelector((state) => state.dashboardInformationReducer);
    const [latestNews, setLatestNews] = useState([]);
    console.log(latestNews);

    useEffect(() => {
        if (Boolean(newsUpdates?.length)) {
            if (newsUpdates.length > 5) {
                setLatestNews(newsUpdates.slice(0, 5));
            } else {
                setLatestNews(newsUpdates);
            }
        }
    }, [newsUpdates?.length]);

    const footerCardData = [
        {
            id: 0,
            cardImg: FooterImg1,
            eventTtitle: "Tawuniya appoints Dr. Ammr Kurdi as Chief Financial Officer",
            eventPic: NewsOne,
            eventTtitleAr: "التعاونية تتشرف بالدكتور عمرو قدري كمدير عام مالي",
            eventParaClass: "pt-5",
        },
        {
            id: 1,
            cardImg: footerImg2,
            eventTtitle:
                "Tawuniya is the first company in the Kingdom to install vehicle insurance policies",
            eventTtitleAr: "التعاونية اول شركه في المملكة تطبق وثائق التامين للمركبات",
            eventPic: NewsOne,
        },
        {
            id: 3,
            cardImg: footerImg4,
            eventTtitle:
                "“Tawuniya Vitality” changes the healthy lifestyle of the Saudi Society New program is a first for the Kingdom, the Middle East and North Africa",
            eventTtitleAr:
                'برنامج "التعاونية الحيوية" يغير نمط الحياة الصحي للمجتمع السعودي البرنامج الجديد هو الأول من نوعه في المملكة والشرق الأوسط وشمال إفريقيا',
            eventPic: NewsOne,
        },
        {
            id: 4,
            cardImg: FooterImg1,
            eventTtitle: "Tawuniya appoints Dr. Ammr Kurdi as Chief Financial Officer",
            eventTtitleAr: "التعاونية تتشرف بالدكتور عمرو قدري كمدير عام مالي",
            eventPic: NewsOne,
        },
    ];

    return (
        <div className="row">
            {footerCardData?.map((item, index) => {
                return (
                    <div
                        className="eventCard"
                        key={index}
                        onClick={() => history.push("/mediacenter")}
                    >
                        <div
                            className="eventCard-content-box"
                            style={{
                                background: `linear-gradient(rgb(124 124 124 / 56%), rgb(54 54 54 / 58%)),url(${item.cardImg})`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "50%",
                            }}
                        >
                            <div className="d-flex justify-content-between">
                                <div>
                                    <NormalButton label={item.category} className="cardEventBtn" />
                                </div>
                                <div>
                                    <p className="fs-11 fw-400 dateText">{formatToDMY(item.dateCreated)}</p>
                                </div>
                            </div>
                            <div className="d-flex align-items-end eventCardSize">
                                <div>
                                    <p className={`${item.eventParaClass} fw-800 eventContent`}>
                                        {currentLanguage === "ar" ? item.eventTtitleAr : item.eventTtitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            <div className="col-lg-12 col-12">
                <div className="d-flex justify-content-end pt-3">
                    <div onClick={() => history.push("/mediacenter")} className="cursor-pointer">
                        <span className="fs-16 fw-800 text-uppercase viewUpdateFooterLink pr-2">
                            {t("footerNews.viewAllUpdates")}
                        </span>{" "}
                        <img src={arrow} className="img-fluid footerOrangeArrow" alt="arrow" />
                    </div>
                </div>
            </div>
        </div>
    );
};
