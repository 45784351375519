import {COMPLAINT_CASE_CREATION_REQUEST,
  COMPLAINT_CASE_CREATION_SUCCESS,
  COMPLAINT_ENQUIRY,
  } from 'service/actionType.js'
  export const complaintCaseCreationReq = (data) => {
    return {
      type: COMPLAINT_CASE_CREATION_REQUEST,
      payload: data,
    };
  };
  export const complaintCaseCreationSuccess = (data) => {
    return {
      type: COMPLAINT_CASE_CREATION_SUCCESS,
      payload: data,
    };
  };
  export const complaintEnquiry = (data) => {
    return {
      type: COMPLAINT_ENQUIRY,
      payload: data,
    };
  }
