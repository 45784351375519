import { TYPE_OF_REPORT } from "service/actionType";
  
const initialState = {
    typeOfReport: "medical"
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE_OF_REPORT:
      return {
        ...state,
        typeOfReport: action.payload,
      };
    default:
      return state;
  }
};

export default reportReducer;