import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { footerData, footerNextData, footerNextExtData } from "../MockData";
import { NormalButton } from "../NormalButton";
import tweet from "assets/svg/Twitter.svg";
import fb from "assets/svg/Facebook.svg";
import uTube from "assets/svg/Youtube.svg";
import linkedIn from "assets/svg/Linkedin.svg";
import insta from "assets/svg/Instagram.svg";
import whatzApp from "assets/svg/Whatsapp.svg";
import appStore from "assets/images/appleStoreFooter.png";
import playStore from "assets/images/playStoreFooter.png";
import backTop from "assets/svg/backTop.svg";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TawuniyaLogo from "../../../assets/svg/LogoIcon.svg";
import { LandingSiteFilesLink } from "../../../helpers/constant";

export const CommonFooter = () => {
  const { i18n, t } = useTranslation(); 
  const currentLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  const location = useLocation();
  const selectedLanguage = localStorage.getItem("lang");
  const iconData = [
    {
      icon: tweet,
      url: "https://twitter.com/tawuniya",
      title:"tweeter"
    },
    {
      icon: fb,
      url: "https://www.facebook.com/Tawuniya",
      title:"faceBook"
    },
    {
      icon: uTube,
      url: "https://www.youtube.com/tawuniya",
      title:"youTube"
    },
    {
      icon: linkedIn,
      url: "https://www.linkedin.com/company/tawuniya/",
      title:"linkedIn"
    },
    {
      icon: insta,
      url: "https://www.instagram.com/tawuniya/",
      title:"instagram"
    },
    {
      icon: whatzApp,
      url: "https://wa.me/920009377",
      title:"what'sApp"
    },
  ];
  const appData = [appStore, playStore];
  const [animatedText, setAnimatedText] = useState(null);

  const handleBackTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  let texts = [
    t("footerNews.animatedText1"),
    t("footerNews.animatedText2"),
    t("footerNews.animatedText3"),
  ];

  const navigatePage = (items) => {
    items?.routeURL && window.location.assign(items?.routeURL);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const navigateBottomPage = (routeURL) => {
    // routeURL && window.location.assign(routeURL);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const navigateNewPage = (items) => {
    console.log("menu", items);
    items?.routeURL && window.location.assign(items?.routeURL);
  };

  useEffect(() => {
    setAnimatedText(texts[0]);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const current = texts.findIndex((item) => animatedText === item);
      if (current !== -1 && current !== texts.length - 1) {
        setAnimatedText(texts[current + 1]);
      } else {
        setAnimatedText(texts[0]);
      }
    }, 2000);
  }, [animatedText]);

  const handleDownloadFile = () => {
    if(i18n.language == "ar"){
      window.open(LandingSiteFilesLink.CustomerProtectionFilear);
    }else{
      window.open(LandingSiteFilesLink.CustomerProtectionFile);
  
    }
  };
  
  const calLem = (url) => {
    window.lmSMTObj.page(url, {
        path: document.location.pathname,
        language: selectedLanguage == 'ar' ? "Arabic" : "English",
        referrer: document.referrer,
        title: document.title,
        url: document.URL,
        search: location.search,
    });
};

const calLemAndRedirect = (url) => {
  window.lmSMTObj.page(url, {
      path: document.location.pathname,
      language: selectedLanguage == 'ar' ? "Arabic" : "English",
      referrer: document.referrer,
      title: document.title,
      url: document.URL,
      search: location.search,
  });
  window.location.assign(
    `https://www.ia.gov.sa/${currentLanguage}`
  )
};

const trackEvent = (tabClicked) => {
  window.lmSMTObj.track(tabClicked);
}

const trackEventProduct = (tabClicked, category) => { 
  window.lmSMTObj.track(tabClicked,{
    "category":category
  });
}

  return (
    <>
      <div className="row footerLayout" id="footerDesktop">
        <React.Fragment></React.Fragment>

        <div className="col-12 curvedFooterContainer">
          {/* <img src={union} className="img-fluid unionCircle" alt="union" /> */}
          <div className="row">
            <div className="col-lg-9 col-12">
              <div className="d-flex flex-row">
                {footerData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="pr-lg-5  pr-md-5 footerBoxContainer"
                    >
                      <p className="fs-16 fw-400 m-0 footerHeading text-uppercase pb-3 mt-5">
                        {currentLanguage === "ar" ? item.titleAr : item.title}
                      </p>
                      {item?.content?.map((items, index) => {
                        const isExternalLink = items.externalLinks;
                        const linkTitle =
                          currentLanguage === "ar"
                            ? items?.labelAr
                            : items.label;

                        return isExternalLink ? (
                          // Use <a> for external links
                          <a
                            href={items.routeURL+`?lang=${selectedLanguage}`}
                            title={linkTitle}
                            className="fs-16 fw-400 footerContentText m-0 pb-2 check"
                            key={index}
                            target="_blank" // Opens in a new tab
                            rel="noopener noreferrer" // Security measure
                            onClick={() => {
                              trackEventProduct(
                                linkTitle + "TabClicked",
                                currentLanguage === "ar"
                                  ? item.titleAr
                                  : item.title
                              );
                            }}
                          >
                            <img
                              src={item.footerIcon}
                              className="img-fluid pr-lg-2 pr-md-2"
                              alt="icon"
                            />
                            {linkTitle}
                          </a>
                        ) : (
                          // Use <Link> for internal links
                          <Link
                            to={items.routeURL}
                            title={linkTitle}
                            className="fs-16 fw-400 footerContentText m-0 pb-2 check"
                            key={index}
                            onClick={() => {
                              trackEventProduct(
                                linkTitle + "TabClicked",
                                currentLanguage === "ar"
                                  ? item.titleAr
                                  : item.title
                              );
                            }}
                          >
                            <img
                              src={item.footerIcon}
                              className="img-fluid pr-lg-2 pr-md-2"
                              alt="icon"
                            />
                            {linkTitle}
                          </Link>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              <div className="d-flex flex-row">
                {footerNextData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="pr-lg-5 pr-md-5 footerNextHeadingContainer d-flex flex-column  footerBoxContainer"
                      id={item.id === 2 ? "alignFooterContent" : ""}
                    >
                      <p className="fs-16 fw-400 m-0 footerNextHeading text-uppercase pb-3 mt-5">
                        {currentLanguage === "ar" ? item.titleAr : item.title}
                      </p>
                      {item?.content?.map((items, index) => {
                        if (items?.routeURL?.startsWith("https://")) {
                          return (
                            <a
                              href={items.routeURL}
                              className="fs-16 fw-400 footerNextContentText m-0 pb-2"
                              key={index}
                              target="_blank"
                              onClick={() => {calLem(items.routeURL);trackEventProduct(currentLanguage === "ar" ? items?.labelAr : items.label+"TabClicked", currentLanguage === "ar" ? item.titleAr : item.title)}}  
                            >
                              <img src={item.footerIcon} className="img-fluid pr-lg-2 pr-md-2" alt="icon" />
                              {currentLanguage === "ar" ? items?.labelAr : items.label}
                            </a>
                          );
                        }
                        return (
                          <Link
                            to={items.routeURL}
                            className="fs-16 fw-400 footerNextContentText m-0 pb-2"
                            key={index}
                            onClick={() => trackEventProduct(currentLanguage === "ar" ? items?.labelAr : items.label+"TabClicked", currentLanguage === "ar" ? item.titleAr : item.title)}
                          >
                            <img src={item.footerIcon} className="img-fluid pr-lg-2 pr-md-2" alt="icon" />
                            {currentLanguage === "ar" ? items?.labelAr : items.label}
                          </Link>
                        );
                      })}
                    </div>
                  );
                })}
                {footerNextExtData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="pr-lg-5 pr-md-5 footerNextHeadingContainer footerBoxContainer"
                      id={item.id === 2 ? "alignFooterContent" : ""}
                    >
                      <p className="fs-16 fw-400 m-0 footerNextHeading text-uppercase pb-3 mt-5">
                        {currentLanguage === "ar" ? item.titleAr : item.title}
                      </p>
                      {item?.content?.map((items, index) => {
                        if (items.routeURL.startsWith("https://")) {
                          return (
                            <a
                              href={items.routeURL}
                              className="fs-16 fw-400 footerNextContentText m-0 pb-2"
                              key={index}
                              target="_blank" 
                              onClick={() => {calLem(items.routeURL);trackEventProduct(currentLanguage === "ar" ? items?.labelAr : items.label+"TabClicked",currentLanguage === "ar" ? item.titleAr : item.title)}}                             
                            >
                              <img src={item.footerIcon} className="img-fluid pr-lg-2 pr-md-2" alt="icon" />
                              {currentLanguage === "ar" ? items.labelAr : items.label}
                            </a>
                          );
                        }
                        return (
                          <Link
                            className="fs-16 fw-400 footerNextContentText m-0 pb-2"
                            title={currentLanguage === "ar" ? items?.labelAr : items.label}
                            to={items.routeURL}
                            // target={"_blank"}
                            onClick={() => {calLem(items.routeURL); trackEventProduct(currentLanguage === "ar" ? items?.labelAr : items.label+"TabClicked",currentLanguage === "ar" ? item.titleAr : item.title)}}
                          >
                            <img src={item.footerIcon} className="img-fluid pr-lg-2 pr-md-2" alt="icon" />
                            {currentLanguage === "ar" ? items?.labelAr : items.label}
                          </Link>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-3 col-12">
              <div className="d-flex justify-content-end">
                <div className="footerIconContainer">
                  <p className="followUsText fs-16 fw-700 text-uppercase m-0 pb-2">
                    {t("footerNews.follow")}
                  </p>
                  <div className="d-flex flex-row" style={{ gap: "10px" }}>
                    {iconData.map((item, index) => {
                      return (
                        <div key={index}>
                          <a title={item.url} href={item.url} target="_blank" onClick={() => {trackEvent(item.title +"IconClicked")}}  >
                            <img src={item.icon} className="img-fluid footerSocialIcon" alt="socialicons" />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 footerLastContainer">
          <div className="row footerContentBox">
            <div className="footerButtonPositon">
              <div className="col-12 footerButtonContainer">
                <NormalButton
                  label={t("footer.backToTop")}
                  className={`${currentLanguage === "en" ? "backTopButton" : "backTopButton-rtl"
                    } mx-auto d-block`}
                  onClick={handleBackTop}
                  needBtnPic={true}
                  src={backTop}
                  adjustIcon={`${currentLanguage === "en" ? "pl-lg-2" : "pr-lg-2"}`}
                />
              </div>
            </div>
            <div className="col-lg-8 col-12 footerBottomLeftSpacing">
              <img src={TawuniyaLogo} alt="TawuniyaLogo" />
              <p className="copyrightText fs-14 fw-400">{t("footer.copyright")}</p>
            </div>
            <div className="col-lg-4 col-12 p-md-0 footerBottomRightSpacing">
              <div className="footerPositionIcons">
                <div
                  className="d-flex flex-lg-row flex-md-row flex-column pb-lg-0 pb-3 classchanges"
                  style={{ cursor: "pointer" }}
                >
                  {appData.map((i, index) => {
                    return (
                      <a
                        title={index === 0 ? "APP Store" : "Google Play"}
                        href={
                          index === 0
                            ? "https://apps.apple.com/us/app/%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%88%D9%86%D9%8A%D8%A9-tawuniya/id1639353191"
                            : "https://play.google.com/store/apps/details?id=com.dxp.TawuniyaInsurance"
                        }
                        target={'_blank'}
                        onClick={() => trackEvent(index === 0 ? "APP Store" : "Google Play"+"TabClicked")}
                        key={index}>
                        <img src={i} className="img-fluid pr-lg-3 pr-md-3" alt="socialapp" />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 footerLining"></div>
          <div className="row pt-3">
            <div className="col-lg-5 col-12">
              <div className="d-flex flex-row">
                <div className="pl-lg-3 pl-3">
                  <Link
                    to={"/privacy-policy"}
                    title={t("footer.privacyPolicy")}
                    className="fs-12 fw-400 footerInlineText"
                    onClick={() => {navigateBottomPage("/privacy-policy");trackEvent(t("footer.privacyPolicy")+"TabClicked")}}
                  >
                    {t("footer.privacyPolicy")}
                  </Link>
                </div>
                <div className="pl-lg-3 pl-3">
                  <a
                    title={t("footer.insurance_activites")}
                    className="fs-12 fw-400 footerInlineText"
                    href="https://tawuniya.com/docs/Online_Insurance_Activities_Regulation.pdf"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {trackEvent(t("footer.insurance_activites") + "TabClicked")
                    }}
                  >
                    {t("footer.insurance_activites")}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-12 pl-0">
              <div className="d-flex justify-content-lg-end justify-content-md-start">
                <div className="d-flex flex-lg-row">
                  <div>
                  <Link
                    to={"/tawuniya-auction"}
                   
                    className="fs-12 fw-400 footerInlineText"
                    onClick={() => {navigateBottomPage("/tawuniya-auction");trackEvent(t("footer.link1")+"TabClicked")}}
                  >
                    {t("footer.link1")}
                  </Link>
                  </div>
                  <div className="pl-lg-3 pl-3">
                    <p className="fs-12 fw-400 footerInlineText" onClick={() => {handleDownloadFile();trackEvent(t("footer.link2")+"TabClicked")}}>
                      {t("footer.link2")}
                    </p>
                  </div>
                  {/* Site Link is there in constant file*/}
                  {/* <div className="pl-lg-3 pl-3">
          <a
           href="https://www.sama.gov.sa/Pages/PageNotFoundError.aspx?requestUrl=http://www.sama.gov.sa/en-US/Laws/InsuranceRulesAndRegulations/IIR_4600_%E2%80%8EOnline_IEN.pdf"
           title={t("footer.link3")}
           className="fs-12 fw-400 footerInlineText"
           onClick={() =>
            window.location.assign(
             "https://www.sama.gov.sa/Pages/PageNotFoundError.aspx?requestUrl=http://www.sama.gov.sa/en-US/Laws/InsuranceRulesAndRegulations/IIR_4600_%E2%80%8EOnline_IEN.pdf"
            )
           }
          >
           {t("footer.link3")}
          </a>
         </div>*/}
                  <div className="pl-lg-3 pl-3">
                    <a
                      href={`https://www.ia.gov.sa/${currentLanguage}`}
                      title={t("footer.link4")}
                      target="_blank"
                      className="fs-12 fw-400 footerInlineText"
                      onClick={() => { calLemAndRedirect(`https://www.ia.gov.sa/${currentLanguage}`);trackEvent(t("footer.link4")+"TabClicked")}                  
                      }
                    >
                      {t("footer.link4")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
