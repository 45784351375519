import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
//import { history } from "service/helpers";
import { QuestionSection } from "./QuestionSection";
import MotorFraud from "assets/images/mobile/Motor.png";
import right_arrow from "assets/images/mobile/right_arrow.png";
import vertical_pointer from "assets/images/mobile/vertical-master-1.svg";
import vertical_pointer2 from "assets/images/mobile/vertical-master-2.svg";
import vertical_pointer3 from "assets/images/mobile/vertical-master-3.svg";
import HeaderCloseNav from "component/common/MobileReuseable/HeaderCloseNavRoad";
import "./style.scss";
import call from "assets/svg/call.svg";
import { Toast } from "primereact/toast";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import PopUpComp from "shared/components/PopUpComp";
import moment from "moment";
import {
	getMemberHistory,
	getServicesHistory,
} from "service/dashboard/dashboardService";
import { RecentPolicyCard } from "component/DashBoard/DashBoardComponents/RecentPolicyCard";
import { RecentInteractionMedical } from "./RecentInteractionMedical";

export const RoadAssistanceMobile = (props) => {
	const { i18n, t } = useTranslation();
	const { item } = props.viewservice;

	const [popupOpen, setPopUpOpen] = useState(false);
	const policyList = useSelector(
		(state) =>
			state.dashboardInformationReducer?.policyInformationValue?.data?.return
				?.data?.policyList
	);
	const eligVouServ = useSelector((state) => state.dashboardInformationReducer?.EligVouSerValue);
	const [isIllegible, setIsIllegible] = useState(false);
	const [isMotorEligible, setIsMotorEligible] = useState(false);
	const [isMotorCoEligible, setIsMotorCoEligible] = useState(false);
	const [roadSideItem, setRoadSideItem] = useState([]);
	const [IsMvpi, setMvpi] = useState([]);
	const digitalCard = useSelector(
		(state) => state?.dashboardInformationReducer?.updateGenerateDigitalCards
	);
	const medicalPolicyDetails = useSelector(
		(state) => state?.dashboardInformationReducer?.medicalPolicyDetails
	);
	const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
	const history = useHistory();

	const [message, setMessage] = useState("");
	const [title, setTitle] = useState("");
	const [btnText, setBtnText] = useState("");
	const toast = useRef(null);

	useEffect(() => {
		const policyItem = policyList?.filter(
			(policy) => policy?.s_Glob === "MD"
		)[0];
		if (policyItem?.s_Glob) {
			setIsIllegible(true);
		} else {
			setIsIllegible(false);
		}
	}, [policyList]);

	useEffect(() => {
		const policyItem = policyList?.filter(
			(policy) => policy?.s_Glob === "MR"
		)[0];
		if (policyItem?.s_Glob) {
			setIsMotorEligible(true);
		} else {
			setIsMotorEligible(false);
		}

		const policyCoItem = policyList?.filter(
			(policyCo) => policyCo?.s_Lob === "CO"
		)[0];
		if (policyCoItem?.s_Lob) {
			setIsMotorCoEligible(true);
		} else {
			setIsMotorCoEligible(false);
		}

	}, [policyList]);

	useEffect(() => {
		const roadAssisstItem = eligVouServ?.vouchersList?.filter(
			(eligibile) => eligibile?.serviceName === "Roadside Assistance"
		)[0];
		setRoadSideItem(roadAssisstItem);
		const MvpiItem = eligVouServ?.vouchersList?.filter(
			(mvpi) => mvpi?.serviceName === "MVPI"
		)[0];
		setMvpi(MvpiItem);
	}, [eligVouServ]);

	const mvpi = props?.viewservice?.mobileClass;
	useEffect(() => {
		localStorage.setItem("title", props?.viewservice.subtitle);
	});
	const isRTL = i18n.dir() === "rtl";

	const handleClose = () => {
		setPopUpOpen(false);
	};

	const medicalPolicyDetail = useSelector(
		(state) =>
			state?.dashboardInformationReducer?.medicalPolicyDetails?.data
				?.medicalMemberList[0]
	);

	const [memberHistory, setMemberHistory] = useState([]);
	const [mvpiData, setMvpiData] = useState([]);
	const [roadSideAssistance, setRoadSideAssistance] = useState([]);

	useEffect(() => {
		if (item?.value === "teleMedicine") {
			const medPolicy = policyList?.filter((policy) => {
				return policy.s_Glob === "MD";
			});
			let data = {
				policyNumber:
					!isEmpty(medPolicy) && medPolicy[0]?.s_PolicyNo?.toString(),
				memberCode: medicalPolicyDetail?.s_MemberCode,
				langId: i18n.language.slice(0, 1).toUpperCase(),
			};
			getMemberHistory(data).then((response) => {
				setMemberHistory(response?.memberHistory);
			});
		}
		if (props.viewservice?.title === "Periodic Inspection") {
			const motorPolicy = policyList?.find((policy) => {
				return policy.s_Glob === "MR";
			});
			var d = new Date();
			var m = d.getMonth();
			d.setMonth(m - 1);
			let data = {
				policyNo: !isEmpty(motorPolicy) && motorPolicy?.s_PolicyNo.toString(),
				serviceId: "2",
				dateFrom: moment(d).format("YYYY-MM-DD"),
				dateTo: moment(new Date()).format("YYYY-MM-DD"),
				lang: "E",
				channel: "Mobile",
			};
			getServicesHistory(data).then((response) => {
				setMvpiData(
					response?.data?.serviceHistoryList?.filter((service) => {
						return service?.serviceName === "MVPI";
					})
				);
			});
		}
		if (item?.value === "roadAssistance") {
			const motorPolicy = policyList?.find((policy) => {
				return policy.s_Glob === "MR";
			});
			var d = new Date();
			var m = d.getMonth();
			d.setMonth(m - 1);
			let data = {
				policyNo: !isEmpty(motorPolicy) && motorPolicy?.s_PolicyNo.toString(),
				serviceId: "2",
				dateFrom: moment(d).format("YYYY-MM-DD"),
				dateTo: moment(new Date()).format("YYYY-MM-DD"),
				lang: "E",
				channel: "Mobile",
			};
			getServicesHistory(data).then((response) => {
				setRoadSideAssistance(
					response?.data?.serviceHistoryList?.filter((service) => {
						return service?.serviceName === "Roadside Assistance";
					})
				);
			});
		}
	}, [policyList]);

	const userID = useSelector(
		(state) => state?.loginDetailsReducer?.loginResponse?.user_info?.userID
	);

	const handleRequestClick = () => {
		if (!isEmpty(userID)) {
			if (item?.value === "pregnancy") {
				let hasFemaleDependent = !isEmpty(
					medicalPolicyDetails?.data?.medicalMemberList
				)
					? medicalPolicyDetails?.data?.medicalMemberList?.some(function (val) {
						return (
							val.s_Gender === "F" &&
							(val.s_Relation === "Employee" ||
								val.s_Relation === "Spouse" ||
								val.s_Relation === "موظف" ||
								val.s_Relation === "زوجه")
						);
					})
					: false;
				if (isEmpty(medicalPolicyDetails?.data?.medicalMemberList)) {
					toast.current.show({
						severity: "error",
						summary: t("Error"),
						detail: t("InfoError"),
						life: 3000,
					});
				} else if (
					isIllegible &&
					!isEmpty(digitalCard?.eCard) &&
					(digitalCard?.eCard[0].networkGold === "Network Gold" ||
						digitalCard?.eCard[0].networkGold === "Network One" ||
						digitalCard?.eCard[0].networkGold === "Network One Plus" ||
						digitalCard?.eCard[0].networkGold === "Network Two" ||
						digitalCard?.eCard[0].networkGold === "Network Two plus") &&
					hasFemaleDependent
				) {
					props?.viewservice?.dashboardHeaderData?.onButtonClick
						? props.viewservice.dashboardHeaderData.onButtonClick()
						: window.location.assign(props?.viewservice.url);
				} else if (!isIllegible) {
					setTitle(t("CTA.healthOnly"));
					setMessage(t("CTA.noHealthPolicy"));
					setBtnText(t("CTA.buyFamily"));
					setPopUpOpen(true);
				} else if (
					!isEmpty(digitalCard?.eCard) &&
					digitalCard?.eCard[0].networkGold !== "Network Gold" &&
					digitalCard?.eCard[0].networkGold !== "Network One" &&
					digitalCard?.eCard[0].networkGold !== "Network One Plus" &&
					digitalCard?.eCard[0].networkGold !== "Network Two" &&
					digitalCard?.eCard[0].networkGold !== "Network Two plus"
				) {
					setTitle(t("CTA.upgrade"));
					setMessage(t("CTA.networkEligible"));
					setBtnText(t("CTA.upgradeNow"));
					setPopUpOpen(true);
				} else if (!hasFemaleDependent) {
					setBtnText(t("CTA.notEligible"));
					setMessage(t("CTA.onlyFemale"));
					setPopUpOpen(true);
				} else {
					toast.current.show({
						severity: "error",
						summary: t("Error"),
						detail: t("InfoError"),
						life: 3000,
					});
				}
			}
			if (item?.value === "teleMedicine") {
				if (
					isIllegible &&
					!isEmpty(medicalPolicyDetails?.data?.medicalMemberList)
				) {
					props?.viewservice?.dashboardHeaderData?.onButtonClick
						? props.viewservice.dashboardHeaderData.onButtonClick()
						: window.location.assign(props?.viewservice.url);
				} else if (!isIllegible) {
					setTitle(t("CTA.healthOnly"));
					setMessage(t("CTA.noHealthPolicy"));
					setBtnText(t("CTA.buyFamily"));
					setPopUpOpen(true);
				} else {
					toast.current.show({
						severity: "error",
						summary: t("Error"),
						detail: t("InfoError"),
						life: 3000,
					});
				}
			}
			if (item?.value === "eligibilityLetter") {
				if (isIllegible) {
					props?.viewservice?.dashboardHeaderData?.onButtonClick
						? props.viewservice.dashboardHeaderData.onButtonClick()
						: window.location.assign(props?.viewservice.url);
				} else {
					setTitle(t("CTA.healthOnly"));
					setMessage(t("CTA.noHealthPolicy"));
					setBtnText(t("CTA.buyFamily"));
					setPopUpOpen(true);
				}
			}
			if (item?.value === "assistAmerica") {
				if (
					isIllegible &&
					!isEmpty(digitalCard?.eCard) &&
					(digitalCard?.eCard[0].networkGold === "Network Gold" ||
						digitalCard?.eCard[0].networkGold === "Network One" ||
						digitalCard?.eCard[0].networkGold === "Network One Plus" ||
						digitalCard?.eCard[0].networkGold === "Network Two" ||
						digitalCard?.eCard[0].networkGold === "Network Two plus")
				) {
					props?.viewservice?.dashboardHeaderData?.onButtonClick
						? props.viewservice.dashboardHeaderData.onButtonClick()
						: window.location.assign(props?.viewservice.url);
				} else if (!isIllegible) {
					setTitle(t("CTA.healthOnly"));
					setMessage(t("CTA.noHealthPolicy"));
					setBtnText(t("CTA.buyFamily"));
					setPopUpOpen(true);
				} else if (
					!isEmpty(digitalCard?.eCard) &&
					digitalCard?.eCard[0].networkGold !== "Network Gold" &&
					digitalCard?.eCard[0].networkGold !== "Network One" &&
					digitalCard?.eCard[0].networkGold !== "Network One Plus" &&
					digitalCard?.eCard[0].networkGold !== "Network Two" &&
					digitalCard?.eCard[0].networkGold !== "Network Two plus"
				) {
					setTitle(t("CTA.upgrade"));
					setMessage(t("CTA.networkEligible"));
					setBtnText(t("CTA.upgradeNow"));
					setPopUpOpen(true);
				} else {
					toast.current.show({
						severity: "error",
						summary: t("Error"),
						detail: t("InfoError"),
						life: 3000,
					});
				}
			}
			if (item?.value === "chronicDisease") {
				if (
					isIllegible &&
					!isEmpty(digitalCard?.eCard) &&
					(digitalCard?.eCard[0].networkGold === "Network Gold" ||
						digitalCard?.eCard[0].networkGold === "Network One" ||
						digitalCard?.eCard[0].networkGold === "Network One Plus" ||
						digitalCard?.eCard[0].networkGold === "Network Two" ||
						digitalCard?.eCard[0].networkGold === "Network Two plus")
				) {
					props?.viewservice?.dashboardHeaderData?.onButtonClick
						? props.viewservice.dashboardHeaderData.onButtonClick()
						: window.location.assign(props?.viewservice.url);
				} else if (!isIllegible) {
					setTitle(t("CTA.healthOnly"));
					setMessage(t("CTA.noHealthPolicy"));
					setBtnText(t("CTA.buyFamily"));
					setPopUpOpen(true);
				} else if (
					!isEmpty(digitalCard?.eCard) &&
					digitalCard?.eCard[0].networkGold !== "Network Gold" &&
					digitalCard?.eCard[0].networkGold !== "Network One Plus" &&
					digitalCard?.eCard[0].networkGold !== "Network Two" &&
					digitalCard?.eCard[0].networkGold !== "Network Two plus"
				) {
					setTitle(t("CTA.upgrade"));
					setMessage(t("CTA.networkEligible"));
					setBtnText(t("CTA.upgradeNow"));
					setPopUpOpen(true);
				} else {
					toast.current.show({
						severity: "error",
						summary: t("Error"),
						detail: t("InfoError"),
						life: 3000,
					});
				}
			}
			if (item?.value === "hcv") {
				const calculateAge = (birthDate) => {
					const startDate = new Date();
					const endDate = new Date(moment(birthDate).format("YYYY-MM-DD"));
					const age = Math.abs(moment.duration(endDate - startDate).years());
					return age;
				};
				let hasChild = !isEmpty(medicalPolicyDetails?.data?.medicalMemberList)
					? medicalPolicyDetails?.data?.medicalMemberList?.some(function (val) {
						return val.s_Relation === "Child" && calculateAge(val.t_DOB) <= 7;
					})
					: false;
				if (
					isIllegible &&
					!isEmpty(digitalCard?.eCard) &&
					(digitalCard?.eCard[0].networkGold === "Network Gold" ||
						digitalCard?.eCard[0].networkGold === "Network One" ||
						digitalCard?.eCard[0].networkGold === "Network One Plus" ||
						digitalCard?.eCard[0].networkGold === "Network Two" ||
						digitalCard?.eCard[0].networkGold === "Network Two plus") &&
					hasChild
				) {
					props?.viewservice?.dashboardHeaderData?.onButtonClick
						? props.viewservice.dashboardHeaderData.onButtonClick()
						: window.location.assign(props?.viewservice.url);
				} else if (!isIllegible) {
					setTitle(t("CTA.healthOnly"));
					setMessage(t("CTA.noHealthPolicy"));
					setBtnText(t("CTA.buyFamily"));
					setPopUpOpen(true);
				} else if (
					!isEmpty(digitalCard?.eCard) &&
					digitalCard?.eCard[0].networkGold !== "Network Gold" &&
					digitalCard?.eCard[0].networkGold !== "Network One" &&
					digitalCard?.eCard[0].networkGold !== "Network One Plus" &&
					digitalCard?.eCard[0].networkGold !== "Network Two" &&
					digitalCard?.eCard[0].networkGold !== "Network Two plus"
				) {
					setTitle(t("CTA.upgrade"));
					setMessage(t("CTA.networkEligible"));
					setBtnText(t("CTA.upgradeNow"));
					setPopUpOpen(true);
				} else if (!hasChild) {
					setTitle(t("CTA.notEligible"));
					setMessage(t("onlyChild"));
					setPopUpOpen(true);
				} else {
					toast.current.show({
						severity: "error",
						summary: t("Error"),
						detail: t("InfoError"),
						life: 3000,
					});
				}
			}
			if (item?.value === "periodicInspection") {
				if (isMotorEligible) {
					if (isMotorCoEligible) {
						if (IsMvpi?.allowedNumber >= IsMvpi?.used) {
							history.push("/dashboard/service/periodic-inspection/new-request");
						}
						else {
							setMessage(t("CTA.NoLimit"));
							setPopUpOpen(true);
						}
					}
					else {
						setTitle(t("CTA.AlshamelOnly"));
						setMessage(t("CTA.SanadPlus"));
						setBtnText(t("CTA.BuyMotor"));
						setPopUpOpen(true);
					}
				}
				else {
					setTitle(t("CTA.MotorCustomer"));
					setMessage(t("CTA.Motorpolicy"));
					setBtnText(t("CTA.MyDashboard"));
					setPopUpOpen(true);
				}
				//window.location.assign(props?.viewservice?.url);
			}
			if (item?.value === "roadAssistance") {
				if (isMotorEligible) {
					if (isMotorCoEligible) {
						if (roadSideItem?.allowedNumber >= roadSideItem?.used) {
							history.push("/dashboard/service/road-assistance/new-request");
						}
						else {
							setMessage(t("CTA.NoLimit"));
							setPopUpOpen(true);
						}
					}
					else {
						setTitle(t("CTA.AlshamelOnly"));
						setMessage(t("CTA.SanadPlus"));
						setBtnText(t("CTA.BuyMotor"));
						setPopUpOpen(true);
					}
				}
				else {
					setTitle(t("CTA.MotorCustomer"));
					setMessage(t("CTA.Motorpolicy"));
					setBtnText(t("CTA.MyDashboard"));
					setPopUpOpen(true);
				}
				//window.location.assign(props?.viewservice?.url);
			}
		} else {
			history.push("/login");
		}
	};

	const trackEvent = (tabClicked) => {
		window.lmSMTObj.track(tabClicked);
	}

	return (
		<>
			<Toast ref={toast} />
			<div className={"road_assistance_container " + mvpi}>
				<HeaderCloseNav
					pageName={props?.viewservice.subtitle}
					title={props?.viewservice.title}
				/>
				<div>
					<div className="motor_fraud_card">
						<img src={props?.viewservice.bannerImg} alt="Motor_fraud" />
						<p className="subhead">{props?.viewservice.bannersubHead}</p>
						<p>{props?.viewservice.content}</p>
					</div>
				</div>
				{!isEmpty(memberHistory) && (
					<RecentInteractionMedical data={memberHistory[0]} isMember={true} />
				)}
				{!isEmpty(mvpiData) && (
					<RecentInteractionMedical data={mvpiData[0]} isMvpi={true} />
				)}
				{!isEmpty(roadSideAssistance) && (
					<RecentInteractionMedical
						data={roadSideAssistance[0]}
						isRoadSide={true}
					/>
				)}

				{!(props?.viewservice?.assist === "true") ? (
					<>
						<div
							className={`${isRTL
								? "motor_fraud_title motor_fraud_title_rtl"
								: "motor_fraud_title"
								}`}
						>
							<h4>{t("Telemedicine.benifitTitle")}</h4>
						</div>
						<div className="service_card">
							<div className="point">
								<img src={vertical_pointer} alt="Line" />
								<h6 className={`${isRTL ? "benefit-text-rtl" : ""}`}>
									{!isEmpty(props?.viewservice?.dashboardProgressData) &&
										props?.viewservice?.dashboardProgressData[0]
											?.progresssTitle}
								</h6>
								<p className={`${isRTL ? "benefit-text-rtl" : ""}`}>
									{!isEmpty(props?.viewservice?.dashboardProgressData) &&
										props?.viewservice?.dashboardProgressData[0]?.progressPara}
								</p>
							</div>
							<div className="point2">
								<img src={vertical_pointer2} alt="Line" />
								<h6 className={`${isRTL ? "benefit-text-rtl" : ""}`}>
									{!isEmpty(props?.viewservice?.dashboardProgressData) &&
										props?.viewservice?.dashboardProgressData[1]
											?.progresssTitle}
								</h6>
								<p className={`${isRTL ? "benefit-text-rtl" : ""}`}>
									{!isEmpty(props?.viewservice?.dashboardProgressData) &&
										props?.viewservice?.dashboardProgressData[1]?.progressPara}
								</p>
							</div>
							{!isEmpty(props?.viewservice?.dashboardProgressData) &&
								props?.viewservice?.dashboardProgressData[2]
									?.progresssTitle && (
									<div className="point3">
										<img src={vertical_pointer3} alt="Line" />
										<h6 className={`${isRTL ? "benefit-text-rtl" : ""}`}>
											{
												props?.viewservice?.dashboardProgressData[2]
													?.progresssTitle
											}
										</h6>
										<p className={`${isRTL ? "benefit-text-rtl" : ""}`}>
											{
												props?.viewservice?.dashboardProgressData[2]
													?.progressPara
											}
										</p>
									</div>
								)}
						</div>
					</>
				) : (
					""
				)}
				{props?.viewservice?.assist === "true" ? (
					<div className="benefit-section">
						<div className="row">
							<div className="col-12">
								<div className={"benefit" + (isRTL ? " ar" : "")}>
									<div className="col-12 benefit-head">
										<h3>{t("assistAmerica.Benefit")}</h3>
										<h5>{t("assistAmerica.Emergency")}</h5>
										<p>{t("assistAmerica.MedicalEmergency")}</p>
									</div>
									<div className="col-12 benefit-contact">
										<div className="col-6 benefit-us rb">
											<div className="call-icon">
												<img src={call} alt="call" />
											</div>
											<div className="call-area">
												<p>{t("assistAmerica.UnitedStates")}</p>
												<a title="tel:8774889857" href="tel:8774889857">8774889857</a>
											</div>
										</div>
										<div className="col-6 benefit-us">
											<div className="call-icon">
												<img src={call} alt="call" />
											</div>
											<div className="call-area">
												<p>{t("assistAmerica.Outside")}</p>
												<a title="tel:+16092754999" href="tel:+16092754999">+1(609)275-4999</a>
											</div>
										</div>
									</div>
									<div className="col-12 benefit-opinion">
										<h3>{t("assistAmerica.SecondMedical")}</h3>
										<p>{t("assistAmerica.RequestSecond")}</p>
										<a
											href="https://smo.assistamerica.com/SMO-Member-Information-Upload-Form.aspx?cid=1"
											target="_blank"
										>
											<button>
												{t("assistAmerica.FillForm")}{" "}
												<i className="pi pi-external-link"></i>
											</button>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				<div className="question_section">
					<h6 className="mx-4">{t("roadAssistance.faqTitle")}</h6>
					<QuestionSection questions={props?.viewservice?.faqDashboardData} />
				</div>
				{/* <div className="view_all_question_bottom">
					<label>{t("roadAssistance.moreFaqs")}</label>
					<img src={right_arrow} alt="Arrow" />
				</div> */}
			</div>
			{!(props?.viewservice?.assist === "true") ? (
				<div className="card container-fluid report_button pt-2">
					<button onClick={() => {handleRequestClick();trackEvent(props?.viewservice.title+"RequestClicked")}}>
						{t("common.requestService")}
					</button>
				</div>
			) : (
				""
			)}
			{popupOpen && (
				<PopUpComp
					title={title}
					content={message}
					btnText={btnText}
					openPopup={popupOpen}
					closePopup={handleClose}
				/>
			)}
		</>
	);
};
