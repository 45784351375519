import React, { useState, useEffect } from "react";
import Menu from "assets/images/mobile/Menu.png";
import Emergency from "assets/images/mobile/emergency_header.svg";
import TawuniyaLogo from "assets/images/mobile/TawuniyaLogo.png";
import MobileMenuPopup from "component/common/NavBarMobile/MobileMenuPopup";
import "./style.scss";
// import { history } from "service/helpers";
import { useTranslation } from "react-i18next";
import HeaderStepsSticky from "component/common/MobileReuseable/HeaderStepsSticky";
import flagEn from "assets/images/mobile/flag-en.svg";
import ArabicIcon from "assets/images/mobile/ArabicIcon.png";
import EnglishIcon from "assets/images/mobile/EnglishIcon.png";
import flagAr from "assets/images/mobile/flag-ar.svg";
import dropArrow from "assets/images/mobile/bi-dir-arrow.svg";
import MobileLanguageModal from "component/common/NavBarMobile/MobileLanguageModal";
import { useHistory } from "react-router-dom";

const HeaderStickyMenu = ({
  customClass = false,
  profileClass = false,
  title = "",
  buttonTitle = "",
  isVisibleSubHeader = false,
  fromMotor,
  quoteFor,
  buyNowLink,
}) => {
  const [scroll, setScroll] = useState(false);
  let [isMenuActive, setMenuActive] = useState(false);
  const { t, i18n } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  useEffect(() => {
    if (isVisibleSubHeader) {
      window.scrollTo(0, 0);
    }
    let pathName = history.location.pathname;
    if (
      pathName === "/individuals" ||
      pathName === "/corporate" ||
      pathName === "/investor"
    ) {
      setMenuActive(true);
    } else {
      setMenuActive(null);
    }
  }, [history.location.pathname]);

  const isRTL = i18n.dir() === "rtl";
  const [isOpenLanguageModal, setIsOpenLanguageModal] = useState(false);

  return (
    <>
      <div
        className={`${customClass && "isLanding"} ${
          profileClass && "profileClass"
        } ${scroll && customClass && "active"} header_mobile_sticky`}
      >
        <div className="header_emergency">
          <img
            src={TawuniyaLogo}
            alt="TawuniyaLogo"
            className={`TawuniyaLogo ${isRTL ? "logo-pad" : ""}`}
            onClick={() => window.location.replace("https://tawuniya.com")}
          />
        </div>
        <div className="d-flex align-items-center">
          <div className="flag-lang">
            <button onClick={() => setIsOpenLanguageModal(true)}>
              <span>{!isRTL ? "العربية" : "English"}</span>
              <img
                src={isRTL ? EnglishIcon : ArabicIcon}
                alt="flag"
                className="language-icon"
              />
              {/*<img src={dropArrow} alt="arrow" />*/}
            </button>
          </div>
          <img
            src={Menu}
            alt="Menu"
            onClick={() => setMenuActive(true)}
            className={"menu-icon"}
          />
        </div>
        {/* <div className="header_emergency">
     <label onClick={() => history.push("/individuals/emergency-support")}>{t("Emergency")}</label>
     <img
      src={Emergency}
      alt="Emergency"
      className="toggle-logo"
      onClick={() => history.push("/individuals/emergency-support")}
     />
    </div>*/}
      </div>
      {isMenuActive && (
        <MobileMenuPopup
          closeMenu={() => setMenuActive(false)}
          active={isMenuActive}
        />
      )}
      {isVisibleSubHeader && !isMenuActive && (
        <HeaderStepsSticky
          title={title}
          buyNowLink={buyNowLink}
          fromMotor={fromMotor}
          buttonTitle={buttonTitle}
          quoteFor={quoteFor}
        />
      )}
      <MobileLanguageModal
        isOpenLanguageModal={isOpenLanguageModal}
        setIsOpenLanguageModal={setIsOpenLanguageModal}
      />
    </>
  );
};

export default HeaderStickyMenu;
