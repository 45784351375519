export const GET_MEMBERS_LIST = "GET_MEMBERS_LIST";
export const GET_MEDICINE_LIST = "GET_MEDICINE_LIST";
export const SET_MEDICINE_ITEM = "SET_MEDICINE_ITEM";
export const SET_MEDICINE_ITEM_NAME = "SET_MEDICINE_ITEM_NAME";
export const DELETE_MEDICINE_ITEM = "DELETE_MEDICINE_ITEM";
export const CLEAR_MEDICINE_LIST = "CLEAR_MEDICINE_LIST";
export const SET_MEDICAL_COLLECT_DETAILS = "SET_MEDICAL_COLLECT_DETAILS";
export const GET_DAWAI_LOVS = "GET_DAWAI_LOVS";
export const GET_MEDICINE_HISTORY = "GET_MEDICINE_HISTORY";
export const SUBMIT_DAWAI_REQUEST = "SUBMIT_DAWAI_REQUEST";
export const SET_MEDICINE_MEMBER = "SET_MEDICINE_MEMBER";
export const SET_ATTACHMENT_FILE = "SET_ATTACHMENT_FILE";
export const DELETE_ATTACHMENT_FILE = "DELETE_ATTACHMENT_FILE";
export const GET_REGIONS_LIST = "GET_REGIONS_LIST";
