import { combineReducers } from "redux";
import languageReducer from "./languageReducer";
import dashboardInformationReducer from "./dashboardInformationReducer";
import loginDetailsReducer from "./loginDetailsReducer";
import complaintReducer from './complaintReducer'
import contactUsReducer from './contactUsReducer'
import dawaiReducer from "./dawaiReducer";
import medicalReimbursement from './medicalReimbursement';
import reportReducer from './reportReducer'
import { RoadAssistantReducer } from "./roadAssistantReducer";
import welcomekit from './welcomekit'
import serviceInsuranceReducer from './serviceInsuranceReducer';

export const reducers = combineReducers({
	dawai: dawaiReducer,
	roadAssistant: RoadAssistantReducer,
	languageReducer,
	dashboardInformationReducer,
	loginDetailsReducer,
	complaintReducer,
	contactUsReducer,
	medicalReimbursement,
	reportReducer,
	welcomekit,
	serviceInsuranceReducer
});
