import { store } from "./helpers";
import instance from "./instance";
import {LandingSiteEmails} from "../helpers/emailConstant";

export async function sendEmail(data) {
	console.log(data,"DATATATA")
	const response = await instance.post(
		"/common/sendEmailLanding",
		{
			subject: data.subject,
			mailType: "PLAINTEXT",
			app: "Portal",
			module: "Motor",
			cc: data.cc,
			bcc: data.bcc,
			body: data.body,
			refCode: "",
			refValue: "",
			lang: "E",
			consumerRefNum: "",
			from: data.from || LandingSiteEmails.ContactUsEmail,
			to: data.to,
			attachments: data.attachments,
		},
		{
			headers: {
				Authorization:
					"Bearer" +
					" " +
					store.getState().loginDetailsReducer?.loginResponse?.user_info?.token,
			},
		}
	);
	return response.data;
}

export const submitWorkFlowApi=async (reqData) => {
	try {
		const {data} = await instance.post(
			"/common/submitWFRequest",
			reqData,
		);
		if (data) return data
		// return response.data;
	} catch (error) {
		return Promise.reject("error");
	}
}


export const submitViolation = async (reqData) => {
	try {
		const { data } = await instance.post(
			"/common/submitViolationNewLanding",
			reqData,
			{
				headers: {
					Authorization:
						"Bearer" +
						" " +
						store.getState().loginDetailsReducer?.loginResponse?.user_info
							?.token,
				},
			}
		);
		if (data) return data
		// return response.data;
	} catch (error) {
		return Promise.reject(error);
	}
};