import React, { useState, useCallback, useRef, useEffect } from "react";
import { history } from "service/helpers";
import { NormalSearch } from "../NormalSearch";
import PhoneNumberInput from "component/common/PhoneNumberInput/index";
import { NormalButton } from "../NormalButton";
import addMail from "assets/svg/Add Mail.svg";
import deleteViolationsFile from "assets/images/delete-violations-file.png";
import viewViolationsFile from "assets/images/view-violations-file.png";
import exclamation from "assets/svg/Exclamation Mark.svg";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { complaintCaseCreation } from "service/complaint/complaintService";
import * as actions from "action/complaintActions";
import Snackbar from "@material-ui/core/Snackbar";
import { getBase64 } from "service/helpers/base64";
import { dialingCodes } from "component/common/MockData";
import { getI18n, useTranslation } from "react-i18next"
import FormValidation from "service/helpers/FormValidation";
import { submitViolation, submitWorkFlowApi } from "service/sendEmail";
import { Toast } from "primereact/toast";
import Profile from "assets/svg/Forms/profilenew.svg";
import ErrorComponent from "../ErrorComponent";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, SnackbarContent } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const specialCharacter =[";","delete","or 1=1","--","\t","*","?","%","&","~", "$", "^"];

const ViolationFormDesktop = ({ emitEvent, onSuccess }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  const { loginResponse } = useSelector((state) => state.loginDetailsReducer);
  const toast = useRef(null);
  const [files, setFiles] = useState([]);
  const [check, setCheck] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showAlert, setShowAlert] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [selectedCode, setSelectedCode] = useState(dialingCodes[0]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [complaintType, setComplaintType] = useState("Motor");
  const [loading, setLoading] = useState(false);
  const [serviceTypeError, setServiceTypeError] = useState("");
  const [formInput, setFormInput] = useState({
    idNumber: "",
    policyNumber: "",
    message: "",
    emailId: "",
    name: "",
    phoneNumber: "",
    subject: "",
    reporterName: "",
    incidentDescription: "",
    promotions: "1",
    certify: "",
    attachedFile: null
  });
  const [maxDescriptionLength] = useState(1000);
  const attachedFileRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [formErrors, setFormErrors] = useState(null);
  const [incidentDescriptionCharacterErr, setIncidentDescriptionCharacterErr] = useState([]);
  const errorMessages = {
    subject: { required: t("contactUs.req_field") },
    reporterName: { required: t("contactUs.req_field") },
    phoneNumber: {
      required: t("contactUs.req_field"),
    },
    emailId: {
      required: t("contactUs.req_field"),
    },
    certify: { required: t("contactUs.req_field") },
    incidentDescription: {
      required: t("contactUs.req_field"),
    },
  };

  useEffect(() => {
    incidentDescriptionContainsAny(formInput.incidentDescription, specialCharacter);
  }, [formInput.incidentDescription]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      const filesArr = [];
      acceptedFiles.map((eachFile) => {
        const size = (eachFile.size / 1024 / 1024).toFixed(2);
        if (size > 10) return setShowAlert("File size can't be greater than 10 MB");
        getBase64(eachFile, (file) => {
          filesArr.push({
            filename: eachFile.name,
            fileBytes: file,
          });
        });
      });
      setFiles(filesArr);
      setShowPreview(true);
    }
  });
  const images = files.map((file) => (
    <div
      className="dnd-container"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
      key={file.name}
    >
      <img
        style={{ width: "100px" }}
        className="dnd-img mx-auto d-block"
        src={file.preview}
        alt="Screen"
      />
    </div>
  ));
  const removeImageHandler = (e) => {
    e.stopPropagation();
    setFiles([]);
    setShowPreview(false);
  };
  const handleFormInputChange = (e) => {
    const { name, value } = e.target || e || {};
    if (name === 'incidentDescription' && value.length > maxDescriptionLength) return;
    setFormInput({ ...formInput, [name]: value });
    if (formErrors && formErrors[name] !== "" && value !== "")
      setFormErrors({ ...formErrors, [name]: "" });
  };
  const submitComplaintForm = async () => {
    let isError = false;
    const errObj = {};
    Object.keys(formInput).forEach((item) => {
      if (formInput[item] === "") {
        errObj[item] = t("contactUs.req_field");
        isError = true;
      } else {
        errObj[item] = "";
      }
    });
    if (isError) return setFormErrors(errObj);
    if (!serviceType) return setServiceTypeError("Select a service type");
    const cmpData = {
      userName: loginResponse.user_info?.fullName || "",
      langCode: loginResponse.user_info?.preferredLanguage || "E",
      requestType: "6",
      subject: `${complaintType}-${serviceType}`,
      eMail: formInput.eMail,
      name: formInput.name,
      idNumber: formInput.idNumber,
      mobileNumber: `966${formInput.mobileNumber}`,
      message: formInput.message,
      policyNumber: formInput.policyNumber,
      attachments: files,
    };
    try {
      setLoading(true);
      dispatch(actions.complaintCaseCreationReq(cmpData));
      const resData = await complaintCaseCreation(cmpData);
      if (resData) dispatch(actions.complaintCaseCreationSuccess(resData));
      history.push("/individuals/customer-service/support-request-confirmation");
    } catch (error) {
      return setShowAlert("Failed to register a complaint. Please submit again.");
    } finally {
      setLoading(false);
    }
  };
  const formSubmit = () => {
    let errors = FormValidation(errorMessages, formInput, formErrors);
    setFormErrors(null);
    if (!errors) {
      if (formInput.incidentDescription.length >= maxDescriptionLength 
        || incidentDescriptionCharacterErr.length > 0
        || formInput.incidentDescription.length < 150) return;
      setLoading(true);
      const mailData = {
        memberDetails: {
          heading: "Member Details",
          data: [
            {
              title: "Reporter Name",
              value: formInput.reporterName,
            },
            {
              title: "Mobile",
              value: formInput.phoneNumber,
            },
            {
              title: "Email",
              value: formInput.emailId,
            },
          ],
        },
        enquiryDetails: {
          heading: "Incident Details",
          data: [
            {
              title: "Description",
              value: formInput.incidentDescription,
            },
          ],
        },
      };
      const reqData = {
        email: formInput.emailId,
        langCode: i18n.language.slice(0,1).toUpperCase(),
        message: formInput.incidentDescription,
        mobile: formInput.phoneNumber,
        name: formInput.reporterName,
        // subject: "Incident Details",
        subject: formInput.subject,
      }

      submitViolation(reqData).then((res) => {
        console.log(reqData,"req")
          if (res?.status?.statusCode === 'S') {
            let finalData = [
              {
                key: "email",
                value: formInput.emailId,
              },
              {
                key: "message",
                value: formInput.incidentDescription,
              },
              {
                key: "mobile",
                value: formInput.phoneNumber,
              },
              {
                key: "name",
                value: formInput.reporterName,
              },
              // {key: "subject", value: "Incident Details",}
              {key: "subject", value: formInput.subject,}
            ];
            const attachments = [];
            if (formInput.attachedFile && formInput.attachedFile.name && formInput.attachedFile.base64File) {
              attachments.push({
                fileName: formInput.attachedFile.name,
                fileReferrence: formInput.attachedFile.name,
                fileData: formInput.attachedFile.base64File.split(",")[1]
              });
            }
            let requestData={
          "userData":finalData,
          "inputs": [],
          "attachments": attachments,
          "ServiceCode": "ReportViolation",
          "CaseSource": "Website",
          "ServiceName": "Report Violation"
          }
          submitWorkFlowApi(requestData).then((response)=>{
          if(response.status === "S"){
            window.lmSMTObj.track("reportNowSubmit", {
              "Status":"Success",
              "Name":formInput.reporterName,
              // "Email":formInput.emailId,
              // "Mobile":formInput.phoneNumber,
              // "subject": formInput.subject,
              // "message": formInput.incidentDescription
             });
            setFormInput({
            ...formInput,
            idNumber: "",
            policyNumber: "",
            message: "",
            emailId: "",
            name: "",
            phoneNumber: "",
            subject: "",
            reporterName: "",
            incidentDescription: "",
            promotions: "1",
            certify: "",
            attachedFile: null
            });
            attachedFileRef.current.value = null;
            if (emitEvent) {
            onSuccess();
            } else {
              toast.current.show({
                severity: "success",
                summary: `${t('SubmittedSuccessfully')}`,
                detail: `${t('RequestNumber')} #${res?.requestNumber}`,
                life: 8000
                });
            }
          }else {
            window.lmSMTObj.track("reportNowSubmit", {
              "Status":"Failed",
              "Name":formInput.reporterName,
              // "Email":formInput.emailId,
              // "Mobile":formInput.phoneNumber,
              // "subject": formInput.subject,
              // "message": formInput.incidentDescription
             });
            toast.current.show({
            severity: "success",
            summary: `${t('SubmittedSuccessfully')}`,
            detail: `${t('RequestNumber')} #${res?.requestNumber}`,
            life: 8000
            });
          }
          }).catch((error) => {
            window.lmSMTObj.track("reportNowSubmit", {
              "Status":"Failed",
              "Name":formInput.reporterName,
              // "Email":formInput.emailId,
              // "Mobile":formInput.phoneNumber,
              // "subject": formInput.subject,
              // "message": formInput.incidentDescription
             });
            toast.current.show({ 
              severity: 'error', 
              summary: t("feedBack.ErrorMessage"), 
              detail: error.response?.data?.errorMessage || error.message, 
              life: 3000 
            });
          })
          .finally(() => setLoading(false));
        } else {
        toast.current.show({
          severity: "error",
          summary:t("feedBack.ErrorMessage"),
          detail: res?.status?.statusDescription,
        });
        }
      }).catch(error => {
        toast.current.show({ 
          severity: 'error', 
          summary: t("feedBack.ErrorMessage"), 
          detail: error.response?.data?.errorMessage || error.message, 
          life: 3000 
        });
      })
      .finally();   
  } else {
   setFormErrors({ ...errors });
  }
 };

  const getFileSize = (fileSize) => {
    if(fileSize.length < 7) return `${Math.round(+fileSize/1024).toFixed(2)} kb`
    return `${(Math.round(+fileSize/1024)/1000).toFixed(2)} MB`;
  };

  const incidentDescriptionContainsAny = (str, substrings) => {
    const list = [];
    for (var i = 0; i != substrings.length; i++) {
      var substring = substrings[i];
      if (str.indexOf(substring) != - 1) {
        list.push(`"${substring}"`);
      }
    }
    setIncidentDescriptionCharacterErr(list);
  }

  let incidentDescriptionError = null;
  if (incidentDescriptionCharacterErr.length > 0) {
    incidentDescriptionError = `${t("reportViolation.incidentDescriptionCharacterNotAllowed")} ${incidentDescriptionCharacterErr.join(' , ')}`;
  } else if (formErrors?.incidentDescription) {
    incidentDescriptionError = formErrors?.incidentDescription?.required;
  } else if (formInput.incidentDescription.length > 0 && formInput.incidentDescription.length < 150) {
    incidentDescriptionError = t("reportViolation.incidentDescriptionMinimumCharacter");
  } else if (formInput.incidentDescription.length >= maxDescriptionLength) {
    incidentDescriptionError = t("reportViolation.incidentDescriptionMaximumCharacter");
  }

  const action = (
        <a 
        style={{ color: "#6b47f5",fontWeight:"bold",paddingInline:'.65rem' }} 
        target="_blank" 
        href={`https://www.tawuniya.com/${getI18n().language}/individuals/customer-service/support`}
        >
          {t("heal_policy.open_req")}
        </a>
  );

  return (
    <>
      <Toast ref={toast} />
      <div className="row">
        <div className="mx-auto mt-3 snack-bar-content" style={{width:"630px"}}>
          <SnackbarContent
            message={
              <>
                <p>{t("reportViolation.complaintsViol")} <bdi>
                  {t("networkCoverage.call_center_num")}
                </bdi></p>
              </>
            }
            action={action}
            style={{ backgroundColor:"#c1e8fb", boxShadow:"none",color:"#333"}}
          />
        </div>
        <div className="col-12">
          <div className="d-flex justify-content-center pt-4">
            <div className="openFormContainer">
              <div>
                <NormalSearch
                  className="formInputFieldOne"
                  name="reporterName"
                  value={formInput.reporterName}
                  placeholder={t("reportViolation.enterYourName")}
                  onChange={handleFormInputChange}
                  needLeftIcon={true}
                  leftIcon={Profile}
                  errorMessage={formErrors?.reporterName ? formErrors?.reporterName?.required : null}
                />
              </div>
              <div className="pt-4">
                <NormalSearch
                  className="formInputFieldOne"
                  name="emailId"
                  value={formInput.emailId}
                  placeholder={t("reportViolation.emailEx")}
                  onChange={handleFormInputChange}
                  needLeftIcon={true}
                  leftIcon={addMail}
                  errorMessage={
                    formErrors?.emailId
                      ? formErrors?.emailId?.emailError || formErrors?.emailId?.required
                      : null
                  }
                />
              </div>
              <div className="d-flex flex-row pt-4">
                <div>
                  <input
                    type="checkbox"
                    className="px-2 pt-2 mx-2"
                    name="promotions"
                    checked={formInput.promotions}
                    onChange={({ target: { name, checked } }) => {
                      handleFormInputChange({
                        target: { name, value: checked },
                      });
                    }}
                  />
                </div>
                <div>
                  <p className="fs-12 pt-1 fw-400 formConditions m-0">{t("reportFraud.sendMeEmails")}</p>
                </div>
              </div>

              <div className="py-4">
                <PhoneNumberInput
                  className="form-Phone-InputComplaint"
                  selectInputClass="form-Select-Input-Width"
                  selectInputFlexType="form-Select-Flex-TypeComplaint"
                  dialingCodes={dialingCodes}
                  selectedCode={selectedCode}
                  setSelectedCode={setSelectedCode}
                  value={formInput.phoneNumber}
                  name="phoneNumber"
                  onChange={handleFormInputChange}
                  errorMessage={
                    formErrors?.phoneNumber
                      ? formErrors?.phoneNumber?.phone || formErrors?.phoneNumber?.required
                      : null
                  }
                />
              </div>
              <div className="borderLiningTwo"></div>
              <div className="pt-4">
                <NormalSearch
                  className="formInputFieldTwo"
                  name="subject"
                  value={formInput.subject}
                  placeholder={t("reportFraud.subject")}
                  onChange={handleFormInputChange}
                  needLeftIcon={false}
                  leftIcon={null}
                  hideLeftIcon="removeLeftIcon"
                  errorMessage={formErrors?.subject ? formErrors?.subject?.required : null}
                />
              </div>
              <div className="pt-3 pb-2 mb-4">
                <p className="messageTitle fs-14 fw-700 m-0 pb-2">
                  {t("reportFraud.FraudIncidentDetails")}
                </p>
                <textarea
                  className="complaintForm-textArea"
                  value={formInput.incidentDescription}
                  name="incidentDescription"
                  onChange={handleFormInputChange}
                  maxLength={maxDescriptionLength}
                >
                  {t("reportFraud.typeHere")}
                </textarea>
                <div className={`the-count ${currentLanguage === 'ar' ? 'the-count-ar' : 'the-count-en'}`}>
                  <span id="current">{formInput.incidentDescription.length}</span>
                  <span id="maximum"> / {maxDescriptionLength}</span>
                </div>
                {incidentDescriptionError && (
                  <ErrorComponent message={incidentDescriptionError} />
                )}
              </div>
              <div className="d-flex flex-row">
                <div>
                  <img src={exclamation} className="img-fluid exclamatorIcon" alt="icon" />
                </div>
                <div>
                  <p className="fs-12 fw-400 formConditions m-0">{t("reportFraud.BeDetailedAsPossible")}</p>
                  <p className="fs-12 fw-400 formConditions m-0">{t("reportFraud.Belowaresometips")}</p>
                </div>
              </div>
              <ul className={`fs-12 fw-400 formConditionsList p${currentLanguage === 'ar' ? 'r' : 'l'}-5 mb-3`}>
                <li>{t("reportFraud.ExplainStep-by-step")}</li>
                <li>{t("reportFraud.IfYouThink")}</li>
                <li>{t("reportFraud.stateWhenThe")}</li>
              </ul>
              <div className="borderLiningThree mb-3"></div>
              <div className="d-flex flex-column">
                <p className="supporting-documents">
                  {t("reportViolation.SupportingDocuments")}
                </p>
                <div>
                  {
                    !formInput.attachedFile ?
                    <div
                      className="choose-files"
                      onClick={() => attachedFileRef.current.click()}>
                      <p>{t("reportViolation.ChooseFiles")}</p>
                    </div>
                  : <div>
                      <div className="one-attch-file">
                        <div className="data">
                          <p className="name">{formInput.attachedFile.name}</p>
                          <p className="size">{getFileSize(formInput.attachedFile.size)}</p>
                        </div>
                        <div className="actions">
                          <img
                            src={viewViolationsFile}
                            className="view"
                            onClick={() => {
                              setModalShow(true);
                              //window.open(formInput.attachedFile.base64File, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=600");
                            }}
                          />

                          <img
                            src={deleteViolationsFile}
                            className="delete"
                            onClick={() => {
                              handleFormInputChange({
                                target: { name: 'attachedFile', value: null },
                              });
                              attachedFileRef.current.value = null;
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  }
                  <input
                    type="file"
                    ref={attachedFileRef}
                    multiple={false}
                    accept="image/jpeg, image/png, .doc, .docx, .pdf"
                    className="attached-file"
                    name="attachedFile"
                    //value={formInput.attachedFile}
                    onChange={({ target: { name, files } }) => {
                      if (!files || (files && files.length === 0)) return;
                      const file = files[0];
                      const size = (file.size / 1024 / 1024).toFixed(2);
                      if (size > 10) return setShowAlert("File size can't be greater than 10 MB");
                      getBase64(file, async (base64File) => {
                        let fileTypes = file.type.split('/');
                        console.log(file.type)
                        //if (file.type)
                        const fileData = {
                          name: file.name,
                          size: file.size,
                          base64File: base64File,
                          type: fileTypes[0]
                        };
                        console.log(fileData.type)
                        handleFormInputChange({
                          target: { name, value: fileData },
                        });
                      });
                    }}
                  />
                </div>
                <p className="allowed-files">
                  {t("reportViolation.AllowedFiles")}
                </p>
              </div>
              <div className="borderLiningFour my-2"></div>
              <div className="d-flex flex-row pt-2">
                <div>
                  <input
                    type="checkbox"
                    className="px-2 pt-2 mx-2"
                    name="certify"
                    checked={formInput.certify}
                    onChange={({ target: { name, checked } }) => {
                      handleFormInputChange({
                        target: { name, value: checked },
                      });
                    }}
                  />
                </div>
                <div>
                  <p className="fs-12 pt-1 fw-400 formConditions m-0">
                    {t("reportViolation.IHerebyDeclare")}
                  </p>
                </div>
              </div>
              {formErrors?.certify && <ErrorComponent message={formErrors?.certify?.required} />}
              <div className="borderLiningFour my-4"></div>
              <NormalButton
                label={t("SupportCenterMobile.complaint.submit")}
                className="complaintFormSubmitBtn p-4"
                onClick={() => formSubmit()}
                disabled={loading}
              />
            </div>
          </div>
        </div>
        <Snackbar
          open={showAlert !== ""}
          onClose={() => setShowAlert("")}
          autoHideDuration={4000}
          message={showAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        />
        {
          formInput.attachedFile &&
          <Dialog
            onClose={() => setModalShow(false)}
            aria-labelledby="customized-dialog-title"
            open={modalShow}
          >
            <DialogTitle sx={{ m: 0, p: 2, gap: 50 }} >
              {formInput.attachedFile.name}
              <IconButton
                aria-label="close"
                onClick={() => setModalShow(false)}
                sx={{
                  position: 'absolute',
                  right: currentLanguage === 'ar' ? 'unset' : 8,
                  left: currentLanguage === 'ar' ? 8 : 'unset',
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon/>
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {
            formInput.attachedFile.type === 'image' ?
                <img
                  src={formInput.attachedFile.base64File}
                  alt={formInput.attachedFile.name}
                  style={{ width: 'auto', maxHeight: '70vh', objectFit: 'contain'}}
                />
              :
                <iframe src={formInput.attachedFile.base64File} width={400}/>
              }
            </DialogContent>
          </Dialog>
        }
      </div>
    </>
  );
};
export default ViolationFormDesktop;