import axios from "axios";
import moment from "moment";

import { history } from "../service/helpers";
export const axiosInstance = axios.create({
	headers: {},
});

export const logout = () => {
	axiosInstance.defaults.headers.common["Authorization"] = "";
	localStorage.clear();
	history.push("/");
	window.location.reload(true);
};

export const formatToDMY = (date) => {
	return moment(date).format("DD MMM YYYY");
};
