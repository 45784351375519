export const config = {
	apiUrl: process.env.REACT_APP_API_URL,
	// preProdWSUrl: "https://webapispreprod.tawuniya.com.sa:5556/ws/",
	// preProdGatewayUrl: "https://webapispreprod.tawuniya.com.sa:5556/gateway/"
	exteralval: process.env.EXTERNAL_IDX
};

export const mapValues = {
		key: process.env.REACT_APP_GOOGLE_MAP,
		language: 'en',
		libraries: ['places', 'geometry', 'drawing', 'visualization']
};

export const mapVal = {
	key: process.env.REACT_APP_GOOGLE_MAP
};

export const mapScript = {
	id: "google-map-script",
	googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
}