import { store } from "service/helpers";
import instance from "service/instance";

import * as utils from "./loginutils";

export const getcustomerValidation = async (data) => {
  try {
    const response = await instance.post(
      "/login/customerValidation",
      utils.getcustomerValidationRequest(data),
      {
        headers: {
          Authorization:
            "Bearer" +
            " " +
            store.getState().loginDetailsReducer?.loginResponse?.user_info
              ?.token,
        },
      }
    );

    return await utils.getcustomerValidationResponse(response);
  } catch (err) {
    return Promise.reject("error");
  }
};

export const customerRegistration = async (data, responseData) => {
  try {
    const response = await instance.post(
      "/login/customerRegistration",
      utils.customerRegistrationRequest(data, responseData),
      {
        headers: {
          Authorization:
            "Bearer" +
            " " +
            store.getState().loginDetailsReducer?.loginResponse?.user_info
              ?.token,
        },
      }
    );

    return await utils.customerRegistrationResponse(response);
  } catch (err) {
    return Promise.reject("error");
  }
};

export const customerLogin = async (data) => {
  try {
    const response = await instance.post(
      "/login/customerLogin",
      utils.customerLoginRequest(data),
      {
        headers: {
          Authorization:
            "Bearer" +
            " " +
            store.getState().loginDetailsReducer?.loginResponse?.user_info
              ?.token,
        },
      }
    );

    return await utils.customerLoginResponse(response);
  } catch (err) {
    return Promise.reject("error");
  }
};

export const confirmOTP = async (data) => {
  try {
    const response = await instance.post(
      "/login/confirmOTP",
      utils.confirmOTPRequest(data),
      {
        headers: {
          Authorization:
            "Bearer" +
            " " +
            store.getState().loginDetailsReducer?.loginResponse?.user_info
              ?.token,
        },
      }
    );

    return await utils.confirmOTPResponse(response);
  } catch (err) {
    return Promise.reject("error");
  }
};

export const sendOTP = async (data) => {
  try {
    const response = await instance.post(
      "/common/sendOTPLanding",
      utils.sendOTPRequest(data),
      {
        headers: {
          Authorization:
            "Bearer" +
            " " +
            store.getState().loginDetailsReducer?.loginResponse?.user_info
              ?.token,
        },
      }
    );

    return await utils.sendOTPResponse(response);
  } catch (err) {
    return Promise.reject("error");
  }
};

export const verifyOtp = async (data) => {
  try {
    const response = await instance.post(
      "/common/validateOTPLanding",
      utils.verifyOtpRequest(data),
      {
        headers: {
          Authorization:
            "Bearer" +
            " " +
            store.getState().loginDetailsReducer?.loginResponse?.user_info
              ?.token,
        },
      }
    );

    return await utils.verifyOtpResponse(response);
  } catch (err) {
    return Promise.reject("error");
  }
};

export const sendElm = async (data) => {
  try {
    const response = await instance.post(
      "/common/sendElmOtp",
      utils.sendElmRequest(data),
      {
        headers: {
          Authorization:
            "Bearer" +
            " " +
            store.getState().loginDetailsReducer?.loginResponse?.user_info
              ?.token,
        },
      }
    );

    return await utils.sendElmResponse(response);
  } catch (err) {
    return Promise.reject("error");
  }
};

export const validateElm = async (data) => {
  try {
    const response = await instance.post(
      "/common/validateElmOtp",
      utils.validateElmRequest(data),
      {
        headers: {
          Authorization:
            "Bearer" +
            " " +
            store.getState().loginDetailsReducer?.loginResponse?.user_info
              ?.token,
        },
      }
    );

    return await utils.validateElmResponse(response);
  } catch (err) {
    return Promise.reject("error");
  }
};

export const gccCustomerLogin = async (data) => {
  try {
    const response = await instance.post(
      "/login/getGccCustLogin",
      utils.gccCustomerLoginRequest(data)
    );

    return await utils.gccCustomerLoginResponse(response);
  } catch (err) {
    return Promise.reject("error");
  }
};

export const sendEmailOtp = async (data) => {
  try {
    const response = await instance.post(
      "/login/sendEmailOTP",
      utils.sendEmailOtpRequest(data)
    );
    return await utils.sendEmailOtpResponse(response);
  } catch (err) {
    return Promise.reject("error");
  }
};

export const verifyEmailOtp = async (data) => {
  try {
    const response = await instance.post(
      "/login/validateEmailOTP",
      utils.verifyEmailOtpRequest(data)
    );
    return await utils.verifyEmailOtpResponse(response);
  } catch (err) {
    return Promise.reject("error");
  }
};

export const updateGccCustEmail = async (data) => {
  try {
    const response = await instance.post(
      "/login/updateGCCCustEmail",
      utils.updateGccCustEmailRequest(data)
    );
    return await utils.updateGccCustEmailResponse(response);
  } catch (err) {
    return Promise.reject("error");
  }
};
