import React, { useState, useEffect, useRef } from 'react';
import './style.scss';
import smartBannerLogo from 'assets/svg/smartBannerLogo.svg';
import MenuCloseIcon from "assets/images/menuicons/menu-close.svg";

import { useTranslation } from 'react-i18next';


const SmartAppBanner = () => {

    const [appInfo, setAppInfo] = useState(null);
    const [appExist, setAppExist] = useState(false);
    const [isPopUp, setIsPopUp] = useState(true)
    const isMountedRef = useRef(false);
    const { t } = useTranslation()

    const handleShowPopUp = () => {
        setIsPopUp(!isPopUp);
    };

    const isIOS = () => {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    };

    const isAndroid = () => /(android)/i.test(navigator.userAgent);

    const isMobile = window.navigator.maxTouchPoints > 0;

    const appExistsIos = (callback) => {
        try {
            const iframe = document.createElement('iframe');
            iframe.setAttribute('src', 'https://apps.apple.com/us/app/التعاونية-tawuniya/id1639353191');
            iframe.setAttribute('style', 'display:none;');
            document.body.appendChild(iframe);
            iframe.onload = function () {
                document.body.removeChild(iframe);
                callback(true);
            };
            iframe.onerror = function () {
                document.body.removeChild(iframe);
                callback(false);
            };
        } catch (error) {
            callback(false);
        }
    };

    const appExistsAndroid = (callback) => {
        if ('getInstalledRelatedApps' in navigator) {
            try {
                navigator.getInstalledRelatedApps().then(relatedApps => {
                    const isInstalled = relatedApps.some(app => app.platform === 'play' && app.url === 'https://play.google.com/store/apps/details?id=com.dxp.TawuniyaInsurance');
                    callback(isInstalled);
                });
            } catch (error) {
                callback(false);
            }
        } else {
            callback(false);
        }
    };

    useEffect(() => {
        isMountedRef.current = true;


        return () => {
            isMountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMountedRef.current) {
            if (isIOS() && isMobile) {
                setAppInfo({
                    linkUrl: 'https://apps.apple.com/us/app/التعاونية-tawuniya/id1639353191'
                });
                appExistsIos(function (exists) {
                    setAppExist(exists);
                });
            } else if (isAndroid() && isMobile) {
                setAppInfo({
                    linkUrl: 'https://play.google.com/store/apps/details?id=com.dxp.TawuniyaInsurance'
                });
                appExistsAndroid(function (exists) {
                    setAppExist(exists);
                });
            }
        }
    }, [isMountedRef]);

    const handleOpenAppLink = () => {
        if (appInfo) {
            window.open(appInfo.linkUrl, '_blank');
        }
    };

    return (
        <>
            {/* {isPopUp && */}

            {appInfo && isPopUp && (
                <div className='smart-app-banner'>
                    <div className='d-flex align-items-center change-rtl'>
                        <img className='mw-100 smart-banner-logo' src={smartBannerLogo} alt='smartBannerLogo' />
                        <div className='smart-app-banner-content'>
                            <h4 className='title'>{t("smartApp.tawuniya")}</h4>
                            <p className='description'>{t("smartApp.openApp")}</p>
                        </div>
                    </div>
                    <div className='open-btn'>
                        <button onClick={handleOpenAppLink}>
                            {appExist ? t("smartApp.open") : t("smartApp.download")}
                        </button>
                        <img className='c-pointer' onClick={() => handleShowPopUp()} src={MenuCloseIcon} alt='MenuClose' />
                    </div>
                </div>
            )}
        </>
    );
};

export default SmartAppBanner;
