import { applyMiddleware } from "redux";
import { legacy_createStore as createStore} from 'redux'
import { reducers } from "../../reducer";
import thunk from "redux-thunk";
import { api } from "service/api";
import { Toast } from "service/toast";
import { composeWithDevTools } from "redux-devtools-extension";

export const store = createStore(
	reducers,
	composeWithDevTools(applyMiddleware(thunk.withExtraArgument({ api, Toast })))
);

export const history = require("history").createBrowserHistory({
	basename: "/",
	forceRefresh: "true"
});
