import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { QuestionSection } from "component/RoadAssistanceMobile/RoadAssistanceMobile/QuestionSection";
import { useHistory } from "react-router-dom";

const serviceTypes = [
	{
		label: "servicesOffered.all_srvs",
		type: "all",
	},
	{
		label: "servicesOffered.motor",
		type: "motor",
	},
	{
		label: "servicesOffered.health",
		type: "health",
	},
	{
		label: "servicesOffered.prpty_casualty",
		type: "prptyncasualty",
	},
];

const motorQuesAns = [
	{
		question: "custm_service.q4",
		answer: "custm_service.a4",
	},
	{
		question: "MotorIns.q2",
		answer: "MotorIns.a2",
	},
	{
		question: "MotorIns.q3",
		answer: "MotorIns.a3",
	},
	{
		question: "MotorIns.q4",
		answer: "MotorIns.a4",
	},
	{
		question: "MotorIns.q5",
		answer: "MotorIns.a5",
	},
	{
		question: "MotorIns.q7",
		answer: "MotorIns.a7",
	},
	{
		question: "MotorIns.q8",
		answer: "MotorIns.a8",
	},
];

const healthQuesAns = [
	{
		question: "servicesOffered.heal_q1",
		answer: "servicesOffered.heal_a1",
	},
	{
		question: "servicesOffered.heal_q2",
		answer: "servicesOffered.heal_a2",
	},
	{
		question: "servicesOffered.heal_q3",
		answer: "servicesOffered.heal_a3",
	},
	{
		question: "servicesOffered.heal_q4",
		answer: "servicesOffered.heal_a4",
	},
	{
		question: "servicesOffered.heal_q5",
		answer: "servicesOffered.heal_a5",
	},
	{
		question: "servicesOffered.heal_q6",
		answer: "servicesOffered.heal_a6",
	},
	{
		question: "servicesOffered.heal_q7",
		answer: "servicesOffered.heal_a7",
	},
	{
		question: "servicesOffered.heal_q8",
		answer: "servicesOffered.heal_a8",
	},
];

const casualQuesAns = [
	{
		question: "internationalTravelData.internationTravelFaqs.question1",
		answer: "internationalTravelData.internationTravelFaqs.answer1",
	},
	{
		question: "internationalTravelData.internationTravelFaqs.question3",
		answer: "internationalTravelData.internationTravelFaqs.answer3",
	},
	{
		question: "internationalTravelData.internationTravelFaqs.question2",
		answer: "internationalTravelData.internationTravelFaqs.answer2",
	},
	{
		question: "internationalTravelData.internationTravelFaqs.question4",
		answer: "internationalTravelData.internationTravelFaqs.answer4",
	},
	{
		question: "custm_service.q2",
		answer: "custm_service.a2",
	},
	{
		question: "homeInsuranceData.homeInsuranceFaqs.question3",
		answer: "homeInsuranceData.homeInsuranceFaqs.answer3",
	},
	{
		question: "homeInsuranceData.homeInsuranceFaqs.question5",
		answer: "homeInsuranceData.homeInsuranceFaqs.answer5",
	},
	{
		question: "homeInsuranceData.homeInsuranceFaqs.question7",
		answer: "homeInsuranceData.homeInsuranceFaqs.answer7",
	},
];

function ProductsAndServices({ data = [], activeType, onClick }) {
	const { t } = useTranslation();
	const history = useHistory();
	const trackEvent = (tabClicked) => {
		window.lmSMTObj.track(tabClicked);
	}
	return (
		<div className="products_services_wrapper">
			<div className="products_services_head">
				<p>{t("servicesOffered.pns")}</p>
				<h6>{t("servicesOffered.our_srvs")}</h6>
				<div className="products_services_head_list">
					{serviceTypes.map((service) => {
						return (
							<div
								key={service.type}
								onClick={() => {onClick(service.type);trackEvent(t(service.label)+"Clicked")}}
								className={`products_services_head_list_item ${
									service.type === activeType &&
									"products_services_head_list_item_active"
								}`}
							>
								<p>{t(service.label)}</p>
							</div>
						);
					})}
				</div>
			</div>
			<div className="products_services_main">
				{data.map((item) => {
					return (
						<div
							key={item.content}
							onClick={() => {history.push(item?.url);trackEvent(t(item.content)+"Clicked")}}
						>
							<img src={item.cardIcon} alt="icon" width={28} height={26} />
							<p>{t(item.content)}</p>
						</div>
					);
				})}
			</div>
			{activeType !== "all" && (
				<div className="products_services_faqs">
					{activeType === "motor" && <h5>{t("servicesOffered.motor_spt")}</h5>}
					{activeType === "health" && <h5>{t("servicesOffered.med_spt")}</h5>}
					{activeType === "prptyncasualty" && (
						<h5>{t("servicesOffered.prpty_casualty_spt")}</h5>
					)}
					<QuestionSection
						questions={
							activeType === "motor"
								? motorQuesAns
								: activeType === "health"
								? healthQuesAns
								: casualQuesAns
						}
						translation
					/>
				</div>
			)}
		</div>
	);
}

export default ProductsAndServices;
