import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom"
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton, AccordionContext } from "react-bootstrap";
import { NormalSearch } from "../NormalSearch";
import { ServiceFeedBackForm } from "../ServiceFeedBackForm";
import { feedbackContentData } from "component/common/MockData/NewMockData";
import { emojiFiData } from "component/common/MockData/NewMockData";
import { ratingData } from "component/common/MockData/NewMockData";
import addIcon from "assets/svg/addIcon.svg";
import hideCollapse from "assets/svg/hideCollapse.svg";
import orangeArrow from "assets/svg/orangeArrow.svg";
import appStore from "assets/images/appleStore.png";
import playStore from "assets/images/playStore.png";
import appGal from "assets/images/appGallery.png";
import messenger from "assets/svg/Facebook messenger.svg";
import editor from "assets/svg/Edit File.svg";
import locatePin from "assets/svg/Location pin.svg";
import grids from "assets/svg/Grid.svg";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { arabTranslate } from "action/LanguageAct";
import { useTranslation } from "react-i18next";
import CoverageAndBenefit from "component/CoverageAndBenefits/CoverageAndBenefits";
import {
  LanguageCard,
  SupportCard,
  GetStartedCard,
  EmergencyCard,
} from "component/HomePage/LandingComponent/NavbarCards";
import { NavbarDashboard } from "component/DashBoard/DashBoardHome/NavbarDashboard";
import PolicyDetailsSec from "component/PolicyDetails/PolicyDetailsSec";
import MotorPolicyDetailsSec from "component/MotorPolicyDetailsSec/MotorPolicyDetailsSec";
import { DetectOutsideClicks } from "hooks";
import { staticSearchData } from "component/common/MockData";
// import { history } from "service/helpers";



function CustomToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button type="button" className="addButton" onClick={decoratedOnClick}>
      <div className="d-flex flex-row">
        <div>
          <img
            src={isCurrentEventKey ? hideCollapse : addIcon}
            className={`${selectedLanguage === "en"
              ? "Road-accordionCollapseIcon"
              : "Road-accordionCollapseIcon-rtl"
              }`}
            alt="icon"
          />
        </div>
        <div className={`${selectedLanguage === "en" ? "text-left" : "text-right"}`}>
          {children}
        </div>
      </div>
    </button>
  );
}

export const PolicyDetailsRightSec = () => {
  const [search, setSearch] = useState("");
  const history = useHistory();

  const arrayIcons = [
    {
      name: appStore,
      link:
        "https://apps.apple.com/us/app/%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%88%D9%86%D9%8A%D8%A9-tawuniya/id1639353191",
    },
    {
      name: playStore,
      link: "https://play.google.com/store/apps/details?id=com.dxp.TawuniyaInsurance",
    },
  ];
  const { t, i18n } = useTranslation();
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
  //console.log("lang", i18n);
  const [toggle, setToggle] = useState(false);
  const [languageCard, setLanguageCard] = useState(false);
  const [supportCard, setSupportCard] = useState(false);
  const [getStartedCard, setGetStartedCard] = useState(false);
  const [emergencyCard, setEmergencyCard] = useState(false);
  const [arabToggle, setArabToggle] = useState(false);
  const dispatch = useDispatch();
  const isBenefitsOpen = useSelector((state) => state?.dashboardInformationReducer?.isBenefitsOpen);
  const isPolicyDetailsOpen = useSelector(
    (state) => state?.dashboardInformationReducer?.openPolicyDetails
  );

  const isMotorPolicyOpen = useSelector(
    (state) => state?.dashboardInformationReducer?.openMotorPolicyDetails
  );

  const arrayHelpIcons = [
    {
      id: 0,
      icon: messenger,
      label: t("PolicyDetailsRight.Chat"),
      link: "https://wa.me/00966920019990",
    },
    {
      id: 1,
      icon: editor,
      label: t("PolicyDetailsRight.Complaints"),
      link: "/dashboard/support-center",
    },
    {
      id: 2,
      icon: locatePin,
      label: t("PolicyDetailsRight.Locate"),
      link: "/contactus",
    },
    {
      id: 3,
      icon: grids,
      label: t("PolicyDetailsRight.AllServices"),
      link: "/dashboard/service",
    },
  ];

  const accordianDashboardData = [
    {
      id: 0,
      question: t("PolicyDetailsRight.Q1"),
      answer: t("PolicyDetailsRight.A1"),
    },
    {
      id: 1,
      question: t("PolicyDetailsRight.Q2"),
      answer: t("PolicyDetailsRight.A2"),
    },
    {
      id: 2,
      question: t("PolicyDetailsRight.Q3"),
      answer: t("PolicyDetailsRight.A3"),
    },
    {
      id: 3,
      question: t("PolicyDetailsRight.Q4"),
      answer: t("PolicyDetailsRight.A4"),
    },
  ];

  const update = {
    needForm: true,
    qOne: true,
    qTwo: true,
    qThree: true,
  };
  const searchArea = useRef(null);
  const isClickOutside = DetectOutsideClicks(searchArea);
  const [isSearchOpened, setIsSearchOpened] = useState(false);
  const { language } = useSelector((data) => data.languageReducer);
  const [searchResultOpen, setSearchResultOpen] = useState(false);
  const [langSpecificResults, setLangSpecificResults] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const isRTL = selectedLanguage === "ar" ? true : false;

  const handleActionClick = (url) => {
    setIsSearchOpened(false);
    setSearch("");
    history.push(url.replace('https://www.tawuniya.com', ''));
  };

  const handleToggler = () => {
    setToggle(true);
  };

  const translateHandler = () => {
    setArabToggle(true);
    selectedLanguage === "ar" ? dispatch(arabTranslate(false)) : dispatch(arabTranslate(true));
  };

  const closeToggler = () => {
    setToggle(false);
  };

  const toggleLanguageCard = () => {
    setLanguageCard(!languageCard);
  };

  const toggleSupportCard = () => {
    setSupportCard(!supportCard);
  };

  const toggleGetStartedCard = () => {
    setGetStartedCard(!getStartedCard);
  };

  const toggleEmergencyCard = () => {
    setEmergencyCard(!emergencyCard);
  };

  useEffect(() => {
    if (selectedLanguage === "ar") {
      document.getElementsByTagName("body")[0].classList.add("language-alignment-right");
      i18n.changeLanguage("ar");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("language-alignment-right");
      i18n.changeLanguage("en");
    }
  }, [selectedLanguage]);

  const handleInputChange = (e) => {
    setSearch(e.target.value);
    setSearchResultOpen(true);
  };

  useEffect(() => {
    if (!search) return setSearchResults([]);

    const fSearch = langSpecificResults.filter((item) =>
      item.keywords.split(",").find((itm) => itm.includes(search))
    );
    setSearchResults(fSearch);
  }, [search]);

  useEffect(() => {
    if (language) {
      if (language === "en") {
        const lSpecData = staticSearchData.filter((item) => item.lang === "EN");
        setLangSpecificResults(lSpecData);
      } else if (language === "ar") {
        const lSpecData = staticSearchData.filter((item) => item.lang === "AR");
        setLangSpecificResults(lSpecData);
      }
    }
  }, [language]);

  return (
    <React.Fragment>
      {isBenefitsOpen ? (
        <CoverageAndBenefit />
      ) : isPolicyDetailsOpen ? (
        <PolicyDetailsSec />
      ) : isMotorPolicyOpen ? (
        <MotorPolicyDetailsSec />
      ) : (
        <div className="row">
          <div className="col-12 needHelpContainer">
            <div className="row">
              <div className={"col-12 top-menu"}>
                <NavbarDashboard
                  handleToggler={handleToggler}
                  toggleLanguageCard={toggleLanguageCard}
                  toggleSupportCard={toggleSupportCard}
                  toggleEmergencyCard={toggleEmergencyCard}
                  toggleGetStartedCard={toggleGetStartedCard}
                  isLogo={false}
                  isWhite={true}
                />
              </div>
              <div className="col-12 px-0 paddingContainer">
                {languageCard ? (
                  <LanguageCard
                    arabToggle={arabToggle}
                    translateHandler={translateHandler}
                    desktopNavBar={true}
                    toggleLangCard={toggleLanguageCard}
                  />
                ) : null}
                {supportCard ? <SupportCard /> : null}
                {getStartedCard ? <GetStartedCard /> : null}
                {emergencyCard ? <EmergencyCard /> : null}
              </div>
            </div>

            <div className="subDashboardContainer p-3">
              <div className="searchDashboardContainer">
                <p className="text-center fs-20 fw-800 helptext">{t("dashboardHome.HelpYou")}</p>
                <div className="otherServiceNavCont" ref={searchArea}>
                  <React.Fragment>
                    <NormalSearch
                      className="headerSearch"
                      name="search"
                      value={search}
                      placeholder={t("navbar.searchPlaceholder")}
                      onChange={handleInputChange}
                      needRightIcon={true}
                    />
                    {searchResultOpen && search ? (
                      <div className="searchResultBox">
                        <p className="fs-12 fw-800 search-ResultTitle">{t("navbar.results")}</p>
                        {search.length === 0 ? (
                          <p className="fs-10 fw-400 search-ResultPara">{t("navbar.noResultFound")}</p>
                        ) : (
                          <React.Fragment>
                            <p className="fs-10 fw-400 search-ResultPara">
                              {t("navbar.search.found")} {searchResults.length} {t("navbar.search.res_for")}
                              <span>“</span>
                              <span className="results_color">{search}</span>
                              <span>”</span>
                            </p>
                            {searchResults.map((item, idx) => {
                              return (
                                <div className="d-flex justify-content-between resultContainer" key={idx}>
                                  <div className="py-2">
                                    <p className="fs-10 fw-400 result_Name m-0">{item.category}</p>
                                  </div>
                                  <div
                                    className="py-2 resultContainer_action"
                                    onClick={() => handleActionClick(item.webUrl)}
                                  >
                                    <p className="fs-10 fw-400 result_Title m-0 cursor-pointer" style={{ color: "#6b47f5" }}>{item.action}</p>

                                  </div>
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                </div>
                <div className="row pt-3 px-3">
                  {arrayHelpIcons.map((item, index) => {
                    return (
                      <div
                        className="col-3 p-0 pr-1"
                        onClick={() =>
                          item.icon === messenger ? window.location.assign(item.link) : history.push(item.link)
                        }
                        key={index}
                      >
                        <div className="dashboard-helpIcons" key={index}>
                          <img src={item.icon} className="img-fluid mx-auto d-block" alt="icon" />
                          <p className="fs-9 fw-400 helpIconLabel text-center pt-2">{item.label}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="row pt-3">
                <p className={`${isRTL ? "fs-20 fw-800 mostFaq mr-4" : "fs-20 fw-800 mostFaq m-0"}`}>
                  {t("sidebar.MostAsked")}
                </p>
                {accordianDashboardData.map((item, index) => {
                  return (
                    <div className="col-lg-12 col-12 p-2" key={index}>
                      <div className="reportDashboardAccordionQuestion">
                        <Accordion defaultActiveKey="1">
                          <CustomToggle eventKey="0">
                            <span
                              className={`fs-12 fw-400 m-0 py-2 ${selectedLanguage === "en"
                                ? "reportDashboardQuestion"
                                : "reportDashboardQuestion-rtl"
                                }`}
                            >
                              {item.question}
                            </span>
                          </CustomToggle>
                          <div>
                            <Accordion.Collapse eventKey="0">
                              <p
                                className={`fs-10 fw-400 p-3 ${selectedLanguage === "en"
                                  ? "reportDashboardAccordionAnswer"
                                  : "reportDashboardAccordionAnswer-rtl"
                                  }`}
                              >
                                {item.answer}
                              </p>
                            </Accordion.Collapse>
                          </div>
                        </Accordion>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* <LandingProductsOfferings isDesktop={true} /> */}
              <div className="d-flex flex-row dashboard-socialIconBox">
                {arrayIcons.map((items) => {
                  return (
                    <div>
                      <img
                        src={items.name}
                        className="img-fluid socio-icons"
                        alt="buttonIcon"
                        onClick={() => window.location.assign(items.link)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="col-12 pl-5">
            <ServiceFeedBackForm
              feedbackContentData={feedbackContentData}
              emojiData={emojiFiData}
              ratingData={ratingData}
              update={update}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
