import React from "react";
import { Box, Typography, Card, Icon, Divider } from "@material-ui/core";
import serviceArrow from "assets/svg/Arrows/serviceArrow.svg";
import styles from "./style.module.scss";
import { history } from "service/helpers";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function CardComp(props) {

	const { item, routeURL, translate = false } = props;
	const { t, i18n } = useTranslation();
	const isRTL = i18n.dir() === "rtl";
	const trackEvent = (tabClicked, product) => {
		window.lmSMTObj.track(tabClicked, {
			"Product": product
		});
	}
	return (
		<Box
			className={styles.sCardContainer}
			sx={{
				mb: 4,
			}}
		>
			<Box className={styles.sCardTextBox}>
				<Typography className={styles.sCardBoxHeading} variant="h6">
					{translate ? t(item.headingEl) : item.headingEl}
				</Typography>
				<Typography
					className={styles.sCardBoxDescription}
					component={"p"}
					variant="h6"
				>
					{translate ? t(item.discrptionEl) : item.discrptionEl}
				</Typography>
			</Box>
			<Box className={styles.sCardIconBox}>
				<img
					src={item.iconE1}
					className={styles.tawuniyaSliderCardIcons}
					alt="Motor"
				/>

				<Link to={item?.url} onClick={() => { trackEvent(t(item.headingEl) + "Clicked", props.pillName) }}>
					<img
						className={`${isRTL ? styles.arCardArrow : styles.sCardArrow}`}
						src={serviceArrow}
						alt="Arrow"
					/>
				</Link>
			</Box>
		</Box>
	);
}

export default CardComp;
