import { welcomekit } from "service/actionType";

const initialState = {
    logo: '',
    urls: [],
    tollfree: '',
    whatsappLink: '',
    language: '',
    digitalCard: [],
};

const welcomekitReducer = (state = initialState, action) => {
    switch (action.type) {
        case welcomekit.getLandingLogo:
            return { ...state, logo: action.payload };
        case welcomekit.getLandingUrls:
            return { ...state, urls: [...state.urls, action.payload] };
        case welcomekit.getTollfreeNum:
            return { ...state, tollfree: action.payload };
        case welcomekit.getWhatsapplink:
            return { ...state, whatsappLink: action.payload }
        case welcomekit.setLanguage:
            return { ...state, language: action.payload }
        case welcomekit.clearLandingUrls:
            return { ...state, urls: [] }
        case welcomekit.getDigitalCard:
            return { ...state, digitalCard: action.payload }
        default:
            return state;
    }
}

export default welcomekitReducer;