import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import corona from "assets/svg/Bluecoronavirus.svg";
import passport from "assets/svg/Bluepassport.svg";
import family from "assets/svg/Bluefamily.svg";
import rightArrow from "assets/svg/right-arw1.svg";
import ArrowForward from "assets/svg/HomeServiceFroward1Arrow.svg";
import ArrowBack from "assets/svg/HomeServiceBackArrow.svg";
import "./style.scss";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	dots: {
		bottom: -48,
		"& li.slick-active button::before": {
			color: "#6B47F5",
		},
		"& li": {
			width: "12px",
			"& button::before": {
				fontSize: theme.typography.pxToRem(9),
				color: "#4C565C",
			},
		},
	},
}));

export const MotorOtherProducts = ({ motorOtherProductsData }) => {
	const otherProductData = [

		{
			id: 1,
			icon: passport,
			otherProductTitle: "International Travel insurance",
			otherProductPara:
				"The company has identified the amount of compensation so that it starts from SR 920 and up to SR 3,000,000 ",
			otherProductSubTitle: "Benefits:",
			otherProductSubParaOne: "Medical Emergency Expenses",
			otherProductSubParaTwo: "Free coverage for children (Under 2 Years)",
		},
		{
			id: 2,
			icon: family,
			otherProductTitle: "My family Health Insurance",
			otherProductPara:
				"The program covers most of the medical services provided in the outpatient as well as inpatient services",
			otherProductSubTitle: "Benefits:",
			otherProductSubParaOne: "Upto 60 Days Claim Periods",
			otherProductSubParaTwo: "Waive Depreciation",
		},
		{
			id: 3,
			icon: family,
			otherProductTitle: "My family Health Insurance",
			otherProductPara:
				"The program covers most of the medical services provided in the outpatient as well as inpatient services",
			otherProductSubTitle: "Benefits:",
			otherProductSubParaOne: "Upto 60 Days Claim Periods",
			otherProductSubParaTwo: "Waive Depreciation",
		},
	];
	const classes = useStyles();
	const sliderRef = useRef(null);

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		arrows: false,
		slidesToScroll: 1,
		dotsClass: `slick-dots ${classes.dots}`,
	};

	return (
		<div className="MotrProContainer py-5">
			<div className="MotrProText mt-5 pb-4 mb-2">
				<h5 className="text-center">You may also like our other products</h5>
				<p className="text-center mb-0">
					We provide the best and trusted service for our customers
				</p>
			</div>
			<div className="MotrPro-slider-root">
				<Slider ref={sliderRef} {...settings}>
					{otherProductData.map((item, index) => {
						return (
							<div
								className="otherProductCard-Container pb-lg-0 pb-3"
								key={index}
							>
								<div className="otherProductCard">
									<img
										src={item.icon}
										className="img-fluid pt-1 pb-3"
										alt="icon"
									/>
									<p className="otherProduct-header fs-24 fw-800 m-0 pb-2">
										{item.otherProductTitle}
									</p>
									<p className="otherProduct-para fs-14 fw-400">
										{item.otherProductPara}
									</p>
									<p className="otherProduct-Subheader fs-18 fw-400 m-0 pb-1">
										{item.otherProductSubTitle}
									</p>
									<ul className="pl-3">
										<li className="otherProduct-Subpara fs-18 fw-400 m-0 pb-1">
											{item.otherProductSubParaOne}
										</li>
										<li className="otherProduct-Subpara fs-18 fw-400 m-0 pb-1">
											{item.otherProductSubParaTwo}
										</li>
									</ul>
									<hr />
									<div className="w-100 d-flex justify-content-end">
										<div className="d-flex align-items-center cursor-pointer">
											<p className="m-0 mr-2 text-uppercase fw-800 fs-16">
												Learn more
											</p>
											<img src={rightArrow} alt="rightArrow" />
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</Slider>
				<div className="arrowDotContainer">
					<div
						className="arrowContainer"
						onClick={() => sliderRef.current.slickPrev()}
					>
						<img src={ArrowBack} alt="ArrowBack" ss />
					</div>
					<div className="dotContainer"></div>

					<div
						className="arrowContainer"
						onClick={() => sliderRef.current.slickNext()}
					>
						<img src={ArrowForward} alt="ArrowForward" />
					</div>
				</div>
			</div>
		</div>
	);
};
