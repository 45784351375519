import { toggleCanvasHandler } from "action/LanguageAct";
import closeIcon from "assets/svg/canvasClose.svg";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./style.scss";

export const CommonCanvasBody = () => {
  const getCardValue = useSelector((data) => data.languageReducer.toggleValue);
	const history = useHistory();

  const getCardContent = useSelector(
    (data) => data.languageReducer.toggleContent
  );

  console.log(getCardContent, "akak");

  const dispatch = useDispatch();

  const closeHandler = () => {
    dispatch(toggleCanvasHandler(false));
  };

  return (
    <div className="p-3">
      <div className="d-flex justify-content-end pb-4">
        <img
          src={closeIcon}
          className="img-fluid PC-canvas-close-icon cursor-pointer"
          onClick={closeHandler}
          alt="close"
        />
      </div>
      <p className="fs-26 fw-800 PC-canvas-header">
        Contractors' All Risks Insurance
      </p>
      {getCardValue && (
        <>
          {getCardContent.map((value) => {
            return (
              <React.Fragment>
                <p className="fs-12 fw-400 PC-canvas-content">{value.paraOne}</p>
                <ul className="pl-4">
                  {value?.list?.map((val) => {
                    return <li className="fs-12 fw-400 PC-canvas-content-list">{val}</li>;
                  })}
                </ul>
              </React.Fragment>
            );
          })}
        </>
      )}
    </div>
  );
};
