import React, { useState } from "react";
import { NormalButton } from "../../NormalButton";
import { NormalSelect } from "../../NormalSelect";
import cam from "assets/svg/natonCamera.svg";
import selectArrow from "assets/svg/selectArrowIconHome.svg";
import "../style.scss";
import { useTranslation } from "react-i18next";
import CommonIndividualsForm from "./CommonIndividualsForm/CommonIndividualsForm";
import { externalLinks } from "helpers/constant";

export const Domeesticwork = ({
	title = "Individuals",
	isBtn = false,
	isRtl = false,
}) => {
	const [btnPill, setBtnPill] = useState(false);
	const { i18n, t } = useTranslation();
	const isRTL = i18n.dir() === "rtl";
	const myFamily = `https://online.tawuniya.com.sa/RetailMedical/public/FamilyMedical.action?langId=${i18n.language === 'ar' ? '2' : '1'}`;
	const smeLink = externalLinks.DomesticWorkerHealth
	return (
		<div className="recentFeed-SubForm mt-3">
			<div className="row">
				<div className="col-12">
					{!isBtn && (
						<div className="row m-0">
							<div className="col-12 p-0">
								<NormalButton
									label={t(title)}
									className={
										!btnPill
											? "inndividualDashboardBtn-highlight"
											: "inndividualDashboardBtn"
									}
									onClick={() => setBtnPill(false)}
								/>
							</div>
							{/* <div className="col-6 p-0 pl-1">
								<NormalButton
									label= {t('SMEs')}
									className={
										btnPill
											? "smeDashboardBtn-highlight"
											: "smeDashboardBtn"
									}
									onClick={() => setBtnPill(true)}
								/>
							</div> */}
						</div>
					)}
				</div>
				<div className="col-12">
					<CommonIndividualsForm sme={btnPill} application={'domestic-worker'} url={btnPill ? smeLink : smeLink} />
					<p className="fs-9 fw-400 recentFeedTerms pt-2 m-0">
						{t('form_agreement')}
					</p>
				</div>
			</div>
		</div>
	);
};
