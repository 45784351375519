import {
  loader,
  policyInfo,
  medicalPolicyDetails,
  motorPolicyDetails,
  travelPolicyDetails,
  serviceDetails,
  medApprovalDetails,
  renewalPolicyDetail,
  accidentDetail,
  teleMedicalPolicyDetails,
  submitTelemedicine,
  memberHistory,
  policyInformationValue,
  motorPolicyDetailsValue,
  EligVouSerValue,
  memberShipValue,
  periodSubmit,
  updateMap,
  selectedCardData,
  updatemedicalProvider,
  faxEligibility,
  updateClaimTrackDetails,
  updateMedicalClaimTrack,
  updateGenerateDigitalCards,
  LOGOUT,
  SET_MEDICAL_MEMBER_LIST,
  SET_BENEFITS_OPEN,
  GET_DRIVE_STATUS,
  SET_POLICY_COUNT,
  OPEN_POLICY_DETAILS_INFO,
  OPEN_POLICY_DETAILS,
  OPEN_MOTOR_POLICY_DETAILS,
  SET_SELECTED_CARD_TYPE,
  SET_DIGITAL_DATA,
  DIGITAL_DATA_BOOLEAN,
  SET_MED_APPROVAL_DATA,
} from "service/actionType";

const initialState = {
  loader: false,
  policyInformation: {},
  medicalPolicyDetails: [],
  motorPolicyDetails: [],
  travelPolicyDetails: [],
  serviceDetails: {},
  accidentDetail: [],
  renewalPolicyDetail: {},
  medApprovalDetails: [],
  memberHistory: {},
  teleMedicalPolicyDetails: [],
  submitTelemedicine: {},
  policyInformationValue: {},
  memberShipValue: {},
  motorPolicyDetailsValue: {},
  EligVouSerValue: {},
  periodSubmit: {},
  updateMap: {},
  selectedCardInfo: {},
  updatemedicalProvider: {},
  updateGenerateDigitalCards: {},
  faxEligibility: {},
  updateClaimTrackDetails: {},
  updateMedicalClaimTrack: {},
  medicalMemberListInfo: {},
  isBenefitsOpen: false,
  driveStatus: {},
  policyCount: 0,
  openPolicyDetailsInfo: {},
  openPolicyDetails: false,
  openMotorPolicyDetails: false,
  selectedCardType: null,
  digitalData: null,
  digitalDataBoolean: false,
  medApprovalData: {},
};

const dashboardInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case loader:
      return { ...state, loader: action.payload };

    case policyInfo:
      return {
        ...state,
        policyInformation: action.payload,
      };

    case medicalPolicyDetails:
      return {
        ...state,
        medicalPolicyDetails: action.payload,
      };
    case motorPolicyDetails:
      return {
        ...state,
        motorPolicyDetails: [...state.motorPolicyDetails, action.payload],
      };
    case travelPolicyDetails:
      return {
        ...state,
        travelPolicyDetails: [...state.travelPolicyDetails, action.payload],
      };
    case serviceDetails:
      return {
        ...state,
        serviceDetails: action.payload,
      };
    case medApprovalDetails:
      return {
        ...state,
        medApprovalDetails: action.payload,
      };
    case accidentDetail:
      return {
        ...state,
        accidentDetail: [...state.accidentDetail, action.payload],
      };
    case renewalPolicyDetail:
      return {
        ...state,
        renewalPolicyDetail: action.payload,
      };

    case memberHistory:
      return { ...state, memberHistory: action.payload };

    case teleMedicalPolicyDetails:
      return { ...state, teleMedicalPolicyDetails: action.payload };

    case submitTelemedicine:
      return { ...state, submitTelemedicine: action.payload };

    case policyInformationValue:
      return { ...state, policyInformationValue: action.payload };

    case motorPolicyDetailsValue:
      return { ...state, motorPolicyDetailsValue: action.payload };

    case EligVouSerValue:
      return { ...state, EligVouSerValue: action.payload };

    case memberShipValue:
      return { ...state, memberShipValue: action.payload };

    case periodSubmit:
      return { ...state, periodSubmit: action.payload };

    case updateMap:
      return { ...state, updateMap: action.payload };

    case selectedCardData:
      return { ...state, selectedCardInfo: action.payload };

    case updatemedicalProvider:
      return { ...state, updatemedicalProvider: action.payload };

    case updateGenerateDigitalCards:
      return { ...state, updateGenerateDigitalCards: action.payload };

    case faxEligibility:
      return { ...state, faxEligibility: action.payload };

    case updateClaimTrackDetails:
      return { ...state, updateClaimTrackDetails: action.payload };

    case updateMedicalClaimTrack:
      return { ...state, updateMedicalClaimTrack: action.payload };

    case LOGOUT:
      return initialState;

    case SET_MEDICAL_MEMBER_LIST:
      return {
        ...state,
        medicalMemberListInfo: action.payload,
      };
    case SET_BENEFITS_OPEN:
      return {
        ...state,
        isBenefitsOpen: action.payload,
      };
    case GET_DRIVE_STATUS:
      return {
        ...state,
        driveStatus: {
          ...state.driveStatus,
          [action.payload?.policyNumber]: action.payload,
        },
      };
    case SET_POLICY_COUNT:
      return {
        ...state,
        policyCount: action.payload,
      };
    case OPEN_POLICY_DETAILS_INFO:
      return {
        ...state,
        openPolicyDetailsInfo: action.payload,
      };
    case OPEN_POLICY_DETAILS:
      return {
        ...state,
        openPolicyDetails: action.payload,
      };
    case OPEN_MOTOR_POLICY_DETAILS:
      return {
        ...state,
        openMotorPolicyDetails: action.payload,
      };
    case SET_SELECTED_CARD_TYPE:
      return {
        ...state,
        selectedCardType: action.payload,
      };
    case SET_DIGITAL_DATA:
      return {
        ...state,
        digitalData: action.payload,
      };
    case DIGITAL_DATA_BOOLEAN:
      return {
        ...state,
        digitalDataBoolean: action.payload,
      };

    case SET_MED_APPROVAL_DATA:
      return {
        ...state,
        medApprovalData: action.payload,
      };

    default:
      return state;
  }
};

export default dashboardInformationReducer;
