import React from "react";
import "./style.scss";
import BannerImage from "assets/images/tawuniya-HQ1.png";
import RightArrowWhiteIcon from "assets/images/menuicons/right-arrow-white-sm.svg";
import RightArrowIcon from "assets/images/menuicons/right-arrow.svg";
import { history } from "service/helpers";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AboutUs = ({ closeMenu, currentMenu }) => {
  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked,{
      category:currentMenu,
      Product:"AboutUs"
    });
  }

  const { i18n, t } = useTranslation(); return (
    <div className="menu-about-us-details">
      <div className="aboutus-banner-section" style={{ backgroundImage: "url(" + BannerImage + ")" }}>
        <div className="aboutus-banner-title">{t("our-value")}</div>
        <p>
          {t("Exceed")}
          <br />
          {t("Exceed-1")}
        </p>
        {/* <button className="orangeBtn capitalize" onClick={() => {closeMenu();trackEvent("readAboutUsTabClicked");}}>
          <Link to="/aboutus" className="global_link global_link_flex">
            {t("Exceed-2")} <img src={RightArrowWhiteIcon} alt="RightArrowWhiteIcon" />
          </Link>
        </button> */}
      </div>
      <div className="aboutus-box-root">
        <div className="aboutus-box-content content-width-50">
          <div className="aboutus-box-title">{t("Exceed-3")}</div>
          <div className="aboutus-box-links">
            <div className="cursor-pointer">
              <Link to="/aboutus/financialhighlights" onClick={() => {closeMenu();trackEvent(t("Exceed-4")+"TabClicked");}}>
                {t("Exceed-4")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
            <div className="cursor-pointer">
              <Link to="/aboutus/financialhighlights" onClick={() => {closeMenu();trackEvent(t("Exceed-5")+"TabClicked");}}>
                {t("Exceed-5")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
            <div className="cursor-pointer">
              <Link to="/aboutus/financialhighlights" onClick={() => {closeMenu();trackEvent(t("Exceed-6")+"TabClicked");}}>
                {t("Exceed-6")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
            <div className="cursor-pointer">
              <Link to="/aboutus/sustainability" onClick={() => {closeMenu();trackEvent(t("Exceed-13")+"TabClicked");}}>
                {t("Exceed-13")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
          </div>
        </div>
        <div className="aboutus-box-content content-width-50">
          <div className="aboutus-box-title cursor-pointer">{t("Exceed-7")}</div>
          <div className="aboutus-box-links">
            <div className="cursor-pointer">
              <Link to="/aboutus/board-of-directors" onClick={() => {closeMenu();trackEvent(t("Exceed-8")+"TabClicked");}}>
                {t("Exceed-8")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
            <div className="cursor-pointer">
              <Link to="/aboutus/senior-excutive" onClick={() => {closeMenu();trackEvent(t("Exceed-9")+"TabClicked");}}>
                {t("Exceed-9")}
                <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
          </div>
        </div>
        <div className="aboutus-box-content">
          <div className="aboutus-box-title">{t("Exceed-10")}</div>
          <div className="aboutus-box-links">
            <div className="cursor-pointer">
              <Link
                to="/social"
                onClick={() => {closeMenu();trackEvent(t("Exceed-11")+"TabClicked");}}
              >
                {t("Exceed-11")}
                <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
            <div>
              <Link
                to="/social"

                onClick={() => {closeMenu();trackEvent(t("Exceed-12")+"TabClicked");}}
              >
                {t("Exceed-12")} <img src={RightArrowIcon} alt="RightArrowWhiteIcon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
