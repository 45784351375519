


// Constant Emails for form
export const LandingSiteEmails ={
    MedmalFraudEmail:"Fraudmed@tawuniya.com.sa",
    MotorFraudEmail:"motorfraud@tawuniya.com",
    PandCFraudEmail:"P&CFraud@tawuniya.com.sa",
    OtherFraudEmail:"Fraud@tawuniya.com.sa",
    ContactUsEmail:"csc@tawuniya.com.sa",
    ViolationEmail:"TAJAWUZAT@tawuniya.com.sa",
}
