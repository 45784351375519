import React, { useEffect, useState } from "react";
import BottomPopup from "../../../component/common/MobileReuseable/BottomPopup";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { arabTranslate, updateLanguage } from "action/LanguageAct";
import { RadioButton } from "primereact/radiobutton";
import "./languageModal.scss";

const MobileLanguageModal = ({
  isOpenLanguageModal,
  setIsOpenLanguageModal,
}) => {
  const [language, setlanguage] = useState(null);
  const { i18n, t } = useTranslation();
  const isRTL = i18n.dir() === "rtl";
  const dispatch = useDispatch();
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");

  const changeLanguage = (language) => {
    if (selectedLanguage !== language) {
      dispatch(updateLanguage(language));
    }
  };

  const handleChange = (lang) => {
    setlanguage(lang);
    changeLanguage(lang);
    selectedLanguage === "ar"
      ? dispatch(arabTranslate(false))
      : dispatch(arabTranslate(true));
  };

  useEffect(() => {
    if (selectedLanguage === "ar") {
      document
        .getElementsByTagName("body")[0]
        .classList.add("language-alignment-right");
      i18n.changeLanguage("ar");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("language-alignment-right");
      i18n.changeLanguage("en");
    }
  }, [selectedLanguage]);

  return (
    <div>
      <BottomPopup open={isOpenLanguageModal} setOpen={setIsOpenLanguageModal}>
        <div className="mobile-lang-modal">
          <div className="my_profile_Topic">{t("myprofile.Language")} </div>
          <div className="my_profile_Topic_content">
            {t("myprofile.Choose")}
          </div>
          <div className="mt-3 mb-3">
            <div className="">
              <div className="col-xs-12">
                {" "}
                <label
                  className={`w-100 ${isRTL ? "label-rtl" : ""}`}
                  htmlFor="lan1"
                >
                  <div className="field-radiobutton english">
                    <RadioButton
                      inputId="lan1"
                      name="language"
                      value="en"
                      checked={selectedLanguage === "en"}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                    English
                  </div>
                </label>
              </div>
              <div className="col-xs-12">
                <label
                  className={`w-100 ${isRTL ? "label-rtl" : ""}`}
                  htmlFor="lan2"
                >
                  <div className="field-radiobutton arabic">
                    <RadioButton
                      inputId="lan2"
                      name="language"
                      value="ar"
                      checked={selectedLanguage === "ar"}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                    العربية
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </BottomPopup>
    </div>
  );
};

export default MobileLanguageModal;
