import React, { useState, useContext, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton, AccordionContext } from "react-bootstrap";
import { NormalSearch } from "../NormalSearch";
import { ServiceFeedBackForm } from "../ServiceFeedBackForm";
import { feedbackContentData } from "component/common/MockData/NewMockData";
import { emojiFiData } from "component/common/MockData/NewMockData";
import { ratingData } from "component/common/MockData/NewMockData";
import addIcon from "assets/svg/addIcon.svg";
import hideCollapse from "assets/svg/hideCollapse.svg";
import orangeArrow from "assets/svg/orangeArrow.svg";
import appStore from "assets/images/appleStore.png";
import playStore from "assets/images/playStore.png";
import appGal from "assets/images/appGallery.png";
import messenger from "assets/svg/Facebook messenger.svg";
import editor from "assets/svg/Edit File.svg";
import locatePin from "assets/svg/Location pin.svg";
import grids from "assets/svg/Grid.svg";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { arabTranslate } from "action/LanguageAct";
import { updateLanguage } from "action/LanguageAct";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  LanguageCard,
  SupportCard,
  GetStartedCard,
  EmergencyCard,
} from "component/HomePage/LandingComponent/NavbarCards";
import { NavbarDashboard } from "component/DashBoard/DashBoardHome/NavbarDashboard";
import RecentInteraction from "../MobileReuseable/RecentInteraction";
import { CircularProgress } from "@mui/material";
import { getMemberHistory, getServicesHistory } from "service/dashboard/dashboardService";
import { isEmpty } from "lodash";

const arrayIcons = [
  {
    icon: appStore,
    url: "https://apps.apple.com/us/app/%D8%A7%D9%84%D8%AA%D8%B9%D8%A7%D9%88%D9%86%D9%8A%D8%A9-tawuniya/id1639353191",
  },
  {
    icon: playStore,
    url: "https://play.google.com/store/apps/details?id=com.dxp.TawuniyaInsurance",
  },
  // {
  // 	icon: appGal,
  // 	url: ""
  // },
];

const accordianDashboardData = [
  {
    id: 0,
    question: "sidebar.question1",
    answer: "sidebar.answer1",
  },
  {
    id: 1,
    question: "sidebar.question2",
    answer: "sidebar.answer2",
  },
  {
    id: 2,
    question: "sidebar.question3",
    answer: "sidebar.answer3",
  },
  {
    id: 3,
    question: "sidebar.question4",
    answer: "sidebar.answer4",
  },
];

function CustomToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button type="button" className="addButton" onClick={decoratedOnClick}>
      <div className="d-flex flex-row">
        <div>
          <img
            src={isCurrentEventKey ? hideCollapse : addIcon}
            className="Road-accordionCollapseIcon"
            alt="icon"
          />
        </div>
        <div className={`${selectedLanguage === "en" ? "text-left" : "text-right"}`}>
          {children}
        </div>
      </div>
    </button>
  );
}

export const NeedHelpContainer = ({
  isRecentFeed = false,
  isWhite = false,
  faqs = accordianDashboardData,
  viewAll = false,
  isTele = false,
  isRoadSide = false,
}) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const medicalReimbursementDetails = useSelector(
    (state) => state.medicalReimbursement.previousRequest
  );
  const { recentInteractionLoading } = useSelector((state) => state.medicalReimbursement);

  const [duration, setDuration] = useState("");
  const [search, setSearch] = useState("");
  const [toggle, setToggle] = useState(false);
  const [languageCard, setLanguageCard] = useState(false);
  const [supportCard, setSupportCard] = useState(false);
  const [getStartedCard, setGetStartedCard] = useState(false);
  const [emergencyCard, setEmergencyCard] = useState(false);
  const [arabToggle, setArabToggle] = useState(false);
  const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");

  const translateHandler = () => {
    setArabToggle(true);
    selectedLanguage === "ar" ? dispatch(arabTranslate(false)) : dispatch(arabTranslate(true));
  };

  useEffect(() => {
    if (selectedLanguage === "ar") {
      document.getElementsByTagName("body")[0].classList.add("language-alignment-right");
      i18n.changeLanguage("ar");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("language-alignment-right");
      i18n.changeLanguage("en");
    }
  }, [selectedLanguage]);

  const arrayHelpIcons = [
    {
      id: 0,
      icon: messenger,
      label: t("sidebar.ChatNow"),
      link: "https://wa.me/00966920019990",
    },
    {
      id: 1,
      icon: editor,
      label: t("sidebar.Complaints"),
      link: "/individuals/customer-service/support",
    },
    {
      id: 2,
      icon: locatePin,
      label: t("sidebar.Locate"),
      link: "/contactus",
    },
    {
      id: 3,
      icon: grids,
      label: t("sidebar.AllServices"),
      link: "/dashboard/service",
    },
  ];

  const update = {
    needForm: true,
    qOne: true,
    qTwo: true,
    qThree: true,
  };

  useEffect(() => {
    if (medicalReimbursementDetails?.t_ClaimDate) {
      const nowDate = new moment();
      const prevDate = new moment(medicalReimbursementDetails.t_ClaimDate);
      setDuration(Math.floor(moment.duration(nowDate.diff(prevDate)).as("minutes")));
    }
  }, [medicalReimbursementDetails]);

  const handleToggler = () => {
    setToggle(true);
  };

  const toggleLanguageCard = () => {
    setLanguageCard(!languageCard);
  };

  const toggleSupportCard = () => {
    setSupportCard(!supportCard);
  };

  const toggleGetStartedCard = () => {
    setGetStartedCard(!getStartedCard);
  };

  const toggleEmergencyCard = () => {
    setEmergencyCard(!emergencyCard);
  };

  const feedbackContentData = {
    formTitle: t("feedBack.reallyAppreciate"),
    contentData: [
      {
        id: 0,
        questionOne: t("feedBack.q1"),
        questionTwo: t("feedBack.q2"),
        questionThree: t("feedBack.q3"),
      },
    ],
  };

  const [memberHistory, setMemberHistory] = useState([]);
  const [mvpiData, setMvpiData] = useState([]);
  const [roadSideAssistance, setRoadSideAssistance] = useState([]);
  const policyinfo = useSelector(
    (state) => state?.dashboardInformationReducer?.policyInformationValue
  );
  const medicalPolicyDetail = useSelector(
    (state) => state?.dashboardInformationReducer?.medicalPolicyDetails?.data?.medicalMemberList[0]
  );
  const policyList = useSelector(
    (state) => state?.dashboardInformationReducer?.policyInformation?.data?.policyList
  );
  useEffect(() => {
    if (isTele) {
      const medPolicy = policyinfo?.data?.return?.data?.policyList?.filter((policy) => {
        return policy.s_Glob === "MD";
      });
      let data = {
        policyNumber: !isEmpty(medPolicy) && medPolicy[0]?.s_PolicyNo?.toString(),
        memberCode: medicalPolicyDetail?.s_MemberCode,
        langId: i18n.language.slice(0, 1).toUpperCase(),
      };
      getMemberHistory(data).then((response) => {
        setMemberHistory(response?.memberHistory);
      });
    }
    if (isRoadSide) {
      const motorPolicy = policyinfo?.data?.return?.data?.policyList?.find((policy) => {
        return policy.s_Glob === "MR";
      });
      var d = new Date();
      var m = d.getMonth();
      d.setMonth(m - 1);
      let data = {
        policyNo: !isEmpty(motorPolicy) && motorPolicy?.s_PolicyNo.toString(),
        serviceId: "2",
        dateFrom: moment(d).format("YYYY-MM-DD"),
        dateTo: moment(new Date()).format("YYYY-MM-DD"),
        lang: "E",
        channel: "Mobile",
      };
      getServicesHistory(data).then((response) => {
        setRoadSideAssistance(
          response?.data?.serviceHistoryList?.filter((service) => {
            return service?.serviceName === "Roadside Assistance";
          })
        );
      });
    }
  }, [policyinfo]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 needHelpContainer">
          <div className="row">
            <div className={"col-12 top-menu"}>
              <NavbarDashboard
                handleToggler={handleToggler}
                toggleLanguageCard={toggleLanguageCard}
                toggleSupportCard={toggleSupportCard}
                toggleEmergencyCard={toggleEmergencyCard}
                toggleGetStartedCard={toggleGetStartedCard}
                isLogo={false}
                isWhite={isWhite}
              />
            </div>
            <div className="col-12 px-0 paddingContainer">
              {languageCard ? (
                <LanguageCard arabToggle={arabToggle} translateHandler={translateHandler} />
              ) : null}
              {supportCard ? <SupportCard /> : null}
              {getStartedCard ? <GetStartedCard /> : null}
              {emergencyCard ? <EmergencyCard /> : null}
            </div>
          </div>
          {isRecentFeed && (
            <div className="need_help_recent_interations">
              {recentInteractionLoading ? (
                <div className="rec_interactions_loader">
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {medicalReimbursementDetails?.error ? (
                    <div className="rec_interactions_not_found">
                      <p>{t("medical_reimbursement.no_recent_interactions")}</p>
                    </div>
                  ) : (
                    <RecentInteraction
                      minute={duration}
                      title={"Medical Reimbursement"}
                      number={medicalReimbursementDetails?.s_MemberName}
                      recentText
                      viewAllText
                      isHealth
                    />
                  )}
                </>
              )}
            </div>
          )}
          {/* {isTele && {}} */}
          <div className="subDashboardContainer p-3">
            <div className="searchDashboardContainer">
              <p className="text-center fs-20 fw-800 helptext">{t("sidebar.HowHelp")}</p>
              <NormalSearch
                className="dashBoardheaderSearch"
                name="search"
                value={search}
                placeholder={t("sidebar.search")}
                onChange={(e) => setSearch(e.target.value)}
                needRightIcon={true}
              />
              <div className="row pt-3 px-3">
                {arrayHelpIcons.map((item, index) => {
                  return (
                    <div className="col-3 p-0 pr-1" onClick={() => window.location.assign(item.link)}>
                      <div className="dashboard-helpIcons" key={index}>
                        <img src={item.icon} className="img-fluid mx-auto d-block" alt="icon" />
                        <p className="fs-9 fw-400 helpIconLabel text-center pt-2">{item.label}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row pt-3">
              <p className="fs-20 fw-800 mostFaq m-0">{t("sidebar.MostAsked")}</p>
              {faqs.map((item, index) => {
                return (
                  <div className="col-lg-12 col-12 p-2" key={index}>
                    <div className="reportDashboardAccordionQuestion">
                      <Accordion defaultActiveKey="1">
                        <CustomToggle eventKey="0">
                          <span className="fs-12 fw-400 reportDashboardQuestion m-0 py-2">
                            {t(item.question)}
                          </span>
                        </CustomToggle>
                        <div>
                          <Accordion.Collapse eventKey="0">
                            <p
                              className={`"fs-10 fw-400 p-3 ${selectedLanguage === "ar"
                                  ? "reportDashboardAccordionAnswer-rtl"
                                  : "reportDashboardAccordionAnswer"
                                }`}
                            >
                              {t(item.answer)}
                            </p>
                          </Accordion.Collapse>
                        </div>
                      </Accordion>
                    </div>
                  </div>
                );
              })}
            </div>
            {viewAll && (
              <div className="d-flex justify-content-end pt-3">
                <div>
                  <span className="viewText fs-12 fw-800 pr-2">{t("sidebar.ViewAll")}</span>
                  <img src={orangeArrow} className="img-fluid orangeArrow" alt="arrow" />
                </div>
              </div>
            )}
            <div className="d-flex flex-row dashboard-socialIconBox">
              {arrayIcons.map((items) => {
                return (
                  <div>
                    <a title={items.url} href={items.url} target="_blank">
                      <img src={items.icon} className="img-fluid socio-icons" alt="buttonIcon" />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="col-12 pl-5">
          {/* <ServiceFeedBackForm
						feedbackContentData={feedbackContentData}
						emojiData={emojiFiData}
						ratingData={ratingData}
						update={update}
					/> */}
        </div>
      </div>
    </React.Fragment>
  );
};
