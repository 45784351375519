import React, { useEffect, useState } from "react";
import { NormalButton } from "../NormalButton";
import closeIcon from "assets/tele-request/close.svg";
import "./style.scss";
import { feedbackLink } from "component/common/MockData/NewMockData";
import { history } from "service/helpers";
import { useTranslation } from "react-i18next";


export const ServiceFeedBackForm = ({
	feedbackContentData,
	emojiData,
	ratingData,
	update,
}) => {
	const [emoji, setEmoji] = useState(0);
	const [rate, setRate] = useState(0);
	const [notify, setNotify] = useState(false);
	const { t, i18n } = useTranslation();
	
	useEffect(() => {
		const timer = setTimeout(() => {
			setNotify(true);
		}, 1000);
		return () => clearTimeout(timer);
	}, [notify]);

	return (
		<>
			{feedbackLink.map((item, i) => {
				return (
					<div key={i}>
						{item?.link?.map((items, i) => {
							return (
								<React.Fragment key={i}>
									{history.location.pathname === items && (
										<>
											{update.needForm && (
												<div className="row">
													<div className="col-12">
														{notify && (
															<div className="serviceFeedabackContainer">
																<div className="p-3 pr-4">
																	<img
																		src={closeIcon}
																		className="img-fluid feedbackCloseIcon cursor-pointer"
																		onClick={() => setNotify(false)}
																		alt="closeicon"
																	/>
																	<div className="service-question-title-container">
																		<p className="fs-13 fw-800 service-question-title m-0 pb-2">
																			{feedbackContentData.formTitle}
																		</p>
																	</div>

																	{feedbackContentData.contentData?.map(
																		(item, i) => {
																			return (
																				<React.Fragment key={i}>
																					{update.qOne && (
																						<div>
																							{item.questionOne && (
																								<div>
																									<p
																										className="fs-12 fw-800 service-question-paraOne m-0 pt-2"
																										key={i}
																									>
																										{item.questionOne}
																									</p>
																									<div className="d-flex justify-content-between pt-4 emojiBoxLining pb-2">
																										{emojiData.map(
																											(item, i) => {
																												return (
																													<div key={i}>
																														<div
																															className={
																																emoji ===
																																item.id
																																	? "highlight-emojiBlockContainer cursor-pointer"
																																	: "normal-emojiBlockContainer cursor-pointer"
																															}
																															onClick={() =>
																																setEmoji(
																																	item.id
																																)
																															}
																														>
																															<img
																																src={item.icon}
																																className="img-fluid emogi-express-icon"
																																alt="icon"
																															/>
																														</div>
																													</div>
																												);
																											}
																										)}
																									</div>
																								</div>
																							)}
																						</div>
																					)}
																					{update.qTwo && (
																						<div>
																							{item.questionTwo && (
																								<div>
																									<p className="fs-12 fw-800 service-question-paraTwo m-0 pt-2">
																										{item.questionTwo}
																									</p>
																									<div className="d-flex justify-content-between pt-4">
																										{ratingData.map(
																											(item, i) => {
																												return (
																													<div
																														key={i}
																														onClick={() =>
																															setRate(item.id)
																														}
																													>
																														<img
																															src={item.icon}
																															className="img-fluid number-rating-icon cursor-pointer"
																															alt="icon"
																														/>
																													</div>
																												);
																											}
																										)}
																									</div>
																									<div className="d-flex justify-content-between pt-2 likeContainer">
																										<div>
																											<p className="poorLike fs-11 fw-700">
																												{t("feedBack.NotLikely")}
																											</p>
																										</div>
																										<div>
																											<p className="goodLike fs-11 fw-700">
																											{t("feedBack.Extremely")}
																											</p>
																										</div>
																									</div>
																								</div>
																							)}
																						</div>
																					)}
																					{update.qThree && (
																						<div>
																							{item.questionThree && (
																								<div>
																									<p className="fs-12 fw-800 service-question-paraThree m-0 pt-2">
																										{item.questionThree}
																									</p>
																									<div className="feebackForm-textArea-liner pb-2">
																										<textarea className="feedback-Form-textArea mt-4">
																											{t("contactUs.type_here")}
																										</textarea>
																									</div>
																								</div>
																							)}
																						</div>
																					)}
																					<div className="pt-2 mt-1">
																						<NormalButton
																							label= {t("feedBack.SendFeedback")}
																							className="sendFeeed-backButton"
																						/>
																					</div>
																				</React.Fragment>
																			);
																		}
																	)}
																</div>
															</div>
														)}
													</div>
												</div>
											)}
										</>
									)}
								</React.Fragment>
							);
						})}
					</div>
				);
			})}
		</>
	);
};
