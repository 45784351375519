import React, { useEffect, useState } from "react";
import "./style.scss";
import closeIcon from "assets/svg/close_icon.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openMotorPolicyDetails } from "action/DashboardInformation";
import { history } from "service/helpers";
import policyTypeImg from "assets/images/motor-policy-type.svg";
import insuredPassengerImg from "assets/images/insured-passengers.svg";
import policyNumberImg from "assets/images/number-health.svg";
import expiryImg from "assets/images/exp-date.svg";
import sumInsuredImg from "assets/images/sum-insured.svg";
import premiumImg from "assets/images/premium.svg";
import vehicleDetImg from "assets/images/vehicle-details.svg";
import plateNumImg from "assets/images/plate-number.svg";
import chassisImg from "assets/images/chassis-number.svg";
import serialNumberImg from "assets/images/serial-number.svg";
import najmImg from "assets/images/najm-status.svg";
import phone from "assets/images/mobile/phone.png";
import whatsapp from "assets/images/mobile/whatsapp.png";
import mail from "assets/images/mobile/mail.png";
import moment from "moment";
import { getMotorPolicyDetails } from "service/dashboard/dashboardService";

const MotorPolicyDetailsSec = ({ setMotorDetailsOpen }) => {
    const { t, i18n } = useTranslation();
    const isRTL = i18n.dir() === "rtl";
    const dispatch = useDispatch();
    const openPolicyDetailsInfo = useSelector(
        (state) => state?.dashboardInformationReducer?.openMotorPolicyDetails
    );

    const handleCloseClick = () => {
        window.innerWidth < 950 ? setMotorDetailsOpen(false) : dispatch(openMotorPolicyDetails(false));
    };
    const loginResponse = useSelector((state) => state.loginDetailsReducer.loginResponse);
    const selectedLanguage = localStorage.getItem("newTawuniyaLanguageParam");
    const selectedCardData = useSelector(
        (state) => state?.dashboardInformationReducer?.selectedCardInfo
    );
    const policy = selectedCardData?.policyData;

    const motorPolicyDetails = useSelector(
        (state) => state?.dashboardInformationReducer?.motorPolicyDetails
    );

    const userID = loginResponse?.user_info?.userID;
    const customerID = loginResponse?.user_info?.customerID;
    const token = loginResponse?.user_info?.token;
    const [motorDetail, setMotorDetail] = useState(null);

    useEffect(() => {
        let element = policy;
        let langId = selectedLanguage.slice(0, 1).toUpperCase();
        getMotorPolicyDetails(element, userID, customerID, langId, token).then((response) => {
            if (response?.data?.resultCode === "0") {
                setMotorDetail(response?.data?.vehiclesList[0]);
            }
        });
    }, []);

    return (
        <div className="policy-details">
            {" "}
            <div className="policy-details-top">
                <div className="d-flex justify-content-end">
                    <img
                        src={closeIcon}
                        alt="Close Icon"
                        className={`close-icon ${isRTL ? "mt-3 ml-3" : "mt-3 mr-3"}`}
                        onClick={handleCloseClick}
                    />
                </div>
                <div className="policy-details-top-text-sec">
                    <span className="top-header">{t("motor_policy.motor_insure")}</span>
                    <span className="top-title">{t("motor_policy.policy_details")}</span>
                </div>
            </div>
            <div className="info">
                <div className="d-flex align-items-center">
                    <img
                        src={policyTypeImg}
                        alt="policy Network"
                        className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
                    />
                    <div className="d-flex flex-column">
                        <span className="item-header">{t("motor_policy.type")}</span>
                        <span className="item-text">
                            {policy?.s_Product.split("-")[0] === "AL" ? "Alshamel" : policy?.s_Product.split("-")[0]}
                        </span>
                    </div>
                </div>
                <hr />

                <div className="d-flex align-items-center">
                    <img
                        src={policyNumberImg}
                        alt="policy number tag"
                        className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
                    />
                    <div className="d-flex flex-column">
                        <span className="item-header">{t("motor_policy.policy_no")}</span>
                        <span className="item-text">{policy?.s_PolicyNo}</span>
                    </div>
                </div>
                <hr />

                <div className="d-flex align-items-center">
                    <img
                        src={policyNumberImg}
                        alt="policy number image"
                        className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
                    />
                    <div className="d-flex flex-column">
                        <span className="item-header">{t("motor_policy.insure_passenger")}</span>
                        <span className="item-text">{motorDetail?.s_InsuredPassengers}</span>
                    </div>
                </div>
                <hr />

                <div className="d-flex align-items-center">
                    <img
                        src={expiryImg}
                        alt="expiryImg"
                        className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
                    />
                    <div className="d-flex flex-column">
                        <span className="item-header">{t("motor_policy.expiry_date")}</span>
                        <span className="item-text">{moment(policy?.d_ExpireDate).format("DD-MM-YYYY")}</span>
                    </div>
                </div>
                <hr />

                <div className="d-flex align-items-center">
                    <img
                        src={sumInsuredImg}
                        alt="sum InsuredImg"
                        className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
                    />
                    <div className="d-flex flex-column">
                        <span className="item-header">{t("motor_policy.sum_insure")}</span>
                        <span className="item-text">{motorDetail?.d_SumInsured}</span>
                    </div>
                </div>
                <hr />

                <div className="d-flex align-items-center">
                    <img
                        src={premiumImg}
                        alt="premium Img"
                        className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
                    />
                    <div className="d-flex flex-column">
                        <span className="item-header">{t("motor_policy.premium")}</span>
                        <span className="item-text">{motorDetail?.d_PremiumAmount}</span>
                    </div>
                </div>
                <hr />

                <div className="d-flex align-items-center">
                    <img
                        src={vehicleDetImg}
                        alt="vehicle Det Img"
                        className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
                    />
                    <div className="d-flex flex-column">
                        <span className="item-header">{t("motor_policy.vehicle_details")}</span>
                        <span className="item-text">{policy?.s_Description?.split(" ").slice(0, 2).join(" ")}</span>
                    </div>
                </div>
                <hr />

                <div className="d-flex align-items-center">
                    <img
                        src={plateNumImg}
                        alt="plate Num Img"
                        className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
                    />
                    <div className="d-flex flex-column">
                        <span className="item-header">{t("motor_policy.plate_no")}</span>
                        <span className="item-text">{policy?.s_Description?.split("No. ")[1]}</span>
                    </div>
                </div>
                <hr />

                <div className="d-flex align-items-center">
                    <img
                        src={chassisImg}
                        alt="chassis Img"
                        className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
                    />
                    <div className="d-flex flex-column">
                        <span className="item-header">{t("motor_policy.chassis_no")}</span>
                        <span className="item-text">{motorDetail?.s_ChassisNo}</span>
                    </div>
                </div>
                <hr />

                <div className="d-flex align-items-center">
                    <img
                        src={serialNumberImg}
                        alt="serial Number Img"
                        className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
                    />
                    <div className="d-flex flex-column">
                        <span className="item-header">{t("motor_policy.serial_no")}</span>
                        <span className="item-text">{motorDetail?.serielNo}</span>
                    </div>
                </div>
                <hr />

                <div className="d-flex align-items-center">
                    <img
                        src={najmImg}
                        alt="najm Img"
                        className={`${selectedLanguage === "en" ? "mr-3" : "ml-3"}`}
                    />
                    <div className="d-flex flex-column">
                        <span className="item-header">{t("motor_policy.najm_status")}</span>
                        <span className="item-text">{motorDetail?.najmStatus}</span>
                    </div>
                </div>

                <div className="contact-sec">
                    <span className="contact">{t("motor_policy.contact_daman")}</span>
                    <div className="support-request-helper-policy">
                        <div className="support-request-callcenter">
                            <img className="phone-img" src={phone} alt="phone" />
                            <span className="call-center">{t("motor_policy.call_center")}</span>
                            <div className="call-number">800 124 9990</div>
                        </div>
                        <div className="support-request-whatsapp">
                            <img className="whatsapp-img" src={whatsapp} alt="whatsapp" />
                            <span className="whatsapp">{t("motor_policy.whatsapp")}</span>
                            <div className="whatsapp-number">9200 19990</div>
                            <div className="whatsapp-chat">{t("motor_policy.chat_no")}</div>
                        </div>

                        <div className="support-request-mail">
                            <img className="mail-img" src={mail} alt="mail" />
                            <span className="support-email">{t("motor_policy.email")}</span>
                            <div className="support-email-care">{t("motor_policy.email_id")}</div>
                        </div>
                        <h2 className="hr-lines">{t("motor_policy.or")}</h2>
                        <div className="support-button">
                            <button
                                className="support-request-button"
                                onClick={() => {
                                    history.push("/all-service", { idx: 1 });
                                }}
                            >
                                {t("motor_policy.open_req")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MotorPolicyDetailsSec;
