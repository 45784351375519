import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./coverageBenefits.scss";
import closeIcon from "assets/svg/close_icon.svg";
import { Dropdown } from "primereact/dropdown";
import { isEmpty } from "lodash";
import { getMedicalBenefits } from "service/dashboard/dashboardService";
import { getBenefitsLimit } from "service/dashboard/dashboardService";
import { history } from "service/helpers";
import { setBenefitsOpen } from "action/DashboardInformation";
import { useTranslation } from "react-i18next";

const CoverageAndBenefit = () => {
	const medicalMemberList = useSelector(
		(state) => state.dashboardInformationReducer?.medicalMemberListInfo
	);
	
	const selectedCardData = useSelector(
		(state) => state?.dashboardInformationReducer?.selectedCardInfo
	);
	const loginResponse = useSelector(
		(state) => state.loginDetailsReducer.loginResponse
	);
	const idNumber = loginResponse?.user_info?.iqamahID;
	const policy = selectedCardData?.policyData;
	const [selectedMember, setSelectedMember] = useState("");
	const memberNames = [];
	const [medBenefitList, setMedBenefitList] = useState([]);
	const [benefitsLimitList, setBenefitsLimitList] = useState([]);
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const isRTL = i18n.dir() === "rtl";

	{
		!isEmpty(medicalMemberList) &&
			medicalMemberList.map((member) => {
				for (let key in member) {
					if (key == "s_MemberName") {
						let data = { name: member[key], code: member["s_MemberCode"] };
						memberNames.push(data);
					}
				}
			});
	}

	const onMemberChange = (e) => {
		setSelectedMember(e.value);
		let data = {
			policyNumber: policy.s_PolicyNo,
			idNumber: idNumber,
			cardCode: e.value.code,
		};
		let langId = i18n.language.slice(0, 1).toUpperCase();
		getMedicalBenefits(data, langId).then((response) => {
			console.log(response);
			setMedBenefitList(response?.return?.data?.medBenefitList);
		});
		getBenefitsLimit(data, langId).then((response) => {
			console.log(response);
			setBenefitsLimitList(response?.benefitsLimitList);
		});
	};

	const handleCloseClick = () => {
		window.innerWidth < 950
			? history.push("/dashboard/policy-detail")
			: dispatch(setBenefitsOpen(false));
	};

	return (
		<>
			<div className="benefits-mobile-container">
				<div className="benefits-mobile-top">
					<div className="d-flex justify-content-end">
						<img
							src={closeIcon}
							alt="Close Icon"
							className={`${isRTL ? "mt-3 ml-3" : "mt-3 mr-3"}`}
							onClick={handleCloseClick}
						/>
					</div>
					<div className="benefits-mobile-top-text-sec">
						<span className="top-header">{t("HealthServices")}</span>
						<span className="top-title">{t("YourPolicyCoverage")}</span>
					</div>
				</div>

				<div className="benefits-mobile-bottom p-4">
					<div className="mt-3">
						<Dropdown
							value={selectedMember}
							options={memberNames}
							onChange={onMemberChange}
							optionLabel="name"
							placeholder="Choose a Member"
							className="w-100"
						/>
					</div>

					<div className="med-benefits-container">
						{!isEmpty(medBenefitList) &&
							medBenefitList.map((benefit) => {
								return (
									<>
										<div className="d-flex justify-content-between mt-4">
											<span
												className={`${
													isRTL
														? "benefits-text-left-rtl"
														: "benefits-text-left"
												}`}
											>
												{benefit?.coverType}
											</span>
											<span className="benefits-text-center">
												{benefit?.coverBenefitsLimit}
											</span>
											<span
												className={`${
													isRTL
														? "benefits-text-right-rtl"
														: "benefits-text-right"
												}`}
											>
												{benefit?.coverBenefitsLimitAmount}
											</span>
										</div>
										<hr />
									</>
								);
							})}
					</div>
					<div className="limit-container mt-3">
						{!isEmpty(benefitsLimitList) && (
							<div className="benefits-limit-text">
								<p>{t("BenefitsLimit")}</p>
							</div>
						)}
						{!isEmpty(benefitsLimitList) &&
							benefitsLimitList.map((limit) => {
								return (
									<>
										<div className="mt-4">
											<div className="d-flex justify-content-between mt-2">
												<span
													className={`${
														isRTL ? "limit-left-rtl" : "limit-left"
													}`}
												>
													{limit?.lableOriginalLimit}
												</span>
												<span
													className={`${
														isRTL ? "limit-right-rtl" : "limit-right"
													}`}
												>
													{limit?.originalLimit}
												</span>
											</div>
											<div className="d-flex justify-content-between mt-2">
												<span
													className={`${
														isRTL ? "limit-left-rtl" : "limit-left"
													}`}
												>
													{limit?.lableRemainingLimit}
												</span>
												<span
													className={`${
														isRTL ? "limit-right-rtl" : "limit-right"
													}`}
												>
													{limit?.remainingLimit}
												</span>
											</div>
										</div>
									</>
								);
							})}
					</div>
				</div>
			</div>
		</>
	);
};

export default CoverageAndBenefit;
