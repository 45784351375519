import React from "react";
import "./style.scss";
import dfappstore from "assets/svg/dfappstore.svg";
import dfgoogleplay from "assets/svg/dfgoogleplay.svg";
import dfappgallery from "assets/svg/dfappgallery.svg";
import textVitality from "assets/svg/textVitality.svg";
import appadverthand from "assets/svg/appadverthand.svg";
import gotoiconOrange from "assets/svg/gotoiconOrange.svg";
import AssistinAccident from "assets/svg/AssistinAccident.svg";
import Assistinmedical from "assets/svg/Assistinmedical.svg";
import RoadSideAssistance from "assets/svg/RoadSideAssistance.svg";

import userCareAccident from "assets/svg/user_care.svg";
import AccidentIcon from "assets/svg/accident_icon.svg";
import gotoiconOffwhite from "assets/svg/gotoiconOffwhite.svg";

import RoadSideAssitance from "assets/svg/road_side_assistence.svg";

import advertManImage from "assets/images/advertManImage.png";
import appAdvertTopLogo from "assets/svg/appAdvertTopLogo_white.svg";
import appAdvertBtmLogo from "assets/svg/appAdvertBtmLogo_white.svg";
import ReportViolation from "component/CustomerService/Violations/ViolationsPage/ReportViolation";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AppStore_Link, PlayStore_Link } from "../../../service/helpers/Constants";

export const TwuniyaAppAdvert = ({ hideReport }) => {
  const { i18n, t } = useTranslation(); const TwuniyaAppAdvertContent = [
    {
      heading: t("Tawuniya App"),
      headingNext: t("Quick_Easy_Hassle_free"),
      subHeading: t(
        "Our friendly customer support team is your extended family. Speak your heart out"
      ),
      preface: t("Download Now"),
      prefaceIcon1: dfappstore,
      prefaceIcon2: dfgoogleplay,
      prefaceIcon3: dfappgallery,
      btmTitle: t("If You Still Need Help"),
      btmSubTitile: t("Contact the legendary support team right now"),
      advertImgCard: {
        advertManImage: advertManImage,
        textVitality: textVitality,
        title: t("Get Off annual fitness time Gym Membership"),
        gotoText: t("Get it now"),
        iconBtn: gotoiconOrange,
      },
      appadverthand: appadverthand,
      advertgotoCardleft: [
        {
          id: 1,
          cardIcon: AssistinAccident,
          content: t("Assist in Accident"),
          gotoicon: true,
          alignBannerCard: "alignAccidentCard",
        },
        {
          id: 1,
          cardIcon: RoadSideAssistance,
          content: t("Hire Car Facility"),
          gotoicon: true,
          alignBannerCard: "alignHireCard",
        },
      ],
      advertgotoCardRight: [
        {
          id: 1,
          cardIcon: Assistinmedical,
          content: t("Assist in medical"),
          gotoicon: true,
          alignBannerCard: "",
        },
        {
          id: 1,
          cardIcon: RoadSideAssistance,
          content: t("Roadside assistance"),
          gotoicon: true,
          alignBannerCard: "",
        },
      ],
    },
  ];

  const trackEvent = (tabClicked) => {
    window.lmSMTObj.track(tabClicked);
  }
  return (
    <div>
      {TwuniyaAppAdvertContent.map((item, index) => {
        return (
          <div key={index} className="app-advert-container  mt-5 mb-5 d-flex justify-content-between overflow-hidden">
            <div className="app-advert-left">
              <div className="app-advert-download-container">
                <div className="app-advert-text">
                  <h2>{item.heading}</h2>
                  <h2>{item.headingNext}</h2>
                  <p className="mt-2 pt-2">{item.subHeading}</p>
                </div>
                <div className="app-advert-download">
                  <p>{item.preface}</p>
                  <div className="app-advert-download-btn-container d-flex justify-content-between mt-2 pt-1">
                    <a title={AppStore_Link} href={AppStore_Link} target="_blank" onClick={() => { trackEvent("appStoreClicked") }}>
                      <img
                        className="app-advert-download-btn"
                        src={item.prefaceIcon1}
                        alt="download on the app store button"
                      />
                    </a>
                    <a title={PlayStore_Link} href={PlayStore_Link} target="_blank" onClick={() => { trackEvent("playStoreClicked") }}>
                      <img
                        className="app-advert-download-btn google"
                        src={item.prefaceIcon2}
                        alt="get it on google play button"
                      />
                    </a>
                  </div>
                </div>
                <div className="app-advert-support-text">
                  <h5>{item.btmTitle}</h5>
                  <p>{item.btmSubTitile}</p>
                </div>
              </div>
            </div>
            <div className="app-advert-right">
              <div
                className="app-advert-fitness-card"
                style={{
                  backgroundImage: `url(${item.advertImgCard.advertManImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img src={item.advertImgCard.textVitality} alt="textVitality" />
                <h5>{item.advertImgCard.title}</h5>
                <div className="advert-fitness-card-btn">
                  <img src={item.advertImgCard.iconBtn} alt="iconBtn" />
                  <span className="pl-2">{item.advertImgCard.gotoText}</span>
                </div>
              </div>

              <div className="d-flex justify-content-between motorBannerCardLayout">
                <div>
                  <div className="motor-BannerCard-container-one">
                    <div className="motor-Banner-Card-one">
                      <img src={AccidentIcon} className="img-fluid pb-2" alt=" AccidentIcon" />
                      <p className="fs-10 fw-700 motorBanner-card-Title-one m-0">{t("Assist in Accident")}</p>
                      <img src={gotoiconOffwhite} className="img-fluid pt-1" alt="icon" />
                    </div>
                  </div>

                  <div className="motor-BannerCard-container-two">
                    <div className="motor-Banner-Card-two">
                      <img src={userCareAccident} className="img-fluid pb-2" alt="user Care Accident" />
                      <p className="fs-10 fw-700 motorBanner-card-Title-two m-0">{t("Hire Car Facility")}</p>
                      <img src={gotoiconOffwhite} className="img-fluid pt-1" alt="icon" />
                    </div>
                  </div>
                </div>
                <div className="advert-card-hand">
                  <img src={item.appadverthand} alt="appadverthand" />
                </div>
                <div>
                  <div className="motor-BannerCard-container-three">
                    <div className="motor-Banner-Card-three">
                      <img src={userCareAccident} className="img-fluid pb-2" alt="userCareAccident" />
                      <p className="fs-10 fw-700 motorBanner-card-Title-three m-0">{t("Assist in medical")}</p>
                      <img src={gotoiconOffwhite} className="img-fluid pt-1" alt="icon" />
                    </div>
                  </div>

                  <div className="motor-BannerCard-container-four">
                    <div className="motor-Banner-Card-four">
                      <img src={RoadSideAssitance} className="img-fluid pb-2" alt="RoadSideAssitance" />
                      <p className="fs-10 fw-700 motorBanner-card-Title-four m-0">
                        {t("Roadside assistance")}
                      </p>
                      <img src={gotoiconOffwhite} className="img-fluid pt-1" alt="icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img className="appAdvertTopLogo" src={appAdvertTopLogo} alt="appAdvertTopLogo" />
            {/* <img className="appAdvertBtmLogo" src={appAdvertBtmLogo} alt="" /> */}
          </div>
        );
      })}
      {/* {!hideReport && <ReportViolation isLayout={false} isMotor={true} cardWidth="motorReportCard" />} */}
    </div>
  );
};
