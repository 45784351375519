import React, { useEffect, useState } from "react";
import { history } from "service/helpers";
import { Card } from "react-bootstrap";
import Phone from "assets/svg/phone.svg";
import Chat from "assets/svg/chaticon.svg";
import LocationMap from "assets/svg/locationMap.svg";
import Communication from "assets/svg/communication.svg";
import "./style.scss";
import { SubReportViolation } from "./SubReportViolation";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import ViolationFormDesktop from "../../../common/ViolationFormDesktop";

const ReportViolation = ({ isLayout, reportViolation, cardWidth, isMotor = false }) => {
    const [hideForm, setHideForm] = useState(false);
    const mailto = "mailto:Care@tawuniya.com.sa";
    const { i18n, t } = useTranslation();
    useEffect(() => {
        const routeLink = history.location.pathname;
        if (routeLink === "/individuals/customer-service/violations") {
            setHideForm(true);
        }
    }, [hideForm]);

    const handleLinkAction = (url) => {
        window.open(url);
    };

    return (
        <div className="container-fluid" style={{ marginBottom: "30px" }}>
            {hideForm && (
                <div className="reporting_channels">
                    <h5>{t("Reporting Channels")}</h5>
                    <span>{t("Contact the legendary support team right now")}</span>
                </div>
            )}
            <>

                <Card className="medical-card" id={cardWidth}>
                    {isMotor ? (
                        <div className="container-fluid">
                            <div className="justify-content-center report-support-box">
                                <Box
                                    className="d-flex cursor-pointer"
                                    onClick={() => handleLinkAction(`tel:00966920019990`)}
                                >
                                    <div className="mt-3 pr-4">
                                        <img src={Phone} alt="Phone" />
                                    </div>
                                    <div>
                                        <p className="mainBannerPara text-left medical-ptag m-0 pt-3 fs-14 fw-400">
                                            {t("reportViolation.CallCenter")}
                                        </p>
                                        <h6 className="mainBannerHeading fs-18 fw-700" dir="ltr">
                                            800 124 9990
                                        </h6>
                                    </div>
                                </Box>
                                <div className="px-4">
                                    <div className="vertical-lines"></div>
                                </div>
                                <Box
                                    className="d-flex cursor-pointer"
                                    onClick={() => handleLinkAction(`https://wa.me/00966920019990`)}
                                >
                                    <div className="mt-3 pr-4">
                                        <img src={Chat} alt="Chat" />
                                    </div>
                                    <div className="chat-opt">
                                        <div className="chat-app">
                                            <p className="mainBannerPara text-left medical-ptag m-0 pt-3 fs-14 fw-400">
                                                {t("Whatsapp")}
                                            </p>
                                            <h6 className="mainBannerHeading text-left fs-18 fw-700 mb-0" dir="ltr">
                                                9200 19990
                                            </h6>
                                            <p className="mainBannerPara fs-14 fw-400">{t("This is a chat only number")}</p>
                                        </div>
                                        <div className="live-chat">
                                            <p className="mainBannerPara text-left medical-ptag pt-3 m-0 fs-14 fw-400">
                                                {" "}
                                                {t("Chat with our executives")}
                                            </p>
                                            <h6 className="mainBannerHeading fs-18 fw-700 mb-0">
                                                {t("Start Live Chat")}
                                                <label className="online_badge">{t("Online")}</label>
                                            </h6>
                                        </div>
                                    </div>
                                </Box>
                                <div className="px-4">
                                    <div className="vertical-lines"></div>
                                </div>
                                <Box className="d-flex" onClick={() => handleLinkAction(`mailto:Care@tawuniya.com.sa`)}>
                                    <div className="mt-3 pr-4">
                                        <img
                                            src={Communication}
                                            className="cursor-pointer"
                                            alt="email"
                                            onClick={(e) => {
                                                window.location = mailto;
                                                e.preventDefault();
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <p className="mainBannerPara text-left medical-ptag pt-3 m-0 fs-14 fw-400">
                                            {t("Email")}
                                        </p>
                                        <h6
                                            className="mainBannerHeading fs-18 fw-600 cursor-pointer"
                                            onClick={(e) => {
                                                window.location.href = mailto;
                                                e.preventDefault();
                                            }}
                                        >
                                            Care@tawuniya.com.sa
                                        </h6>
                                    </div>
                                </Box>
                            </div>
                        </div>
                    ) : (
                        <div className="container-fluid">
                            <div className="d-flex justify-content-center report-support-box">
                                <div className="row mt-3 mb-3">
                                    <div className="col-3">
                                        <Box className="d-flex cursor-pointer card-width" onClick={() => handleLinkAction(`https://wa.me/00966920019990`)}>
                                            <div className="mt-3 pr-4">
                                                <img src={Phone} alt="Chat" />
                                            </div>
                                            <div>
                                                <p className="mainBannerPara text-left medical-ptag m-0 pt-3 fs-14 fw-400">
                                                    {t("voilationData.mobileNumberTitle")}
                                                </p>
                                                <h6 className="mainBannerHeading text-left fs-18 mt-3 fw-700 mb-0" dir="ltr">
                                                    {t("voilationData.mobileNumber")}
                                                </h6>
                                                <h6 className="mainBannerHeading text-left fs-18 mt-2 fw-700 mb-0" dir="ltr">
                                                    {t("voilationData.workingHours")}
                                                </h6>
                                                <h6 className="mainBannerHeading text-left fs-18 mt-2 fw-700 mb-0" dir="ltr">
                                                    {t('voilationData.workingHoursTime')}
                                                </h6>
                                            </div>
                                        </Box>
                                    </div>
                                    <div className="px-4 col-1">
                                        <div className="vertical-lines"></div>
                                    </div>
                                    <div className="col-4">
                                        <Box className="d-flex cursor-pointer card-width" onClick={() => handleLinkAction(`tel:00966920019990`)}>
                                            <div className="mt-3 pr-4">
                                                <img src={LocationMap} alt="Phone" />
                                            </div>
                                            <div>
                                                <p className="mainBannerPara text-left medical-ptag m-0 pt-3 fs-14 fw-400">
                                                    {t('voilationData.postalAdressTitle')}
                                                </p>
                                                <h6 className="mainBannerHeading fs-18 fw-700 mt-3" dir="ltr">
                                                    {t('voilationData.postalAdress')}
                                                </h6>
                                            </div>
                                        </Box>
                                    </div>
                                    <div className="px-3 col-1">
                                        <div className="vertical-lines"></div>
                                    </div>
                                    <div className="col-3">
                                        <Box className="d-flex cursor-pointer card-width" onClick={() => handleLinkAction(`mailto:TAJAWUZAT@tawuniya.com.sa`)}>
                                            <div className="mt-3 pr-4">
                                                <img src={Communication} className="cursor-pointer" alt="email" />
                                            </div>
                                            <div>
                                                <p className="mainBannerPara text-left medical-ptag pt-3 m-0 fs-14 fw-400">
                                                    {t("voilationData.email")}
                                                </p>
                                                <h6 className="mainBannerHeading fs-18 mt-3 fw-600 cursor-pointer">TAJAWUZAT@tawuniya.com.sa</h6>
                                            </div>
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </Card>

                <SubReportViolation />

            </>
            <>

                <React.Fragment>
                    <div className="row">
                        <div className="col-12 pt-4">
                            <p className="reportViolationTitle fw-800 text-center m-0">
                                {t("reportViolation.reportNow")}
                            </p>
                            <p className="reportViolationPara fs-16 fw-400 text-center m-0">
                                {t("reportViolation.pleaseFillOut")}
                            </p>
                        </div>
                    </div>
                    <div className="row" ref={reportViolation}>
                        <div className="col-12">
                            <ViolationFormDesktop />
                        </div>
                    </div>
                </React.Fragment>

            </>
        </div>
    );
};
export default ReportViolation;
