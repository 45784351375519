import { Box, styled } from "@mui/material";

const MoreProductCardWrapper = styled(Box)`
	* {
	}
	.corporate-moreProduct-title {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		// font-family: "Bliss 2" !important;
		p {
			margin: 0;
			padding: 0;
		}
	}
	.typesContainer {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: space-between;
		padding: 0 15px;
		&:first-of-type {
			flex: 1.3 !important;
		}
		.typesWrapper {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			gap: 15px;
			width: 100%;
		}
	}
`;
export default MoreProductCardWrapper;
